import { Component, ElementRef, OnInit, Input, NgZone } from '@angular/core';
import { DomSanitizer, SafeStyle } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';
import { AppService } from 'app/services/app';
import { Location } from '@angular/common';
import { Router } from '@angular/router';

import { LogicService } from 'app/services/logic';

type UpdateStatsFieldsValues = 'views' | 'page' | 'section';
type UpdateStatsFields = UpdateStatsFieldsValues[];

type PageSummaryStates = 'new' | 'started' | 'complete';

interface ItemSummary {
  type: string;
  value: any;
}

import {
  Workflow,
  WorkFlowTypes,
  WorkflowSection,
  WorkflowPage,
  WorkflowItem,
  WorkflowItemParams,
  WorkflowState,
  WorkflowTarget,
  WorkflowTargetTypeValues,
  WorkflowTargetOnValues
} from 'app/models/workflow';

interface URLParams {
  workflow?: string;
  section?: string;
  page?: string;
  params?: string
}

@Component( {
  moduleId: module.id,
  selector: 'page-item-content-section-review',
  templateUrl: 'section.review.component.pug',
  styleUrls: [ 'section.review.component.less' ]
} )
export class ViewsWorkflowSectionReviewComponent implements OnInit {

  // public globalState: HashTree<string | number | boolean> = {};
  @Input()
  public section: string = null;

  @Input()
  public workflowTargetState = <WorkflowState>null;

  @Input()
  public workflowDefinition: Workflow = null;

  @Input()
  public go: ( page: WorkflowPage ) => void = null;

  public $el: JQuery;

  constructor( public app: AppService, public logicService: LogicService, public el: ElementRef, public route: ActivatedRoute, public location: Location, public router: Router, public sanitizer: DomSanitizer, public zone: NgZone ) {

    this.$el = $( el.nativeElement );

  }

  public ngOnInit(): void {

    // console.log( 'ViewsWorkflowSectionReviewComponent section', this.section );
    // console.log( 'ViewsWorkflowSectionReviewComponent workflowTargetState', this.workflowTargetState );
    // console.log( 'ViewsWorkflowSectionReviewComponent workflowDefinition', this.workflowDefinition );

  }

  public getSection(): WorkflowSection {

    let output = null;

    if ( !this.workflowDefinition || !Array.isArray( this.workflowDefinition.sections ) ) {
      return output;
    }

    this.workflowDefinition.sections.forEach( ( section ) => {
      if ( output === null && section && section.id === this.section ) {
        output = section;
      }
    } );

    return output;

  }

  public getPages(): WorkflowPage[] {

    let section = this.getSection();

    if ( !section || !Array.isArray( section.pages ) ) {
      return [];
    }

    let inputTypes = [
      'text',
      'textarea',
      'listing-upload',
      'datepicker',
      'dropdown',
      'checkbox'
    ];

    return section.pages.filter( ( page ) => {

      let keepPage = false;

      page.items.forEach( ( item ) => {

        if ( keepPage ) {
          return;
        }

        keepPage = !!item.type && inputTypes.indexOf( item.type ) > -1;

      } );

      return keepPage;

    } );

  }

  public getItems( page: WorkflowPage ): ItemSummary[] {

    let items = [];

    if ( !page || !Array.isArray( page.items ) ) {
      return items;
    }

    page.items.forEach( ( item ) => {

      let target = item.target || null;
      let value = typeof target === 'string' ? this.getTargetValue( target ) : null;
      let type = item.type || null;

      if ( !type ) {
        return;
      }

      switch ( type ) {
        case 'checkbox':
          value = !!value; // clamp to boolean
          break;
        case 'dropdown':
          // NO-OP
          break;
        case 'text':
        case 'textarea':
        case 'datepicker':
          // NO-OP
          break;
        case 'listing-upload':

          if ( typeof value === 'number' && !isNaN( value ) && value > 0 ) {
            if ( value > 1 ) {
              value = `${value} files uploaded`;
            } else {
              value = `1 file uploaded`;
            }
          } else {
            value = `No files uploaded`;
          }

          break;
        default:
          return;
      }

      items.push( {
        type: type,
        value: value
      } );
    } );

    return items;

  }

  public pageTitle( page: WorkflowPage ): string {
    return page.title || page.id || '';
  }

  public editTitle( page: WorkflowPage ): string {
    let state = this.pageState( page );

    if ( state === 'new' ) {
      return 'Start';
    } else if ( state === 'started' ) {
      return 'Continue';
    }

    return 'Edit';

  }

  public pageState( page: WorkflowPage ): PageSummaryStates {

    let hasAnswered = false;
    let hasIncomplete = false;

    page.items.forEach( ( item ) => {

      if ( !hasAnswered ) {
        hasAnswered = this.isAnsweredItem( item );
        if ( hasAnswered ) {
          // console.log( 'item', item );
        }
      }

      if ( !hasIncomplete ) {
        hasIncomplete = !this.isCompleteItem( item );
      }

    } );


    if ( !hasAnswered ) {
      return 'new';
    } else if ( !hasIncomplete ) {
      return 'complete';
    }

    return 'started';

  }

  public isAnsweredItem( item: WorkflowItem ): boolean {

    // if not an input type, is answered
    if ( !item.hasOwnProperty( 'type' ) || !item.hasOwnProperty( 'target' ) || item.allowBlank === true ) {
      return true;
    }

    let currentVal = this.getTargetValue( item.target );

    if ( item.target === 'data.generalBusinessDescription' || item.target === 'data.generalReasonForSelling' ) {
      // console.log( 'isAnsweredItem value', item, JSON.stringify( currentVal ) );
    }

    if ( currentVal === null || currentVal === undefined ) {
      if ( item.target === 'data.generalBusinessDescription' || item.target === 'data.generalReasonForSelling' ) {
        // console.log( 'isAnsweredItem null|undefined', item, JSON.stringify( currentVal ) );
      }
      return false;
    }

    if ( typeof currentVal === 'string' ) {
      if ( item.target === 'data.generalBusinessDescription' || item.target === 'data.generalReasonForSelling' ) {
        // console.log( 'isAnsweredItem string', item, JSON.stringify( currentVal ) );
      }
      return currentVal.trim().length > 0;
    }

    if ( typeof currentVal === 'number' ) {
      if ( item.target === 'data.generalBusinessDescription' || item.target === 'data.generalReasonForSelling' ) {
        // console.log( 'isAnsweredItem number', item, JSON.stringify( currentVal ) );
      }
      return currentVal !== 0;
    }

    if ( typeof currentVal === 'boolean' ) {
      if ( item.target === 'data.generalBusinessDescription' || item.target === 'data.generalReasonForSelling' ) {
        // console.log( 'isAnsweredItem boolean', item, JSON.stringify( currentVal ) );
      }
      return true;
    }

    if ( isNaN( currentVal ) ) {
      return false;
    }

    return !!currentVal;

  }

  public isCompleteItem( item: WorkflowItem ): boolean {

    if ( !this.isAnsweredItem( item ) ) {
      return false;
    }

    // if no completionCheck, item is always complete
    if ( !item.hasOwnProperty( 'completeCheck' ) ) {
      return true;
    }

    let logic = item.completeCheck;

    if ( typeof logic === 'string' ) {

      let regex = logic;

      if ( !regex.match( /^\// ) ) {
        regex = '/' + regex + '/';
      }

      logic = {
        '$regex': [
          regex,
          item.target
        ]
      };

    }

    return this.logicService.eval( this.workflowTargetState, logic );

  }

  public getTargetValue( target: string ): any {
    return this.logicService.getDataValue( this.workflowTargetState, target );
  }

  public goToPage( page: WorkflowPage ): void {

    // console.log( 'go this page', page );
    if ( typeof this.go === 'function' ) {
      this.go( page );
    }

  }

}
