import { OnInit, OnDestroy, ElementRef, NgZone, ChangeDetectorRef } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { FormBuilder, Validators } from '@angular/forms';
import { Location } from '@angular/common';
import { DomSanitizer } from '@angular/platform-browser';
import { AppService } from 'app/services/app';
import * as moment from 'moment-timezone';
var ViewsArticleEditorComponent = /** @class */ (function () {
    function ViewsArticleEditorComponent(app, route, router, formBuilder, el, location, sanitizer, zone, cdRef) {
        var _this = this;
        this.app = app;
        this.route = route;
        this.router = router;
        this.formBuilder = formBuilder;
        this.el = el;
        this.location = location;
        this.sanitizer = sanitizer;
        this.zone = zone;
        this.cdRef = cdRef;
        this.authenticatedProfile = null;
        this.stateArticleId = null;
        this.saving = false;
        // public listingDocuments: ListingFile[] = null;
        this.articleDocumentsLoading = false;
        this.listings = null;
        this.listingsLoading = false;
        this.articleHistory = null;
        this.article = null;
        this.articleEdited = null;
        this.profiles = null;
        this.profilesById = {};
        this.selectedVersion = '-1';
        this.accessLevelUserSelected = null;
        this.fixedMenu = false;
        this.dirty = false;
        this.saveMessage = null;
        this.saveMessageTimeout = null;
        this.$el = null;
        this.subscriptions = [];
        this.submenuDataMode = false;
        this.authors = null;
        this.legalDocuments = null;
        this.questionnaires = null;
        this.articleDocuments = null;
        this.generalDirtyCheckHandler = null;
        this.loadingDocuments = true;
        this.bannerLayoutValues = [
            ['blank', 'Blank'],
            ['standard', 'Standard'],
            ['centeredNoSubTitle', 'Centered, No Sub-Title'],
            ['centeredNoPreTitle', 'Centered, No Pre-Title'],
            ['testimonial', 'Testimonial'],
            ['testimonialCentered', 'Testimonial, Centered']
        ];
        // Forms
        this.editorMenuGeneralForm = null;
        this.editorMenuComponentsInitialized = false;
        this.editorMenuComponents = [
            ['text', 'Text', 'glyphicon-font'],
            ['heading', 'Heading', 'glyphicon-header'],
            ['img', 'Image', 'glyphicon-picture'],
            ['vimeo', 'Vimeo', 'glyphicon-facetime-video'],
            ['hr', 'Horizontal Rule', 'glyphicon-resize-horizontal'],
            ['swot', 'SWOT', 'glyphicon-random'],
            ['exio-score', 'Scores', 'glyphicon-align-left'],
            // [ 'vr', 'Vertical Rule', 'glyphicon-resize-vertical' ],
            ['carousel', 'Carousel', 'glyphicon-blackboard'],
            ['grid', 'Grid', 'glyphicon-th'],
            ['leadership', 'Leadership', 'glyphicon-user'],
            ['feature', 'Feature', 'glyphicon-list'],
        ];
        this.bannerPositionValues = [
            'top left',
            'top center',
            'top right',
            'center left',
            'center center',
            'center right',
            'bottom left',
            'bottom center',
            'bottom right'
        ];
        this.bannerOpacityValues = [];
        this.sortHintValues = [];
        this.app.contentLoading(true);
        this.app.toolbar.whiteOverContent = false;
        this.app.toolbar.backgroundColor = null;
        for (var i = 0; i <= 100; i++) {
            this.sortHintValues.push("" + i);
        }
        for (var i = 0; i <= 100; i += 5) {
            this.bannerOpacityValues.push("" + i);
        }
        this.$el = $(this.el.nativeElement);
        this.zone.runOutsideAngular(function () {
            var observer = new MutationObserver(function () {
                _this.watchDom();
            });
            observer.observe(_this.el.nativeElement, {
                childList: true,
                attributes: false,
                characterData: false,
                subtree: true,
                attributeOldValue: false,
                characterDataOldValue: false
            });
        });
    }
    ViewsArticleEditorComponent.prototype.ngOnDestroy = function () {
        this.subscriptions.forEach(function (subscription) {
            subscription.unsubscribe();
        });
    };
    ViewsArticleEditorComponent.prototype.refreshDocuments = function () {
        this.loadDocuments(true);
    };
    ViewsArticleEditorComponent.prototype.setSaveMessage = function (message) {
        var _this = this;
        this.saveMessage = message;
        if (this.saveMessageTimeout) {
            window.clearTimeout(this.saveMessageTimeout);
            this.saveMessageTimeout = null;
        }
        if (message) {
            this.saveMessageTimeout = window.setTimeout(function () {
                _this.saveMessageTimeout = null;
                _this.saveMessage = null;
            }, 10000);
        }
    };
    ViewsArticleEditorComponent.prototype.watchDom = function () {
        var _this = this;
        this.zone.runOutsideAngular(function () {
            _this.setupScrollMenuFix();
            _this.cdRef.markForCheck();
        });
        if (!this.editorMenuComponentsInitialized) {
            var $editorMenuComponents_1 = this.$el
                .find('.editor-components-menu')
                .find('.editor-component.content-type:not(.ui-draggable)');
            if ($editorMenuComponents_1.length >= this.editorMenuComponents.length) {
                this.editorMenuComponentsInitialized = true;
                this.zone.runOutsideAngular(function () {
                    $editorMenuComponents_1
                        .draggable({
                        helper: 'clone',
                        'start': function () {
                            _this.zone.run(function () {
                                _this.app.content.dragging = true;
                                _this.cdRef.markForCheck();
                            });
                        },
                        'stop': function () {
                            window.setTimeout(function () {
                                _this.zone.run(function () {
                                    _this.app.content.dragging = false;
                                    _this.editorDirtyCheck();
                                    _this.cdRef.markForCheck();
                                });
                            });
                        }
                    });
                });
            }
        }
        this.zone.runOutsideAngular(function () {
            if (_this.articleEdited && _this.articleEdited.sections) {
                var sectionsDeleted_1 = false;
                _this.articleEdited.sections.forEach(function (section) {
                    if (sectionsDeleted_1 || section.delete === true) {
                        sectionsDeleted_1 = true;
                        return;
                    }
                });
                if (sectionsDeleted_1) {
                    window.setTimeout(function () {
                        _this.articleEdited.sections = _this.articleEdited.sections.filter(function (section) {
                            return !section.delete;
                        });
                        _this.zone.run(function () {
                            _this.editorDirtyCheck();
                            _this.cdRef.markForCheck();
                        });
                    });
                }
            }
        });
        this.editorDirtyCheck();
    };
    ViewsArticleEditorComponent.prototype.listingSelectChanged = function () {
        // let listingId = this.getSelectedListingId();
        //
        // if ( this.articleEdited && listingId ) {
        //
        // 	if ( !this.articleEdited.data ) {
        // 		this.articleEdited.data = {};
        // 	}
        //
        // 	// if ( !Array.isArray( this.articleEdited.data.listingDocuments ) ) {
        // 	// 	this.articleEdited.data.listingDocuments = [];
        // 	// }
        //
        // 	// this.zone.run( () => {
        // 	// 	this.articleDocumentsLoading = true;
        // 	// 	this.cdRef.markForCheck();
        // 	// } );
        // 	// this.articleDocumentsLoading = true;
        //
        // 	this.app.listingModel.listPublishedUploads( { listingId: listingId, path: '/' } )
        // 		.then( ( listingDocuments: ListingFile[] ) => {
        // 			this.zone.run( () => {
        //
        // 				this.articleDocumentsLoading = false;
        //
        // 				let articleDocumentIndex = {};
        // 				let resultsDocumentIndex = {};
        //
        // 				listingDocuments = listingDocuments.filter( ( document ) => {
        //
        // 					if ( document && document.id ) {
        // 						resultsDocumentIndex[ document.id ] = document;
        // 						return true;
        // 					}
        //
        // 					return false;
        //
        // 				} );
        //
        // 				if ( !Array.isArray( this.articleEdited.data.listingDocuments ) ) {
        // 					this.articleEdited.data.listingDocuments = [];
        // 				}
        //
        // 				this.articleEdited.data.listingDocuments = (<ListingFile[]>this.articleEdited.data.listingDocuments).filter( ( document ) => {
        //
        // 					if ( document && document.id && resultsDocumentIndex.hasOwnProperty( document.id ) ) {
        //
        // 						articleDocumentIndex[ document.id ] = document;
        //
        // 						document.name = resultsDocumentIndex[ document.id ].name;
        // 						document.webViewLink = resultsDocumentIndex[ document.id ].webViewLink;
        // 						document.downloadLink = resultsDocumentIndex[ document.id ].downloadLink;
        //
        // 						return true;
        // 					}
        //
        // 					return false;
        //
        // 				} );
        //
        // 				listingDocuments.forEach( ( document ) => {
        //
        // 					if ( !articleDocumentIndex.hasOwnProperty( document.id ) ) {
        //
        // 						document.accessLevel = 'exclusive';
        // 						articleDocumentIndex[ document.id ] = document;
        // 						(<ListingFile[]>this.articleEdited.data.listingDocuments).push( document );
        // 					}
        //
        // 				} );
        //
        // 				(<ListingFile[]>this.articleEdited.data.listingDocuments).sort( ( a, b ) => {
        // 					if ( a.name < b.name ) return -1;
        // 					if ( a.name > b.name ) return 1;
        // 					return 0;
        // 				} );
        //
        // 				this.cdRef.markForCheck();
        //
        // 			} );
        // 		} )
        // 		.catch( ( e ) => {
        // 			console.error( 'Error: listPublishedUploads', e );
        // 		} );
        //
        // } else {
        //
        // 	if ( this.articleEdited && this.articleEdited.data && this.articleEdited.data.listingDocuments ) {
        // 		this.articleEdited.data.listingDocuments = [];
        // 	}
        //
        // }
    };
    ViewsArticleEditorComponent.prototype.fileAccessChanged = function () {
        this.editorDirtyCheck();
    };
    ViewsArticleEditorComponent.prototype.articleCount = function () {
        var list = this.getArticleDocuments();
        return list === null ? -1 : list.length;
    };
    ViewsArticleEditorComponent.prototype.getArticleDocuments = function () {
        if (!this.articleEdited) {
            return null;
        }
        if (!this.articleEdited.data) {
            this.articleEdited.data = {};
        }
        if (!Array.isArray(this.articleEdited.data.documents)) {
            this.articleEdited.data.documents = [];
        }
        return this.articleEdited.data.documents;
    };
    // public getListingDocuments(): ListingFile[] {
    //
    // 	if ( this.articleEdited.data && this.articleEdited.data.listingDocuments ) {
    // 		return <ListingFile[]>this.articleEdited.data.listingDocuments;
    // 	}
    //
    // 	return [];
    // }
    ViewsArticleEditorComponent.prototype.getSelectedListingId = function () {
        if (this.articleEdited &&
            (this.articleEdited.type === 'listing' ||
                this.articleEdited.type === 'buyer') &&
            this.articleEdited.data &&
            this.articleEdited.data.listingId !== 'none') {
            return this.articleEdited.data.listingId;
        }
        return null;
    };
    ViewsArticleEditorComponent.prototype.getListings = function () {
        var _this = this;
        if (this.listings === null && !this.listingsLoading) {
            this.listingsLoading = true;
            this.cdRef.markForCheck();
            this.app.listingModel.query({ deleted: false })
                .then(function (listings) {
                _this.zone.run(function () {
                    _this.listingsLoading = false;
                    _this.listings = listings;
                    _this.cdRef.markForCheck();
                });
            })
                .catch(function (e) {
                console.error('error querying listings', e);
            });
        }
        return this.listings;
    };
    ViewsArticleEditorComponent.prototype.updateAuthor = function () {
        if (!this.authenticatedProfile || !this.articleEdited) {
            return;
        }
        if (typeof this.articleEdited.author !== 'string' || this.articleEdited.author.trim().length < 1) {
            this.articleEdited.author = this.authenticatedProfile.id;
        }
    };
    ViewsArticleEditorComponent.prototype.ngOnInit = function () {
        var _this = this;
        document.body.scrollTop = 0;
        this.app.questionnaireModel.query()
            .then(function (questionnaires) {
            _this.questionnaires = questionnaires.map(function (questionnaire) {
                return [questionnaire.id, questionnaire.title];
            });
            // alphabetize list by display value
            _this.questionnaires.sort(function (a, b) {
                if (a[1] < b[1]) {
                    return -1;
                }
                if (b[1] < a[1]) {
                    return 1;
                }
                return 0;
            });
        })
            .catch(function (e) {
            console.error('failed to load questionnaires');
        });
        this.app.legalDocumentModel.list()
            .then(function (legalDocuments) {
            _this.legalDocuments = legalDocuments.map(function (legalDocument) {
                return [legalDocument.id, legalDocument.name];
            });
            // alphabetize list by display value
            _this.legalDocuments.sort(function (a, b) {
                if (a[1] < b[1]) {
                    return -1;
                }
                if (b[1] < a[1]) {
                    return 1;
                }
                return 0;
            });
        })
            .catch(function (e) {
            console.error('failed to load legal documents', e);
        });
        this.app.profileModel.listEmployees()
            .then(function (profiles) {
            _this.authors = profiles.map(function (profile) {
                return [
                    profile.id,
                    profile.lastName + ", " + profile.firstName + " <" + profile.email + ">"
                ];
            });
            // alphabetize list by display value
            _this.authors.sort(function (a, b) {
                if (a[1] < b[1]) {
                    return -1;
                }
                if (b[1] < a[1]) {
                    return 1;
                }
                return 0;
            });
        })
            .catch(function (e) {
            console.error('failed to load employee profiles', e);
        });
        if (this.profiles === null) {
            this.profiles = [];
            this.app.profileModel.list()
                .then(function (profiles) {
                _this.zone.run(function () {
                    _this.profiles = profiles;
                    profiles.forEach(function (profile) {
                        _this.profilesById[profile.id] = profile;
                    });
                    _this.cdRef.markForCheck();
                });
            })
                .catch(function (e) {
                console.error('error listing profiles', e);
            });
        }
        this.subscriptions.push(this.app.getAuthenticatedProfile({
            next: function (profile) {
                if (typeof profile === 'boolean' && !profile) {
                    _this.app.contentLoading(true);
                }
                else if (!profile || !profile.isEmployee) {
                    _this.router.navigate(['/']);
                }
                else {
                    _this.updateAuthor();
                    // init with all articles
                    _this.subscriptions.push(_this.route.params.subscribe(function (params) {
                        if (params && params.articleId) {
                            _this.resetArticle(params.articleId === 'copy' ? 'new' : params.articleId, params.copyArticleId || null);
                        }
                    }));
                }
            },
            error: function () {
                _this.router.navigate(['/']).then();
            },
            complete: function () {
                _this.router.navigate(['/']).then();
            }
        }));
    };
    ViewsArticleEditorComponent.prototype.isFixedComponentMenu = function () {
        return this.fixedMenu;
    };
    ViewsArticleEditorComponent.prototype.setupScrollMenuFix = function () {
        var _this = this;
        var $editorComponentsMenu = this.$el.find('.editor-components-menu');
        if ($editorComponentsMenu.length < 1 || $editorComponentsMenu.hasClass('ui-scroll-top-menu')) {
            return;
        }
        $editorComponentsMenu.addClass('ui-scroll-top-menu');
        var $editorComponentsMenuPlaceHolder = this.$el.find('.editor-components-menu-placeholder');
        var $window = $(window);
        var menuScrollTop = $editorComponentsMenu.offset().top;
        var triggerScrollHeight = function (windowScrollTop) {
            var fixedMenu = windowScrollTop - menuScrollTop >= -30;
            if (fixedMenu) {
                $editorComponentsMenu.addClass('fixed-menu');
                $editorComponentsMenuPlaceHolder.addClass('fixed-menu');
            }
            else {
                $editorComponentsMenu.removeClass('fixed-menu');
                $editorComponentsMenuPlaceHolder.removeClass('fixed-menu');
            }
            if (fixedMenu !== _this.fixedMenu) {
                _this.zone.run(function () {
                    _this.fixedMenu = fixedMenu;
                    _this.cdRef.markForCheck();
                });
            }
        };
        this.zone.runOutsideAngular(function () {
            $window.scroll(function () {
                triggerScrollHeight($window.scrollTop());
            });
        });
    };
    ViewsArticleEditorComponent.prototype.resetArticle = function (articleId, copyArticleId) {
        var _this = this;
        this.zone.run(function () {
            if (articleId === _this.stateArticleId) {
                return;
            }
            _this.stateArticleId = articleId;
            _this.article = null;
            _this.articleEdited = null;
            if (articleId === 'new' && !copyArticleId) {
                _this.app.contentLoading(false);
                var article = {
                    id: null,
                    path: null,
                    author: null,
                    type: 'post',
                    deleted: false,
                    data: {},
                    published: false,
                    hidden: false,
                    title: null,
                    sortHint: 1,
                    redirectPaths: [],
                    categories: [],
                    actions: [],
                    submenu: [],
                    sections: [],
                    accessLevel: 'public',
                    accessLevelExclusive: [],
                    accessRequirements: {
                        public: null,
                        private: null,
                        exclusive: null
                    }
                };
                _this.article = _this.app.articleModel.clone(article);
                var date = new Date().toISOString();
                var title = 'New Article ' + date;
                var path = title.toLowerCase().replace(/ /g, '-');
                article.title = title;
                article.path = path;
                _this.articleEdited = _this.app.articleModel.clone(article);
                _this.renderEditor();
            }
            else {
                var copy_1 = false;
                if (articleId === 'new') {
                    copy_1 = true;
                    articleId = copyArticleId;
                }
                _this.app.contentLoading(true);
                _this.app
                    .articleModel
                    .get(articleId)
                    .then(function (article) {
                    _this.app.contentLoading(false);
                    if (article) {
                        if (!article.data) {
                            article.data = {};
                        }
                        if (article.created) {
                            article.created = moment(article.created).format('MM/DD/YYYY');
                        }
                        else {
                            article.created = moment().format('MM/DD/YYYY');
                        }
                        _this.article = _this.app.articleModel.clone(article);
                        // don't clone the VDR link for the previous listing
                        if (copyArticleId && _this.article.data) {
                            delete _this.article.data.vdrUrl;
                        }
                        _this.articleEdited = _this.app.articleModel.clone(article);
                        _this.loadDocuments();
                        if (copy_1) {
                            _this.location.replaceState('/articles-editor/new');
                            _this.stateArticleId = null;
                            _this.article.id = null;
                            _this.articleEdited.id = null;
                            _this.articleEdited.title += ' Copy';
                            _this.articleEdited.path += '-copy';
                            _this.articleEdited.published = false;
                            _this.articleEdited.hidden = false;
                            _this.articleEdited.accessLevelExclusive = [];
                            _this.articleEdited.accessLevelPrivate = [];
                        }
                        // this.listingSelectChanged();
                    }
                    _this.renderEditor();
                })
                    .catch(function () {
                    _this.renderEditor();
                });
                if (copy_1) {
                    articleId = 'new';
                }
            }
            _this.cdRef.markForCheck();
        });
    };
    ViewsArticleEditorComponent.prototype.loadDocuments = function (cacheBust) {
        var _this = this;
        this.loadingDocuments = true;
        this.app
            .articleModel
            .listPublishedUploads(this.article.id, !!cacheBust)
            .then(function (articleDocuments) {
            _this.articleDocumentsLoading = false;
            var articleDocumentIndex = {};
            var resultsDocumentIndex = {};
            articleDocuments = articleDocuments.filter(function (document) {
                if (document && document.id) {
                    resultsDocumentIndex[document.id] = document;
                    return true;
                }
                return false;
            });
            _this.articleEdited.data.documents = _this.articleEdited.data.documents.filter(function (document) {
                if (document && document.id && resultsDocumentIndex.hasOwnProperty(document.id)) {
                    articleDocumentIndex[document.id] = document;
                    document.name = resultsDocumentIndex[document.id].name;
                    document.webViewLink = resultsDocumentIndex[document.id].webViewLink;
                    document.webContentLink = resultsDocumentIndex[document.id].webContentLink;
                    document.parentId = resultsDocumentIndex[document.id].parentId;
                    delete document.downloadLink;
                    return true;
                }
                return false;
            });
            articleDocuments.forEach(function (document) {
                if (!articleDocumentIndex.hasOwnProperty(document.id)) {
                    document.accessLevel = 'exclusive';
                    articleDocumentIndex[document.id] = document;
                    _this.articleEdited.data.documents.push(document);
                }
            });
            _this.articleEdited.data.documents.sort(function (a, b) {
                if (a.name < b.name) {
                    return -1;
                }
                if (a.name > b.name) {
                    return 1;
                }
                return 0;
            });
            _this.cdRef.markForCheck();
            _this.loadingDocuments = false;
        })
            .catch(function (e) {
            console.error('error loading documents', e);
            _this.loadingDocuments = false;
        });
    };
    ViewsArticleEditorComponent.prototype.renderEditor = function () {
        var _this = this;
        this.updateAuthor();
        if (this.article &&
            typeof this.article.id === 'string' &&
            this.article.id.length > 0 &&
            this.article.id !== 'new' &&
            this.article.id !== this.stateArticleId) {
            this.location.replaceState('/articles-editor/' + this.article.id);
        }
        this.app.articleModel.getVersions(this.article.id)
            .then(function (articleHistory) {
            if (Array.isArray(articleHistory)) {
                _this.zone.run(function () {
                    _this.articleHistory = articleHistory;
                    _this.cdRef.markForCheck();
                });
            }
        })
            .catch(function (e) {
            console.error('error listing article versions', e);
        });
        this.renderEditorMenu();
    };
    ViewsArticleEditorComponent.prototype.generalChangeDetection = function () {
        this.editorDirtyCheck();
    };
    ViewsArticleEditorComponent.prototype.renderEditorMenu = function () {
        this.renderEditorMenuGeneral();
    };
    ViewsArticleEditorComponent.prototype.renderEditorMenuGeneral = function () {
        var _this = this;
        this.zone.run(function () {
            if (!_this.articleEdited) {
                _this.editorMenuGeneralForm = null;
                return;
            }
            _this.editorMenuGeneralForm = _this.formBuilder.group({
                'listingBuyerFee': [
                    _this.articleEdited.data.listingBuyerFee || 0, []
                ],
                'listingCompanyName': [
                    _this.articleEdited.data.listingCompanyName || null, []
                ],
                'listingCompanyIndustry': [
                    _this.articleEdited.data.listingCompanyIndustry || null, []
                ],
                'projectCodeName': [
                    _this.articleEdited.data.projectCodeName || null, []
                ],
                'buyerInterestsByState': [
                    _this.articleEdited.data.buyerInterestsByState || null, []
                ],
                'buyerInterestsPriceMin': [
                    _this.articleEdited.data.buyerInterestsPriceMin || null, []
                ],
                'buyerInterestsPriceMax': [
                    _this.articleEdited.data.buyerInterestsPriceMax || null, []
                ],
                'listingPrice': [
                    _this.articleEdited.data.listingPrice || null, []
                ],
                'exioScore': [
                    _this.articleEdited.data.exioScore || null, []
                ],
                'naicsCode': [
                    _this.articleEdited.data.naicsCode || null, []
                ],
                'buyerInterestsNAICS': [
                    _this.articleEdited.data.buyerInterestsNAICS || null, []
                ],
                'listingLocation': [
                    _this.articleEdited.data.listingLocation || null, []
                ],
                'listingLocationState': [
                    _this.articleEdited.data.listingLocationState || null, []
                ],
                'listingLink': [
                    _this.articleEdited.data.listingId || 'none', []
                ],
                'published': [
                    _this.articleEdited.published, []
                ],
                'hidden': [
                    _this.articleEdited.hidden, []
                ],
                'path': [
                    _this.articleEdited.path, [
                        Validators.required,
                        Validators.pattern(/^(https?:\/\/)?([\da-z\.-]+)\.([a-z\.]{2,6})([\/\w \.-]*)*\/?$/)
                    ]
                ],
                'type': [
                    _this.articleEdited.type, [
                        Validators.required,
                        Validators.pattern(/^video|listing|post$/)
                    ]
                ],
                'categories': _this.formBuilder.array([]),
                'redirectPaths': _this.formBuilder.array([]),
                'created': [
                    _this.articleEdited.created, [
                        Validators.required
                    ]
                ], 'title': [
                    _this.articleEdited.title, [
                        Validators.required,
                        Validators.pattern(/^[a-zA-Z0-9 _\-\*]+$/)
                    ]
                ],
                'tagline': [
                    _this.articleEdited.tagline, [
                        Validators.required,
                        Validators.pattern(/^[a-zA-Z0-9 _\-\*]+$/)
                    ]
                ],
                'description': [
                    _this.articleEdited.description, [
                        Validators.required,
                        Validators.pattern(/^[a-zA-Z0-9 _\-\*\n]+$/)
                    ]
                ],
                'sortHint': [
                    _this.articleEdited.sortHint, [
                        Validators.required,
                        Validators.pattern(/^[0-9]+$/)
                    ]
                ],
                'author': [
                    _this.articleEdited.author, []
                ],
                'nda': [
                    _this.articleEdited.nda, []
                ],
                'formId': [
                    _this.articleEdited.formId || null, []
                ],
                'tileImgUrl': [
                    _this.articleEdited.tileImgUrl, [
                        Validators.pattern(/^(?:\s*[a-zA-Z0-9\-]+\s*,\s*)*(?:\s*[a-zA-Z0-9\-]+\s*)$/)
                    ]
                ],
                'tileImgLargeUrl': [
                    _this.articleEdited.tileImgLargeUrl, [
                        Validators.pattern(/^(?:\s*[a-zA-Z0-9\-]+\s*,\s*)*(?:\s*[a-zA-Z0-9\-]+\s*)$/)
                    ]
                ],
                'bannerOpacity': [
                    _this.articleEdited.bannerOpacity, [
                        Validators.required,
                        Validators.pattern(/^[0-9]*$/)
                    ]
                ],
                'bannerOpacityMobile': [
                    _this.articleEdited.bannerOpacityMobile, [
                        Validators.required,
                        Validators.pattern(/^[0-9]*$/)
                    ]
                ],
                'bannerImgPosition': [
                    _this.articleEdited.bannerImgPosition, []
                ],
                'bannerImgPositionMobile': [
                    _this.articleEdited.bannerImgPositionMobile, []
                ],
                'bannerImgUrl': [
                    _this.articleEdited.bannerImgUrl, [
                        Validators.pattern(/^(?:\s*[a-zA-Z0-9\-]+\s*,\s*)*(?:\s*[a-zA-Z0-9\-]+\s*)$/)
                    ]
                ],
                'bannerImgUrlMobile': [
                    _this.articleEdited.bannerImgUrlMobile, [
                        Validators.pattern(/^(?:\s*[a-zA-Z0-9\-]+\s*,\s*)*(?:\s*[a-zA-Z0-9\-]+\s*)$/)
                    ]
                ],
                'bannerLayout': [
                    _this.articleEdited.bannerLayout || 'blank'
                ],
                'bannerLayoutMobile': [
                    _this.articleEdited.bannerLayoutMobile || 'blank'
                ],
                'actions': _this.formBuilder.array([]),
                'submenu': _this.formBuilder.array([])
            });
            _this.articleEdited.categories.forEach(function (category) {
                if (typeof category === 'string' && category.trim().length > 0) {
                    _this.addCategory(category);
                }
            });
            _this.articleEdited.redirectPaths.forEach(function (redirectPath) {
                if (typeof redirectPath === 'string' && redirectPath.trim().length > 0) {
                    _this.addUrlAlias(redirectPath);
                }
            });
            _this.articleEdited.actions.forEach(function (action) {
                var url = action.actionParams ? action.actionParams.href : _this.articleEdited.path;
                _this.addAction(action.name, url);
            });
            _this.articleEdited.submenu.forEach(function (submenuItem) {
                _this.addSubmenuItem(submenuItem.name, submenuItem.url);
            });
            _this.subscriptions.push(_this.editorMenuGeneralForm.valueChanges.subscribe(function () {
                _this.syncFormToModel(_this.editorMenuGeneralForm);
            }));
            _this.cdRef.markForCheck();
        });
    };
    ViewsArticleEditorComponent.prototype.addAction = function (name, url) {
        if (typeof name !== 'string') {
            name = 'New Action';
        }
        if (typeof url !== 'string') {
            url = this.articleEdited.path || this.article.path || '/';
        }
        var control = this.editorMenuGeneralForm.controls['actions'];
        control.push(this.formBuilder.group({
            name: [name, [Validators.required]],
            url: [url, [Validators.required]]
        }));
    };
    ViewsArticleEditorComponent.prototype.removeAction = function (i) {
        var control = this.editorMenuGeneralForm.controls['actions'];
        control.removeAt(i);
    };
    ViewsArticleEditorComponent.prototype.addSubmenuItem = function (name, url) {
        if (typeof name !== 'string') {
            name = 'New Item';
        }
        if (typeof url !== 'string') {
            url = this.articleEdited.path || this.article.path || '/';
        }
        url = '/' + url.replace(/^\//, '');
        var control = this.editorMenuGeneralForm.controls['submenu'];
        control.push(this.formBuilder.group({
            name: [name, [Validators.required]],
            url: [url, [Validators.required]]
        }));
    };
    ViewsArticleEditorComponent.prototype.removeSubmenuItem = function (i) {
        var control = this.editorMenuGeneralForm.controls['submenu'];
        control.removeAt(i);
    };
    ViewsArticleEditorComponent.prototype.addUrlAlias = function (redirectPath) {
        if (typeof redirectPath !== 'string') {
            redirectPath = '';
        }
        var control = this.editorMenuGeneralForm.controls['redirectPaths'];
        control.push(this.formBuilder.control(redirectPath, [
            Validators.required,
            Validators.pattern(/^(?:\s*[a-zA-Z0-9\-]+\s*,\s*)*(?:\s*[a-zA-Z0-9\-]+\s*)$/)
        ]));
    };
    ViewsArticleEditorComponent.prototype.removeUrlAlias = function (i) {
        var control = this.editorMenuGeneralForm.controls['redirectPaths'];
        control.removeAt(i);
    };
    ViewsArticleEditorComponent.prototype.addCategory = function (category) {
        if (typeof category !== 'string') {
            category = '';
        }
        var control = this.editorMenuGeneralForm.controls['categories'];
        control.push(this.formBuilder.control(category, [
            Validators.required,
            Validators.pattern(/^(?:\s*[a-zA-Z0-9\-]+\s*,\s*)*(?:\s*[a-zA-Z0-9\-]+\s*)$/)
        ]));
    };
    ViewsArticleEditorComponent.prototype.removeCategory = function (i) {
        var control = this.editorMenuGeneralForm.controls['categories'];
        control.removeAt(i);
    };
    ViewsArticleEditorComponent.prototype.backgroundImgUrl = function (url) {
        if (typeof url !== 'string') {
            url = '';
        }
        return "url(\"" + url + "\")";
    };
    ViewsArticleEditorComponent.prototype.syncFormToModel = function (form) {
        var _this = this;
        var _loop_1 = function (field) {
            if (form.value.hasOwnProperty(field)) {
                switch (field) {
                    case 'listingBuyerFee':
                        this_1.articleEdited.data.listingBuyerFee = parseFloat(form.value[field]);
                        break;
                    case 'listingCompanyName':
                        this_1.articleEdited.data.listingCompanyName = form.value[field];
                        break;
                    case 'listingCompanyIndustry':
                        this_1.articleEdited.data.listingCompanyIndustry = form.value[field];
                        break;
                    case 'projectCodeName':
                        this_1.articleEdited.data.projectCodeName = form.value[field];
                        break;
                    case 'buyerInterestsByState':
                        this_1.articleEdited.data.buyerInterestsByState = form.value[field];
                        break;
                    case 'listingLocation':
                        this_1.articleEdited.data.listingLocation = form.value[field];
                        break;
                    case 'listingLocationState':
                        this_1.articleEdited.data.listingLocationState = form.value[field];
                        break;
                    case 'listingPrice':
                        this_1.articleEdited.data.listingPrice = parseInt(form.value[field], 10);
                        break;
                    case 'buyerInterestsPriceMin':
                        this_1.articleEdited.data.buyerInterestsPriceMin = parseInt(form.value[field], 10);
                        break;
                    case 'buyerInterestsPriceMax':
                        this_1.articleEdited.data.buyerInterestsPriceMax = parseInt(form.value[field], 10);
                        break;
                    case 'exioScore':
                        this_1.articleEdited.data.exioScore = parseInt(form.value[field], 10);
                        break;
                    case 'naicsCode':
                        this_1.articleEdited.data.naicsCode = form.value[field];
                        break;
                    case 'buyerInterestsNAICS':
                        this_1.articleEdited.data.buyerInterestsNAICS = form.value[field];
                        break;
                    case 'listingLink':
                        this_1.articleEdited.data.listingId = form.value[field];
                        break;
                    case 'actions':
                        this_1.articleEdited[field] = [];
                        form.value.actions.forEach(function (action) {
                            action.name = action.name.trim();
                            action.url = action.url.trim();
                            if (action.name.length > 0 || action.url.length > 0) {
                                _this.articleEdited[field].push({
                                    name: action.name,
                                    actionType: 'url',
                                    actionParams: {
                                        href: action.url
                                    }
                                });
                            }
                        });
                        break;
                    case 'submenu':
                        this_1.articleEdited[field] = [];
                        form.value.submenu.forEach(function (submenuItem) {
                            submenuItem.name = submenuItem.name.trim();
                            submenuItem.url = submenuItem.url.trim();
                            if (submenuItem.name.length > 0 || submenuItem.url.length > 0) {
                                _this.articleEdited[field].push({
                                    name: submenuItem.name,
                                    url: submenuItem.url
                                });
                            }
                        });
                        break;
                    case 'redirectPaths':
                        this_1.articleEdited[field] = [];
                        form.value.redirectPaths.forEach(function (name) {
                            name = name.toLowerCase().trim();
                            if (name.length > 0 && _this.articleEdited[field].indexOf(name) < 0) {
                                _this.articleEdited[field].push(name);
                            }
                        });
                        break;
                    case 'categories':
                        this_1.articleEdited[field] = [];
                        form.value.categories.forEach(function (name) {
                            name = name.toLowerCase();
                            var parts = name.split('-');
                            parts.forEach(function (part, i) {
                                parts[i] = part.trim();
                            });
                            name = parts.join(' ');
                            if (name.length > 0 && _this.articleEdited[field].indexOf(name) < 0) {
                                _this.articleEdited[field].push(name);
                            }
                        });
                        break;
                    default:
                        this_1.articleEdited[field] = form.value[field];
                        break;
                }
            }
        };
        var this_1 = this;
        for (var field in form.value) {
            _loop_1(field);
        }
        this.editorDirtyCheck();
    };
    ViewsArticleEditorComponent.prototype.getEditorDirtyCheckHandler = function () {
        if (typeof this.generalDirtyCheckHandler !== 'function') {
            var self_1 = this;
            this.generalDirtyCheckHandler = function () {
                self_1.editorDirtyCheck();
            };
        }
        return this.generalDirtyCheckHandler;
    };
    ViewsArticleEditorComponent.prototype.editorDirtyCheck = function () {
        var _this = this;
        if (this.articleEdited && Array.isArray(this.articleEdited.sections)) {
            this.articleEdited.sections.forEach(function (section) {
                if (section.delete) {
                    // console.log( 'section delete 1', section );
                }
            });
        }
        var newState = JSON.stringify(this.article) !== JSON.stringify(this.articleEdited);
        // only trigger timeout if state changed
        if (newState !== this.dirty) {
            window.setTimeout(function () {
                _this.zone.run(function () {
                    _this.dirty = newState;
                    _this.cdRef.markForCheck();
                });
            });
        }
        return newState;
    };
    ViewsArticleEditorComponent.prototype.saveEnabled = function () {
        return this.dirty;
    };
    ViewsArticleEditorComponent.prototype.resetEnabled = function () {
        return this.dirty;
    };
    ViewsArticleEditorComponent.prototype.sanitizeForSave = function (article) {
        article = this.app.articleModel.clone(article);
        if (Array.isArray(article.sections)) {
            article.sections.forEach(function (section) {
                delete section.editing;
            });
        }
        if (typeof article.created === 'string' && article.created.match(/[0-9]+\/[0-9]+\/[0-9]+/)) {
            var parts = article.created.match(/([0-9]+)\/([0-9]+)\/([0-9]+)/);
            article.created = parts[3] + "-" + parts[1] + "-" + parts[2];
        }
        article.created = moment(article.created).toISOString();
        // these fields are managed elsewhere do not overwrite them here when saving.
        delete article.adminUsers;
        delete article.accessLevelExclusive;
        delete article.accessLevelExclusivePending;
        delete article.accessLevelPrivate;
        delete article.accessLevelPrivatePending;
        delete article.accessLevelInvites;
        return article;
    };
    ViewsArticleEditorComponent.prototype.save = function () {
        var _this = this;
        if (this.editorMenuGeneralForm) {
            this.syncFormToModel(this.editorMenuGeneralForm);
        }
        this.saving = true;
        this.app
            .articleModel
            .save(this.sanitizeForSave(this.articleEdited))
            .then(function (article) {
            _this.zone.run(function () {
                _this.saving = false;
                if (article) {
                    if (article.id !== _this.articleEdited.id || article.id !== _this.stateArticleId) {
                        _this.location.replaceState('/articles-editor/' + article.id);
                    }
                }
                _this.articleEdited.id = article.id;
                _this.articleEdited.data = article.data;
                _this.stateArticleId = article.id;
                _this.setReferenceArticle(_this.articleEdited);
                _this.editorDirtyCheck();
                _this.cdRef.markForCheck();
            });
        })
            .catch(function (err) {
            if (err) {
                _this.setSaveMessage(err.message || 'An unknown error occurred');
            }
            _this.zone.run(function () {
                _this.saving = false;
                _this.cdRef.markForCheck();
            });
        });
    };
    ViewsArticleEditorComponent.prototype.setReferenceArticle = function (article) {
        this.article = this.app.articleModel.clone(article);
        if (this.editorDirtyCheck()) {
            this.renderEditor();
        }
    };
    ViewsArticleEditorComponent.prototype.setEditedArticle = function (article) {
        this.articleEdited = this.app.articleModel.clone(article);
        if (this.editorDirtyCheck()) {
            this.renderEditor();
        }
    };
    ViewsArticleEditorComponent.prototype.setArticle = function (article) {
        if (article) {
            this.setReferenceArticle(article);
            this.setEditedArticle(article);
        }
    };
    ViewsArticleEditorComponent.prototype.cancel = function () {
        this.resetArticle(this.article.id);
    };
    ViewsArticleEditorComponent.prototype.viewListingReference = function () {
        var selectedListing = this.$el.find('.article-listing-reference select').val();
        window.open('/action/view-listing/begin/target-init/' + selectedListing, 'Listing Reference');
    };
    ViewsArticleEditorComponent.prototype.viewListingVdr = function () {
        var selectedListing = this.$el.find('.article-listing-reference select').val();
        var listing = null;
        this.listings.forEach(function (listingCheck) {
            if (listingCheck.id === selectedListing) {
                listing = listingCheck;
            }
        });
        if (listing && listing.data && listing.data.vdrUrl) {
            window.open(listing.data.vdrUrl, '_blank');
        }
        else {
            alert('Error opening VDR, please refresh and try again');
        }
    };
    ViewsArticleEditorComponent.prototype.hasArticleVdr = function () {
        return !!this.article && !!this.article.data && typeof this.article.data.vdrUrl === 'string';
    };
    ViewsArticleEditorComponent.prototype.viewArticleVdr = function () {
        if (this.hasArticleVdr()) {
            window.open(this.article.data.vdrUrl, '_blank');
        }
        else {
            alert('Error opening VDR, please refresh and try again');
        }
    };
    ViewsArticleEditorComponent.prototype.getProfileName = function (id) {
        var profile = this.getProfile(id);
        if (!profile) {
            return 'Loading...';
        }
        return profile.lastName + ", " + profile.firstName + " <" + profile.email + ">";
    };
    ViewsArticleEditorComponent.prototype.getProfile = function (id) {
        var _this = this;
        if (typeof id !== 'string' || id.length < 1) {
            return null;
        }
        if (!this.profilesById.hasOwnProperty(id)) {
            this.profilesById[id] = null;
            this.app.profileModel.get(id)
                .then(function (profile) {
                _this.zone.run(function () {
                    _this.profilesById[id] = profile;
                });
            })
                .catch(function (e) {
                console.error('error listing profile: ' + id, e);
            });
        }
        return this.profilesById[id];
    };
    // public accessLevelProfilesReady(): boolean {
    //
    // 	return Array.isArray( this.profiles );
    // }
    ViewsArticleEditorComponent.prototype.accessLevelProfiles = function () {
        return (this.profiles || []).filter(function (profile) {
            return !profile.isEmployee;
        });
    };
    // public accessLevelProfileOptions(): string[][] {
    //
    // 	let options = [];
    //
    // 	let profiles = this.accessLevelProfiles();
    //
    // 	if ( !Array.isArray( this.articleEdited.accessLevelExclusive ) ) {
    // 		this.articleEdited.accessLevelExclusive = [];
    // 	}
    //
    // 	profiles.forEach( ( profile ) => {
    //
    // 		if ( this.articleEdited.accessLevelExclusive.indexOf( profile.id ) > -1 ) {
    // 			return;
    // 		}
    //
    // 		options.push( [ profile.id, `${profile.lastName}, ${profile.firstName} <${profile.email}>` ] )
    // 	} );
    //
    // 	if ( options.length < 1 ) {
    // 		this.accessLevelUserSelected = null;
    // 	}
    //
    // 	return options;
    //
    // }
    ViewsArticleEditorComponent.prototype.revertVersion = function () {
        var _this = this;
        var index = parseInt(this.selectedVersion, 10);
        if (index < 0 || index - 1 > this.articleHistory.length) {
            return;
        }
        this.zone.run(function () {
            if (!_this.confirm('Are you sure you want to revert and discard unsaved changes?')) {
                return;
            }
            _this.selectedVersion = '-1';
            _this.setEditedArticle(_this.articleHistory[index]);
            _this.cdRef.markForCheck();
        });
    };
    ViewsArticleEditorComponent.prototype.confirm = function (message) {
        if (!this.dirty) {
            return true;
        }
        return window.confirm(message);
    };
    // public accessLevelUserAdd( level: 'exclusive' | 'private' ): void {
    //
    // 	if ( !this.articleEdited ) {
    // 		return;
    // 	}
    //
    // 	let $input = this.$el.find( '.article-access-level-selection-container' ).find( 'select' );
    // 	let profileId = $input.val();
    //
    // 	if ( typeof profileId !== 'string' || profileId.trim().length < 1 ) {
    // 		return;
    // 	}
    //
    // 	if ( !Array.isArray( this.articleEdited.accessLevelExclusive ) ) {
    // 		this.articleEdited.accessLevelExclusive = [];
    // 	}
    // 	if ( !Array.isArray( this.articleEdited.accessLevelPrivate ) ) {
    // 		this.articleEdited.accessLevelPrivate = [];
    // 	}
    //
    // 	this.accessLevelUserRemove( profileId ); // only put user on one list at a time
    //
    // 	if ( level === 'exclusive' ) {
    // 		if ( this.articleEdited.accessLevelExclusive.indexOf( profileId ) < 0 ) {
    // 			this.articleEdited.accessLevelExclusive.push( profileId )
    // 		}
    // 	} else if ( level === 'private' ) {
    // 		if ( this.articleEdited.accessLevelPrivate.indexOf( profileId ) < 0 ) {
    // 			this.articleEdited.accessLevelPrivate.push( profileId )
    // 		}
    // 	}
    //
    // }
    // public accessLevelUserRemove( profileId: string ): void {
    //
    // 	if ( !this.articleEdited ) {
    // 		return;
    // 	}
    //
    // 	if ( !Array.isArray( this.articleEdited.accessLevelExclusive ) ) {
    // 		this.articleEdited.accessLevelExclusive = [];
    // 	}
    //
    // 	let index = this.articleEdited.accessLevelExclusive.indexOf( profileId );
    // 	if ( index > -1 ) {
    // 		this.articleEdited.accessLevelExclusive.splice( index, 1 );
    // 	}
    // 	index = this.articleEdited.accessLevelPrivate.indexOf( profileId );
    // 	if ( index > -1 ) {
    // 		this.articleEdited.accessLevelPrivate.splice( index, 1 );
    // 	}
    //
    // }
    ViewsArticleEditorComponent.prototype.tileImgUrl = function (large) {
        return this.sanitizer.bypassSecurityTrustUrl(large ? this.articleEdited.tileImgLargeUrl : this.articleEdited.tileImgUrl);
    };
    ViewsArticleEditorComponent.prototype.showSubMenuCode = function () {
        var _this = this;
        this.submenuDataMode = true;
        window.setTimeout(function () {
            var $textarea = _this.$el.find('.article-submenus .article-submenus-code textarea');
            if (!Array.isArray(_this.articleEdited.submenu)) {
                _this.articleEdited.submenu = [];
            }
            $textarea.val(JSON.stringify(_this.articleEdited.submenu, null, 4));
        });
    };
    ViewsArticleEditorComponent.prototype.showSubMenuForm = function () {
        var _this = this;
        if (!this.isValidSubMenuData()) {
            return;
        }
        this.submenuDataMode = false;
        var $textarea = this.$el.find('.article-submenus .article-submenus-code textarea');
        var control = this.editorMenuGeneralForm.controls['submenu'];
        while (control.length > 0) {
            control.removeAt(control.length - 1);
        }
        this.articleEdited.submenu = JSON.parse($textarea.val());
        if (!Array.isArray(this.articleEdited.submenu)) {
            this.articleEdited.submenu = [];
        }
        this.articleEdited.submenu.forEach(function (submenuItem) {
            _this.addSubmenuItem(submenuItem.name, submenuItem.url);
        });
    };
    ViewsArticleEditorComponent.prototype.isValidSubMenuData = function () {
        var result = false;
        var $textarea = this.$el.find('.article-submenus .article-submenus-code textarea');
        try {
            JSON.parse($textarea.val());
            result = true;
        }
        catch (e) {
            result = false;
        }
        return result;
    };
    return ViewsArticleEditorComponent;
}());
export { ViewsArticleEditorComponent };
