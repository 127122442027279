import {ChatWorkFlow} from 'app/services/chat'

export const chatDefListingBuyerFeePublicCommon: ChatWorkFlow = {
  authorEmail: 'doug.miller@goexio.com',
  pages: [
    {
      title: 'Get Access',
      items: [
        {
          type: 'paragraph',
          params: {
            content: 'In order to access the confidential information in this listing you will need to agree to the following terms of the listing’s non-disclosure agreement.'
          }
        },
        {
          type: 'paragraph',
          params: {
            content: 'Please note: this business agreement contains a pay agreement should you successfully transact the business. Exio requires no monthly subscription fee, and there is no obligation to pay the one-time **[[BUYER_FEE]]%** closing fee **unless you successfully acquire this business**.'
          }
        },
        {
          type: 'paragraph',
          params: {
            content: 'Please click below to review the agreement and submit your request for access.'
          }
        }
      ],
      continueText: 'Review NDA',
      continueDisabled: false
    },
    {
      title: 'Sign NDA',
      items: [
        {
          type: 'paragraph',
          params: {
            content: '#Non-Disclosure Agreement'
          }
        },
        {
          type: 'contract',
          params: {
            path: 'non-disclosure-agreement',
            buyerFee: true
          }
        }
      ],
      continueText: 'Send Email Verification',
      continueDisabled: false
    },
    {
      title: 'Check Your Email',
      items: [
        {
          type: 'paragraph',
          params: {
            content: 'Your request is not yet complete.\n'
          }
        },
        {
          type: 'paragraph',
          params: {
            content: 'You must click the link in the verification email in order to finish submitting your request.'
          }
        }
      ],
      continueText: 'I Will Check My Email',
      continueDisabled: false
    }
  ]
};
