/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./main.component.css.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "./main.component";
import * as i4 from "../../../services/app";
import * as i5 from "../../../services/video";
import * as i6 from "@angular/router";
var styles_ViewsSignupMainComponent = [i0.styles];
var RenderType_ViewsSignupMainComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_ViewsSignupMainComponent, data: {} });
export { RenderType_ViewsSignupMainComponent as RenderType_ViewsSignupMainComponent };
function View_ViewsSignupMainComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "div", [["class", "selected"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Selected"]))], null, null); }
function View_ViewsSignupMainComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 0, "div", [["class", "selected"]], null, null, null, null, null))], null, null); }
function View_ViewsSignupMainComponent_4(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, "div", [["class", "point-container"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "div", [["class", "check-box"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 0, "div", [["class", "component-icon check active"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 1, "div", [["class", "point-description"]], null, null, null, null, null)), (_l()(), i1.ɵted(4, null, ["", ""]))], null, function (_ck, _v) { var currVal_0 = _v.context.$implicit; _ck(_v, 4, 0, currVal_0); }); }
function View_ViewsSignupMainComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 20, "div", [["class", "signup-type-container"]], [[2, "selected-type", null]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.changeSelection(_v.context.$implicit.id) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 19, "div", [["class", "signup-type"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 1, "div", [["class", "name"]], null, null, null, null, null)), (_l()(), i1.ɵted(3, null, ["", ""])), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ViewsSignupMainComponent_2)), i1.ɵdid(5, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ViewsSignupMainComponent_3)), i1.ɵdid(7, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(8, 0, null, null, 0, "div", [["class", "description"]], [[8, "innerHTML", 1]], null, null, null, null)), (_l()(), i1.ɵeld(9, 0, null, null, 2, "div", [["class", "points-container"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ViewsSignupMainComponent_4)), i1.ɵdid(11, 278528, null, 0, i2.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null), (_l()(), i1.ɵeld(12, 0, null, null, 2, "div", [["class", "action"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.signupGo(_v.context.$implicit) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(13, 0, null, null, 1, "div", [["class", "title"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Request Access"])), (_l()(), i1.ɵeld(15, 0, null, null, 5, "div", [["class", "video-action-container"]], null, null, null, null, null)), (_l()(), i1.ɵeld(16, 0, null, null, 4, "div", [["class", "video-action"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.playVideo(_v.context.$implicit) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(17, 0, null, null, 1, "div", [["class", "video-icon"]], null, null, null, null, null)), (_l()(), i1.ɵeld(18, 0, null, null, 0, "div", [["class", "component-icon video-play active"]], null, null, null, null, null)), (_l()(), i1.ɵeld(19, 0, null, null, 1, "div", [["class", "video-text"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["See the Difference"]))], function (_ck, _v) { var _co = _v.component; var currVal_2 = (_v.context.$implicit.id === _co.signupType); _ck(_v, 5, 0, currVal_2); var currVal_3 = (_v.context.$implicit.id !== _co.signupType); _ck(_v, 7, 0, currVal_3); var currVal_5 = _v.context.$implicit.points; _ck(_v, 11, 0, currVal_5); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = (_v.context.$implicit.id === _co.signupType); _ck(_v, 0, 0, currVal_0); var currVal_1 = _v.context.$implicit.name; _ck(_v, 3, 0, currVal_1); var currVal_4 = _co.appService.markdownToHtml(_v.context.$implicit.description); _ck(_v, 8, 0, currVal_4); }); }
export function View_ViewsSignupMainComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "div", [["class", "signup-types-container"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ViewsSignupMainComponent_1)), i1.ɵdid(2, 278528, null, 0, i2.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.getSignupTypeDefs(); _ck(_v, 2, 0, currVal_0); }, null); }
export function View_ViewsSignupMainComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "div", [["class", "app-view-signup"]], null, null, null, View_ViewsSignupMainComponent_0, RenderType_ViewsSignupMainComponent)), i1.ɵdid(1, 245760, null, 0, i3.ViewsSignupMainComponent, [i4.AppService, i5.VideoService, i6.ActivatedRoute, i2.Location, i6.Router], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var ViewsSignupMainComponentNgFactory = i1.ɵccf(".app-view-signup", i3.ViewsSignupMainComponent, View_ViewsSignupMainComponent_Host_0, {}, {}, []);
export { ViewsSignupMainComponentNgFactory as ViewsSignupMainComponentNgFactory };
