/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./dropdown.component.css.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/forms";
import * as i3 from "@angular/common";
import * as i4 from "./dropdown.component";
var styles_StandardInputDropdownComponent = [i0.styles];
var RenderType_StandardInputDropdownComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_StandardInputDropdownComponent, data: {} });
export { RenderType_StandardInputDropdownComponent as RenderType_StandardInputDropdownComponent };
function View_StandardInputDropdownComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 5, "input", [["autofocus", "autofocus"], ["class", "input-type-dropdown-value-contents"], ["placeholder", "Filter"], ["type", "text"]], [[2, "ng-untouched", null], [2, "ng-touched", null], [2, "ng-pristine", null], [2, "ng-dirty", null], [2, "ng-valid", null], [2, "ng-invalid", null], [2, "ng-pending", null]], [[null, "blur"], [null, "ngModelChange"], [null, "change"], [null, "input"], [null, "compositionstart"], [null, "compositionend"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("input" === en)) {
        var pd_0 = (i1.ɵnov(_v, 1)._handleInput($event.target.value) !== false);
        ad = (pd_0 && ad);
    } if (("blur" === en)) {
        var pd_1 = (i1.ɵnov(_v, 1).onTouched() !== false);
        ad = (pd_1 && ad);
    } if (("compositionstart" === en)) {
        var pd_2 = (i1.ɵnov(_v, 1)._compositionStart() !== false);
        ad = (pd_2 && ad);
    } if (("compositionend" === en)) {
        var pd_3 = (i1.ɵnov(_v, 1)._compositionEnd($event.target.value) !== false);
        ad = (pd_3 && ad);
    } if (("blur" === en)) {
        var pd_4 = (_co.blur() !== false);
        ad = (pd_4 && ad);
    } if (("ngModelChange" === en)) {
        var pd_5 = ((_co.filter = $event) !== false);
        ad = (pd_5 && ad);
    } if (("change" === en)) {
        var pd_6 = (_co.filterChanged() !== false);
        ad = (pd_6 && ad);
    } return ad; }, null, null)), i1.ɵdid(1, 16384, null, 0, i2.DefaultValueAccessor, [i1.Renderer2, i1.ElementRef, [2, i2.COMPOSITION_BUFFER_MODE]], null, null), i1.ɵprd(1024, null, i2.NG_VALUE_ACCESSOR, function (p0_0) { return [p0_0]; }, [i2.DefaultValueAccessor]), i1.ɵdid(3, 671744, null, 0, i2.NgModel, [[8, null], [8, null], [8, null], [6, i2.NG_VALUE_ACCESSOR]], { model: [0, "model"] }, { update: "ngModelChange" }), i1.ɵprd(2048, null, i2.NgControl, null, [i2.NgModel]), i1.ɵdid(5, 16384, null, 0, i2.NgControlStatus, [[4, i2.NgControl]], null, null)], function (_ck, _v) { var _co = _v.component; var currVal_7 = _co.filter; _ck(_v, 3, 0, currVal_7); }, function (_ck, _v) { var currVal_0 = i1.ɵnov(_v, 5).ngClassUntouched; var currVal_1 = i1.ɵnov(_v, 5).ngClassTouched; var currVal_2 = i1.ɵnov(_v, 5).ngClassPristine; var currVal_3 = i1.ɵnov(_v, 5).ngClassDirty; var currVal_4 = i1.ɵnov(_v, 5).ngClassValid; var currVal_5 = i1.ɵnov(_v, 5).ngClassInvalid; var currVal_6 = i1.ɵnov(_v, 5).ngClassPending; _ck(_v, 0, 0, currVal_0, currVal_1, currVal_2, currVal_3, currVal_4, currVal_5, currVal_6); }); }
function View_StandardInputDropdownComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "div", [["class", "input-type-dropdown-value-contents"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.toggleOpen() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(1, null, ["", ""]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.currentDisplayValue; _ck(_v, 1, 0, currVal_0); }); }
function View_StandardInputDropdownComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "div", [["class", "input-type-dropdown-selector-option-container"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.clickOptionItem(_v.context.$implicit) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "div", [["class", "input-type-dropdown-selector-option"]], null, null, null, null, null)), (_l()(), i1.ɵted(2, null, ["", ""]))], null, function (_ck, _v) { var currVal_0 = _v.context.$implicit[1]; _ck(_v, 2, 0, currVal_0); }); }
export function View_StandardInputDropdownComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 12, "div", [["class", "input-type-dropdown-container"]], [[2, "show-selector", null]], null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 6, "div", [["class", "input-type-dropdown-value"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_StandardInputDropdownComponent_1)), i1.ɵdid(3, 16384, null, 0, i3.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_StandardInputDropdownComponent_2)), i1.ɵdid(5, 16384, null, 0, i3.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(6, 0, null, null, 1, "div", [["class", "component-icon-container"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.toggleOpen() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(7, 0, null, null, 0, "div", [["class", "component-icon"]], [[2, "arrow-down", null], [2, "arrow-up", null]], null, null, null, null)), (_l()(), i1.ɵeld(8, 0, null, null, 4, "div", [["class", "input-type-dropdown-selector"]], null, null, null, null, null)), (_l()(), i1.ɵeld(9, 0, null, null, 3, "div", [["class", "input-type-dropdown-selector-scroll-container"]], null, null, null, null, null)), (_l()(), i1.ɵeld(10, 0, null, null, 2, "div", [["class", "input-type-dropdown-selector-container"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_StandardInputDropdownComponent_3)), i1.ɵdid(12, 278528, null, 0, i3.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null), (_l()(), i1.ɵeld(13, 0, null, null, 4, "select", [["class", "hidden-select"]], null, null, null, null, null)), (_l()(), i1.ɵeld(14, 0, null, null, 3, "option", [["selected", ""], ["value", ""]], null, null, null, null, null)), i1.ɵdid(15, 147456, null, 0, i2.NgSelectOption, [i1.ElementRef, i1.Renderer2, [8, null]], { value: [0, "value"] }, null), i1.ɵdid(16, 147456, null, 0, i2.ɵangular_packages_forms_forms_s, [i1.ElementRef, i1.Renderer2, [8, null]], { value: [0, "value"] }, null), (_l()(), i1.ɵted(-1, null, ["Please Select"]))], function (_ck, _v) { var _co = _v.component; var currVal_1 = _co.showSelector; _ck(_v, 3, 0, currVal_1); var currVal_2 = !_co.showSelector; _ck(_v, 5, 0, currVal_2); var currVal_5 = _co.filteredOptions(); _ck(_v, 12, 0, currVal_5); var currVal_6 = ""; _ck(_v, 15, 0, currVal_6); var currVal_7 = ""; _ck(_v, 16, 0, currVal_7); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.showSelector; _ck(_v, 0, 0, currVal_0); var currVal_3 = !_co.showSelector; var currVal_4 = _co.showSelector; _ck(_v, 7, 0, currVal_3, currVal_4); }); }
export function View_StandardInputDropdownComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "div", [["class", "input-type-dropdown"]], null, null, null, View_StandardInputDropdownComponent_0, RenderType_StandardInputDropdownComponent)), i1.ɵdid(1, 8503296, null, 0, i4.StandardInputDropdownComponent, [i1.ElementRef], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var StandardInputDropdownComponentNgFactory = i1.ɵccf(".input-type-dropdown", i4.StandardInputDropdownComponent, View_StandardInputDropdownComponent_Host_0, { id: "id", name: "name", sortOptions: "sortOptions", options: "options", value: "value", unselectedDisplayValue: "unselectedDisplayValue" }, { valueChange: "valueChange" }, []);
export { StandardInputDropdownComponentNgFactory as StandardInputDropdownComponentNgFactory };
