/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./submenu.component.css.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "./submenu.component";
import * as i4 from "../../services/app";
import * as i5 from "@angular/router";
var styles_MainSubmenuComponent = [i0.styles];
var RenderType_MainSubmenuComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_MainSubmenuComponent, data: {} });
export { RenderType_MainSubmenuComponent as RenderType_MainSubmenuComponent };
function View_MainSubmenuComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, "div", [["class", "submenu-item"]], [[2, "active", null]], null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "span", [], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.clickLink(_v.context.$implicit, $event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(2, null, ["", ""])), (_l()(), i1.ɵeld(3, 0, null, null, 1, "a", [["class", "seo-link"]], [[8, "href", 4]], null, null, null, null)), (_l()(), i1.ɵted(4, null, ["", ""]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.isActive(_v.context.$implicit); _ck(_v, 0, 0, currVal_0); var currVal_1 = _v.context.$implicit.name; _ck(_v, 2, 0, currVal_1); var currVal_2 = _v.context.$implicit.url; _ck(_v, 3, 0, currVal_2); var currVal_3 = _v.context.$implicit.name; _ck(_v, 4, 0, currVal_3); }); }
function View_MainSubmenuComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, "div", [["class", "submenu-container"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 3, "div", [["class", "submenu-items-container"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 2, "div", [["class", "submenu-items"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_MainSubmenuComponent_2)), i1.ɵdid(4, 278528, null, 0, i2.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.items; _ck(_v, 4, 0, currVal_0); }, null); }
export function View_MainSubmenuComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵand(16777216, null, null, 1, null, View_MainSubmenuComponent_1)), i1.ɵdid(1, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = (_co.items && (_co.items.length > 0)); _ck(_v, 1, 0, currVal_0); }, null); }
export function View_MainSubmenuComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-submenu", [], null, null, null, View_MainSubmenuComponent_0, RenderType_MainSubmenuComponent)), i1.ɵdid(1, 245760, null, 0, i3.MainSubmenuComponent, [i4.AppService, i5.Router, i2.Location], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var MainSubmenuComponentNgFactory = i1.ɵccf("app-submenu", i3.MainSubmenuComponent, View_MainSubmenuComponent_Host_0, { items: "items" }, {}, []);
export { MainSubmenuComponentNgFactory as MainSubmenuComponentNgFactory };
