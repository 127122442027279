/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./legal.document.view.component.less.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "./legal.document.component.ngfactory";
import * as i4 from "./legal.document.component";
import * as i5 from "../../../services/app";
import * as i6 from "./legal.document.view.component";
var styles_ContentRenderLegalDocumentViewComponent = [i0.styles];
var RenderType_ContentRenderLegalDocumentViewComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_ContentRenderLegalDocumentViewComponent, data: {} });
export { RenderType_ContentRenderLegalDocumentViewComponent as RenderType_ContentRenderLegalDocumentViewComponent };
function View_ContentRenderLegalDocumentViewComponent_4(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 5, "div", [["class", "legal-document-requested-signature-contents col-xs-12"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "div", [["class", "legal-document-requested-signature-name col-xs-6"]], null, null, null, null, null)), (_l()(), i1.ɵted(2, null, ["", " ", ""])), (_l()(), i1.ɵeld(3, 0, null, null, 2, "div", [["class", "legal-document-requested-signature-requested-date col-xs-6"]], null, null, null, null, null)), (_l()(), i1.ɵted(4, null, ["Sent: ", ""])), i1.ɵppd(5, 2)], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.signerProfile(_v.parent.context.$implicit).firstName; var currVal_1 = _co.signerProfile(_v.parent.context.$implicit).lastName; _ck(_v, 2, 0, currVal_0, currVal_1); var currVal_2 = i1.ɵunv(_v, 4, 0, _ck(_v, 5, 0, i1.ɵnov(_v.parent.parent.parent.parent, 0), _v.parent.context.$implicit.requested, "longDate")); _ck(_v, 4, 0, currVal_2); }); }
function View_ContentRenderLegalDocumentViewComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "div", [["class", "legal-document-requested-signature col-xs-12"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ContentRenderLegalDocumentViewComponent_4)), i1.ɵdid(2, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = !!_co.signerProfile(_v.context.$implicit); _ck(_v, 2, 0, currVal_0); }, null); }
function View_ContentRenderLegalDocumentViewComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 6, "div", [["class", "legal-document-requested-signatures"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 0, "div", [["class", "col-xs-4"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 1, "div", [["class", "legal-document-requested-signatures-title col-xs-4"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Waiting for the Following Signatures"])), (_l()(), i1.ɵeld(4, 0, null, null, 0, "div", [["class", "col-xs-4"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ContentRenderLegalDocumentViewComponent_3)), i1.ɵdid(6, 278528, null, 0, i2.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.listRequestedSigners(); _ck(_v, 6, 0, currVal_0); }, null); }
function View_ContentRenderLegalDocumentViewComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, "div", [["class", "legal-document-view"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "div", [["class", "content-render-legal-document"]], null, null, null, i3.View_ContentRenderLegalDocumentComponent_0, i3.RenderType_ContentRenderLegalDocumentComponent)), i1.ɵdid(2, 8503296, null, 0, i4.ContentRenderLegalDocumentComponent, [i5.AppService, i1.ElementRef, i1.NgZone], { document: [0, "document"], instance: [1, "instance"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ContentRenderLegalDocumentViewComponent_2)), i1.ɵdid(4, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.document; var currVal_1 = _co.instance; _ck(_v, 2, 0, currVal_0, currVal_1); var currVal_2 = (_co.showWaitingSignatures && (_co.listRequestedSigners().length > 0)); _ck(_v, 4, 0, currVal_2); }, null); }
export function View_ContentRenderLegalDocumentViewComponent_0(_l) { return i1.ɵvid(0, [i1.ɵpid(0, i2.DatePipe, [i1.LOCALE_ID]), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ContentRenderLegalDocumentViewComponent_1)), i1.ɵdid(2, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = ((!_co.loading && _co.document) && _co.instance); _ck(_v, 2, 0, currVal_0); }, null); }
export function View_ContentRenderLegalDocumentViewComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "div", [["class", "content-render-legal-document-view"]], null, null, null, View_ContentRenderLegalDocumentViewComponent_0, RenderType_ContentRenderLegalDocumentViewComponent)), i1.ɵdid(1, 114688, null, 0, i6.ContentRenderLegalDocumentViewComponent, [i5.AppService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var ContentRenderLegalDocumentViewComponentNgFactory = i1.ɵccf(".content-render-legal-document-view", i6.ContentRenderLegalDocumentViewComponent, View_ContentRenderLegalDocumentViewComponent_Host_0, { path: "path", instanceId: "instanceId", showWaitingSignatures: "showWaitingSignatures" }, {}, []);
export { ContentRenderLegalDocumentViewComponentNgFactory as ContentRenderLegalDocumentViewComponentNgFactory };
