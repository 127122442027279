import {Component, OnInit, OnDestroy} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {Location} from '@angular/common';

import {AppService} from 'app/services/app';
import {ArticleSection} from 'app/models/article';

@Component({
  moduleId: module.id,
  selector: '.app-view-home-email-verification',
  templateUrl: 'email-verification.component.pug',
  styleUrls: ['email-verification.component.less']
})
export class ViewsHomeEmailVerificationComponent implements OnInit, OnDestroy {

  public sections: ArticleSection[] = [];

  constructor(public appService: AppService, public route: ActivatedRoute, public location: Location) {
    this.appService.toolbar.whiteOverContent = false;
  }

  public subscriptions: any[] = [];

  ngOnInit(): void {

    this.subscriptions.push(this.route.params.subscribe((params: { emailVerificationId: string }) => {
      // TODO hit the API and actually check for this
      console.error('verification params', params);
    }));

  }

  ngOnDestroy(): void {
    console.error('email verification destroy');

    this.subscriptions.forEach((subscription) => {
      subscription.unsubscribe();
    });
  }

}
