/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./listing.upload.component.less.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../loader.spinner.component.ngfactory";
import * as i3 from "../loader.spinner.component";
import * as i4 from "@angular/common";
import * as i5 from "./listing.upload.component";
import * as i6 from "../../../services/app";
import * as i7 from "@angular/router";
import * as i8 from "../../../services/logic";
var styles_ContentRenderListingUploadComponent = [i0.styles];
var RenderType_ContentRenderListingUploadComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_ContentRenderListingUploadComponent, data: {} });
export { RenderType_ContentRenderListingUploadComponent as RenderType_ContentRenderListingUploadComponent };
function View_ContentRenderListingUploadComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 0, "input", [["class", "listing-upload-input"], ["multiple", ""], ["type", "file"]], null, null, null, null, null))], null, null); }
function View_ContentRenderListingUploadComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 0, "input", [["class", "listing-upload-input"], ["multiple", ""], ["type", "file"]], [[8, "accept", 0]], null, null, null, null))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.accept; _ck(_v, 0, 0, currVal_0); }); }
function View_ContentRenderListingUploadComponent_4(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "div", [["class", "loader-spinner"]], null, null, null, i2.View_ContentLoaderSpinnerComponent_0, i2.RenderType_ContentLoaderSpinnerComponent)), i1.ɵdid(1, 49152, null, 0, i3.ContentLoaderSpinnerComponent, [], null, null)], null, null); }
function View_ContentRenderListingUploadComponent_5(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "div", [["class", "listing-uploaded-files"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 2, "div", [["class", "listing-uploaded-file"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 1, "div", [["class", "listing-uploaded-file-field listing-uploaded-file-name"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["No Files Uploaded"]))], null, null); }
function View_ContentRenderListingUploadComponent_8(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "div", [["class", "listing-uploaded-file-field listing-uploaded-file-remove"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.removeFile(_v.parent.context.$implicit) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, ["Remove"]))], null, null); }
function View_ContentRenderListingUploadComponent_9(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "div", [["class", "listing-uploaded-file-field listing-uploaded-file-progress"]], null, null, null, null, null)), (_l()(), i1.ɵted(1, null, ["", ""]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.formatPercent(_v.parent.context.$implicit.uploadProgress); _ck(_v, 1, 0, currVal_0); }); }
function View_ContentRenderListingUploadComponent_7(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 6, "div", [["class", "listing-uploaded-file"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "div", [["class", "listing-uploaded-file-field listing-uploaded-file-name"]], null, null, null, null, null)), (_l()(), i1.ɵted(2, null, ["", ""])), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ContentRenderListingUploadComponent_8)), i1.ɵdid(4, 16384, null, 0, i4.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ContentRenderListingUploadComponent_9)), i1.ɵdid(6, 16384, null, 0, i4.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_1 = _co.uploadComplete(_v.context.$implicit); _ck(_v, 4, 0, currVal_1); var currVal_2 = !_co.uploadComplete(_v.context.$implicit); _ck(_v, 6, 0, currVal_2); }, function (_ck, _v) { var currVal_0 = _v.context.$implicit.name; _ck(_v, 2, 0, currVal_0); }); }
function View_ContentRenderListingUploadComponent_6(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "div", [["class", "listing-uploaded-files"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ContentRenderListingUploadComponent_7)), i1.ɵdid(2, 278528, null, 0, i4.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.fileList(); _ck(_v, 2, 0, currVal_0); }, null); }
function View_ContentRenderListingUploadComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 13, "div", [["class", "listing-upload-container"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ContentRenderListingUploadComponent_2)), i1.ɵdid(2, 16384, null, 0, i4.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ContentRenderListingUploadComponent_3)), i1.ɵdid(4, 16384, null, 0, i4.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(5, 0, null, null, 2, "div", [["class", "listing-upload-button"]], null, null, null, null, null)), (_l()(), i1.ɵeld(6, 0, null, null, 1, "button", [["class", "active enabled"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.openDialogue() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, ["Upload File"])), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ContentRenderListingUploadComponent_4)), i1.ɵdid(9, 16384, null, 0, i4.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ContentRenderListingUploadComponent_5)), i1.ɵdid(11, 16384, null, 0, i4.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ContentRenderListingUploadComponent_6)), i1.ɵdid(13, 16384, null, 0, i4.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = ((_co.files !== null) && !_co.accept); _ck(_v, 2, 0, currVal_0); var currVal_1 = ((_co.files !== null) && !!_co.accept); _ck(_v, 4, 0, currVal_1); var currVal_2 = (_co.files === null); _ck(_v, 9, 0, currVal_2); var currVal_3 = ((_co.files !== null) && (_co.files.length < 1)); _ck(_v, 11, 0, currVal_3); var currVal_4 = (_co.files !== null); _ck(_v, 13, 0, currVal_4); }, null); }
export function View_ContentRenderListingUploadComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵand(16777216, null, null, 1, null, View_ContentRenderListingUploadComponent_1)), i1.ɵdid(1, 16384, null, 0, i4.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = (((!!_co.workflowTargetState && !!_co.path) && !!_co.workflowTargetState.id) && (_co.workflowTargetState.id !== "new")); _ck(_v, 1, 0, currVal_0); }, null); }
export function View_ContentRenderListingUploadComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "div", [["class", "content-render-listing-upload"]], null, null, null, View_ContentRenderListingUploadComponent_0, RenderType_ContentRenderListingUploadComponent)), i1.ɵdid(1, 114688, null, 0, i5.ContentRenderListingUploadComponent, [i6.AppService, i7.Router, i1.ElementRef, i8.LogicService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var ContentRenderListingUploadComponentNgFactory = i1.ɵccf(".content-render-listing-upload", i5.ContentRenderListingUploadComponent, View_ContentRenderListingUploadComponent_Host_0, { workflowTargetState: "workflowTargetState", path: "path", accept: "accept", item: "item" }, {}, []);
export { ContentRenderListingUploadComponentNgFactory as ContentRenderListingUploadComponentNgFactory };
