/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./pending.upgrade.dialogue.component.less.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/material/dialog";
import * as i3 from "../../../../../node_modules/@angular/material/button/typings/index.ngfactory";
import * as i4 from "@angular/material/button";
import * as i5 from "@angular/cdk/platform";
import * as i6 from "@angular/cdk/a11y";
import * as i7 from "@angular/platform-browser/animations";
import * as i8 from "./pending.upgrade.dialogue.component";
var styles_PendingUpgradeDialogueComponent = [i0.styles];
var RenderType_PendingUpgradeDialogueComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_PendingUpgradeDialogueComponent, data: {} });
export { RenderType_PendingUpgradeDialogueComponent as RenderType_PendingUpgradeDialogueComponent };
export function View_PendingUpgradeDialogueComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "h1", [["class", "mat-dialog-title"], ["mat-dialog-title", ""]], [[8, "id", 0]], null, null, null, null)), i1.ɵdid(1, 81920, null, 0, i2.MatDialogTitle, [[2, i2.MatDialogRef], i1.ElementRef, i2.MatDialog], null, null), (_l()(), i1.ɵted(-1, null, ["Upgrade Pending"])), (_l()(), i1.ɵeld(3, 0, null, null, 3, "div", [["class", "mat-dialog-content"], ["mat-dialog-content", ""]], null, null, null, null, null)), i1.ɵdid(4, 16384, null, 0, i2.MatDialogContent, [], null, null), (_l()(), i1.ɵeld(5, 0, null, null, 1, "p", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Please check back in 1 business day."])), (_l()(), i1.ɵeld(7, 0, null, null, 5, "div", [["class", "mat-dialog-actions"], ["mat-dialog-actions", ""]], null, null, null, null, null)), i1.ɵdid(8, 16384, null, 0, i2.MatDialogActions, [], null, null), (_l()(), i1.ɵeld(9, 0, null, null, 3, "button", [["mat-button", ""], ["mat-dialog-close", ""], ["type", "button"]], [[8, "disabled", 0], [2, "_mat-animation-noopable", null], [1, "aria-label", 0]], [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 11).dialogRef.close(i1.ɵnov(_v, 11).dialogResult) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i3.View_MatButton_0, i3.RenderType_MatButton)), i1.ɵdid(10, 180224, null, 0, i4.MatButton, [i1.ElementRef, i5.Platform, i6.FocusMonitor, [2, i7.ANIMATION_MODULE_TYPE]], null, null), i1.ɵdid(11, 606208, null, 0, i2.MatDialogClose, [[2, i2.MatDialogRef], i1.ElementRef, i2.MatDialog], { dialogResult: [0, "dialogResult"] }, null), (_l()(), i1.ɵted(-1, 0, ["Ok"]))], function (_ck, _v) { _ck(_v, 1, 0); var currVal_4 = ""; _ck(_v, 11, 0, currVal_4); }, function (_ck, _v) { var currVal_0 = i1.ɵnov(_v, 1).id; _ck(_v, 0, 0, currVal_0); var currVal_1 = (i1.ɵnov(_v, 10).disabled || null); var currVal_2 = (i1.ɵnov(_v, 10)._animationMode === "NoopAnimations"); var currVal_3 = (i1.ɵnov(_v, 11).ariaLabel || null); _ck(_v, 9, 0, currVal_1, currVal_2, currVal_3); }); }
export function View_PendingUpgradeDialogueComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-content-pending-upgrade-dialogue", [], null, null, null, View_PendingUpgradeDialogueComponent_0, RenderType_PendingUpgradeDialogueComponent)), i1.ɵdid(1, 49152, null, 0, i8.PendingUpgradeDialogueComponent, [], null, null)], null, null); }
var PendingUpgradeDialogueComponentNgFactory = i1.ɵccf("app-content-pending-upgrade-dialogue", i8.PendingUpgradeDialogueComponent, View_PendingUpgradeDialogueComponent_Host_0, {}, {}, []);
export { PendingUpgradeDialogueComponentNgFactory as PendingUpgradeDialogueComponentNgFactory };
