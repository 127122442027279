import * as tslib_1 from "tslib";
import { ApiService } from 'app/services/api';
import * as Bluebird from 'bluebird';
import * as moment from 'moment';
// Types
import { ArticleModel } from './article';
import { ProfileModel } from './profile';
import { GenericModel, GenericMasterSchemaType, GenericSchemaType } from './generic';
var LegalDocumentInstanceSigner = /** @class */ (function (_super) {
    tslib_1.__extends(LegalDocumentInstanceSigner, _super);
    function LegalDocumentInstanceSigner() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    return LegalDocumentInstanceSigner;
}(GenericSchemaType));
export { LegalDocumentInstanceSigner };
var LegalDocumentInstance = /** @class */ (function (_super) {
    tslib_1.__extends(LegalDocumentInstance, _super);
    function LegalDocumentInstance() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    return LegalDocumentInstance;
}(GenericSchemaType));
export { LegalDocumentInstance };
// Schema Type
var LegalDocument = /** @class */ (function (_super) {
    tslib_1.__extends(LegalDocument, _super);
    function LegalDocument() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    return LegalDocument;
}(GenericMasterSchemaType));
export { LegalDocument };
var ByUserLegalDocument = /** @class */ (function (_super) {
    tslib_1.__extends(ByUserLegalDocument, _super);
    function ByUserLegalDocument() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    return ByUserLegalDocument;
}(GenericMasterSchemaType));
export { ByUserLegalDocument };
var MyLegalDocumentRecord = /** @class */ (function () {
    function MyLegalDocumentRecord() {
    }
    return MyLegalDocumentRecord;
}());
export { MyLegalDocumentRecord };
var LegalDocumentModel = /** @class */ (function (_super) {
    tslib_1.__extends(LegalDocumentModel, _super);
    function LegalDocumentModel(api, profile, article) {
        var _this = _super.call(this) || this;
        _this.api = api;
        _this.profile = profile;
        _this.article = article;
        _this.init(api, 'legalDocument', {
            id: null,
            name: null,
            url: null,
            title: null,
            instructions: null,
            parties: null,
            description: null,
            version: null,
            sections: [],
            preSigners: [],
            notifications: [],
            instanceCount: null,
            signatureSuccess: null,
            returnToPreviousPageAfterSignature: false
        }, 0);
        return _this;
    }
    LegalDocumentModel.prototype.getInstance = function (ref) {
        return ref;
    };
    LegalDocumentModel.prototype.saveInstance = function (instance) {
        return this.saveDocumentInstance(instance);
    };
    LegalDocumentModel.prototype.saveDocumentInstance = function (instance) {
        return this.api.call('legalDocument.saveInstance', instance);
    };
    LegalDocumentModel.prototype.upsertArticleNdaInstance = function (params) {
        return this.api.call('legalDocument.upsertArticleNdaInstance', params);
    };
    LegalDocumentModel.prototype.getArticleNdaAnonymousInstance = function (params) {
        return this.api.call('legalDocument.getArticleNdaAnonymousInstance', params);
    };
    LegalDocumentModel.prototype.confirmEmail = function (params) {
        return this.api.call('legalDocument.confirmEmail', params);
    };
    LegalDocumentModel.prototype.getDocumentInstances = function (query) {
        return this.api.call('legalDocument.getInstance', query);
    };
    LegalDocumentModel.prototype.list = function () {
        return this.api.call('legalDocument.list', {});
    };
    LegalDocumentModel.prototype.byUserId = function (id) {
        return this.api.call('legalDocument.byUserId', { id: id });
    };
    LegalDocumentModel.prototype.getByPath = function (params) {
        return this.api.call('legalDocument.getByPath', params);
    };
    LegalDocumentModel.prototype.upsertSignerInstance = function (params) {
        return this.api.call('legalDocument.upsertSignerInstance', params);
    };
    LegalDocumentModel.prototype.upsertSignerInstanceByName = function (params) {
        return this.api.call('legalDocument.upsertSignerInstanceByName', params);
    };
    LegalDocumentModel.prototype.getSignerInstance = function (params) {
        return this.api.call('legalDocument.getSignerInstance', params);
    };
    LegalDocumentModel.prototype.acceptInstance = function (params) {
        if (!params.urlRef) {
            params.urlRef = window.location.pathname;
        }
        return this.api.call('legalDocument.acceptInstance', params);
    };
    LegalDocumentModel.prototype.pdfUrlByInstanceId = function (params) {
        return this.api.call('legalDocument.pdfUrlByInstanceId', params);
    };
    LegalDocumentModel.prototype.sendNDAConfirmationEmail = function (params) {
        return this.api.call('legalDocument.sendNDAConfirmationEmail', params);
    };
    LegalDocumentModel.prototype.declineInstance = function (params) {
        return this.api.call('legalDocument.declineInstance', params);
    };
    LegalDocumentModel.prototype.myRecords = function () {
        return this.api.call('legalDocument.myRecords', {});
    };
    LegalDocumentModel.prototype.search = function (params) {
        var _this = this;
        return new Bluebird(function (resolve, reject) {
            _this.api.call('legalDocument.search', params)
                .then(function (searchResponse) {
                searchResponse.documents.forEach(function (document, i) {
                    document = new LegalDocument(document);
                    searchResponse.documents[i] = document;
                });
                resolve(searchResponse);
            })
                .catch(reject);
        });
    };
    LegalDocumentModel.prototype.instancesByLegalDocumentId = function (id) {
        return this.api.call('legalDocument.instancesByLegalDocumentId', { id: id });
    };
    LegalDocumentModel.prototype.instanceById = function (id) {
        return this.api.call('legalDocument.instanceById', { id: id });
    };
    LegalDocumentModel.prototype.addInstanceSigner = function (instanceId, userId) {
        return this.api.call('legalDocument.addInstanceSigner', {
            instanceId: instanceId,
            userId: userId
        });
    };
    LegalDocumentModel.prototype.removeInstanceSigner = function (instanceId, userId) {
        return this.api.call('legalDocument.removeInstanceSigner', {
            instanceId: instanceId,
            userId: userId
        });
    };
    LegalDocumentModel.prototype.applyMergeCodes = function (legalDocumentRaw, signerProfile, mergeData) {
        // clone
        var legalDocument = JSON.parse(JSON.stringify(legalDocumentRaw));
        if (signerProfile) {
            var partyName = signerProfile.email;
            if (signerProfile.firstName && signerProfile.lastName) {
                partyName = signerProfile.firstName + " " + signerProfile.lastName + " <" + signerProfile.email + ">";
            }
            mergeData.partyName = partyName;
        }
        else {
            mergeData.partyName = 'signer';
        }
        if (!mergeData.partyName) {
            mergeData.partyName = '[SIGNER]';
        }
        mergeData.effectiveDate = moment().tz('America/New_York').format('MMMM D, YYYY');
        var applyField = function (sourceField) {
            var targetField = sourceField.replace(/([A-Z])/g, '_$1').toUpperCase();
            var mergeValue = mergeData[sourceField];
            var typeOfMergeValue = typeof mergeValue;
            if (typeOfMergeValue !== 'string' &&
                typeOfMergeValue !== 'number') {
                return;
            }
            var swapMatch = new RegExp('{{\s*' + targetField + '\s*}}', 'g');
            var swapText = function (text) {
                if (typeof text !== 'string') {
                    return text;
                }
                return text.replace(swapMatch, mergeValue);
            };
            [
                'name',
                'title',
                'parties',
                'instructions',
                'description',
                'signatureSuccess'
            ].forEach(function (field) {
                try {
                    legalDocument[field] = swapText(legalDocument[field]);
                }
                catch (e) {
                    console.error('error processing', sourceField, '->', field, e);
                }
            });
            legalDocument.sections.forEach(function (section, i) {
                [
                    'title',
                    'contents'
                ].forEach(function (field) {
                    try {
                        section[field] = swapText(section[field]);
                    }
                    catch (e) {
                        console.error('error processing', sourceField, '->', field, e);
                    }
                });
            });
        };
        Object.keys(mergeData).forEach(applyField);
        return legalDocument;
    };
    return LegalDocumentModel;
}(GenericModel));
export { LegalDocumentModel };
