import {Injectable} from '@angular/core';
import {BehaviorSubject, Observer, Subscription} from 'rxjs';

export interface VideoReference {
  videoId: string,
  metaData?: any
}

@Injectable()
export class VideoService {

  private subject: VideoReference = null;

  private loadSubject: BehaviorSubject<VideoReference>;
  private playSubject: BehaviorSubject<VideoReference>;
  private pauseSubject: BehaviorSubject<VideoReference>;

  constructor() {
    this.loadSubject = new BehaviorSubject<VideoReference>(null);
    this.playSubject = new BehaviorSubject<VideoReference>(null);
    this.pauseSubject = new BehaviorSubject<VideoReference>(null);
  }

  public currentVideoId(): string {
    return this.subject && this.subject.videoId || null;
  }

  public load(subject: VideoReference): void {

    if (this.currentVideoId() === subject.videoId) {
      return;
    }

    this.subject = subject;
    this.loadSubject.next(subject);

  }

  public play(params?: VideoReference): void {

    if (params) {
      this.load(params);
    }

    if (this.currentVideoId()) {
      this.playSubject.next(this.subject);
    }
  }

  public pause(): void {
    if (this.currentVideoId()) {
      this.pauseSubject.next(this.subject);
    }
  }

  public onLoad(observer: Observer<VideoReference>): Subscription {
    return this.loadSubject.subscribe(observer);
  }

  public onPlay(observer: Observer<VideoReference>): Subscription {
    return this.playSubject.subscribe(observer);
  }

  public onPause(observer: Observer<VideoReference>): Subscription {
    return this.pauseSubject.subscribe(observer);
  }


}
