/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./main.component.less.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "./sections.component.ngfactory";
import * as i3 from "./sections.component";
import * as i4 from "../../../services/app";
import * as i5 from "@angular/common";
import * as i6 from "./main.component";
var styles_ContentRenderMainComponent = [i0.styles];
var RenderType_ContentRenderMainComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_ContentRenderMainComponent, data: {} });
export { RenderType_ContentRenderMainComponent as RenderType_ContentRenderMainComponent };
function View_ContentRenderMainComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-content-render-sections", [], null, null, null, i2.View_ContentRenderSectionsComponent_0, i2.RenderType_ContentRenderSectionsComponent)), i1.ɵdid(1, 245760, null, 0, i3.ContentRenderSectionsComponent, [i4.AppService, i1.ElementRef, i1.NgZone, i1.ChangeDetectorRef], { sections: [0, "sections"], mode: [1, "mode"], lockedCallback: [2, "lockedCallback"], accessLevel: [3, "accessLevel"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.article.sections; var currVal_1 = _co.mode; var currVal_2 = _co.lockedCallback; var currVal_3 = _co.accessLevel; _ck(_v, 1, 0, currVal_0, currVal_1, currVal_2, currVal_3); }, null); }
export function View_ContentRenderMainComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵand(16777216, null, null, 1, null, View_ContentRenderMainComponent_1)), i1.ɵdid(1, 16384, null, 0, i5.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = (!!_co.article && !!_co.article.sections); _ck(_v, 1, 0, currVal_0); }, null); }
export function View_ContentRenderMainComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "div", [["class", "content-render"]], null, null, null, View_ContentRenderMainComponent_0, RenderType_ContentRenderMainComponent)), i1.ɵdid(1, 49152, null, 0, i6.ContentRenderMainComponent, [], null, null)], null, null); }
var ContentRenderMainComponentNgFactory = i1.ɵccf(".content-render", i6.ContentRenderMainComponent, View_ContentRenderMainComponent_Host_0, { article: "article", mode: "mode", lockedCallback: "lockedCallback", accessLevel: "accessLevel" }, {}, []);
export { ContentRenderMainComponentNgFactory as ContentRenderMainComponentNgFactory };
