
<h4>Grid Content</h4><br><br>
<div class="select-access-level">Access Level</div>
<div class="input-type-select-access-level" [(accessLevel)]="section.accessLevel"></div><br><br>
<div class="input-title">Contents Name</div>
<textarea class="contents-entry-name" placeholder="A short name" [(ngModel)]="section.contentsName"></textarea><br><br>
<div class="input-title">Contents Icon</div>
<div class="input-type-select-icon" [(icon)]="section.contentsIcon"></div><br><br>
<div class="select-rows">Rows</div>
<input type="text" placeholder="Enter an integer &gt; 0" [(ngModel)]="getContent().rows"><br>
<div class="select-rows">Rows</div>
<input type="text" placeholder="Enter an integer &gt; 0" [(ngModel)]="getContent().rows"><br>
<div class="select-columns">Columns</div>
<input type="text" placeholder="Enter an integer &gt; 0" [(ngModel)]="getContent().columns"><br><br>
<div class="select-padding-top">Padding Top</div>
<input type="text" placeholder="Enter an integer" [(ngModel)]="section.contentPaddingTop"><br>
<div class="select-padding-bottom">Padding Bottom</div>
<input type="text" placeholder="Enter an integer" [(ngModel)]="section.contentPaddingBottom"><br><br>
<div class="select-horizontal-align">Horizontal Align:
  <select [(ngModel)]="section.contentHorizontalAlign">
    <option value="left" selected>Left</option>
    <option value="center">Center</option>
    <option value="right">Right</option>
    <option value="justify">Justify</option>
  </select>
</div><br><br>
<div class="select-foreground-color">Foreground Color</div>
<div class="select-foreground-color-block" *ngFor="let color of app.colors;" [style.background-color]="color" (click)="section.contentForegroundColor = color"></div><a class="reset-foreground active" (click)="section.contentForegroundColor = null">Reset</a><br><br>
<div class="select-background-color">Background Color</div>
<div class="select-background-color-block" *ngFor="let color of app.colors;" [style.background-color]="color" (click)="section.contentBackgroundColor = color"></div><a class="reset-background active" (click)="section.contentBackgroundColor = null">Reset</a><br><br>