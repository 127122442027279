import {Injectable} from '@angular/core';
import {ProfileModel} from 'app/models/profile';
import {ApiService} from 'app/services/api';
import * as Bluebird from 'bluebird';

// Types
import {GenericModel, GenericMasterSchemaType, GenericSchemaType} from './generic';
import {Cache, Hash, HashTree} from 'app/types/containers';
import {Listing} from './listing';

type QueryParamsValueTypes = string | number | null | boolean | Array<any>;
type QueryParamsType = Hash<QueryParamsValueTypes | Hash<QueryParamsValueTypes | Hash<QueryParamsValueTypes>>>;

export type AccessLevels = 'public' | 'private' | 'exclusive' | 'privatePending' | 'exclusivePending' | 'hidden';
export type AccessRequirementTypes = 'contract' | 'profile';

export interface AccessRequirementsTypes {
  type: AccessRequirementTypes;
  value: string;
};

export interface ArticleSectionContentGrid {
  rows: number;
  columns: number;
  fields: string[][];
}

export class ArticleFileOwner {
  kind: string;
  displayName: string;
  me: boolean;
  permissionId: string;
  emailAddress: string;
}

export class ArticleFile {
  kind?: string;
  id?: string;
  name: string;
  parentId?: string;
  parents?: string[];
  accessLevel?: 'public' | 'private' | 'exclusive' | 'hidden';
  locked?: boolean;
  canEdit?: boolean;
  mimeType?: string;
  createdTime?: string;
  modifiedTime?: string;
  description?: string;
  webViewLink?: string;
  webContentLink?: string;
  downloadLink?: string;
  iconLink?: string;
  thumbnailLink?: string;
  owners?: ArticleFileOwner[];
  lastModifyingUser?: ArticleFileOwner;
  viewersCanCopyContent?: boolean;
  writersCanShare?: boolean;
  uploadProgress?: number;
  version?: string;
  trashed?: boolean;
  explicitlyTrashed?: boolean;
}

export type ArticleSectionContent = any | HashTree<any>;

export class ArticleSection extends GenericSchemaType {
  public delete: boolean;
  public editing: boolean;
  public type: 'section'
    | 'text'
    | 'img'
    | 'h1'
    | 'h2'
    | 'h3'
    | 'h4'
    | 'h5'
    | 'hr'
    | 'vr'
    | 'carousel'
    | 'swot'
    | 'exio-score'
    | 'vimeo'
    | 'grid'
    | 'tiles'
    | 'feature'
    | 'hidden'
    | 'hidden-vimeo';
  public class: string | string[];
  public backgroundImgUrl: string;
  public backgroundImgPosition: string;
  public backgroundImgFit: string;
  public backgroundBackground: string;
  public backgroundLayout: string;
  public contentsName: string;
  public contentsIcon: string;
  public content: ArticleSectionContent;
  public meta?: any;
  public contentPaddingTop: number;
  public contentPaddingBottom: number;
  public contentForegroundColor: string;
  public contentBackgroundColor: string;
  public contentHorizontalAlign: 'left' | 'center' | 'right' | 'justify';
  public contentVerticalAlign: 'top' | 'middle' | 'bottom';
  public accessLevel: AccessLevels;

  constructor(ref?: any) {

    super(ref);

    if (this.type === 'section' && Array.isArray(this.content)) {
      (<ArticleSection[]>this.content).forEach((section, i) => {
        (<ArticleSection[]>this.content)[i] = new ArticleSection(section);
      });
    }

  }

}

export class ArticleGridSection extends ArticleSection {
  public content: ArticleSectionContentGrid;
}

export class SubMenu {
  public name: string;
  public url: string;
  public callToAction?: boolean;
}

export class ArticleAction {
  public name: string;
  public actionType: string;
  public actionParams: QueryParamsType;
}

// Schema Type
export class Article extends GenericMasterSchemaType {

  public type: 'video' | 'listing' | 'buyer' | 'advisor' | 'post' | 'subscribe' | null;

  public path?: string | null;
  public redirectPaths?: string[];
  public deleted?: boolean | null;
  public data?: QueryParamsType | null;
  public published?: boolean | null;
  public hidden?: boolean | null;
  public author?: string | null;
  public created?: string | null;
  public nda?: string | null;
  public formId?: string | null;
  public updated?: string | null;
  public categories?: string[];
  public title?: string | null;
  public tagline?: string | null;
  public description?: string | null;
  public sortHint?: number | null;
  public tileWidth?: number | null;
  public tileImgUrl?: string | null;
  public tileImgLargeUrl?: string | null;
  public bannerImgUrl?: string | null;
  public bannerImgUrlMobile?: string | null;
  public bannerImgPosition?: string | null;
  public bannerImgPositionMobile?: string | null;
  public bannerImgFit?: string | null;
  public bannerImgFitMobile?: string | null;
  public bannerBackground?: string | null;
  public bannerBackgroundMobile?: string | null;
  public bannerLayout?: string | null;
  public bannerLayoutMobile?: string | null;
  public bannerOpacity?: '0' | '10' | '20' | '30' | '40' | '50' | '60' | '70' | '80' | '90' | '100' | null;
  public bannerOpacityMobile?: '0' | '10' | '20' | '30' | '40' | '50' | '60' | '70' | '80' | '90' | '100' | null;
  public submenu?: SubMenu[];
  public actions?: ArticleAction[];
  public sections?: ArticleSection[];
  public accessLevel?: AccessLevels;
  public adminUsers?: string[]; // an array of user ids that have exclusive access to this listing
  public deleteAccess?: string[]; // an array of user ids that should be removed from the listing
  public accessLevelExclusive?: string[]; // an array of user ids that have exclusive access to this listing
  public accessLevelExclusivePending?: string[]; // an array of user ids that have exclusive access to this listing
  public accessLevelPrivate?: string[]; // an array of user ids that have private access to this listing
  public accessLevelPrivatePending?: string[]; // an array of user ids that have private access to this listing
  public accessLevelInvites?: Hash<AccessLevels>; // index is user id, tracks what the user was invited to see
  public accessRequirements?: {
    public: AccessRequirementsTypes;
    private: AccessRequirementsTypes;
    exclusive: AccessRequirementsTypes;
  };

  constructor(ref?: any) {

    super(ref);

    if (Array.isArray(this.sections)) {
      this.sections.forEach((section, i) => {
        this.sections[i] = new ArticleSection(section);
      });
    }


  }

}

export interface SearchParamsQueryBase {
  authors?: Array<string>,
  categories?: Array<string>,
  path?: string,
  type?: string,
  ids?: Array<string>,
  myAdmin?: boolean
}

export type SearchParamsQuery = SearchParamsQueryBase;

export interface SearchParams {
  query?: SearchParamsQuery;
  skip?: number;
  limit?: number;
  sort?: Hash<number>;
}

export interface SearchResponse {
  articles: Article[],
  count: number,
  total: number
}

export interface MetaData {
  authors: string[];
  categories: string[];
}

@Injectable()
export class ArticleModel extends GenericModel<Article> {

  constructor(public api: ApiService, public profile: ProfileModel) {
    super();

    this.init(api, 'article', {
      id: null,
      path: null,
      type: null,
      redirectPaths: [],
      deleted: null,
      data: {},
      published: null,
      author: null,
      created: null,
      nda: null,
      formId: null,
      updated: null,
      categories: [],
      tagline: null,
      title: null,
      description: null,
      sortHint: null,
      tileWidth: null,
      tileImgUrl: null,
      tileImgLargeUrl: null,
      bannerImgUrl: null,
      bannerImgUrlMobile: null,
      bannerImgPosition: null,
      bannerImgPositionMobile: null,
      bannerImgFit: null,
      bannerImgFitMobile: null,
      bannerBackground: null,
      bannerBackgroundMobile: null,
      bannerLayout: null,
      bannerLayoutMobile: null,
      bannerOpacity: null,
      bannerOpacityMobile: null,
      submenu: null,
      actions: [],
      sections: [],
      accessLevel: null,
      accessLevelExclusive: [],
      accessLevelExclusivePending: [],
      accessLevelPrivate: [],
      accessLevelPrivatePending: [],
      accessLevelInvites: null,
      accessRequirements: {
        public: null,
        private: null,
        exclusive: null
      }
    }, 1000);

  }

  public getInstance(ref: Article): Article {
    return new Article(ref);
  }

  // public addUserPrivate( articleId: string ): Bluebird<MetaData> {
  // 	return this.api.call( 'article.addUserPrivate', { articleId: articleId } );
  // }
  //
  // public addUserExclusive( articleId: string ): Bluebird<MetaData> {
  // 	return this.api.call( 'article.addUserExclusive', { articleId: articleId } );
  // }

  public metaData(): Bluebird<MetaData> {
    return this.api.call('article.metaData', {});
  }

  public addPermission(params: {
    articleId: string,
    userId: string,
    accessLevel: AccessLevels
  }): Bluebird<any> {
    return this.api.call('article.addPermission', params);
  }

  public listPublishedUploads(id: string, cacheBust?: boolean): Bluebird<any> {
    return this.api.call('article.listPublishedUploads', {id: id, cacheBust: cacheBust});
  }

  public search(params: SearchParams): Bluebird<SearchResponse> {

    return this.api.call('article.search', params)
      .then((searchResponse: SearchResponse): SearchResponse => {

        searchResponse.articles.sort((left, right) => {

          const leftSortHint = left.sortHint || 0;
          const rightSortHint = right.sortHint || 0;

          return leftSortHint - rightSortHint;

        });

        searchResponse.articles.forEach((article: Article, i: number): void => {

          article = new Article(article);

          searchResponse.articles[i] = article;

        });

        return searchResponse;

      });

  }

  public getByPath(params: { path: string }, cacheBust?: boolean): Bluebird<Article> {

    return this.api.call('article.getByPath', params)
      .then((article: Article): Article => {

        if (article) {
          article = new Article(article);
        }

        return article;

      });

  }

  public documentByNameAndPath(params: { name: string, path: string }): Bluebird<ArticleFile> {
    return this.api.call('article.documentByNameAndPath', params);
  }


}



