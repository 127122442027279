import { ElementRef, OnInit, OnDestroy, AfterViewChecked, NgZone } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { AppService } from 'app/services/app';
import { Router } from '@angular/router';
import * as Clipboard from 'clipboard';
var ViewsCdnbrowserMainComponent = /** @class */ (function () {
    function ViewsCdnbrowserMainComponent(appService, el, sanitizer, zone, router) {
        this.appService = appService;
        this.el = el;
        this.sanitizer = sanitizer;
        this.zone = zone;
        this.router = router;
        this.files = [];
        this.fileUploads = {};
        this.deletingFile = null;
        this.$el = null;
        this.$uploadInput = null;
        this.$inputHandle = null;
        this.$fileUploadDrop = null;
        this.assetFilter = '';
        this.assetSort = 'filename';
        this.refreshProcessingCount = 0;
        this.cacheBust = null;
        this.subscriptions = [];
        this.appService.contentLoading(true);
        this.appService.toolbar.whiteOverContent = false;
        this.appService.toolbar.backgroundColor = null;
        this.$el = $(el.nativeElement);
    }
    ViewsCdnbrowserMainComponent.prototype.pageIsLoading = function () {
        return this.appService.content.loading;
    };
    ViewsCdnbrowserMainComponent.prototype.uploadFilenames = function () {
        return Object.keys(this.fileUploads);
    };
    ViewsCdnbrowserMainComponent.prototype.resetCacheBust = function () {
        this.cacheBust = "" + new Date().getTime();
    };
    ViewsCdnbrowserMainComponent.prototype.ngOnDestroy = function () {
        this.subscriptions.forEach(function (subscription) {
            subscription.unsubscribe();
        });
    };
    ViewsCdnbrowserMainComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.subscriptions.push(this.appService.getAuthenticatedProfile({
            next: function (profile) {
                if (typeof profile === 'boolean' && !profile) {
                    _this.appService.contentLoading(true);
                }
                else if (!profile || !profile.isEmployee) {
                    _this.router.navigate(['/']);
                }
                else {
                    _this.refresh();
                }
            },
            error: function () {
                _this.router.navigate(['/']);
            },
            complete: function () {
                _this.router.navigate(['/']);
            }
        }));
        document.body.scrollTop = 0;
        this.$inputHandle = this.$el.find('input.hidden-file-input');
        this.$inputHandle.change(function () {
            _this.deletingFile = null;
            _this.handleFileUpload(_this.$inputHandle.get(0).files);
        });
    };
    ViewsCdnbrowserMainComponent.prototype.handleFileUpload = function (files) {
        // console.log( 'handle file uploads', files.length );
        var _this = this;
        this.resetCacheBust();
        var _loop_1 = function (i) {
            var file = files[i];
            var filename = file.name.toLowerCase().trim().replace(/[^a-z_\.0-9]+/g, '');
            if (filename.length < 1 || file.size > 20000000) {
                return "continue";
            }
            var uploadId = this_1.addUpload(file);
            // console.log( 'processing file', j, filename );
            window.setTimeout(function () {
                _this.appService.cdnModel
                    .getSignedUploadRequest({
                    filename: 'assets/' + filename
                })
                    .then(function (response) {
                    // console.log( 'signed upload for file', j, response.requestUrl );
                    $.ajax(response.requestUrl, {
                        type: 'PUT',
                        contentType: response.requestContentType,
                        crossDomain: true,
                        processData: false,
                        data: file
                    }).then(function () {
                        _this.finishUpload(uploadId);
                    });
                });
            }, 1000);
        };
        var this_1 = this;
        for (var i = 0; i < Math.min(10, files.length); i++) {
            _loop_1(i);
        }
    };
    ViewsCdnbrowserMainComponent.prototype.finishUpload = function (uploadId) {
        var _this = this;
        this.zone.run(function () {
            var file = _this.fileUploads[uploadId];
            delete _this.fileUploads[uploadId];
            file.preview = null;
        });
    };
    ViewsCdnbrowserMainComponent.prototype.addUpload = function (file) {
        // console.log( 'addUpload', file.name );
        var _this = this;
        var uploadId = file.name;
        var uploadRecord = {
            filename: file.name,
            contentType: file.type,
            modified: new Date().toISOString(),
            preview: '/assets/images/icons/page.svg',
            progress: -1,
            size: file.size
        };
        this.zone.run(function () {
            _this.fileUploads[uploadId] = uploadRecord;
            _this.addUploadsToFileList();
        });
        if (file.type.match(/^image/) && file.size < 1000000) {
            window.setTimeout(function () {
                var reader = new FileReader();
                reader.onload = function (e) {
                    _this.zone.run(function () {
                        uploadRecord.preview = e.target.result;
                    });
                };
                reader.readAsDataURL(file);
            }, 100);
        }
        return uploadId;
    };
    ViewsCdnbrowserMainComponent.prototype.ngAfterViewChecked = function () {
        // console.log( '$el 2', this.$el.find( '.cdn-browser-upload-input' ).length );
        var _this = this;
        // if ( this.$el ) {
        // 	console.log( '$el', this.$el.find( '.cdn-browser-upload-input' ) );
        // }
        this.zone.runOutsideAngular(function () {
            var $fileNameContents = _this.$el.find('.cdn-browser-file-contents .filename-content');
            $fileNameContents.each(function (i, el) {
                var $el = $(el);
                if ($el.hasClass('scroll-hover-set') || $el.html().trim().length < 1) {
                    return;
                }
                $el.addClass('scroll-hover-set');
                var $parent = $el.parent();
                var elWidth = $el.get(0).scrollWidth;
                var parentWidth = Math.floor($parent.width() + 0.5);
                var scrollOffset = Math.max(0, Math.floor(elWidth - parentWidth));
                if (scrollOffset < 1) {
                    return;
                }
                var $scrollTrigger = $parent.parent();
                var nextScrollLeftTrigger = false; // initially text should be scrolled to the left side showing
                var alreadyScrolling = false;
                var scrolling = false;
                var animationControl = function (startScrolling) {
                    // console.log( 'animationControl', $el.html(), startScrolling );
                    if (startScrolling) {
                        scrolling = true;
                        animate();
                    }
                    else {
                        scrolling = false;
                    }
                };
                var animate = function () {
                    if (alreadyScrolling) {
                        // console.log( 'already scrolling' );
                        return;
                    }
                    alreadyScrolling = true;
                    var scrollLeftTo = nextScrollLeftTrigger ? 0 : scrollOffset;
                    // console.log( 'animate', $el.html() );
                    // console.log( 'scrolling?', scrolling );
                    // console.log( 'nextScrollLeftTrigger?', nextScrollLeftTrigger );
                    // console.log( 'scrollLeftTrigger?', scrollLeftTrigger );
                    // console.log( 'scrollLeftTo?', scrollLeftTo );
                    // console.log( "\n\n" );
                    nextScrollLeftTrigger = !nextScrollLeftTrigger;
                    $el.animate({
                        scrollLeft: scrollLeftTo
                    }, {
                        duration: 2000,
                        easing: 'swing',
                        complete: function () {
                            // if we are not scrolling, or need to scroll back to left, go again
                            if (scrolling || scrollLeftTo > 0) {
                                window.setTimeout(function () {
                                    alreadyScrolling = false;
                                    animate();
                                }, 500);
                            }
                            else {
                                alreadyScrolling = false;
                            }
                        }
                    });
                };
                $scrollTrigger.first().bind('mouseenter', function () {
                    animationControl(true);
                });
                $scrollTrigger.first().bind('mouseleave', function () {
                    animationControl(false);
                });
            });
        });
        this.$fileUploadDrop = this.$el.find('.cdn-browser-upload-input-border');
        if (this.$fileUploadDrop.length > 0 && !this.$fileUploadDrop.hasClass('file-drop-enabled')) {
            this.$fileUploadDrop.addClass('file-drop-enabled');
            var nested_1 = 0;
            this.$fileUploadDrop.on('dragenter', function (e) {
                e.stopPropagation();
                e.preventDefault();
                nested_1++;
                _this.$fileUploadDrop.addClass('active');
            });
            // this.$fileUploadDrop.on( 'dragover', ( e ) => {
            // 	e.stopPropagation();
            // 	e.preventDefault();
            // } );
            this.$fileUploadDrop.on('dragleave', function (e) {
                e.stopPropagation();
                e.preventDefault();
                nested_1--;
                if (nested_1 <= 0) {
                    nested_1 = 0;
                    _this.$fileUploadDrop.removeClass('active');
                }
            });
            this.$fileUploadDrop.on('drop', function (e) {
                e.stopPropagation();
                e.preventDefault();
                nested_1 = 0;
                _this.$fileUploadDrop.removeClass('active');
                _this.handleFileUpload(e.originalEvent.dataTransfer.files);
            });
        }
        this.$uploadInput = this.$el.find('.cdn-browser-upload-input');
        if (this.$uploadInput.length > 0 && !this.$uploadInput.hasClass('cdn-upload-input-setup')) {
            this.$uploadInput.addClass('cdn-upload-input-setup');
            this.$uploadInput.click(function () {
                _this.$inputHandle.trigger('click');
            });
        }
        this.$el
            .find('.cdn-browser-file-contents .actions span.copy-link')
            .each(function () {
            var _this = this;
            var $el = $(this);
            if ($el.hasClass('copy-link-configured')) {
                return;
            }
            $el.addClass('copy-link-configured');
            var clipboard = new Clipboard($el.get(0));
            clipboard.on('success', function () {
                _this.deletingFile = null;
                $el.removeClass('glyphicon-copy');
                $el.addClass('glyphicon-ok');
                window.setTimeout(function () {
                    $el.addClass('glyphicon-copy');
                    $el.removeClass('glyphicon-ok');
                }, 3000);
            });
        });
    };
    ViewsCdnbrowserMainComponent.prototype.filesList = function () {
        var _this = this;
        return this.files
            .filter(function (file) {
            if (typeof _this.assetFilter !== 'string') {
                return true;
            }
            var assetFilter = _this.assetFilter.toLowerCase().trim();
            if (assetFilter.length < 1) {
                return true;
            }
            var filename = typeof file.filename === 'string' ? file.filename.toLowerCase().trim() : '';
            var modified = typeof file.modified === 'string' ? file.modified.toLowerCase().trim() : '';
            var contentType = typeof file.contentType === 'string' ? file.contentType.toLowerCase().replace(/^.*\//, '').trim() : '';
            return filename.includes(assetFilter) || modified.includes(assetFilter) || contentType.includes(assetFilter);
        })
            .sort(function (file1, file2) {
            var textA = typeof file1[_this.assetSort] === 'string' ? file1[_this.assetSort].toUpperCase() : '';
            var textB = typeof file2[_this.assetSort] === 'string' ? file2[_this.assetSort].toUpperCase() : '';
            return (textA < textB) ? -1 : (textA > textB) ? 1 : 0;
        });
    };
    ViewsCdnbrowserMainComponent.prototype.addUploadsToFileList = function () {
        var _this = this;
        this.uploadFilenames().forEach(function (filename) {
            var found = false;
            _this.files.forEach(function (file) {
                // console.log( 'file.filename, filename', JSON.stringify( file.filename ), JSON.stringify( filename ), file.filename === filename );
                if (file.filename === filename) {
                    found = true;
                }
            });
            if (!found) {
                // console.log( 'pushing upload file', filename );
                _this.files.push(_this.fileUploadByKey(filename));
            }
        });
    };
    ViewsCdnbrowserMainComponent.prototype.refresh = function () {
        var _this = this;
        this.refreshProcessingCount++;
        this.appService.cdnModel
            .list({ path: '/assets/' })
            .then(function (files) {
            _this.resetCacheBust();
            _this.refreshProcessingCount--;
            if (_this.refreshProcessingCount > 0) {
                // console.log( 'other refreshes running, let last one apply update' );
                return;
            }
            _this.refreshProcessingCount = 0;
            // console.log( 'this was the last refresh, apply', new Date().toISOString() );
            _this.appService.contentLoading(false);
            if (Array.isArray(files)) {
                _this.files = files;
            }
            else {
                _this.files = [];
            }
            _this.addUploadsToFileList();
        });
    };
    ViewsCdnbrowserMainComponent.prototype.fileIsUploading = function (file) {
        return file.hasOwnProperty('progress') && typeof file.progress === 'number' && file.progress > -1;
    };
    ViewsCdnbrowserMainComponent.prototype.previewImage = function (file) {
        if (!file || !file.contentType) {
            return null;
        }
        var url = this.appService.config.cms + "/assets/images/icons/page-inactive.svg";
        var contentType = file.contentType.toLowerCase();
        if (contentType.match(/^image\//)) {
            if (typeof file.preview === 'string' && file.preview.length > 0) {
                url = file.preview;
            }
            else {
                url = this.appService.exio.image.getUrl(this.downloadLink(file), {
                    actions: [
                        {
                            type: 'shrink',
                            width: 228
                        }
                    ],
                    format: 'png'
                });
                file.preview = url;
            }
            // url = `https://rsz.io/${url.replace( /^https?:\/\//, '' )}`;
            // url = `https://images1-focus-opensocial.googleusercontent.com/gadgets/proxy?url=${encodeURIComponent( url )}&container=focus&refresh=10&resize_w=228`;
        }
        return this.sanitizer.bypassSecurityTrustStyle("url(\"" + url + "\")");
    };
    ViewsCdnbrowserMainComponent.prototype.downloadLink = function (file, processed) {
        var url = this.appService.config.cdn + '/assets/' + file.filename;
        // apply default
        if (processed) {
            url = this.appService.exio.image.getUrl(url, { format: 'png' });
        }
        return url;
    };
    ViewsCdnbrowserMainComponent.prototype.downloadLinkBlur = function (file, radius) {
        var url = this.downloadLink(file);
        if (typeof radius !== 'number') {
            radius = 40;
        }
        radius = Math.max(0, radius);
        url = this.appService.exio.image.getUrl(url, {
            format: 'jpg',
            actions: [
                {
                    type: 'blur',
                    radius: radius
                },
                {
                    type: 'quality',
                    quality: 90
                }
            ]
        });
        return url;
    };
    ViewsCdnbrowserMainComponent.prototype.deleteFile = function (searchFile) {
        var removeIndex = null;
        this.files.forEach(function (file, i) {
            if (file.filename === searchFile.filename) {
                removeIndex = i;
            }
        });
        if (removeIndex !== null) {
            this.files.splice(removeIndex, 1);
        }
        this.deletingFile = null;
        this.appService.cdnModel.delete([searchFile.filename]);
    };
    ViewsCdnbrowserMainComponent.prototype.fileUploadByKey = function (key) {
        if (this.fileUploads.hasOwnProperty(key)) {
            return this.fileUploads[key];
        }
        return null;
    };
    ViewsCdnbrowserMainComponent.prototype.fileSize = function (file) {
        var size = file.size;
        if (typeof size !== 'number' || size < 0) {
            return 'Unknown Size';
        }
        var units = 'MB';
        if (size >= 1000000) {
            size = Math.floor(size / 100000) / 10;
            units = 'MB';
        }
        else if (size >= 1000) {
            size = Math.floor(size / 100) / 10;
            units = 'KB';
        }
        else if (size > 0) {
            units = 'Bytes';
        }
        return size + " " + units;
    };
    return ViewsCdnbrowserMainComponent;
}());
export { ViewsCdnbrowserMainComponent };
