import { Component, Input, ChangeDetectorRef } from '@angular/core';
import { ArticleSection } from 'app/models/article';

import { AppService } from 'app/services/app';

@Component( {
	moduleId: module.id,
	selector: '.content-render-editor-hr',
	templateUrl: 'hr.component.html',
	styleUrls: [ 'hr.component.css' ]
} )
export class ContentRenderEditorHrComponent {

	@Input()
	public section: ArticleSection = null;

	constructor( public app: AppService, public changeDetector: ChangeDetectorRef ) {
	}

	public delete(): void {
		this.section.delete = true;
		this.changeDetector.markForCheck();
	}

}
