import { Component, Input } from '@angular/core';
import { Profile } from 'app/models/profile';

@Component( {
  moduleId: module.id,
  selector: 'app-content-render-legal-document-signature',
  templateUrl: 'legal.document.signature.component.pug',
  styleUrls: [ 'legal.document.signature.component.less' ]
} )
export class ContentRenderLegalDocumentSignatureComponent {

  @Input()
  public signed: string = null;

  @Input()
  public signerProfile = <Profile>null;

  constructor() {

  }

  public getSignatureClass(): Object {

    let signatureStyle = 'cedarville';

    if ( this.signerProfile && typeof this.signerProfile.signatureStyle === 'string' && this.signerProfile.signatureStyle.length > 0 ) {
      signatureStyle = this.signerProfile.signatureStyle;
    }

    let fontClass = `font-family-${signatureStyle}`;

    let result = {};

    result[ fontClass ] = true;

    return result;

  }

  public hasAddress(): boolean {
    return !!this.signerProfile.address && (!!this.signerProfile.address.street1 || !!this.signerProfile.address.city || !!this.signerProfile.address.state);
  }

  public getAddress(): string {
    let address = '';

    if ( this.signerProfile && this.signerProfile.address ) {

      [
        'street1',
        'city',
        'state',
        'zip',
      ].forEach( ( field ) => {

        if ( this.signerProfile.address[ field ] ) {
          address += `${this.signerProfile.address[ field ]}, `;
        }

      } );

      address = address.replace( /, $/, '' );
    }

    return address;
  }

}
