import {Component, OnInit, OnDestroy, ElementRef, NgZone} from '@angular/core';
import {Router, NavigationExtras} from '@angular/router';
import {Location} from '@angular/common';


import {ExioService} from 'app/services/exio';
import {AppService} from 'app/services/app';
import {ApiService} from 'app/services/api';
import {
  ChatService,
  ChatWorkFlow,
  ChatWorkFlowPage,
  ChatWorkFlowPageItem,
  ChatWorkFlowLoad,
  ChatWorkFlowPageLink,
  ChatWorkFlowRedirect,
  ChatWorkFlowPageItemContract
} from 'app/services/chat';
import {
  LegalDocument,
  LegalDocumentSection,
  LegalDocumentInstance,
  LegalDocumentInstanceSigner
} from 'app/models/legal.document';
import {Profile, ProfileModel} from 'app/models/profile';
import {DomSanitizer, SafeUrl} from '@angular/platform-browser';
import {Subscription} from 'rxjs';
import {HashTree} from '../../types/containers';
import * as moment from 'moment';

@Component({
  moduleId: module.id,
  selector: 'app-chat-window',
  templateUrl: 'chat.component.pug',
  styleUrls: ['chat.component.less']
})
export class ContentChatComponent implements OnInit, OnDestroy {

  public authenticatedProfile: Profile = null;
  public profile: Profile = null;
  public profileFullName: string = '';
  public loginErrorMessage: string = null;
  public profiles: Profile[] = [];

  public isBouncing: boolean = false;

  public workflowId: string = null;
  public workflowPageId: number = 0;
  public workflowDef: ChatWorkFlow = null;

  public workflowReplacements: HashTree<any> = null;
  public workflowOptions: HashTree<any> = null;

  public loading: boolean = true;
  public showing: boolean = false;
  public userHidden: boolean = false;

  public loadedLegalDocuments: boolean = false;
  public legalDocument: LegalDocument = null;
  public legalDocumentInstance: LegalDocumentInstance = null;
  public legalDocumentInstanceSigner: LegalDocumentInstanceSigner = null;
  public legalDocumentElectronicSignatureAgree: boolean = false;
  public legalDocumentTermsOfServiceAgree: boolean = false;

  public socialNetworks = ['linkedIn', 'google', 'email'];

  public loginWaitNetwork: string = null;
  public loginPopUp: Window = null;
  public loginWait: number = null;
  public legalDocumentState: 'display' | 'next' | 'sign' | 'complete' = 'display';

  public processing: boolean = false;

  public idleWaitWatch: () => void = null;
  public idleWaitTimeout: number = null;

  public subscriptions: Subscription[] = [];

  public $host: JQuery = null;
  public $lastInputFocus: JQuery = null;

  constructor(public appService: AppService,
              public location: Location,
              public exioService: ExioService,
              public router: Router,
              public profileModel: ProfileModel,
              public api: ApiService,
              public chatService: ChatService,
              public dSanitizer: DomSanitizer,
              public el: ElementRef,
              public zone: NgZone) {

    this.init();
  }

  public resetLegalDocuments(): void {
    this.legalDocumentState = 'display';
    this.loadedLegalDocuments = false;

    this.legalDocument = null;
    this.legalDocumentInstance = null;
    this.legalDocumentInstanceSigner = null;
    this.legalDocumentTermsOfServiceAgree = false;
    this.legalDocumentElectronicSignatureAgree = false;
  }

  public init(): void {

    this.resetChat();
    this.resetLegalDocuments();
    this.$host = $(this.el.nativeElement);

    this.profileModel.listEmployees()
      .then((profiles: Profile[]) => {
        this.profiles = profiles;
        this.findProfile();
      })
      .catch(() => {
        this.profiles = [];
        this.findProfile();
      });

    this.subscriptions.push(this.appService.getAuthenticatedProfile({
      next: (profile) => {
        if (profile) {

          // there is a profile
          this.authenticatedProfile = profile;

          if (profile.firstName && profile.lastName) {
            this.profileFullName = `${profile.firstName} ${profile.lastName}`;
          }

        } else if (!profile) {
          this.authenticatedProfile = this.profileModel.newInstance();
        }

      },
      error: () => {
        this.authenticatedProfile = this.profileModel.newInstance();
      },
      complete: () => {
        this.authenticatedProfile = this.profileModel.newInstance();
      }
    }));


    this.hide();

  }

  public hasSignature(): boolean {
    return typeof this.profileFullName === 'string' && this.profileFullName.trim().length > 0;
  }

  public contractReady(path: string): boolean {

    // this.log('\n\n');

    const hasContract = this.hasContract(path);
    const hasContractInstance = this.hasContractInstance(path);


    // this.log('hasContract', hasContract);
    // this.log('this.hasContractInstance(path)', hasContractInstance);

    return hasContract && hasContractInstance;

  }

  public hasContract(path: string): boolean {
    return this.getContract(path) !== null;
  }

  public getContract(path: string): LegalDocument {

    // this.log('debug 1');
    if (!this.getContractInstance(path)) {
      // this.log('debug 2');
      return null;
    }
    // this.log('debug 3', !!this.legalDocument);

    return this.legalDocument;
  }

  public getContractInstanceSigner(path: string): LegalDocumentInstanceSigner {

    if (!this.getContractInstance(path)) {
      return null;
    }

    return this.legalDocumentInstanceSigner;

  }

  public isValidEmail(email: string): boolean {

    if (typeof email !== 'string' || email.trim().length < 1) {
      return false;
    }

    return !!email.match(/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/);

  }

  public sendLink(email: string, link: string, done?: (err?: Error) => void): void {

    this.appService.profileModel.sendLoginLink({
      email: email.trim().toLowerCase(),
      redirect: link || null
    })
      .asCallback(done);

  }

  public emailLogin(): void {

    this.loginErrorMessage = null;

    if (this.isValidEmail(this.workflowOptions.email)) {

      this.processing = true;
      this.sendLink(this.workflowOptions.email, window.location.pathname, (err) => {

        window.setTimeout(() => {
          this.processing = false;

          if (err) {
            this.loginErrorMessage = 'Something went wrong, please try again in a few minutes.';
            return;
          } else {
            this.continue(true);
          }

        }, 1000);

      });

    } else {

      this.loginErrorMessage = 'Please enter a valid email address.';

      const $inputs = this.$host.find('.login-item input');
      $inputs.addClass('focused');

      if (!this.isBouncing) {
        this.isBouncing = true;

        requestAnimationFrame(() => {
          $inputs.effect('bounce', {times: 3, distance: 5}, 'slow', () => {
            this.isBouncing = false;
          });
        });

      }

    }

  }

  public getContractInstance(path: string): LegalDocumentInstance {

    // this.log('DEBUG 4');

    const loadDocument = () => {
      this.appService
        .legalDocumentModel
        .get(this.legalDocumentInstance.legalDocumentId)
        .then((document: LegalDocument) => {

          if (!document) {
            this.legalDocument = null;
            console.error('missing document');
            return null;
          }

          this.legalDocument = this.appService
            .legalDocumentModel
            .applyMergeCodes(document, this.authenticatedProfile, this.legalDocumentInstance.data);

          return null;

        })
        .catch((e) => {
          console.error('failed to load document', e);
          this.resetLegalDocuments();
          this.legalDocument = null;
          this.legalDocumentInstance = null;
        });

    };

    // this.log('DEBUG 5');

    const loadDocumentInstance = () => {

      const handleError = (e) => {
        console.error('failed to load instance', e);
        this.resetLegalDocuments();
        this.loading = false;
        this.legalDocument = null;
        this.legalDocumentInstance = null;
      };

      const handleInstance = (instance: LegalDocumentInstance) => {

        if (instance) {
          this.legalDocumentInstance = instance;

          loadDocument();

          if (instance && instance.complete) {
            this.legalDocumentState = 'complete';
          }

        } else {
          this.legalDocumentInstance = null;
          this.resetLegalDocuments();
        }

        return null;

      };

      this.appService
        .legalDocumentModel
        .getArticleNdaAnonymousInstance({
          legalDocumentId: this.workflowOptions.contract.id,
          articleId: this.workflowOptions.contract.articleId
        })
        .then(handleInstance)
        .catch(handleError);

    };

    // this.log('this.loadedLegalDocuments', !this.loadedLegalDocuments);
    // this.log('this.legalDocumentInstance === null', this.legalDocumentInstance === null);
    // this.log('this.legalDocument === null', this.legalDocument === null);

    if (!this.loadedLegalDocuments && (this.legalDocumentInstance === null || this.legalDocument === null)) {
      this.loadedLegalDocuments = true;
      loadDocumentInstance();
    }

    return this.legalDocumentInstance;

  }

  public hasContractInstance(path: string): boolean {
    return this.getContractInstance(path) !== null;
  }

  public sectionContent(i: number, section: LegalDocumentSection): string {
    return `${i + 1}\\.  **${section.title}**. ${section.contents}`;
  }

  public log(message?: any, ...optionalParams: any[]): void {

    const args = arguments;

    this.zone.runOutsideAngular(() => {
      console.log.apply(console, args);
    })

  }

  public ngOnInit(): void {

    // this.log('ngOnInit');

    const apply = (workflowLoad: ChatWorkFlowLoad) => {

      if (!workflowLoad) {
        workflowLoad = {
          id: null
        }
      }

      this.resetLegalDocuments();

      this.workflowReplacements = workflowLoad.replacements || {};
      this.workflowOptions = workflowLoad.options || {};

      const workflowId = workflowLoad.id;
      const workflowIdleWait = workflowLoad.idleWait;

      if (this.workflowId === workflowId && this.userHidden && !workflowLoad.force) {
        this.clearIdleWait();
        return;
      }

      this.legalDocumentState = 'display';

      const bounce = this.showing && this.workflowId === workflowId;

      // if new workflow allow show again
      this.userHidden = false;

      this.hide();

      this.workflowId = workflowId || null;
      this.workflowPageId = 0;
      this.loading = true;

      if (workflowId) {

        const workFlowDef = this.getWorkFlow(workflowId);

        if (workFlowDef) {

          const doReplacements = (text: string): string => {
            if (typeof text !== 'string') {
              return text;
            }
            Object.keys(this.workflowReplacements).forEach(key => {
              text = text.replace(`[[${key}]]`, this.workflowReplacements[key]);
            });

            return text;
          };

          workFlowDef.pages.forEach(page => {

            page.title = doReplacements(page.title);

            page.items.forEach(item => {

              if (item.type === 'paragraph') {
                item.params.content = doReplacements(item.params.content);
              }

              if (item.type === 'checklist') {
                item.params.forEach(param => {
                  param.name = doReplacements(param.name);
                });
              }
            });
          });

          this.workflowDef = workFlowDef;
          this.loading = false;
          this.workflowShow(workflowIdleWait);

          if (bounce && !this.isBouncing) {

            this.isBouncing = true;
            requestAnimationFrame(() => {
              this.$host.effect('bounce', {times: 3, distance: 20}, 'slow', () => {
                this.isBouncing = false;
              });
            });
          }

        } else {
          apply(null);
        }

      } else {
        this.hide();
        this.clearIdleWait();
        this.clearSocialLogin();
        this.loading = false;
        this.workflowDef = null;
      }

      // this is a hack to fix a rendering issue where changes are not detected
      this.zone.runTask(() => {
        // NO-OP
      })

    };

    this.subscriptions.push(this.chatService.onLoad({
      next: apply,
      complete: () => {
        apply(null);
      },
      error: () => {
        apply(null);
      }
    }));

  }

  public setIdleWait(idleWait: number): void {

    const throttle = (func: () => void, wait: number) => {

      let timeout = null;
      let queued = false;

      const waitQueue = () => {

        // no queue
        if (timeout === null) {

          // if  one was queued, fire it
          if (queued) {
            queued = false;
            func();
          }

        } else {

          // already queue waiting
          return;
        }

        timeout = window.setTimeout(() => {

          timeout = null;

          // if func called while waiting, trigger that and wait again
          if (queued) {
            waitQueue();
          }

        }, wait);

      };

      return function () {

        // always queue one
        queued = true;

        waitQueue();

      };
    };

    this.clearIdleWait();

    let animationFrameRequested = false;
    const handler = () => {

      if (animationFrameRequested) {
        return;
      }

      animationFrameRequested = true;
      requestAnimationFrame(() => {
        animationFrameRequested = false;

        // each time there is activity,
        if (this.idleWaitTimeout) {
          window.clearTimeout(this.idleWaitTimeout);
          this.idleWaitTimeout = null;
        }

        this.idleWaitTimeout = window.setTimeout(() => {
          this.clearIdleWait();
          this.show();
        }, idleWait * 1000);

      });

    };

    const $window = $(window);

    this.idleWaitWatch = throttle(handler, 100);

    $window.on('scroll', this.idleWaitWatch);
    $window.on('mousemove', this.idleWaitWatch);
    $window.on('keypress', this.idleWaitWatch);

  }

  public clearIdleWait(): void {
    if (this.idleWaitWatch) {
      const $window = $(window);
      $window.off('scroll', this.idleWaitWatch);
      $window.off('mousemove', this.idleWaitWatch);
      $window.off('keypress', this.idleWaitWatch);
      this.idleWaitWatch = null;
    }
    if (this.idleWaitTimeout) {
      window.clearTimeout(this.idleWaitTimeout);
      this.idleWaitTimeout = null;
    }
  }

  public workflowShow(idleWait: number): void {

    if (idleWait < 1) {
      this.clearIdleWait();
      this.show();
      return;
    }

    this.setIdleWait(idleWait);

  }

  public ngOnDestroy(): void {

    this.subscriptions.forEach((subscription) => {
      subscription.unsubscribe();
    });
    this.subscriptions = [];

    this.clearIdleWait();
    this.clearSocialLogin();

  }

  public goProfile(): void {

    if (this.profile && typeof this.profile.id === 'string') {
      this.hide();
      this.router.navigate(['/learn/leadership/', this.profile.id]);
    }

  }

  public profilePicture(): SafeUrl {

    if (this.profile && this.profile.headshotURL) {

      const url = this.exioService.image.getUrl(this.profile.headshotURL, {
        format: 'jpg',
        actions: <any>[
          {
            type: 'shrink',
            width: 30
          },
          {
            type: 'quality',
            quality: 100
          }
        ]
      });

      return `url(${url})`;
    }

    return null;

  }

  public findProfile(): void {
    this.profile = null;
    if (this.profiles && this.workflowDef && this.workflowDef.authorEmail) {

      this.profiles.forEach((profile: Profile) => {
        if (profile && profile.email === this.workflowDef.authorEmail) {
          this.profile = profile;
        }
      });
    }
  }

  public show(): void {
    this.findProfile();
    this.showing = true;
    this.$host.removeClass('hide-chat');
  }

  public resetChat(): void {

    // this.log('resetChat');

    this.workflowId = null;
    this.workflowPageId = 0;
    this.workflowDef = null;

    this.workflowOptions = null;

    this.loading = true;
    this.showing = false;
    this.userHidden = false;

    this.loadedLegalDocuments = false;
    this.legalDocument = null;
    this.legalDocumentInstance = null;
    this.legalDocumentInstanceSigner = null;
    this.legalDocumentTermsOfServiceAgree = false;
    this.legalDocumentElectronicSignatureAgree = false;

    this.socialNetworks = ['linkedIn', 'google', 'email'];

    this.loginWaitNetwork = null;
    this.loginPopUp = null;
    this.loginWait = null;
    this.legalDocumentState = 'display';

    this.processing = false;

    this.idleWaitWatch = null;

    if (this.idleWaitTimeout) {
      window.clearTimeout(this.idleWaitTimeout);
    }
    this.idleWaitTimeout = null;

  }

  public closed(): void {
    this.userHidden = true;
    this.hide();
    this.resetChat();
    this.chatService.complete(false);
  }

  public hide(): void {
    this.showing = false;
    this.$host.addClass('hide-chat');
  }

  public getWorkFlow(id: string): ChatWorkFlow {

    return this.chatService.getWorkFlowDef(id);

  }

  public hasCurrentPage(): boolean {
    return !!this.currentPage();
  }

  public currentTitle(): string {

    if (!this.hasCurrentPage()) {
      return null;
    }

    let title = this.currentPage().title;

    if (typeof title === 'string') {
      title = title.trim();
    } else {
      title = '';
    }

    return title.length > 0 ? title : null;

  }

  public currentContinue(): string {

    if (!this.hasCurrentPage()) {
      return 'Continue';
    }

    let result = this.currentPage().continueText;

    if (this.hasLegalDocument()) {

      if (this.legalDocumentState === 'display') {
        result = 'Continue';
      } else if (this.isContractFormComplete()) {
        result = 'Send Email Verification';
      } else if (!result) {
        result = 'Continue';
      }

    }

    return result;

  }

  public isLastPage(): boolean {

    if (!this.hasCurrentPage()) {
      return true;
    }

    return this.workflowPageId >= this.workflowDef.pages.length - 1;

  }

  public currentContinueDisabled(): boolean {

    if (!this.hasCurrentPage()) {
      return null;
    }

    if (this.legalDocumentInstance && this.legalDocumentInstance.complete) {
      return true;
    }

    return this.currentPage().continueDisabled;

  }

  public continueDisabled(continueDisabled: boolean): void {
    this.currentPage().continueDisabled = continueDisabled;
  }

  public currentPage(): ChatWorkFlowPage {

    if (this.workflowDef && this.workflowDef.pages && this.workflowPageId > -1 && this.workflowPageId < this.workflowDef.pages.length) {
      return this.workflowDef.pages[this.workflowPageId];
    }

    return null;

  }

  public pageItems(): ChatWorkFlowPageItem[] {

    if (!this.hasCurrentPage()) {
      return [];
    }

    return this.currentPage().items;

  }

  public markdown(markdown: string): string {
    return this.appService.markdownToHtml(markdown);
  }

  public getIncompleteContractFields(): JQuery[] {

    const $inputs = this.$host.find('.contract-signature-container input').filter('[required]');

    const results = [];

    $inputs.each((i, el) => {

      const $el = $(el);

      const val = $el.val();

      if (typeof val === 'string' && val.trim().length < 1) {
        results.push($el);
      }

    });

    const $checkInput = this.$host.find('.contract-signature-container .check-group input.input-check:checkbox:not(:checked)');

    if ($checkInput.length > 0) {
      results.push($checkInput);
    }

    return results;

  }

  public isContractFormComplete(): boolean {

    // if (!this.legalDocumentElectronicSignatureAgree || !this.legalDocumentTermsOfServiceAgree) {
    //   return false;
    // }

    const fields = this.getIncompleteContractFields();

    return fields.length < 1;

  }

  public getLegalDocumentPath(): string {

    if (!this.workflowDef || !this.workflowDef.pages || this.workflowDef.pages.length < 1) {
      return null;
    }

    let contractToSign: string = null;

    const page = this.workflowDef.pages[this.workflowPageId] || null;

    if (!page) {
      return null;
    }

    page.items.forEach((item) => {

      if (contractToSign) {
        return;
      }

      if (item && item.type === 'contract') {
        contractToSign = (<ChatWorkFlowPageItemContract>item).params.path;
      }
    });

    return contractToSign;

  }

  public hasLegalDocument(): boolean {

    const path = this.getLegalDocumentPath();

    return typeof path === 'string' && path.trim().length > 0;

  }

  public highlightNextLegalDocumentInputField(): void {

    window.setTimeout(() => {

      const inputs = this.getIncompleteContractFields();

      if (inputs.length < 1) {
        return;
      }

      let $highlight = inputs[0];
      let done = false;

      inputs.forEach(($input, i) => {

        $input.removeClass('focused');

        if (!done && this.$lastInputFocus && this.$lastInputFocus.get(0) === $input.get(0)) {

          i++;
          if (i > inputs.length - 1) {
            i = 0;
          }


          $highlight = inputs[i];
          done = true;
        }

      });

      $highlight.addClass('focused');

      if (this.$lastInputFocus) {
        this.inputLostFocus((<HTMLInputElement>this.$lastInputFocus.get(0)));
      }
      this.$lastInputFocus = $highlight;
      this.inputGotFocus((<HTMLInputElement>this.$lastInputFocus.get(0)));

      const $chatBody = this.$host.find('.chat-body');
      const $chatBodyContainer = this.$host.find('.chat-body-container');

      this.zone.runOutsideAngular(() => {
        requestAnimationFrame(() => {
          let top = $highlight.position().top;

          top -= $chatBodyContainer.position().top;

          $chatBody.stop().animate({scrollTop: top}, 1000);
        });
      });

    });

  }

  public inputLostFocus(el: HTMLInputElement): void {
    const $el = $(el);
    $el.data('has-focus', false);
    $el.removeClass('focused');
  }

  public inputGotFocus(el: HTMLInputElement): void {
    const $el = $(el);
    this.$lastInputFocus = $el;
    $el.data('has-focus', true);
    $el.addClass('focused');
  }

  private trackLinkedInConversion(): void {

    const pixel = $('<img height="1" width="1" style="display:none;" alt="" ' +
      'src="https://dc.ads.linkedin.com/collect/?pid=165700&conversionId=241193&fmt=gif" />');

    $('body').append(pixel);

    window.setTimeout(() => {
      pixel.remove();
    }, 5000);

  }

  public processLegalDocument(done: (e?: Error) => void): void {

    if (!this.workflowDef || !this.workflowDef.pages || this.workflowDef.pages.length < 1) {
      return done();
    }

    const contractToSign: string = this.getLegalDocumentPath();

    if (!contractToSign) {
      return done();
    }

    if (this.isContractFormComplete() && this.legalDocumentState === 'next') {
      this.legalDocumentState = 'sign';
    }

    if (this.legalDocumentState === 'display') {

      this.$host.find('.chat-body').scrollTop(0);
      this.legalDocumentState = 'next';

      this.processing = false;

    } else if (this.legalDocumentState === 'next') {

      this.highlightNextLegalDocumentInputField();
      this.processing = false;

    } else {

      if (typeof this.profileFullName !== 'string') {
        this.profileFullName = '';
      }

      const nameParts = this.profileFullName.trim().split(/\s+/);

      if (nameParts.length < 1) {
        this.processing = false;
        return;
      } else if (nameParts.length < 2) {
        nameParts.push('');
      }

      this.authenticatedProfile.firstName = nameParts[0];
      this.authenticatedProfile.lastName = nameParts[nameParts.length - 1];

      this.appService.legalDocumentModel
        .sendNDAConfirmationEmail({
          profile: this.authenticatedProfile,
          instanceId: this.legalDocumentInstance.id,
          author: this.legalDocumentInstance.author,
          access: this.workflowId === 'cano-public-common' ? 'private' : null
        })
        .then((result) => {
          done();
          return null;
        })
        .catch((e) => {
          console.error('failed to send NDA confirmation email', e);
          done(e);
        });

    }


  }

  public isPageLink(): boolean {
    return !!this.getPageLink();
  }

  public goPageLink(): void {

    const link = this.getPageLink();

    const loader: ChatWorkFlowLoad = {
      id: link.id,
      force: true
    };

    if (link.hasOwnProperty('page')) {
      loader.page = link.page;
    }

    loader.options = this.workflowOptions || {};

    this.chatService.load(loader);

  }

  public getPageLink(): ChatWorkFlowPageLink {

    if (!this.hasCurrentPage()) {
      return null;
    }

    const currentPage = this.currentPage();

    if (currentPage.link) {
      return currentPage.link;
    }

    return null;

  }

  public isRedirect(): boolean {
    return !!this.getRedirect();
  }

  public goRedirect(): void {

    const redirect = this.getRedirect();

    if (!redirect || typeof redirect.url !== 'string') {
      this.hide();
      return;
    }

    let url = redirect.url;

    if (typeof redirect.return === 'string') {
      url = `${url}?${redirect.return}=${encodeURIComponent(this.location.path(false).replace(/;.*$/, '').replace(/\?.*$/, ''))}`;
    }

    this.router.navigateByUrl(url);

  }

  public getRedirect(): ChatWorkFlowRedirect {

    if (!this.hasCurrentPage()) {
      return null;
    }

    const currentPage = this.currentPage();

    if (currentPage.redirect) {
      return currentPage.redirect;
    }

    return null;

  }

  public continue(force?: boolean): void {

    if (this.isLastPage()) {
      this.closed();
      return;
    }

    if (!force && this.currentContinueDisabled()) {
      return;
    }

    this.processing = true;

    this.processLegalDocument(() => {

      this.resetLegalDocuments();
      this.workflowPageId++;

      this.chatService.pageAdvance(this.currentPage());

      if (this.isRedirect()) {
        this.goRedirect();
      } else if (this.isPageLink()) {
        this.goPageLink();
      } else if (this.isLastPage()) {
        this.chatService.complete(true);
        // } else if (this.hasContract() ) {
        //   this.loadContract();
      }

      this.processing = false;

    });

  }

  // public loadContract() : void {
  //
  // }

  public lostLoginWindow(): void {

    if (this.loginWaitNetwork) {
      this.socialLogin(this.loginWaitNetwork);
    }

  }

  public isLoggingIn(): boolean {
    return this.loginWait !== null;
  }

  public clearSocialLogin(): void {

    if (this.loginPopUp) {
      if (this.loginPopUp.close) {
        this.loginPopUp.close();
      }
      this.loginPopUp = null;
    }
    this.loginWaitNetwork = null;
    if (this.loginWait) {
      window.clearInterval(this.loginWait);
      this.loginWait = null;
    }

  }

  public socialLogin(network: string): void {

    network = network.toLowerCase();

    if (network === 'email') {

      if (!this.workflowOptions || !this.workflowOptions.contract || !this.workflowOptions.contract.id) {

        // if no contract passed to chat window, just login normal
        this.router.navigate([
          'login/email', {
            redirect: this.location.path()
          }
        ]);

        return;

      }

      // contract passed to chat window, so route login through that contract before coming back to the listing
      this.appService.legalDocumentModel
        .get(this.workflowOptions.contract.id)
        .then((legalDocument: LegalDocument) => {

          // route through document signature, and then back to the current page
          const listingUrl = encodeURIComponent(this.location.path() + ';ndaComplete=true');
          const ndaSignatureUrl = `/sign/${legalDocument.url};redirect=${listingUrl}`;

          this.router.navigate([
            'login/email', {
              redirect: ndaSignatureUrl
            }
          ]);

        })
        .catch((e) => {
          console.error('failed to get legal document', e);
        });

      return;

    }

    this.clearSocialLogin();

    // The user is about to login, clear the FullStory session
    const FS = (<any>window).FS;

    if (FS) {
      FS.clearUserCookie();
    }

    const queryStr = `login-target=${encodeURIComponent('close')}`;

    const goUrl = this.appService.api.getFullUrl('/auth/social/' + network, queryStr);

    this.loginWaitNetwork = network;
    this.loginPopUp = this.appService.windowOpen({
      url: goUrl,
      target: '_blank',
      features: 'menubar=no,titlebar=no',
      height: 700,
      width: 600
    });

    this.loginWait = window.setInterval(() => {
      if (this.loginPopUp.closed) {

        window.clearInterval(this.loginWait);
        this.loginWait = null;

        this.appService.profileModel.me()
          .then((profile) => {
            this.clearSocialLogin();
            if (profile) {
              this.appService.setAuthenticatedProfile(profile);
              this.continue(true);
            }
          })
          .catch((e) => {
            this.clearSocialLogin();
          });

      }
    }, 500);


  }

}
