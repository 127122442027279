import {Component, OnInit, OnDestroy} from '@angular/core';

import {ActivatedRoute, Router} from '@angular/router';
import {Location} from '@angular/common';
import {AppService} from 'app/services/app';
import {VideoService} from 'app/services/video';

type SignupTypes = 'seller' | 'buyer' | 'partner';

type SignupTypeDef = {
  id: SignupTypes;
  name: string;
  description: string;
  points: string[];
  link: string;
  aboutLink: string;
  videoId: string;
};

@Component({
  moduleId: module.id,
  selector: '.app-view-signup',
  templateUrl: 'main.component.html',
  styleUrls: ['main.component.css']
})
export class ViewsSignupMainComponent implements OnInit, OnDestroy {

  public signupType: SignupTypes = null;

  constructor(public appService: AppService, public videoService: VideoService, public route: ActivatedRoute, public location: Location, public router: Router) {

    this.appService.toolbar.whiteOverContent = false;
    this.appService.contentLoading(false);

    this.appService.titleService.setTitle(`Exio - Sign Up`);

  }

  public subscriptions: any[] = [];

  public ngOnDestroy(): void {
    this.subscriptions.forEach((subscription) => {
      subscription.unsubscribe();
    });
  }

  public ngOnInit(): void {

    this.appService.contentLoading(false);

    this.subscriptions.push(this.route.params.subscribe((params: { type: SignupTypes }) => {

      if (params && params.type) {
        this.signupType = params.type;
      } else {
        this.signupType = null;
      }

    }));


  }

  public changeSelection(type: SignupTypes): void {

    if (this.signupType !== type) {
      this.location.replaceState('/signup/' + type);
    }

    this.signupType = type;

  }

  public playVideo(signupTypeDef: SignupTypeDef): void {

    if (signupTypeDef && typeof signupTypeDef.videoId === 'string' && signupTypeDef.videoId.trim().length > 0) {
      this.videoService.play({videoId: signupTypeDef.videoId});
    }

  }

  public signupGo(type: SignupTypeDef): void {
    this.router.navigate([type.link]);
  }

  public getSignupTypeDefs(): SignupTypeDef[] {

    return [
      {
        id: 'seller',
        name: 'Sellers',
        description: `Ready to maximize your exit experience? Take the first step toward uncovering your company’s full market value and let Exio craft your story so you are prepared for the market when you’re ready to sell. As a potential **Seller**, joining the Exio platform allow you to:`,
        points: [
          'Utilize Exio’s exclusive technology',
          'Learn how to avoid the pitfalls and maximize your return with Exio’s expertise & experience',
          'Start generating the right content for your Private Listing',
          'Begin dialogue with Exio advisor',
          'Receive vetted buyers',
          'Manage your exit and stay in control of your deal process',
          'Access network of deal experts'
        ],
        link: '/action/signup-seller',
        aboutLink: null,
        videoId: '176810926'
      },
      {
        id: 'buyer',
        name: 'Buyers',
        description: `For those in acquisition mode, the Exio platform helps identify and attract multiple quality, pre-filtered opportunities in the most efficient and transparent manner possible. Joining the Exio platform and unlocking **Premier Buyer** status allows you to:`,
        points: [
          'Create a funnel of deal flow that meets your acquisition goals',
          'Leverage your unique vision and attributes to attract Sellers who may not have been for sale',
          'Manage your Seller engagements',
          'Let Exio craft your powerful story, including a cinematic video that highlights your Premier status',
          'Experience a custom Seller workflow that funnels you the information you value most for your due diligence process'
        ],
        link: '/action/signup-buyer',
        aboutLink: null,
        videoId: '212813103'
      },
      {
        id: 'partner',
        name: 'Partners',
        description: `If you’re a professional with clients in need of M&A services, joining the Exio platform will unlock a new stream of opportunities that will bring more value to you, your practice, and those you serve. As a **Network Partner**, creating a free account allows you to: `,
        points: [
          'Promote yourself on the Exio website by creating your Network Partner profile, which will include your portrait and a short bio',
          'Gain access to exclusive market info',
          'Know when buy/sell opportunities occur, before they go public',
          'Provide your customers with the most efficient, technologically advanced, Market Maker and Deal Maker platform',
          'Unlock a new revenue stream with Exio’s referral program'
        ],
        link: '/action/signup-partner',
        aboutLink: null,
        videoId: '208555145'
      }
    ]

  }

}
