
<div class="page-title">Contract Instances</div>
<div class="page-subtitle" *ngIf="document">for {{ document.name }}</div>
<div class="page-subtitle" *ngIf="!document">Loading...</div>
<div class="instance-controls">
  <div class="instance-control instance-add control-link" (click)="addInstance()">Add</div>
</div>
<div class="search-results-list-loading" *ngIf="instancesLoading">
  <div class="loader-spinner"></div>
</div>
<div class="search-results" *ngIf="!instancesLoading">
  <div class="search-results-list" *ngIf="instances &amp;&amp; instances.length &gt; 0">
    <div class="search-result hidden-xs">
      <div class="result-field instance-name col-md-3">Name</div>
      <div class="result-field instance-author col-md-3">Author</div>
      <div class="result-field instance-complete col-md-3">Status</div>
      <div class="result-field instance-actions col-md-3">Actions</div>
    </div>
    <div class="search-result" *ngFor="let instance of instances; let i = index;">
      <div class="result-field instance-name col-md-3">{{ instance.name }}</div>
      <div class="result-field instance-author col-md-3">{{ authorName(instance.author) }}</div>
      <div class="result-field instance-complete col-md-3" *ngIf="instance.complete">Complete</div>
      <div class="result-field instance-complete col-md-3" *ngIf="!instance.complete">In-Progress {{ signatureCount(instance) }}</div>
      <div class="result-field instance-actions col-md-3" *ngIf="removeInstanceCheck !== i">
        <div class="instance-control control-link" (click)="editInstance(instance)">Edit</div>
        <div class="instance-control">|</div>
        <div class="instance-control control-link" (click)="removeInstanceCheck = i">Remove</div>
      </div>
      <div class="result-field instance-actions col-md-3" *ngIf="removeInstanceCheck === i">Are you sure?
        <div class="instance-control control-link" (click)="removeInstanceCheck = null; removeInstance(instance)">Yes</div>
        <div class="instance-control">|</div>
        <div class="instance-control control-link" (click)="removeInstanceCheck = null;">No</div>
      </div>
    </div>
  </div>
  <div class="search-results-not-found" *ngIf="!instances || instances.length &lt; 1">No Instances Found</div>
</div>