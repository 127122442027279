import {Component, Input} from '@angular/core';
import {Article} from 'app/models/article';

@Component({
  moduleId: module.id,
  selector: '.content-render',
  templateUrl: 'main.component.pug',
  styleUrls: ['main.component.less']
})
export class ContentRenderMainComponent {

  @Input()
  public article: Article = null;

  @Input()
  public mode: 'normal' | 'edit' = 'normal';

  @Input()
  public lockedCallback: () => void = null;

  @Input()
  public accessLevel: 'public' | 'private' | 'exclusive' = 'public';

  constructor() {
  }

}
