
<div class="links-hide" *ngIf="!app.footer.showMenu"></div>
<div class="links" *ngIf="app.footer.showMenu">
  <div class="section" *ngFor="let section of getMenuItems()">
    <div class="title">{{ section.name }}</div><a *ngFor="let item of section.items" [routerLink]="item.url" [routerLinkActiveOptions]="{ exact: true }" routerLinkActive="active">{{ item.name }}</a><a class="seo-link" *ngFor="let item of section.items" [href]="item.url">{{ item.name }}</a>
  </div>
  <!--.section-->
  <!--	.title Marketplace-->
  <!--	a(routerLink="/marketplace/private-sellers", href="/marketplace/private-sellers", [routerLinkActiveOptions]="{ exact: true }", routerLinkActive="active") Private Sellers-->
  <!--	a.seo-link(href="/marketplace/private-sellers") Private Sellers-->
  <!--	a(routerLink="/marketplace/premier-buyers", [routerLinkActiveOptions]="{ exact: true }", routerLinkActive="active") Premier Buyers-->
  <!--	a.seo-link(href="/marketplace/premier-buyers") Premier Buyers-->
  <!--.section-->
  <!--	.title Services-->
  <!--	a(routerLink="/selling", [routerLinkActiveOptions]="{ exact: true }", routerLinkActive="active") Sell Your Company-->
  <!--	a.seo-link(href="/selling") Sell Your Company-->
  <!--	a(routerLink="/buying", [routerLinkActiveOptions]="{ exact: true }", routerLinkActive="active") Buy A Company-->
  <!--	a.seo-link(href="/buying") Buy A Company-->
  <!--	a(routerLink="/incubator", [routerLinkActiveOptions]="{ exact: true }", routerLinkActive="active") Grow Your Company-->
  <!--	a.seo-link(href="/incubator") Grow Your Company-->
  <!--.section-->
  <!--	.title Learn-->
  <!--	a(routerLink="/learn", [routerLinkActiveOptions]="{ exact: true }", routerLinkActive="active") About Exio-->
  <!--	a.seo-link(href="/learn") About Exio-->
  <!--	a(routerLink="/leadership", [routerLinkActiveOptions]="{ exact: true }", routerLinkActive="active") Leadership-->
  <!--	a(routerLink="/case-study", [routerLinkActiveOptions]="{ exact: true }", routerLinkActive="active") Case Study-->
  <!--	a(routerLink="/video-library", [routerLinkActiveOptions]="{ exact: true }", routerLinkActive="active") Video Library-->
  <!--	//a(routerLink="/learn-email-course", [routerLinkActiveOptions]="{ exact: true }", routerLinkActive="active") Email Course-->
  <!--	a(routerLink="/blog", [routerLinkActiveOptions]="{ exact: true }", routerLinkActive="active") Blog-->
  <!--	//a(routerLink="/news", [routerLinkActiveOptions]="{ exact: true }", routerLinkActive="active") News-->
  <!--.section-->
  <!--	.title Partners-->
  <!--	a(routerLink="/partner-program", [routerLinkActiveOptions]="{ exact: true }", routerLinkActive="active") Programs-->
  <!--	a(routerLink="/our-partners", [routerLinkActiveOptions]="{ exact: true }", routerLinkActive="active") Our Partners-->
  <!--.section-->
  <!--	.title Contact-->
  <!--	a(routerLink="/contact", [routerLinkActiveOptions]="{ exact: true }", routerLinkActive="active") Contact Us-->
</div>
<div class="break"></div>
<div class="legal">
  <div class="copyright">Copyright &copy; {{copyRightYear}} Exio. All rights reserved.</div>
  <div class="documents"><a routerLink="/privacy-policy" [routerLinkActiveOptions]="{ exact: true }" routerLinkActive="active">Privacy Policy</a>&nbsp;|&nbsp;<a routerLink="/terms-and-conditions" [routerLinkActiveOptions]="{ exact: true }" routerLinkActive="active">Terms & Conditions</a></div>
</div>