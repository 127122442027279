import { Component, OnInit, OnDestroy } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { AppService } from 'app/services/app';

@Component( {
  moduleId: module.id,
  selector: 'app-view-legal-document-sign',
  templateUrl: 'sign.component.pug',
  styleUrls: [ 'sign.component.less' ]
} )
export class ViewsLegalDocumentSignComponent implements OnInit, OnDestroy {

  public path: string = null;
  public redirect: string = null;
  public instanceId: string = null;
  public subscriptions: any[] = [];

  constructor( public app: AppService, public route: ActivatedRoute ) {

    this.app.toolbar.whiteOverContent = false;
    this.app.toolbar.backgroundColor = null;
    this.app.contentLoading( true );

  }

  public ngOnDestroy(): void {
    this.subscriptions.forEach( ( subscription ) => {
      subscription.unsubscribe();
    } );
  }

  public ngOnInit(): void {

    document.body.scrollTop = 0;

    this.subscriptions.push( this.route.params.subscribe( ( params: { path: string, redirect?: string, instanceId?: string } ) => {

      // console.log( 'sign params', params );

      this.path = params.path;
      this.redirect = params.redirect;
      this.instanceId = params.instanceId;

    } ) );

  }

}
