import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {AppService} from 'app/services/app';
import {Subscription} from 'rxjs';
import {Profile} from 'app/models/profile';

@Component({
  moduleId: module.id,
  selector: '.app-view-login-main',
  templateUrl: 'link.component.html',
  styleUrls: ['link.component.css']
})
export class ViewsLoginLinkComponent implements OnInit {

  public profile: Profile = null;
  public invalidToken: boolean = false;
  public resending: boolean = false;
  public resent: boolean = false;
  public subscriptions: Subscription[] = [];
  public email: string = null;
  public token: string = null;
  public redirect: string = null;

  constructor(public app: AppService, public router: Router, public route: ActivatedRoute) {

    this.app.contentLoading(true);
    this.app.content.backgroundLight = false;
    this.app.toolbar.whiteOverContent = true;
    this.app.toolbar.backgroundColor = '#4797EE';
    this.app.footer.showMenu = false;

    this.app.titleService.setTitle(`Exio - Login Link`);

  }

  public ngOnDestroy(): void {
    this.subscriptions.forEach((subscription) => {
      subscription.unsubscribe();
    });
  }

  ngOnInit(): void {

    this.subscriptions.push(this.app.getAuthenticatedProfile({
      next: (profile) => {

        if (typeof profile === 'boolean' && !profile) {
          // NO-OP, profile still loading
        } else {

          this.subscriptions.push(this.route.params.subscribe((params: { email: string, token: string, redirect?: string }) => {

            this.email = params.email;
            this.token = params.token;
            this.redirect = params.redirect || '/';

            if (profile && profile.email === this.email) {
              this.router.navigateByUrl(this.redirect);
              return;
            }

            this.app.profileModel.getByLoginToken({token: params.token})
              .then((result) => {

                this.profile = result.profile || null;

                if (this.profile) {
                  this.redirect = result.redirect || '/';
                } else {
                  this.app.contentLoading(false);
                  this.invalidToken = true;
                }

                if (!this.showResend()) {
                  this.doLogin(result.redirect || '/');
                }

                return null;
              })
              .catch((e) => {
                console.error('error retrieving profile from token', e);
                this.invalidToken = true;
                this.app.contentLoading(false);
              });

          }));

        }

      },
      error: () => {
        // NO-OP
      },
      complete: () => {
        // NO-OP
      }
    }));

  }

  public showProfileInComplete(): boolean {
    // return !this.invalidToken && this.profile && (typeof this.profile.firstName !== 'string' || this.profile.firstName.trim().length < 1 || typeof this.profile.lastName !== 'string' || this.profile.lastName.trim().length < 1);
    return false;
  }

  public showResend(): boolean {
    return this.invalidToken && !this.resent;
  }

  public doLogin(redirect: string): void {

    this.app.profileModel.loginByToken({token: this.token})
      .then((profile) => {

        if (!profile) {
          this.app.contentLoading(false);
          this.invalidToken = true;
          return;
        }

        this.app.setAuthenticatedProfile(profile);

        this.router.navigateByUrl(redirect);

        return null;
      })
      .catch(() => {
        this.app.contentLoading(false);
        this.invalidToken = true;
      });

  }

  public isValidResend(): boolean {
    if (typeof this.email !== 'string' || this.email.trim().length < 1) {
      return false;
    }

    return !!this.email.match(/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/);
  }

  public doResend(): void {

    if (!this.isValidResend()) {
      return;
    }

    this.resending = true;

    this.app.profileModel.sendLoginLink({email: this.email, redirect: this.redirect})
      .then(() => {
        this.resent = true;
        this.resending = false;
        return null;
      })
      .catch(() => {
        this.resending = false;
      });

  }

  public showResent(): boolean {
    return this.resent;
  }

}
