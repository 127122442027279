import {Component, OnInit, OnDestroy, ViewChild} from '@angular/core';
import {DomSanitizer} from '@angular/platform-browser';
import {ActivatedRoute, Router} from '@angular/router';
import {Location} from '@angular/common';
import * as Bluebird from 'bluebird';

import {AppService} from 'app/services/app';
import {Article, ArticleModel, AccessLevels} from 'app/models/article';
import {MailchimpModel, List as MailchimpList} from 'app/models/mailchimp';
import {LegalDocumentInstance} from 'app/models/legal.document';
import {Profile} from 'app/models/profile';
import {Hash} from 'app/types/containers';
import * as async from 'async';

import {MatTableDataSource, MatSort, MatPaginator} from '@angular/material';

import {Subscription} from 'rxjs';
import {ApiService} from '../../../services/api';

interface ProfileListRecord extends Profile {
  name: string;
}

@Component({
  moduleId: module.id,
  selector: 'app-view-listing-main',
  templateUrl: 'management.component.pug',
  styleUrls: ['management.component.less']
})
export class ViewsListingManagementComponent implements OnInit, OnDestroy {

  public pageSize = 20;
  public pageSizeOptions = [5, 10, 20, 40];
  public profileFilter: string = '';

  public subscriptions: Subscription[] = [];

  public article: Article = null;

  public mailchimpLists: MailchimpList[] = null;
  public selectedMailchimpListInvites: MailchimpList = {id: null, name: 'Default'};
  public selectedMailchimpListActiveSubscriptions: MailchimpList = {id: null, name: 'Default'};

  public api: ApiService;


  @ViewChild('adminTablePaginator', {read: MatPaginator}) adminUserProfilesFilteredDataSourcePaginator: MatPaginator;
  @ViewChild('adminTableSort', {read: MatSort}) adminUserProfilesFilteredDataSourceSort: MatSort;
  public adminUserProfilesFilteredDataSource: MatTableDataSource<ProfileListRecord> = null;
  public adminUserProfilesFiltered: Profile[] = [];
  public adminUserProfiles: Profile[] = [];

  @ViewChild('permissionsTablePaginator', {read: MatPaginator}) activeSubscriptionProfilesFilteredDataSourcePaginator: MatPaginator;
  @ViewChild('permissionsTableSort', {read: MatSort}) activeSubscriptionProfilesFilteredDataSourceSort: MatSort;
  public activeSubscriptionProfilesFilteredDataSource: MatTableDataSource<ProfileListRecord> = null;
  public activeSubscriptionProfilesFiltered: Profile[] = [];
  private activeSubscriptionProfilesRaw: Profile[] = [];
  public subscriptionProfilesDeleted: Profile[] = [];
  public adminUserProfilesDeleted: Profile[] = [];

  @ViewChild('inviteTablePaginator', {read: MatPaginator}) invitedSubscriptionsFilteredDataSourcePaginator: MatPaginator;
  @ViewChild('inviteTableSort', {read: MatSort}) invitedSubscriptionsFilteredDataSourceSort: MatSort;
  public invitedSubscriptionsFilteredDataSource: MatTableDataSource<ProfileListRecord> = null;
  public invitedSubscriptionsFiltered: Profile[] = []; // Subscriptions after applying filter
  public invitedSubscriptionsRaw: Profile[] = []; // All subscriptions from API

  @ViewChild('pendingPermissionsTableSort', {read: MatSort}) pendingApprovalsEditFilteredDataSourceSort: MatSort;
  public pendingApprovalsEditFilteredDataSource: MatTableDataSource<ProfileListRecord> = null;
  public pendingApprovalsEditFiltered: Profile[] = [];
  public pendingApprovals: Profile[] = [];
  public pendingApprovalsEdit: Profile[] = [];
  public pendingApprovalsDeleted: string[] = [];

  public authenticatedProfile: Profile = null;

  public savingSubscriptionPermissions: boolean = false;
  public savingAdminPermissions: boolean = false;
  public addingAdminUser: boolean = false;
  public addingUser: boolean = false;
  public invitingUser: boolean = false;

  public accessLevels: Hash<AccessLevels> = {};
  public accessLevelsEdit: Hash<AccessLevels | 'delete'> = {};

  public adminUsers: string[] = [];
  public adminUsersEdit: string[] = [];

  public addSubscriptionEmails: string = '';
  public addAdminEmails: string = '';

  public inviteEmails: string = '';
  public inviteAccessLevel: AccessLevels = 'public';
  public activeAccessLevel: AccessLevels = 'private';

  constructor(public appService: AppService,
              public articleModel: ArticleModel,
              public apiService: ApiService,
              public route: ActivatedRoute,
              public router: Router,
              public location: Location,
              public sanitizer: DomSanitizer,
              public mailchimp: MailchimpModel) {

    this.appService.contentLoading(true);
    this.appService.toolbar.whiteOverContent = false;
    this.appService.titleService.setTitle(`Exio - Listing Management`);

    this.updateDataSourceLists();

  }

  public ngOnInit(): void {

    const getMailchimpLists = () => {

      const approvedEmails = [
        'anthony.hildoer@goexio.com',
        'bill.littlefield@goexio.com',
        'tera.poirier@goexio.com'
      ];

      if (!this.authenticatedProfile || approvedEmails.indexOf(this.authenticatedProfile.email) < 0) {
        return;
      }

      this.mailchimp
        .getLists({
          fields: ['id', 'name']
        })
        .then((lists) => {

          // sort alphabetically
          lists = lists.sort(function (a, b) {

            const aName = a.name.toLowerCase();
            const bName = b.name.toLowerCase();

            if (aName < bName) {
              return -1;
            } else if (aName > bName) {
              return 1;
            } else {
              return 0;
            }

          });

          // add default method to start of list
          lists.unshift({id: null, name: 'Default'});

          this.mailchimpLists = lists;

        })
        .catch((e) => {
          console.error('failed to load mailchimp lists', e);
        });

    };

    this.subscriptions.push(this.appService.getAuthenticatedProfile({
      next: (profile) => {

        if (typeof profile === 'boolean' && !profile) {

          this.appService.contentLoading(true);

        } else if (!profile) {

          this.router.navigate(['/']);

        } else {

          this.authenticatedProfile = profile;

          // already have a subscription for params
          if (this.subscriptions.length > 1) {
            return;
          }

          // init with all articles
          this.subscriptions.push(this.route.params.subscribe((params: { id: string }) => {
            this.loadListing(params.id);
          }));


          getMailchimpLists();

        }

      },
      error: () => {
        this.router.navigate(['/']);
      },
      complete: () => {
        this.router.navigate(['/']);
      }
    }));

  }

  public updateDataSourceLists(): void {

    this.invitedSubscriptionsFilteredDataSource =
      new MatTableDataSource<ProfileListRecord>((<ProfileListRecord[]>this.invitedSubscriptionsFiltered)
        .map((record) => {

          record.name = this.displayName(record);
          record.accessLevel = <'public' | 'private' | 'exclusive'>this.getInviteAccessLevel(record);

          return record;

        }));
    this.invitedSubscriptionsFilteredDataSource.sort = this.invitedSubscriptionsFilteredDataSourceSort;
    this.invitedSubscriptionsFilteredDataSource.paginator = this.invitedSubscriptionsFilteredDataSourcePaginator;


    this.activeSubscriptionProfilesFilteredDataSource =
      new MatTableDataSource<ProfileListRecord>((<ProfileListRecord[]>this.activeSubscriptionProfilesFiltered)
        .map((record) => {

          record.name = this.displayName(record);
          return record;

        }));
    this.activeSubscriptionProfilesFilteredDataSource.sort = this.activeSubscriptionProfilesFilteredDataSourceSort;
    this.activeSubscriptionProfilesFilteredDataSource.paginator = this.activeSubscriptionProfilesFilteredDataSourcePaginator;


    this.pendingApprovalsEditFilteredDataSource =
      new MatTableDataSource<ProfileListRecord>((<ProfileListRecord[]>this.pendingApprovalsEditFiltered)
        .map((record) => {

          record.name = this.displayName(record);
          record.accessLevel = <'public' | 'private' | 'exclusive'>this.requestedAccessLevel(record);

          return record;

        }));
    this.pendingApprovalsEditFilteredDataSource.sort = this.pendingApprovalsEditFilteredDataSourceSort;


    this.adminUserProfilesFilteredDataSource =
      new MatTableDataSource<ProfileListRecord>((<ProfileListRecord[]>this.adminUserProfilesFiltered)
        .map((record) => {

          record.name = this.displayName(record);
          record.accessLevel = null;

          return record;

        }));
    this.adminUserProfilesFilteredDataSource.sort = this.adminUserProfilesFilteredDataSourceSort;
    this.adminUserProfilesFilteredDataSource.paginator = this.adminUserProfilesFilteredDataSourcePaginator;

  }

  public showMailchimpLists(): boolean {
    return Array.isArray(this.mailchimpLists);
  }

  public openProfile(profile: Profile): void {

    const url = this.location.prepareExternalUrl('/profile/' + profile.id);

    window.open(url, '_blank');

  }

  public ngOnDestroy(): void {
    this.subscriptions.forEach((subscription) => {
      subscription.unsubscribe();
    });
  }

  public listingName(): string {

    if (!this.article) {
      return 'Loading...';
    }

    if (!this.article.data) {
      this.article.data = {};
    }

    if (this.article.data.listingCompanyName) {
      return (<string>this.article.data.listingCompanyName).replace(/\n/g, ' ').replace(/ +/g, ' ');
    }

    return this.article.title;

  }

  public getAccessLevel(profileId: string): string {

    if (!profileId || typeof profileId !== 'string') {
      return 'public'
    }

    if (this.accessLevelsEdit &&
      this.accessLevelsEdit.hasOwnProperty(profileId) &&
      typeof this.accessLevelsEdit[profileId] === 'string') {

      return this.accessLevelsEdit[profileId];

    }

    if (this.accessLevels &&
      this.accessLevels.hasOwnProperty(profileId) &&
      typeof this.accessLevels[profileId] === 'string') {

      return this.accessLevels[profileId];
    }

    return 'public';

  }

  public accessLevelsChanged(): boolean {
    return Object.keys(this.accessLevelsEdit).length > 0 ||
      Object.keys(this.pendingApprovalsEdit).length > 0 ||
      this.subscriptionProfilesDeleted.length > 0;
  }

  public adminUsersChanged(): boolean {

    const oldValue = JSON.stringify(this.adminUsers.sort());
    const newValue = JSON.stringify(this.adminUsersEdit.sort());

    return oldValue !== newValue;

  }

  public initPage(): void {

    this.pendingApprovalsDeleted = [];
    this.activeSubscriptionProfilesRaw = [];
    this.adminUserProfiles = [];
    this.adminUsers = JSON.parse(JSON.stringify(this.article.adminUsers)); // clone
    this.adminUsersEdit = JSON.parse(JSON.stringify(this.article.adminUsers)); // clone
    this.subscriptionProfilesDeleted = [];
    this.adminUserProfilesDeleted = [];
    this.accessLevels = {};
    this.accessLevelsEdit = {};
    this.selectedMailchimpListInvites = {id: null, name: 'Default'};
    this.selectedMailchimpListActiveSubscriptions = {id: null, name: 'Default'};

    if (!this.article) {
      return;
    }
    const article = this.article;

    const subscriptionProfileIds = [];

    const pushSubscriptionProfileId = (profileId: string, level: AccessLevels): void => {
      this.accessLevels[profileId] = level;

      if (subscriptionProfileIds.indexOf(profileId) < 0) {
        subscriptionProfileIds.push(profileId);
      }

    };

    const pushProfileIdGeneric = (level: AccessLevels): ((profileId: string) => void) => {
      return (profileId: string): void => {
        pushSubscriptionProfileId(profileId, level);
      };
    };

    this.article.accessLevelPrivate.forEach(pushProfileIdGeneric('private'));
    this.article.accessLevelExclusive.forEach(pushProfileIdGeneric('exclusive'));

    async.waterfall([
      done => {

        let ids = [];

        if (this.article.accessLevelInvites && typeof this.article.accessLevelInvites === 'object') {
          ids = ids.concat(Object.keys(this.article.accessLevelInvites));
        }

        [
          'adminUsers',
          'accessLevelPrivate',
          'accessLevelExclusive',
          'accessLevelExclusivePending',
          'accessLevelPrivatePending'
        ].forEach(field => {

          if (Array.isArray(article[field])) {
            ids = ids.concat(article[field]);
          }

        });

        done(null, ids.length);


      },
      (profileCount, done) => {

        const pendingApprovals = [];

        this.article.accessLevelPrivatePending.concat(this.article.accessLevelExclusivePending).forEach((pendingId) => {
          pendingApprovals.push(pendingId);
        });

        async.mapLimit(pendingApprovals, 5, (profileId, done) => {
          this.appService.profileModel.get(profileId).asCallback(done);
        }, done);

      },
      (pendingApprovals: Profile[], done) => {

        this.pendingApprovals = this.sortProfiles(pendingApprovals);
        this.pendingApprovalsEdit = JSON.parse(JSON.stringify(this.pendingApprovals));

        done();
      },
      (done) => {

        if (!this.article.accessLevelInvites) {
          return done(null, []);
        }

        async.mapLimit(Object.keys(this.article.accessLevelInvites), 5, (profileId, done) => {
          this.appService.profileModel.get(profileId).asCallback(done);
        }, done);

      },
      (inviteProfiles, done) => {

        this.invitedSubscriptionsRaw = [];

        inviteProfiles.forEach((profile) => {
          if (profile) {
            this.invitedSubscriptionsRaw.push(profile);
          }
        });

        this.invitedSubscriptionsRaw = this.sortProfiles(this.invitedSubscriptionsRaw);

        done();

      },
      (done) => {

        async.mapLimit(subscriptionProfileIds, 5, (profileId, done) => {
          this.appService.profileModel.get(profileId).asCallback(done);
        }, done);

      },
      (subscriptionProfiles, done) => {

        subscriptionProfiles.forEach((subscriptionProfile) => {
          this.pushSubscriptionProfile(subscriptionProfile);
        });

        done();

      },
      (done) => {

        async.mapLimit(this.article.adminUsers, 5, (profileId, done) => {
          this.appService.profileModel.get(profileId).asCallback(done);
        }, done);

      },
      (adminUserProfiles, done) => {

        adminUserProfiles.forEach((profile) => {
          this.pushAdminProfile(profile);
        });

        done();

      },
      (done) => {
        this.applyProfileFilter();
        done();
      }
    ]);

  }

  public sortProfiles(profiles: Profile[]): Profile[] {

    return profiles.sort((a, b) => {

      const aName = this.displayNameAndEmail(a);
      const bName = this.displayNameAndEmail(b);

      if (aName < bName) {
        return -1;
      }

      if (aName > bName) {
        return 1;
      }

      return 0;

    });

  }

  public pushProfileGeneric(profile: Profile, list: Profile[]): Profile[] {

    if (!profile) {
      return;
    }

    const index = this.profileIndexOf(profile, list);

    if (index !== null) {
      return;
    }

    list.push(profile);


    const temp = this.sortProfiles(list);

    this.applyProfileFilter();

    return temp;

  }

  public pushSubscriptionProfile(profile: Profile): void {
    this.pushProfileGeneric(profile, this.activeSubscriptionProfilesRaw);
  }

  public pushAdminProfile(profile: Profile): void {
    this.pushProfileGeneric(profile, this.adminUserProfiles);
  }

  public loadListing(id?: string): Bluebird<any> {

    if (!id) {

      if (!this.article || !this.article.id) {
        this.appService.contentLoading(false);
        console.error('Can not load article without an id');
        return;
      }

      id = this.article.id;

    }

    return this.articleModel.get(id, true)
      .then((article) => {

        this.article = article || null;

        if (this.article) {
          this.appService.contentLoading(false);
        } else {
          this.appService.contentLoading(false, 404);
        }

        if (!this.authenticatedProfile || !this.authenticatedProfile.isEmployee) {

          const adminUsers = Array.isArray(article.adminUsers) ? article.adminUsers : [];

          if (!this.authenticatedProfile.id || adminUsers.indexOf(this.authenticatedProfile.id) < 0) {
            this.router.navigate(['/']);
          }

        }

        return null;

      })
      .catch((e) => {
        console.error('failed to load listing article', e);
        return null;
      })
      .finally(() => {
        this.initPage();
      });

  }

  public setAccessLevel(profileId: string, accessLevel: AccessLevels): void {

    if (this.accessLevels[profileId] === accessLevel) {
      delete this.accessLevelsEdit[profileId];
    } else {
      this.accessLevelsEdit[profileId] = accessLevel;
    }

    this.removeFromDeleted(profileId);

  }

  public saveSubscriptions(): Bluebird<any> {

    if (!this.accessLevelsChanged()) {
      return;
    }

    const editedAccessLevels = this.accessLevelsEdit;

    this.savingSubscriptionPermissions = true;

    // this.article.accessLevelPrivatePending.concat( this.article.accessLevelExclusivePending ).forEach( ( pendingId ) => {
    // 	pendingApprovals.push( pendingId );
    // } );

    const article = new Article({
      id: this.article.id,
      type: this.article.type,
      deleteAccess: [],
      accessLevelPrivate: [],
      accessLevelExclusive: []
    });

    for (const profileId in editedAccessLevels) {

      if (!editedAccessLevels.hasOwnProperty(profileId)) {
        continue;
      }

      const editedAccessLevel = editedAccessLevels[profileId];

      const accessLevelChanged = !this.accessLevels.hasOwnProperty(profileId) || this.accessLevels[profileId] !== editedAccessLevel;

      if (editedAccessLevel === 'exclusive') {

        // add exclusive access
        if (article.accessLevelExclusive.indexOf(profileId) < 0) {
          article.accessLevelExclusive.push(profileId);
        }

        // remove private access
        const privateIndex = article.accessLevelPrivate.indexOf(profileId);
        if (privateIndex > -1) {
          article.accessLevelPrivate.splice(privateIndex, 1);
        }

      } else if (editedAccessLevel === 'private') {

        // add private access
        if (article.accessLevelPrivate.indexOf(profileId) < 0) {
          article.accessLevelPrivate.push(profileId);
        }

        // remove exclusive access
        const exclusiveIndex = article.accessLevelExclusive.indexOf(profileId);
        if (exclusiveIndex > -1) {
          article.accessLevelExclusive.splice(exclusiveIndex, 1);
        }

      }

      // TODO: move this to the server
      if (accessLevelChanged) {

        this.appService.profileModel
          .get(profileId)
          .asCallback((err, profile) => {

            if (err) {
              console.error('failed to get profile to email user', err);
              return;
            }

            if (!profile.id) {
              profile.id = profileId;
            }

            this.emailLink(profile, editedAccessLevel, false, this.selectedMailchimpListActiveSubscriptions);

          });

      }

    }

    const deletedIds = this.subscriptionProfilesDeleted.map((profile) => profile.id);

    return this.articleModel.save({...article, deleteAccess: deletedIds})
      .catch((e) => {
        console.error('failed to save article', e);
        return null;
      })
      .finally(() => {
        this.savingSubscriptionPermissions = false;
        return this.loadListing();
      });

  }

  public mailchimpListDisplay(list: MailchimpList): string {
    return list ? list.name : undefined;
  }

  public displayNameAndEmail(profile: Profile): string {

    if (!profile) {
      return null;
    }

    return this.displayName(profile) + '<' + this.displayEmail(profile) + '>';
  }

  public displayName(profile: Profile): string {

    if (!profile) {
      return null;
    }

    let name = 'Name Missing <' + profile.id + '>';

    const firstName = (typeof profile.firstName === 'string' && profile.firstName.trim().length > 1)
      ? profile.firstName.trim() : null;

    const lastName = (typeof profile.lastName === 'string' && profile.lastName.trim().length > 1)
      ? profile.lastName.trim() : null;

    if (firstName && lastName) {
      name = `${lastName}, ${firstName}`;
    } else if (firstName) {
      name = `<missing>, ${firstName}`;
    } else if (lastName) {
      name = `${lastName}, <missing>`;
    }

    if (profile.id === this.authenticatedProfile.id) {
      name += ' (your account)';
    }

    return name.trim();

  }

  public displayEmail(profile: Profile): string {

    if (!profile) {
      return null;
    }

    let email = profile.email;

    if (typeof email !== 'string' || email.trim().length < 3) {
      email = '<missing>'
    }

    return email.trim();

  }

  public profileIndexOf(profile: Profile, profiles: Profile[]): number {
    return this.profileIndexOfId(profile.id, profiles);
  }

  public profileIndexOfId(profileId: string, profiles: Profile[]): number {

    let index = null;

    profiles.forEach((searchProfile, i) => {
      if (searchProfile && searchProfile.id === profileId) {
        index = i;
      }
    });

    return index;

  }

  public resendInvite(profile: Profile): void {

    const accessLevel = this.getInviteAccessLevel(profile);

    this._sendInvites([profile.email], accessLevel);

  }

  public emailLink(profile: Profile,
                   accessLevel: AccessLevels | 'delete',
                   needNDA: boolean,
                   mailchimpList: MailchimpList,
                   done?: () => void): void {

    if (typeof done !== 'function') {

      done = () => {
        // NO-OP
      };

    }

    let accessLevelDisplay: 'Public' | 'Private' | 'Exclusive' = 'Public';

    if (accessLevel === 'exclusive') {
      accessLevelDisplay = 'Exclusive';
    } else if (accessLevel === 'private') {
      accessLevelDisplay = 'Private';
    }

    const title = needNDA ? this.article.title : this.listingName();

    // default to sending them to the article
    let redirect = this.article.path;

    let body = `<p>You have been granted ${accessLevelDisplay} Access to the listing ${title}.` +
      ` Click 'View Listing' below to view the listing now.</p>`;

    async.waterfall([
      (done) => {

        if (needNDA) {

          body += `<p>An NDA is required to view this listing. If you have not already signed one you will be` +
            ` prompted to sign an NDA to protect the confidentiality of the listing.</p>`;

          this.appService
            .legalDocumentModel
            .upsertArticleNdaInstance({
              signerId: profile.id,
              legalDocumentId: this.article.nda,
              articleId: this.article.id,
              accessLevel: accessLevel
            })
            .then((legalDocumentInstance: LegalDocumentInstance) => {

              if (!legalDocumentInstance || !legalDocumentInstance.id) {
                throw new Error('nda instance not found');
              }

              // route through document sign instead
              redirect = `/sign/non-disclosure-agreement/${legalDocumentInstance.id};redirect=` + encodeURIComponent(redirect);

            })
            .return()
            .asCallback(done);

        } else {
          done();
        }

      },
      (done) => {

        let from: string = null;

        if (this.authenticatedProfile) {
          if (this.authenticatedProfile.email) {
            from = this.authenticatedProfile.email;

            if (this.authenticatedProfile.firstName && this.authenticatedProfile.lastName) {
              from = `${this.authenticatedProfile.firstName} ${this.authenticatedProfile.lastName}<${from}>`;
            }

          }
        }

        this.appService.profileModel
          .sendLoginLink({
            from: from,
            email: profile.email,
            redirect: redirect,
            subject: accessLevelDisplay + ' Access Granted to listing ' + title,
            body: body,
            headlines: [
              accessLevelDisplay + ' Access Granted'
            ],
            cta: 'View Listing',
            mailChimpList: {
              listId: mailchimpList ? mailchimpList.id : null,
              access: accessLevelDisplay
            }
          })
          .return()
          .asCallback(done);

      }
    ], done);

  }

  public declineAccessRequest(profile: Profile): void {

    if (!profile || !profile.id) {
      return;
    }

    const index = this.profileIndexOf(profile, this.pendingApprovalsEdit);
    if (index !== null) {
      this.subscriptionProfilesDeleted.push(this.pendingApprovalsEdit[index]);
      this.pendingApprovalsEdit.splice(index, 1);
    }
    this.pendingApprovalsDeleted.push(profile.id);

    this.applyProfileFilter();

  }

  public removeSubscriptionAccess(profile: Profile): void {

    if (!profile || !profile.id) {
      return;
    }

    const index = this.profileIndexOf(profile, this.activeSubscriptionProfilesRaw);

    if (index !== null) {
      this.subscriptionProfilesDeleted.push(this.activeSubscriptionProfilesRaw[index]);
      this.activeSubscriptionProfilesRaw.splice(index, 1);
    }

    this.applyProfileFilter();

  }

  public removeAdminAccess(profile: Profile): void {

    if (profile && profile.id) {

      let index = this.adminUsersEdit.indexOf(profile.id);

      if (index !== null) {
        this.adminUsersEdit.splice(index, 1);
      }

      index = this.profileIndexOf(profile, this.adminUserProfiles);

      if (index !== null) {
        this.adminUserProfilesDeleted.push(this.adminUserProfiles[index]);
        this.adminUserProfiles.splice(index, 1);
      }

      this.applyProfileFilter();

    }

  }

  public cancelSubscriptions(): void {

    if (!this.accessLevelsChanged()) {
      return;
    }

    // while ( this.subscriptionProfilesDeleted.length > 0 ) {
    // 	this.pushSubscriptionProfile( this.subscriptionProfilesDeleted.pop() );
    // }
    //
    // // poor man's clone
    // this.accessLevelsEdit = JSON.parse( JSON.stringify( this.accessLevels ) );

    this.initPage();

  }

  public downloadCSV(): void {
    this.apiService.download(`/downloads/listing/${this.article.id}/activity`);
  }

  public cancelAdmins(): void {

    if (!this.adminUsersChanged()) {
      return;
    }

    // while ( this.adminUserProfilesDeleted.length > 0 ) {
    // 	this.pushAdminProfile( this.adminUserProfilesDeleted.pop() );
    // }
    //
    // this.adminUsersEdit = JSON.parse( JSON.stringify( this.adminUsers ) );

    this.initPage();

  }

  public addSubscriptionUsers(): void {

    this.addingUser = true;
    const emails = this.parseEmailList(this.addSubscriptionEmails);
    this.addSubscriptionEmails = '';
    const activeAccessLevel = this.activeAccessLevel;
    this.activeAccessLevel = 'private';

    async.each(emails, (email, done) => {
      this.appService.profileModel.upsertByEmail(email)
        .then((profile) => {

          const existingProfile = this.profileIndexOf(profile, this.activeSubscriptionProfilesRaw);

          if (existingProfile === null) {
            this.activeSubscriptionProfilesRaw.push(profile);
            this.setAccessLevel(profile.id, activeAccessLevel);
          } else {
            console.error('profile already exists', profile);
          }

        })
        .return()
        .asCallback(done);
    }, () => {
      this.addingUser = false;
      this.applyProfileFilter();
    });

  }

  private removeFromDeleted(profileId: string): void {
    let index = this.pendingApprovalsDeleted.indexOf(profileId);
    if (index > -1) {
      this.pendingApprovalsDeleted.splice(index, 1);
    }
    index = this.subscriptionProfilesDeleted.findIndex((profile) => profile.id === profileId);
    if (index > -1) {
      this.subscriptionProfilesDeleted.splice(index, 1);
    }
  }

  public addAdminUsers(): void {

    this.addingAdminUser = true;

    const emails = this.parseEmailList(this.addAdminEmails);
    this.addAdminEmails = '';

    async.each(emails, (email, done) => {
      this.appService.profileModel.upsertByEmail(email)
        .then((profile) => {
          if (profile && profile.id) {

            if (this.adminUsersEdit.indexOf(profile.id) < 0) {
              this.adminUsersEdit.push(profile.id);
            }

            this.pushAdminProfile(profile);
          }

        })
        .return()
        .asCallback(done);
    }, () => {
      this.addingAdminUser = false;
      this.updateDataSourceLists();
    });

  }

  public saveAdmins(): void {

    if (!this.adminUsersChanged()) {
      return;
    }

    this.savingAdminPermissions = true;

    const article = new Article({
      id: this.article.id,
      type: this.article.type,
      adminUsers: this.adminUsersEdit
    });

    this.articleModel
      .save(article)
      .then(() => {
        this.savingAdminPermissions = false;
        return null;
      })
      .catch(() => {
        this.savingAdminPermissions = false;
        return null;
      })
      .finally(() => {
        return this.loadListing();
      });

  }

  public sendNewInvites(): void {

    const emails = this.parseEmailList(this.inviteEmails);
    this.inviteEmails = '';

    const accessLevel = this.inviteAccessLevel;
    this.inviteAccessLevel = 'public';

    this._sendInvites(emails, accessLevel);

  }

  public _sendInvites(emails: string[], accessLevel: AccessLevels): void {

    // console.log( '_sendInvites', emails, accessLevel );

    this.invitingUser = true;
    emails = emails
      .filter((email) => {
        return typeof email === 'string' && email.trim().length >= 3 && email.match(/@/);
      })
      .map((email) => {
        return email.trim().toLowerCase();
      });

    const usersToMail: Profile[] = [];
    const mailChimpList = this.selectedMailchimpListInvites || null;

    async.waterfall([
      (done) => {

        // const userIds = [];

        async.eachLimit(emails, 2, (email, done) => {

          async.waterfall([
            (done) => {
              this.appService.profileModel.upsertByEmail(email).asCallback(done);
            },
            (profile: Profile, done) => {

              if (!profile || !profile.id) {
                return done();
              }

              // userIds.push( profile.id );

              if (!this.article.accessLevelInvites) {
                this.article.accessLevelInvites = {};
              }


              this.article.accessLevelInvites[profile.id] = accessLevel;

              usersToMail.push(profile);

              done();

            }
          ], done);

        }, done);

      },
      (done) => {
        this.saveInvites(done);
      },
      (done) => {

        // const userIds = [];

        async.eachLimit(usersToMail, 2, (user, done) => {
          this.emailLink(user, accessLevel, false, mailChimpList, done);
        }, done);

      }
    ], (err) => {
      this.invitingUser = false;
    });

  }

  public parseEmailList(emailList: string): string[] {

    const emails = emailList.split(',');

    emails.forEach((email, i) => {
      emails[i] = email.trim().toLowerCase();
    });

    return emails;

  }

  public approveAccessRequest(profile: Profile): void {

    // add profile as if manually added to active subscriptions
    this.accessLevelsEdit[profile.id] = this.requestedAccessLevel(profile);
    this.pushSubscriptionProfile(profile);

    const index = this.profileIndexOf(profile, this.pendingApprovalsEdit);
    if (index !== null) {
      this.pendingApprovalsEdit.splice(index, 1);
    }

    this.applyProfileFilter();

  }

  public requestedAccessLevel(profile: Profile): AccessLevels {

    let accessLevel: AccessLevels = 'public';

    if (this.article.accessLevelExclusivePending.indexOf(profile.id) > -1) {
      accessLevel = 'exclusive';
    } else if (this.article.accessLevelPrivatePending.indexOf(profile.id) > -1) {
      accessLevel = 'private';
    }

    return accessLevel;

  }

  public requestedAccessLevelDisplay(profile: Profile): string {

    switch (this.requestedAccessLevel(profile)) {
      case 'public':
        return 'Public';
      case 'private':
        return 'Private';
      case 'exclusive':
        return 'Exclusive';
      default:
        return 'Public';
    }
  }

  public archiveInvites(): void {

    const article = new Article({
      id: this.article.id,
      type: this.article.type,
      accessLevelInvites: {}
    });

    this.invitedSubscriptionsFiltered.map(profile => profile).forEach((profile) => {
      article.accessLevelInvites[profile.id] = null;
      this._archiveInvite(profile);
    });

    this.articleModel.save(article)
      .then(() => {
        this.invitingUser = false;
        return null;
      })
      .catch(() => {
        this.invitingUser = false;
        return null;
      })
      .finally(() => {
        return this.loadListing();
      });

  }

  public _archiveInvite(profile: Profile): void {


    const index = this.profileIndexOf(profile, this.invitedSubscriptionsFiltered);

    if (index !== null) {
      this.invitedSubscriptionsFiltered.splice(index, 1);
      this.updateDataSourceLists();
    }

    delete this.article.accessLevelInvites[profile.id];

  }

  public applyProfileFilter(): void {

    const filter = this.profileFilter
      .trim()
      .toLowerCase()
      .replace(/,/g, ' ')
      .replace(/\s+/g, ' ')
      .split(/\s+/);

    const matches = (profile): boolean => {

      if (!profile) {
        return false;
      }

      const name = this.displayNameAndEmail(profile).toLowerCase();

      let result = true; // profile matches until one bit misses

      filter.forEach((bit) => {

        if (result === false) { // we already missed, skip this bit check for this profile
          return;
        }

        if (name.indexOf(bit) < 0) { // if bit misses, skip profile
          result = false;
        }

      });

      return result;

    };

    this.invitedSubscriptionsFiltered = []; // clear the array
    this.invitedSubscriptionsRaw.forEach((profile) => {
      if (matches(profile)) {
        this.invitedSubscriptionsFiltered.push(profile);
      }
    });

    // this.pendingApprovalsEdit = JSON.parse( JSON.stringify( this.pendingApprovals ) );
    this.pendingApprovalsEditFiltered = [];
    this.pendingApprovalsEdit.forEach((profile) => {
      if (matches(profile)) {
        this.pendingApprovalsEditFiltered.push(profile);
      }
    });

    this.activeSubscriptionProfilesFiltered = [];
    this.activeSubscriptionProfilesRaw.forEach((profile) => {
      if (matches(profile)) {
        this.activeSubscriptionProfilesFiltered.push(profile);
      }
    });

    this.adminUserProfilesFiltered = [];
    this.adminUserProfiles.forEach((profile) => {
      if (matches(profile)) {
        this.adminUserProfilesFiltered.push(profile);
      }
    });

    this.updateDataSourceLists();

  }

  public tabSectionChanged(): void {
    this.loadListing();
  }

  public archiveInvite(profile: Profile): void {

    this._archiveInvite(profile);

    const article = new Article({
      id: this.article.id,
      type: this.article.type,
      accessLevelInvites: {}
    });

    article.accessLevelInvites[profile.id] = null;

    this.articleModel.save(article)
      .then(() => {
        this.invitingUser = false;
        return null;
      })
      .catch(() => {
        this.invitingUser = false;
      })
      .finally(() => {
        this.loadListing();
      });

  }

  public saveInvites(done): void {

    const article = new Article({
      id: this.article.id,
      type: this.article.type,
      author: this.article.author,
      accessLevelInvites: this.article.accessLevelInvites
    });

    this.articleModel
      .save(article)
      .then(() => {
        return this.loadListing()
      })
      .return()
      .asCallback(done)

  }

  public getInviteAccessLevel(profile: Profile): AccessLevels {

    if (profile && profile.id &&
      this.article && this.article.accessLevelInvites &&
      this.article.accessLevelInvites.hasOwnProperty(profile.id)) {

      return (<Hash<AccessLevels>>this.article.accessLevelInvites)[profile.id];

    }

    return 'public';

  }

  public inviteAccessLevelDisplay(profile: Profile): string {

    const accessLevel = this.getInviteAccessLevel(profile);

    if (accessLevel === 'private') {
      return 'Private';
    }

    if (accessLevel === 'exclusive') {
      return 'Exclusive';
    }


    return 'Public';

  }

}
