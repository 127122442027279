import * as tslib_1 from "tslib";
import { ApiService } from 'app/services/api';
// Types
import { GenericModel, GenericMasterSchemaType } from './generic';
// Schema Type
var Questionnaire = /** @class */ (function (_super) {
    tslib_1.__extends(Questionnaire, _super);
    function Questionnaire() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    return Questionnaire;
}(GenericMasterSchemaType));
export { Questionnaire };
var QuestionnaireModel = /** @class */ (function (_super) {
    tslib_1.__extends(QuestionnaireModel, _super);
    function QuestionnaireModel(api) {
        var _this = _super.call(this) || this;
        _this.api = api;
        _this.init(api, 'questionnaire', {
            id: null,
            title: null,
            url: null
        }, 0);
        return _this;
    }
    QuestionnaireModel.prototype.getInstance = function (ref) {
        return ref;
    };
    QuestionnaireModel.prototype.upsertSession = function (listingId) {
        return this.api.call('questionnaire.upsertSession', { listingId: listingId });
    };
    QuestionnaireModel.prototype.getSessionsByUserId = function (userId) {
        return this.api.call('questionnaire.getSessionsByUserId', { userId: userId });
    };
    QuestionnaireModel.prototype.getSessionBySubmissionId = function (submissionId) {
        return this.api.call('questionnaire.getSessionBySubmissionId', { submissionId: submissionId });
    };
    return QuestionnaireModel;
}(GenericModel));
export { QuestionnaireModel };
