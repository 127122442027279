import { Component, ElementRef, OnInit } from '@angular/core';

import { AppService } from 'app/services/app';

@Component( {
	moduleId: module.id,
	selector: 'app-social-share',
	templateUrl: 'social.share.component.html',
	styleUrls: [ 'social.share.component.css' ]
} )
export class SocialShareComponent implements OnInit {

	public shareSetup: boolean = false;

	public $el: JQuery = null;

	constructor( public app: AppService, public element: ElementRef ) {
		this.$el = $( element.nativeElement );
	}

	public ngOnInit(): void {

		let checkShares = () => {

			if ( this.shareSetup ) {
				return;
			}

			let $shareIcons = this.$el.find( '.share-icons' );

			if ( $shareIcons.length > 0 && !this.app.content.loading ) {
				this.shareSetup = true;

				$shareIcons.jsSocials( {
					showLabel: false,
					showCount: false,
					shares: [ "linkedin", "twitter", "facebook", "googleplus", "pinterest", "email" ]
				} );

			} else {
				setTimeout( checkShares, 1000 );
			}

		};

		checkShares();

	}


}
