
<div class="to-top">
  <div class="to-top-container" (click)="scrollUp()">
    <div class="component-icon scroll-up"></div>
  </div>
</div>
<div class="to-top floater">
  <div class="to-top-container" (click)="scrollUp()">
    <div class="component-icon scroll-up"></div>
  </div>
</div>
<div class="break"></div>