/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./vimeo.service.component.less.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "./loader.spinner.component.ngfactory";
import * as i3 from "./loader.spinner.component";
import * as i4 from "@angular/common";
import * as i5 from "./vimeo.service.component";
import * as i6 from "../../services/app";
import * as i7 from "../../services/api";
import * as i8 from "../../services/video";
var styles_ContentVimeoServiceComponent = [i0.styles];
var RenderType_ContentVimeoServiceComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_ContentVimeoServiceComponent, data: {} });
export { RenderType_ContentVimeoServiceComponent as RenderType_ContentVimeoServiceComponent };
function View_ContentVimeoServiceComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 0, "div", [["class", "vimeo-thumbnail-play"]], null, null, null, null, null))], null, null); }
function View_ContentVimeoServiceComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 0, "div", [["class", "vimeo-thumbnail-play-locked"]], null, null, null, null, null))], null, null); }
function View_ContentVimeoServiceComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "div", [["class", "vimeo-title"]], null, null, null, null, null)), (_l()(), i1.ɵted(1, null, ["", ""]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.metaData.name.trim(); _ck(_v, 1, 0, currVal_0); }); }
function View_ContentVimeoServiceComponent_4(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 0, "div", [["class", "vimeo-description"]], [[8, "innerHTML", 1]], null, null, null, null))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.getDescription(); _ck(_v, 0, 0, currVal_0); }); }
export function View_ContentVimeoServiceComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 14, "div", [["class", "vimeo-container"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 0, "div", [["class", "vimeo-message vimeo-hide"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 2, "div", [["class", "vimeo-wait vimeo-hide"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 1, "div", [["class", "loader-spinner"]], null, null, null, i2.View_ContentLoaderSpinnerComponent_0, i2.RenderType_ContentLoaderSpinnerComponent)), i1.ɵdid(4, 49152, null, 0, i3.ContentLoaderSpinnerComponent, [], null, null), (_l()(), i1.ɵeld(5, 0, null, null, 9, "div", [["class", "vimeo-content"]], null, null, null, null, null)), (_l()(), i1.ɵeld(6, 0, null, null, 8, "div", [["class", "vimeo-thumbnail"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ContentVimeoServiceComponent_1)), i1.ɵdid(8, 16384, null, 0, i4.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ContentVimeoServiceComponent_2)), i1.ɵdid(10, 16384, null, 0, i4.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ContentVimeoServiceComponent_3)), i1.ɵdid(12, 16384, null, 0, i4.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ContentVimeoServiceComponent_4)), i1.ɵdid(14, 16384, null, 0, i4.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = !_co.locked; _ck(_v, 8, 0, currVal_0); var currVal_1 = _co.locked; _ck(_v, 10, 0, currVal_1); var currVal_2 = _co.hasTitle(); _ck(_v, 12, 0, currVal_2); var currVal_3 = _co.hasDescription(); _ck(_v, 14, 0, currVal_3); }, null); }
export function View_ContentVimeoServiceComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "div", [["class", "vimeo-player-service"]], null, null, null, View_ContentVimeoServiceComponent_0, RenderType_ContentVimeoServiceComponent)), i1.ɵdid(1, 245760, null, 0, i5.ContentVimeoServiceComponent, [i6.AppService, i7.ApiService, i8.VideoService, i1.ElementRef, i1.NgZone, i1.ChangeDetectorRef], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var ContentVimeoServiceComponentNgFactory = i1.ɵccf(".vimeo-player-service", i5.ContentVimeoServiceComponent, View_ContentVimeoServiceComponent_Host_0, { videoId: "videoId", thumbnailUrl: "thumbnailUrl", fillType: "fillType", locked: "locked", lockedCallback: "lockedCallback", hideTitle: "hideTitle", hideDescription: "hideDescription", metaData: "metaData" }, {}, []);
export { ContentVimeoServiceComponentNgFactory as ContentVimeoServiceComponentNgFactory };
