/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "@angular/forms";
import * as i2 from "@angular/common";
import * as i3 from "./select.access.level.component";
import * as i4 from "../../../services/app";
var styles_StandardInputSelectAccessLevelComponent = [];
var RenderType_StandardInputSelectAccessLevelComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_StandardInputSelectAccessLevelComponent, data: {} });
export { RenderType_StandardInputSelectAccessLevelComponent as RenderType_StandardInputSelectAccessLevelComponent };
function View_StandardInputSelectAccessLevelComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 3, "option", [], null, null, null, null, null)), i0.ɵdid(1, 147456, null, 0, i1.NgSelectOption, [i0.ElementRef, i0.Renderer2, [2, i1.SelectControlValueAccessor]], { value: [0, "value"] }, null), i0.ɵdid(2, 147456, null, 0, i1.ɵangular_packages_forms_forms_s, [i0.ElementRef, i0.Renderer2, [8, null]], { value: [0, "value"] }, null), (_l()(), i0.ɵted(3, null, ["", ""]))], function (_ck, _v) { var currVal_0 = _v.context.$implicit[0]; _ck(_v, 1, 0, currVal_0); var currVal_1 = _v.context.$implicit[0]; _ck(_v, 2, 0, currVal_1); }, function (_ck, _v) { var currVal_2 = _v.context.$implicit[1]; _ck(_v, 3, 0, currVal_2); }); }
export function View_StandardInputSelectAccessLevelComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 7, "select", [], [[2, "ng-untouched", null], [2, "ng-touched", null], [2, "ng-pristine", null], [2, "ng-dirty", null], [2, "ng-valid", null], [2, "ng-invalid", null], [2, "ng-pending", null]], [[null, "change"], [null, "blur"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("change" === en)) {
        var pd_0 = (i0.ɵnov(_v, 1).onChange($event.target.value) !== false);
        ad = (pd_0 && ad);
    } if (("blur" === en)) {
        var pd_1 = (i0.ɵnov(_v, 1).onTouched() !== false);
        ad = (pd_1 && ad);
    } if (("change" === en)) {
        var pd_2 = (_co.accessLevelChanged($event) !== false);
        ad = (pd_2 && ad);
    } return ad; }, null, null)), i0.ɵdid(1, 16384, null, 0, i1.SelectControlValueAccessor, [i0.Renderer2, i0.ElementRef], null, null), i0.ɵprd(1024, null, i1.NG_VALUE_ACCESSOR, function (p0_0) { return [p0_0]; }, [i1.SelectControlValueAccessor]), i0.ɵdid(3, 671744, null, 0, i1.NgModel, [[8, null], [8, null], [8, null], [6, i1.NG_VALUE_ACCESSOR]], { model: [0, "model"] }, null), i0.ɵprd(2048, null, i1.NgControl, null, [i1.NgModel]), i0.ɵdid(5, 16384, null, 0, i1.NgControlStatus, [[4, i1.NgControl]], null, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_StandardInputSelectAccessLevelComponent_1)), i0.ɵdid(7, 278528, null, 0, i2.NgForOf, [i0.ViewContainerRef, i0.TemplateRef, i0.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_7 = _co.accessLevel; _ck(_v, 3, 0, currVal_7); var currVal_8 = _co.options; _ck(_v, 7, 0, currVal_8); }, function (_ck, _v) { var currVal_0 = i0.ɵnov(_v, 5).ngClassUntouched; var currVal_1 = i0.ɵnov(_v, 5).ngClassTouched; var currVal_2 = i0.ɵnov(_v, 5).ngClassPristine; var currVal_3 = i0.ɵnov(_v, 5).ngClassDirty; var currVal_4 = i0.ɵnov(_v, 5).ngClassValid; var currVal_5 = i0.ɵnov(_v, 5).ngClassInvalid; var currVal_6 = i0.ɵnov(_v, 5).ngClassPending; _ck(_v, 0, 0, currVal_0, currVal_1, currVal_2, currVal_3, currVal_4, currVal_5, currVal_6); }); }
export function View_StandardInputSelectAccessLevelComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "div", [["class", "input-type-select-access-level"]], null, null, null, View_StandardInputSelectAccessLevelComponent_0, RenderType_StandardInputSelectAccessLevelComponent)), i0.ɵdid(1, 114688, null, 0, i3.StandardInputSelectAccessLevelComponent, [i4.AppService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var StandardInputSelectAccessLevelComponentNgFactory = i0.ɵccf(".input-type-select-access-level", i3.StandardInputSelectAccessLevelComponent, View_StandardInputSelectAccessLevelComponent_Host_0, { accessLevel: "accessLevel" }, { accessLevelChange: "accessLevelChange" }, []);
export { StandardInputSelectAccessLevelComponentNgFactory as StandardInputSelectAccessLevelComponentNgFactory };
