import { Component } from '@angular/core';

@Component( {
	moduleId: module.id,
	selector: '.loader-spinner',
	templateUrl: 'loader.spinner.component.html',
	styleUrls: [ 'loader.spinner.component.css' ],

} )
export class ContentLoaderSpinnerComponent {

	constructor() {
	}

}
