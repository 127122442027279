import {Injectable} from '@angular/core';
import {ApiService} from 'app/services/api';
import * as Bluebird from 'bluebird';

// Types
import {GenericModel} from './generic';

// Schema Type
export type List = {
  id: string;
  name: string;
}

@Injectable()
export class MailchimpModel extends GenericModel<List> {

  constructor(api: ApiService) {
    super();
    this.init(api, 'vimeo', {
      id: null,
      name: null
    }, 10000);
  }

  public getInstance(ref: any): List {
    return ref;
  }

  getLists(params?: {
    fields?: string[]
  }): Bluebird<List[]> {
    return this.api.call('mailchimp.getLists', params || {});
  }

}
