/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./grid.component.less.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/forms";
import * as i3 from "@angular/common";
import * as i4 from "./grid.component";
import * as i5 from "../../../services/app";
var styles_ContentRenderGridComponent = [i0.styles];
var RenderType_ContentRenderGridComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_ContentRenderGridComponent, data: {} });
export { RenderType_ContentRenderGridComponent as RenderType_ContentRenderGridComponent };
function View_ContentRenderGridComponent_5(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 0, "div", [["class", "section-grid-cell-content-inner"]], [[8, "innerHTML", 1]], null, null, null, null))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.getFieldContent(_v.parent.parent.parent.context.$implicit, _v.parent.parent.context.$implicit); _ck(_v, 0, 0, currVal_0); }); }
function View_ContentRenderGridComponent_6(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 5, "textarea", [["class", "section-grid-cell-content-inner editing"], ["placeholder", "Enter markdown here"]], [[2, "ng-untouched", null], [2, "ng-touched", null], [2, "ng-pristine", null], [2, "ng-dirty", null], [2, "ng-valid", null], [2, "ng-invalid", null], [2, "ng-pending", null]], [[null, "ngModelChange"], [null, "input"], [null, "blur"], [null, "compositionstart"], [null, "compositionend"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("input" === en)) {
        var pd_0 = (i1.ɵnov(_v, 1)._handleInput($event.target.value) !== false);
        ad = (pd_0 && ad);
    } if (("blur" === en)) {
        var pd_1 = (i1.ɵnov(_v, 1).onTouched() !== false);
        ad = (pd_1 && ad);
    } if (("compositionstart" === en)) {
        var pd_2 = (i1.ɵnov(_v, 1)._compositionStart() !== false);
        ad = (pd_2 && ad);
    } if (("compositionend" === en)) {
        var pd_3 = (i1.ɵnov(_v, 1)._compositionEnd($event.target.value) !== false);
        ad = (pd_3 && ad);
    } if (("ngModelChange" === en)) {
        var pd_4 = ((_co.section.content.fields[_v.parent.parent.parent.context.$implicit][_v.parent.parent.context.$implicit] = $event) !== false);
        ad = (pd_4 && ad);
    } return ad; }, null, null)), i1.ɵdid(1, 16384, null, 0, i2.DefaultValueAccessor, [i1.Renderer2, i1.ElementRef, [2, i2.COMPOSITION_BUFFER_MODE]], null, null), i1.ɵprd(1024, null, i2.NG_VALUE_ACCESSOR, function (p0_0) { return [p0_0]; }, [i2.DefaultValueAccessor]), i1.ɵdid(3, 671744, null, 0, i2.NgModel, [[8, null], [8, null], [8, null], [6, i2.NG_VALUE_ACCESSOR]], { model: [0, "model"] }, { update: "ngModelChange" }), i1.ɵprd(2048, null, i2.NgControl, null, [i2.NgModel]), i1.ɵdid(5, 16384, null, 0, i2.NgControlStatus, [[4, i2.NgControl]], null, null)], function (_ck, _v) { var _co = _v.component; var currVal_7 = _co.section.content.fields[_v.parent.parent.parent.context.$implicit][_v.parent.parent.context.$implicit]; _ck(_v, 3, 0, currVal_7); }, function (_ck, _v) { var currVal_0 = i1.ɵnov(_v, 5).ngClassUntouched; var currVal_1 = i1.ɵnov(_v, 5).ngClassTouched; var currVal_2 = i1.ɵnov(_v, 5).ngClassPristine; var currVal_3 = i1.ɵnov(_v, 5).ngClassDirty; var currVal_4 = i1.ɵnov(_v, 5).ngClassValid; var currVal_5 = i1.ɵnov(_v, 5).ngClassInvalid; var currVal_6 = i1.ɵnov(_v, 5).ngClassPending; _ck(_v, 0, 0, currVal_0, currVal_1, currVal_2, currVal_3, currVal_4, currVal_5, currVal_6); }); }
function View_ContentRenderGridComponent_4(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, "div", [["class", "section-grid-cell-content-outer"]], [[2, "editing", null]], null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ContentRenderGridComponent_5)), i1.ɵdid(2, 16384, null, 0, i3.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ContentRenderGridComponent_6)), i1.ɵdid(4, 16384, null, 0, i3.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_1 = !_co.section.editing; _ck(_v, 2, 0, currVal_1); var currVal_2 = _co.section.editing; _ck(_v, 4, 0, currVal_2); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.section.editing; _ck(_v, 0, 0, currVal_0); }); }
function View_ContentRenderGridComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "div", [["class", "section-grid-cell"]], [[4, "width", null]], null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ContentRenderGridComponent_4)), i1.ɵdid(2, 16384, null, 0, i3.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_1 = _co.cellExists(_v.parent.context.$implicit, _v.context.$implicit); _ck(_v, 2, 0, currVal_1); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.columnWidth(); _ck(_v, 0, 0, currVal_0); }); }
function View_ContentRenderGridComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "div", [["class", "section-grid-row"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ContentRenderGridComponent_3)), i1.ɵdid(2, 278528, null, 0, i3.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.iterator(_co.section.content.columns); _ck(_v, 2, 0, currVal_0); }, null); }
function View_ContentRenderGridComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "div", [["class", "section-grid-container"]], [[4, "text-align", null], [4, "background-color", null], [4, "color", null]], null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ContentRenderGridComponent_2)), i1.ɵdid(2, 278528, null, 0, i3.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_3 = _co.iterator(_co.section.content.rows); _ck(_v, 2, 0, currVal_3); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.section.contentHorizontalAlign; var currVal_1 = _co.section.contentBackgroundColor; var currVal_2 = _co.section.contentForegroundColor; _ck(_v, 0, 0, currVal_0, currVal_1, currVal_2); }); }
function View_ContentRenderGridComponent_8(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 0, "div", [["class", "section-mobile-item"]], [[8, "innerHTML", 1]], null, null, null, null))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.markdownToHtml(_v.context.$implicit); _ck(_v, 0, 0, currVal_0); }); }
function View_ContentRenderGridComponent_7(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "div", [["class", "section-grid-container-mobile"]], [[4, "text-align", null], [4, "background-color", null], [4, "color", null]], null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 2, "div", [["class", "section-mobile-container"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ContentRenderGridComponent_8)), i1.ɵdid(3, 278528, null, 0, i3.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_3 = _co.mobileFields; _ck(_v, 3, 0, currVal_3); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.section.contentHorizontalAlign; var currVal_1 = _co.section.contentBackgroundColor; var currVal_2 = _co.section.contentForegroundColor; _ck(_v, 0, 0, currVal_0, currVal_1, currVal_2); }); }
export function View_ContentRenderGridComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵand(16777216, null, null, 1, null, View_ContentRenderGridComponent_1)), i1.ɵdid(1, 16384, null, 0, i3.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ContentRenderGridComponent_7)), i1.ɵdid(3, 16384, null, 0, i3.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = (((((_co.renderMode === "desktop") && _co.section) && _co.section.content) && _co.section.content.rows) && _co.section.content.columns); _ck(_v, 1, 0, currVal_0); var currVal_1 = (((_co.renderMode === "mobile") && _co.section) && _co.section.content); _ck(_v, 3, 0, currVal_1); }, null); }
export function View_ContentRenderGridComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "div", [["class", "section-content section-grid"]], null, null, null, View_ContentRenderGridComponent_0, RenderType_ContentRenderGridComponent)), i1.ɵdid(1, 114688, null, 0, i4.ContentRenderGridComponent, [i5.AppService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var ContentRenderGridComponentNgFactory = i1.ɵccf(".section-content.section-grid", i4.ContentRenderGridComponent, View_ContentRenderGridComponent_Host_0, { section: "section", mode: "mode", renderMode: "renderMode" }, {}, []);
export { ContentRenderGridComponentNgFactory as ContentRenderGridComponentNgFactory };
