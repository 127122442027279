/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./document.component.less.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "./document.component";
import * as i4 from "../../../services/app";
import * as i5 from "@angular/router";
import * as i6 from "@angular/platform-browser";
var styles_ViewsListingDocumentComponent = [i0.styles];
var RenderType_ViewsListingDocumentComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_ViewsListingDocumentComponent, data: {} });
export { RenderType_ViewsListingDocumentComponent as RenderType_ViewsListingDocumentComponent };
function View_ViewsListingDocumentComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 0, "iframe", [["allowfullscreen", ""], ["class", "display-window"]], [[8, "src", 5]], null, null, null, null))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.documentLink; _ck(_v, 0, 0, currVal_0); }); }
export function View_ViewsListingDocumentComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵand(16777216, null, null, 1, null, View_ViewsListingDocumentComponent_1)), i1.ɵdid(1, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.documentLink; _ck(_v, 1, 0, currVal_0); }, null); }
export function View_ViewsListingDocumentComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "div", [["class", "app-view-listing-document"]], null, null, null, View_ViewsListingDocumentComponent_0, RenderType_ViewsListingDocumentComponent)), i1.ɵdid(1, 245760, null, 0, i3.ViewsListingDocumentComponent, [i4.AppService, i5.ActivatedRoute, i6.DomSanitizer, i1.ElementRef], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var ViewsListingDocumentComponentNgFactory = i1.ɵccf(".app-view-listing-document", i3.ViewsListingDocumentComponent, View_ViewsListingDocumentComponent_Host_0, {}, {}, []);
export { ViewsListingDocumentComponentNgFactory as ViewsListingDocumentComponentNgFactory };
