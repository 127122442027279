import { Component } from '@angular/core';

import { AppService } from 'app/services/app';

@Component( {
	moduleId: module.id,
	selector: '.app-view-news-main',
	templateUrl: 'main.component.html',
	styleUrls: [ 'main.component.css' ]
} )
export class ViewsNewsMainComponent {

	constructor( public appService: AppService ) {
		this.appService.toolbar.whiteOverContent = false;
		this.appService.toolbar.backgroundColor = null;
	}

}
