/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./leadership.component.css.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../standard/input/select.access.level.component.ngfactory";
import * as i3 from "../../../standard/input/select.access.level.component";
import * as i4 from "../../../../services/app";
import * as i5 from "@angular/forms";
import * as i6 from "./leadership.component";
var styles_ContentRenderEditorLeadershipComponent = [i0.styles];
var RenderType_ContentRenderEditorLeadershipComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_ContentRenderEditorLeadershipComponent, data: {} });
export { RenderType_ContentRenderEditorLeadershipComponent as RenderType_ContentRenderEditorLeadershipComponent };
export function View_ContentRenderEditorLeadershipComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "h4", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Leadership Content"])), (_l()(), i1.ɵeld(2, 0, null, null, 0, "br", [], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 0, "br", [], null, null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 1, "div", [["class", "select-access-level"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Access Level:"])), (_l()(), i1.ɵeld(6, 0, null, null, 1, "div", [["class", "input-type-select-access-level"]], null, [[null, "accessLevelChange"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("accessLevelChange" === en)) {
        var pd_0 = ((_co.section.accessLevel = $event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i2.View_StandardInputSelectAccessLevelComponent_0, i2.RenderType_StandardInputSelectAccessLevelComponent)), i1.ɵdid(7, 114688, null, 0, i3.StandardInputSelectAccessLevelComponent, [i4.AppService], { accessLevel: [0, "accessLevel"] }, { accessLevelChange: "accessLevelChange" }), (_l()(), i1.ɵeld(8, 0, null, null, 0, "br", [], null, null, null, null, null)), (_l()(), i1.ɵeld(9, 0, null, null, 0, "br", [], null, null, null, null, null)), (_l()(), i1.ɵeld(10, 0, null, null, 1, "div", [["class", "select-padding-top"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Padding Top"])), (_l()(), i1.ɵeld(12, 0, null, null, 5, "input", [["placeholder", "Enter an integer"], ["type", "text"]], [[2, "ng-untouched", null], [2, "ng-touched", null], [2, "ng-pristine", null], [2, "ng-dirty", null], [2, "ng-valid", null], [2, "ng-invalid", null], [2, "ng-pending", null]], [[null, "ngModelChange"], [null, "input"], [null, "blur"], [null, "compositionstart"], [null, "compositionend"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("input" === en)) {
        var pd_0 = (i1.ɵnov(_v, 13)._handleInput($event.target.value) !== false);
        ad = (pd_0 && ad);
    } if (("blur" === en)) {
        var pd_1 = (i1.ɵnov(_v, 13).onTouched() !== false);
        ad = (pd_1 && ad);
    } if (("compositionstart" === en)) {
        var pd_2 = (i1.ɵnov(_v, 13)._compositionStart() !== false);
        ad = (pd_2 && ad);
    } if (("compositionend" === en)) {
        var pd_3 = (i1.ɵnov(_v, 13)._compositionEnd($event.target.value) !== false);
        ad = (pd_3 && ad);
    } if (("ngModelChange" === en)) {
        var pd_4 = ((_co.section.contentPaddingTop = $event) !== false);
        ad = (pd_4 && ad);
    } return ad; }, null, null)), i1.ɵdid(13, 16384, null, 0, i5.DefaultValueAccessor, [i1.Renderer2, i1.ElementRef, [2, i5.COMPOSITION_BUFFER_MODE]], null, null), i1.ɵprd(1024, null, i5.NG_VALUE_ACCESSOR, function (p0_0) { return [p0_0]; }, [i5.DefaultValueAccessor]), i1.ɵdid(15, 671744, null, 0, i5.NgModel, [[8, null], [8, null], [8, null], [6, i5.NG_VALUE_ACCESSOR]], { model: [0, "model"] }, { update: "ngModelChange" }), i1.ɵprd(2048, null, i5.NgControl, null, [i5.NgModel]), i1.ɵdid(17, 16384, null, 0, i5.NgControlStatus, [[4, i5.NgControl]], null, null), (_l()(), i1.ɵeld(18, 0, null, null, 0, "br", [], null, null, null, null, null)), (_l()(), i1.ɵeld(19, 0, null, null, 1, "div", [["class", "select-padding-bottom"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Padding Bottom"])), (_l()(), i1.ɵeld(21, 0, null, null, 5, "input", [["placeholder", "Enter an integer"], ["type", "text"]], [[2, "ng-untouched", null], [2, "ng-touched", null], [2, "ng-pristine", null], [2, "ng-dirty", null], [2, "ng-valid", null], [2, "ng-invalid", null], [2, "ng-pending", null]], [[null, "ngModelChange"], [null, "input"], [null, "blur"], [null, "compositionstart"], [null, "compositionend"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("input" === en)) {
        var pd_0 = (i1.ɵnov(_v, 22)._handleInput($event.target.value) !== false);
        ad = (pd_0 && ad);
    } if (("blur" === en)) {
        var pd_1 = (i1.ɵnov(_v, 22).onTouched() !== false);
        ad = (pd_1 && ad);
    } if (("compositionstart" === en)) {
        var pd_2 = (i1.ɵnov(_v, 22)._compositionStart() !== false);
        ad = (pd_2 && ad);
    } if (("compositionend" === en)) {
        var pd_3 = (i1.ɵnov(_v, 22)._compositionEnd($event.target.value) !== false);
        ad = (pd_3 && ad);
    } if (("ngModelChange" === en)) {
        var pd_4 = ((_co.section.contentPaddingBottom = $event) !== false);
        ad = (pd_4 && ad);
    } return ad; }, null, null)), i1.ɵdid(22, 16384, null, 0, i5.DefaultValueAccessor, [i1.Renderer2, i1.ElementRef, [2, i5.COMPOSITION_BUFFER_MODE]], null, null), i1.ɵprd(1024, null, i5.NG_VALUE_ACCESSOR, function (p0_0) { return [p0_0]; }, [i5.DefaultValueAccessor]), i1.ɵdid(24, 671744, null, 0, i5.NgModel, [[8, null], [8, null], [8, null], [6, i5.NG_VALUE_ACCESSOR]], { model: [0, "model"] }, { update: "ngModelChange" }), i1.ɵprd(2048, null, i5.NgControl, null, [i5.NgModel]), i1.ɵdid(26, 16384, null, 0, i5.NgControlStatus, [[4, i5.NgControl]], null, null), (_l()(), i1.ɵeld(27, 0, null, null, 0, "br", [], null, null, null, null, null)), (_l()(), i1.ɵeld(28, 0, null, null, 0, "br", [], null, null, null, null, null))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.section.accessLevel; _ck(_v, 7, 0, currVal_0); var currVal_8 = _co.section.contentPaddingTop; _ck(_v, 15, 0, currVal_8); var currVal_16 = _co.section.contentPaddingBottom; _ck(_v, 24, 0, currVal_16); }, function (_ck, _v) { var currVal_1 = i1.ɵnov(_v, 17).ngClassUntouched; var currVal_2 = i1.ɵnov(_v, 17).ngClassTouched; var currVal_3 = i1.ɵnov(_v, 17).ngClassPristine; var currVal_4 = i1.ɵnov(_v, 17).ngClassDirty; var currVal_5 = i1.ɵnov(_v, 17).ngClassValid; var currVal_6 = i1.ɵnov(_v, 17).ngClassInvalid; var currVal_7 = i1.ɵnov(_v, 17).ngClassPending; _ck(_v, 12, 0, currVal_1, currVal_2, currVal_3, currVal_4, currVal_5, currVal_6, currVal_7); var currVal_9 = i1.ɵnov(_v, 26).ngClassUntouched; var currVal_10 = i1.ɵnov(_v, 26).ngClassTouched; var currVal_11 = i1.ɵnov(_v, 26).ngClassPristine; var currVal_12 = i1.ɵnov(_v, 26).ngClassDirty; var currVal_13 = i1.ɵnov(_v, 26).ngClassValid; var currVal_14 = i1.ɵnov(_v, 26).ngClassInvalid; var currVal_15 = i1.ɵnov(_v, 26).ngClassPending; _ck(_v, 21, 0, currVal_9, currVal_10, currVal_11, currVal_12, currVal_13, currVal_14, currVal_15); }); }
export function View_ContentRenderEditorLeadershipComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "div", [["class", "content-render-editor-leadership"]], null, null, null, View_ContentRenderEditorLeadershipComponent_0, RenderType_ContentRenderEditorLeadershipComponent)), i1.ɵdid(1, 49152, null, 0, i6.ContentRenderEditorLeadershipComponent, [i4.AppService, i1.ChangeDetectorRef], null, null)], null, null); }
var ContentRenderEditorLeadershipComponentNgFactory = i1.ɵccf(".content-render-editor-leadership", i6.ContentRenderEditorLeadershipComponent, View_ContentRenderEditorLeadershipComponent_Host_0, { section: "section" }, {}, []);
export { ContentRenderEditorLeadershipComponentNgFactory as ContentRenderEditorLeadershipComponentNgFactory };
