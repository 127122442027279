import { Component, ViewChild, OnInit, OnDestroy } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { NgForm } from '@angular/forms';
import { Router } from '@angular/router';
import { Location } from '@angular/common';

import { AppService } from 'app/services/app';
import { Article, SearchParams, SearchResponse, MetaData as ArticleMetaData } from 'app/models/article';
import { Profile } from 'app/models/profile';
import { Hash } from 'app/types/containers';

@Component( {
  moduleId: module.id,
  selector: 'app-view-article-main',
  templateUrl: 'main.component.pug',
  styleUrls: [ 'main.component.less' ]
} )
export class ViewsArticleMainComponent implements OnInit, OnDestroy {

  @ViewChild( 'searchForm' )
  public searchForm: NgForm;

  public articlesLoading: boolean = true;
  public resultsFiltered: SearchResponse = null;
  public results: SearchResponse = null;
  public categories: string[] = [];
  public authorIds: string[] = [ 'null' ]; // blank initial id is anonymous article
  public authorsById: Hash<Profile> = {};

  public keywordFilter: string = null;

  public showSearch: boolean = true;

  public deleteArticleCheck: string = null;
  public subscriptions: any[] = [];

  constructor( public appService: AppService, public router: Router, public location: Location, public sanitizer: DomSanitizer ) {
    this.articlesLoading = true;
    this.appService.contentLoading( true );
    this.appService.toolbar.whiteOverContent = false;
    this.appService.titleService.setTitle( `Exio - Articles` );
  }

  public ngOnDestroy(): void {
    this.subscriptions.forEach( ( subscription ) => {
      subscription.unsubscribe();
    } );
  }

  ngOnInit(): void {

    document.body.scrollTop = 0;

    this.subscriptions.push( this.appService.getAuthenticatedProfile( {
      next: ( profile ) => {

        if ( typeof profile === 'boolean' && !profile ) {
          this.appService.contentLoading( true );
        } else if ( !profile || !profile.isEmployee ) {
          this.router.navigate( [ '/' ] ).then();
        } else {
          // init with all articles
          this.search();
        }

      },
      error: () => {
        this.router.navigate( [ '/' ] ).then();
      },
      complete: () => {
        this.router.navigate( [ '/' ] ).then();
      }
    } ) );

  }

  public updateSearchControls(): void {

    const articles = this.results && Array.isArray( this.results.articles ) ? this.results.articles : [];

    this.authorIds = [];
    this.categories = [];

    articles.forEach( ( article: Article ) => {

      const authorId = article.author;

      // make sure we have a list of all authorIds
      if ( authorId && this.authorIds.indexOf( authorId ) < 0 ) {

        this.authorIds.push( authorId );

        if ( authorId !== 'null' && !this.authorsById.hasOwnProperty( authorId ) ) {

          this.appService
            .profileModel
            .get( authorId )
            .then( ( author: Profile ) => {

              if ( author && author.id ) {
                this.authorsById[ author.id ] = author;
              }

            } );

        }

      }

      // make sure we have a list of all category names
      if ( article.categories ) {
        article.categories.forEach( ( category ) => {
          if ( this.categories.indexOf( category ) < 0 ) {
            this.categories.push( category );
          }
        } );
      }
    } );

    this.categories = this.categories.sort( ( a, b ) => {

      a = this.categoryDisplayName( a );
      b = this.categoryDisplayName( b );

      if ( a < b ) {
        return -1;
      }

      if ( a > b ) {
        return 1;
      }

      return 0;

    } );

  }

  public markdownToHtml( markdownStr: string ): string {

    if ( typeof markdownStr !== 'string' ) {
      markdownStr = '';
    }

    markdownStr = markdownStr.replace( /\n/g, ' ' ).replace( /\s\s*/g, ' ' ).trim();

    return this.appService.markdownToHtml( markdownStr );

  }

  public clear(): void {
    this.searchForm.resetForm();
    this.search();
  }

  public categoryDisplayName( category: string ): string {

    category = category.toLowerCase();

    const parts = category.split( '-' );

    parts.forEach( ( part, i ) => {
      parts[ i ] = part.trim();
    } );

    return parts.join( ' ' );

  }

  public updateFilteredResults(): void {

    this.resultsFiltered = {
      articles: [],
      count: 0,
      total: 0
    };

    if ( !this.results || !Array.isArray( this.results.articles ) ) {
      return;
    }

    let keywords = [];
    const filter = typeof this.keywordFilter === 'string' ? this.keywordFilter.trim() : '';

    if ( filter.length > 0 ) {
      keywords = filter.split( /[\s \t]+/ );
    }
    // console.log( 'keywords', keywords );

    const cleanText = ( str: string ): string => {

      if ( typeof str !== 'string' ) {
        str = '';
      }

      return str.toLowerCase().replace( /[^a-z0-9]/g, '' ).trim();
    };

    keywords.forEach( ( keyword, i ) => {
      // console.log( 'cleaning keyword', keyword );
      keywords[ i ] = cleanText( keyword );
    } );

    const articleSearchContext = ( article: Article ): string => {
      let context = '';

      [
        'tagline',
        'title',
        'description',
        'path',
        'type',
        'created',
        'updated',
        'accessLevel'
      ].forEach( ( field ) => {
        context += ' ' + cleanText( article[ field ] );
      } );

      return context + ' ';
    };

    // console.log( 'keywords', keywords );

    this.resultsFiltered.articles = this.results.articles.filter( ( article ) => {

      if ( keywords.length < 1 ) {
        return true;
      }

      let foundOne = false;
      const context = articleSearchContext( article );

      keywords.forEach( ( keyword ) => {

        if ( foundOne ) {
          return;
        }

        foundOne = context.indexOf( keyword ) > -1;

      } );

      return foundOne;

    } );

    this.resultsFiltered.count = this.resultsFiltered.articles.length;
    this.resultsFiltered.total = this.results.total;

  }

  public search(): void {

    const searchControls: SearchParams = {
      query: {
        authors: [],
        categories: [],
        path: null
      },
      limit: 100000
    };

    const form = this.searchForm.value;

    // this.appService.cookieService.putObject( 'article.search.form', form );

    [
      'authors',
      'categories'
    ].forEach( ( field: string ) => {
      if ( form.hasOwnProperty( field ) && form[ field ] ) {

        for ( const id in form[ field ] ) {

          if ( form[ field ].hasOwnProperty( id ) && form[ field ][ id ] ) {
            searchControls.query[ field ].push( id );
          }
        }

      }

    } );

    this.articlesLoading = true;
    this.appService
      .articleModel
      .search( searchControls )
      .then( ( results: SearchResponse ) => {
        this.appService.contentLoading( false );
        this.articlesLoading = false;
        this.results = results;
        this.updateFilteredResults();
        this.updateSearchControls();
      } )
      .catch( () => {
        this.appService.contentLoading( false );
        this.articlesLoading = false;
        this.results = null;
        this.updateFilteredResults();
        this.updateSearchControls();
      } );

  }

  public authorById( id: string ): string {

    if ( !id || id.length < 1 || id === 'null' ) {
      return 'Anonymous';
    }

    return this.authorName( this.authorsById[ id ] || null );

  }

  public authorName( author: Profile ): string {

    if ( author ) {
      return author.firstName + ' ' + author.lastName;
    }

    return 'Loading...';

  }

  public sortedCategories(): string[] {
    return this.categories.sort();
  }

  public sortedAuthorIds(): string[] {
    return this.authorIds.sort( ( x, y ) => {

      // anonymous always first
      if ( x.length < 1 ) {
        return -1;
      }
      if ( y.length < 1 ) {
        return 1;
      }

      const xName = this.authorById( x );
      const yName = this.authorById( y );

      if ( xName < yName ) {
        return -1;
      }

      return 1;

    } );
  }

  public dateOnly( date: string ): string {
    return date.replace( /T.*$/, '' );
  }

  public searchEnabled(): boolean {
    return this.searchForm.form.dirty && this.authorIds.length > 0 && this.categories.length > 0;
  }

  public resetEnabled(): boolean {
    return this.searchForm.form.dirty || (this.authorIds.length < 1 && this.categories.length < 1);
  }

  public addArticle(): void {

    const url = this.location.prepareExternalUrl( '/articles-editor/new' );

    window.open( url, '_blank' );

  }

  public copyArticle( article: Article ): void {

    const url = this.location.prepareExternalUrl( '/articles-editor/copy/' + article.id );

    window.open( url, '_blank' );

  }


  public removeArticle( article: Article ): void {

    if ( !article || !article.id ) {
      return;
    }

    this.deleteArticleCheck = null;

    const results = this.results;

    let found: number = null;

    results.articles.forEach( ( item, i ) => {

      if ( item.id === article.id ) {
        found = i;
      }

    } );

    // remove from list immediately
    if ( found !== null ) {

      results.articles.splice( found, 1 );
      results.count--;
      results.total--;

    }

    this.appService.articleModel.delete( article.id ).then();

  }

  public editArticle( article: Article ): void {

    const url = this.location.prepareExternalUrl( '/articles-editor/' + article.id );

    window.open( url, '_blank' );

  }

}
