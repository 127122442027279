/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./main.component.css.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../content/loader.spinner.component.ngfactory";
import * as i3 from "../../../content/loader.spinner.component";
import * as i4 from "@angular/common";
import * as i5 from "./main.component";
import * as i6 from "../../../../services/app";
import * as i7 from "@angular/router";
var styles_ViewsContractsInstanceMainComponent = [i0.styles];
var RenderType_ViewsContractsInstanceMainComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_ViewsContractsInstanceMainComponent, data: {} });
export { RenderType_ViewsContractsInstanceMainComponent as RenderType_ViewsContractsInstanceMainComponent };
function View_ViewsContractsInstanceMainComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "div", [["class", "page-subtitle"]], null, null, null, null, null)), (_l()(), i1.ɵted(1, null, ["for ", ""]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.document.name; _ck(_v, 1, 0, currVal_0); }); }
function View_ViewsContractsInstanceMainComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "div", [["class", "page-subtitle"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Loading..."]))], null, null); }
function View_ViewsContractsInstanceMainComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "div", [["class", "search-results-list-loading"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "div", [["class", "loader-spinner"]], null, null, null, i2.View_ContentLoaderSpinnerComponent_0, i2.RenderType_ContentLoaderSpinnerComponent)), i1.ɵdid(2, 49152, null, 0, i3.ContentLoaderSpinnerComponent, [], null, null)], null, null); }
function View_ViewsContractsInstanceMainComponent_7(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "div", [["class", "result-field instance-complete col-md-3"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Complete"]))], null, null); }
function View_ViewsContractsInstanceMainComponent_8(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "div", [["class", "result-field instance-complete col-md-3"]], null, null, null, null, null)), (_l()(), i1.ɵted(1, null, ["In-Progress ", ""]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.signatureCount(_v.parent.context.$implicit); _ck(_v, 1, 0, currVal_0); }); }
function View_ViewsContractsInstanceMainComponent_9(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 6, "div", [["class", "result-field instance-actions col-md-3"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "div", [["class", "instance-control control-link"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.editInstance(_v.parent.context.$implicit) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, ["Edit"])), (_l()(), i1.ɵeld(3, 0, null, null, 1, "div", [["class", "instance-control"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["|"])), (_l()(), i1.ɵeld(5, 0, null, null, 1, "div", [["class", "instance-control control-link"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = ((_co.removeInstanceCheck = _v.parent.context.index) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, ["Remove"]))], null, null); }
function View_ViewsContractsInstanceMainComponent_10(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 7, "div", [["class", "result-field instance-actions col-md-3"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Are you sure? "])), (_l()(), i1.ɵeld(2, 0, null, null, 1, "div", [["class", "instance-control control-link"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        _co.removeInstanceCheck = null;
        var pd_0 = (_co.removeInstance(_v.parent.context.$implicit) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, ["Yes"])), (_l()(), i1.ɵeld(4, 0, null, null, 1, "div", [["class", "instance-control"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["|"])), (_l()(), i1.ɵeld(6, 0, null, null, 1, "div", [["class", "instance-control control-link"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = ((_co.removeInstanceCheck = null) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, ["No"]))], null, null); }
function View_ViewsContractsInstanceMainComponent_6(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 12, "div", [["class", "search-result"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "div", [["class", "result-field instance-name col-md-3"]], null, null, null, null, null)), (_l()(), i1.ɵted(2, null, ["", ""])), (_l()(), i1.ɵeld(3, 0, null, null, 1, "div", [["class", "result-field instance-author col-md-3"]], null, null, null, null, null)), (_l()(), i1.ɵted(4, null, ["", ""])), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ViewsContractsInstanceMainComponent_7)), i1.ɵdid(6, 16384, null, 0, i4.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ViewsContractsInstanceMainComponent_8)), i1.ɵdid(8, 16384, null, 0, i4.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ViewsContractsInstanceMainComponent_9)), i1.ɵdid(10, 16384, null, 0, i4.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ViewsContractsInstanceMainComponent_10)), i1.ɵdid(12, 16384, null, 0, i4.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_2 = _v.context.$implicit.complete; _ck(_v, 6, 0, currVal_2); var currVal_3 = !_v.context.$implicit.complete; _ck(_v, 8, 0, currVal_3); var currVal_4 = (_co.removeInstanceCheck !== _v.context.index); _ck(_v, 10, 0, currVal_4); var currVal_5 = (_co.removeInstanceCheck === _v.context.index); _ck(_v, 12, 0, currVal_5); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = _v.context.$implicit.name; _ck(_v, 2, 0, currVal_0); var currVal_1 = _co.authorName(_v.context.$implicit.author); _ck(_v, 4, 0, currVal_1); }); }
function View_ViewsContractsInstanceMainComponent_5(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 11, "div", [["class", "search-results-list"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 8, "div", [["class", "search-result hidden-xs"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 1, "div", [["class", "result-field instance-name col-md-3"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Name"])), (_l()(), i1.ɵeld(4, 0, null, null, 1, "div", [["class", "result-field instance-author col-md-3"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Author"])), (_l()(), i1.ɵeld(6, 0, null, null, 1, "div", [["class", "result-field instance-complete col-md-3"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Status"])), (_l()(), i1.ɵeld(8, 0, null, null, 1, "div", [["class", "result-field instance-actions col-md-3"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Actions"])), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ViewsContractsInstanceMainComponent_6)), i1.ɵdid(11, 278528, null, 0, i4.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.instances; _ck(_v, 11, 0, currVal_0); }, null); }
function View_ViewsContractsInstanceMainComponent_11(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "div", [["class", "search-results-not-found"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["No Instances Found"]))], null, null); }
function View_ViewsContractsInstanceMainComponent_4(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, "div", [["class", "search-results"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ViewsContractsInstanceMainComponent_5)), i1.ɵdid(2, 16384, null, 0, i4.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ViewsContractsInstanceMainComponent_11)), i1.ɵdid(4, 16384, null, 0, i4.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = (_co.instances && (_co.instances.length > 0)); _ck(_v, 2, 0, currVal_0); var currVal_1 = (!_co.instances || (_co.instances.length < 1)); _ck(_v, 4, 0, currVal_1); }, null); }
export function View_ViewsContractsInstanceMainComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "div", [["class", "page-title"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Contract Instances"])), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ViewsContractsInstanceMainComponent_1)), i1.ɵdid(3, 16384, null, 0, i4.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ViewsContractsInstanceMainComponent_2)), i1.ɵdid(5, 16384, null, 0, i4.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(6, 0, null, null, 2, "div", [["class", "instance-controls"]], null, null, null, null, null)), (_l()(), i1.ɵeld(7, 0, null, null, 1, "div", [["class", "instance-control instance-add control-link"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.addInstance() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, ["Add"])), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ViewsContractsInstanceMainComponent_3)), i1.ɵdid(10, 16384, null, 0, i4.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ViewsContractsInstanceMainComponent_4)), i1.ɵdid(12, 16384, null, 0, i4.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.document; _ck(_v, 3, 0, currVal_0); var currVal_1 = !_co.document; _ck(_v, 5, 0, currVal_1); var currVal_2 = _co.instancesLoading; _ck(_v, 10, 0, currVal_2); var currVal_3 = !_co.instancesLoading; _ck(_v, 12, 0, currVal_3); }, null); }
export function View_ViewsContractsInstanceMainComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "div", [["class", "app-view-contracts-instance-main"]], null, null, null, View_ViewsContractsInstanceMainComponent_0, RenderType_ViewsContractsInstanceMainComponent)), i1.ɵdid(1, 245760, null, 0, i5.ViewsContractsInstanceMainComponent, [i6.AppService, i7.Router, i7.ActivatedRoute], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var ViewsContractsInstanceMainComponentNgFactory = i1.ɵccf(".app-view-contracts-instance-main", i5.ViewsContractsInstanceMainComponent, View_ViewsContractsInstanceMainComponent_Host_0, {}, {}, []);
export { ViewsContractsInstanceMainComponentNgFactory as ViewsContractsInstanceMainComponentNgFactory };
