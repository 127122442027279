import { Component } from '@angular/core';

@Component( {
	moduleId: module.id,
	selector: '.loader-bar',
	templateUrl: 'loader.bar.component.html',
	styleUrls: [ 'loader.bar.component.css' ],

} )
export class ContentLoaderBarComponent {

	constructor(  ) {
	}

}
