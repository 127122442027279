import { Component, OnInit, OnDestroy } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Location } from '@angular/common';
import { AppService } from 'app/services/app';
import { Article } from 'app/models/article';
import { Profile } from 'app/models/profile';

@Component( {
  moduleId: module.id,
  selector: '.app-view-blog-entry',
  templateUrl: 'entry.component.pug',
  styleUrls: [ 'entry.component.less' ]
} )
export class ViewsBlogEntryComponent implements OnInit, OnDestroy {

  public article: Article = null;
  public authorProfile: Profile = null;

  constructor( public appService: AppService, public route: ActivatedRoute, public location: Location, public router: Router ) {
    this.appService.toolbar.whiteOverContent = true;
    this.appService.toolbar.backgroundColor = null;
    this.appService.contentLoading( true );
    this.appService.titleService.setTitle( `Exio - Blog` );
  }

  public subscriptions: any[] = [];

  public ngOnDestroy(): void {
    this.subscriptions.forEach( ( subscription ) => {
      subscription.unsubscribe();
    } );
  }

  ngOnInit(): void {

    this.appService.contentLoading( true );

    document.body.scrollTop = 0;

    this.subscriptions.push( this.route.params.subscribe( () => {
      this.appService
        .articleModel
        .getByPath( {
          path: this.location.path()
        } )
        .then( ( article: Article ) => {

          this.appService.contentLoading( false );
          this.article = article || null;

          if ( this.article ) {
            this.appService.titleService.setTitle( `Exio - Blog - ${article.title}` );
          }

          this.appService.toolbar.whiteOverContent = this.article && this.article.bannerImgUrl && this.article.bannerImgUrl.length > 0;

          if ( this.article && typeof this.article.author === 'string' && this.article.author.length > 0 ) {
            this.appService
              .profileModel
              .get( this.article.author )
              .then( ( authorProfile: Profile ) => {
                this.authorProfile = authorProfile || null;
              } )
              .catch( () => {
                this.authorProfile = null;
              } );
          }

        } )
        .catch( () => {
          this.appService.contentLoading( false );
          this.article = null;
          this.authorProfile = null;
        } );

    } ) );

  }

  public goToAuthor( author: Profile ) {

    if ( author && author.id ) {
      this.router.navigate( [ '/learn/leadership/', author.id ] );
    }

  }

  public formatTitle( title: string, mobile: boolean ): string {

    if ( mobile ) {
      return title.replace( /[\n\r]/g, " " );
    }

    return title.replace( /[\n\r]/g, "<br/>\n" );

  }

}
