/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./main.component.less.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "./main.component";
import * as i3 from "../../../../services/app";
import * as i4 from "@angular/router";
var styles_ViewContractsInstanceConfirmEmailMainComponent = [i0.styles];
var RenderType_ViewContractsInstanceConfirmEmailMainComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_ViewContractsInstanceConfirmEmailMainComponent, data: {} });
export { RenderType_ViewContractsInstanceConfirmEmailMainComponent as RenderType_ViewContractsInstanceConfirmEmailMainComponent };
export function View_ViewContractsInstanceConfirmEmailMainComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "div", [["class", "nothing"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["to see here"]))], null, null); }
export function View_ViewContractsInstanceConfirmEmailMainComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-view-contracts-instance-confirm-email-main", [], null, null, null, View_ViewContractsInstanceConfirmEmailMainComponent_0, RenderType_ViewContractsInstanceConfirmEmailMainComponent)), i1.ɵdid(1, 180224, null, 0, i2.ViewContractsInstanceConfirmEmailMainComponent, [i3.AppService, i4.Router, i4.ActivatedRoute], null, null)], null, null); }
var ViewContractsInstanceConfirmEmailMainComponentNgFactory = i1.ɵccf("app-view-contracts-instance-confirm-email-main", i2.ViewContractsInstanceConfirmEmailMainComponent, View_ViewContractsInstanceConfirmEmailMainComponent_Host_0, {}, {}, []);
export { ViewContractsInstanceConfirmEmailMainComponentNgFactory as ViewContractsInstanceConfirmEmailMainComponentNgFactory };
