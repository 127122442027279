/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./loader.bar.component.css.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "./loader.bar.component";
var styles_ContentLoaderBarComponent = [i0.styles];
var RenderType_ContentLoaderBarComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_ContentLoaderBarComponent, data: {} });
export { RenderType_ContentLoaderBarComponent as RenderType_ContentLoaderBarComponent };
export function View_ContentLoaderBarComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 5, "div", [["class", "spinner"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 0, "div", [["class", "rect1"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 0, "div", [["class", "rect2"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 0, "div", [["class", "rect3"]], null, null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 0, "div", [["class", "rect4"]], null, null, null, null, null)), (_l()(), i1.ɵeld(5, 0, null, null, 0, "div", [["class", "rect5"]], null, null, null, null, null))], null, null); }
export function View_ContentLoaderBarComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "div", [["class", "loader-bar"]], null, null, null, View_ContentLoaderBarComponent_0, RenderType_ContentLoaderBarComponent)), i1.ɵdid(1, 49152, null, 0, i2.ContentLoaderBarComponent, [], null, null)], null, null); }
var ContentLoaderBarComponentNgFactory = i1.ɵccf(".loader-bar", i2.ContentLoaderBarComponent, View_ContentLoaderBarComponent_Host_0, {}, {}, []);
export { ContentLoaderBarComponentNgFactory as ContentLoaderBarComponentNgFactory };
