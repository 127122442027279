/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./my.legal.documents.component.css.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "./my.legal.documents.component";
import * as i4 from "../../../services/app";
import * as i5 from "@angular/router";
var styles_ContentRenderMyLegalDocumentsComponent = [i0.styles];
var RenderType_ContentRenderMyLegalDocumentsComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_ContentRenderMyLegalDocumentsComponent, data: {} });
export { RenderType_ContentRenderMyLegalDocumentsComponent as RenderType_ContentRenderMyLegalDocumentsComponent };
function View_ContentRenderMyLegalDocumentsComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "div", [["class", "my-legal-documents-field right col-xs-6 col-md-2"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "div", [["class", "component-icon-container"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 0, "div", [["class", "component-icon check active"]], null, null, null, null, null))], null, null); }
function View_ContentRenderMyLegalDocumentsComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "div", [["class", "my-legal-documents-field right col-xs-6 col-md-2"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "div", [["class", "sign-document"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.signDocument(_v.parent.context.$implicit) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, ["Sign Now"]))], null, null); }
function View_ContentRenderMyLegalDocumentsComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 7, "div", [["class", "my-legal-documents-row"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 2, "div", [["class", "my-legal-documents-field left col-xs-6 col-md-10"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 1, "div", [["class", "view-document"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.viewDocument(_v.context.$implicit) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(3, null, ["", ""])), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ContentRenderMyLegalDocumentsComponent_2)), i1.ɵdid(5, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ContentRenderMyLegalDocumentsComponent_3)), i1.ɵdid(7, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var currVal_1 = _v.context.$implicit.signed; _ck(_v, 5, 0, currVal_1); var currVal_2 = !_v.context.$implicit.signed; _ck(_v, 7, 0, currVal_2); }, function (_ck, _v) { var currVal_0 = _v.context.$implicit.document.name; _ck(_v, 3, 0, currVal_0); }); }
export function View_ContentRenderMyLegalDocumentsComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 7, "div", [["class", "my-legal-documents-container"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 4, "div", [["class", "my-legal-documents-row my-legal-documents-header"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 1, "div", [["class", "my-legal-documents-field left col-xs-6 col-md-10"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Name"])), (_l()(), i1.ɵeld(4, 0, null, null, 1, "div", [["class", "my-legal-documents-field right col-xs-6 col-md-2"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Status"])), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ContentRenderMyLegalDocumentsComponent_1)), i1.ɵdid(7, 278528, null, 0, i2.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.records; _ck(_v, 7, 0, currVal_0); }, null); }
export function View_ContentRenderMyLegalDocumentsComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "div", [["class", "content-render-my-legal-documents"]], null, null, null, View_ContentRenderMyLegalDocumentsComponent_0, RenderType_ContentRenderMyLegalDocumentsComponent)), i1.ɵdid(1, 245760, null, 0, i3.ContentRenderMyLegalDocumentsComponent, [i4.AppService, i5.Router], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var ContentRenderMyLegalDocumentsComponentNgFactory = i1.ɵccf(".content-render-my-legal-documents", i3.ContentRenderMyLegalDocumentsComponent, View_ContentRenderMyLegalDocumentsComponent_Host_0, {}, {}, []);
export { ContentRenderMyLegalDocumentsComponentNgFactory as ContentRenderMyLegalDocumentsComponentNgFactory };
