import { Component, Input, OnInit, AfterContentChecked, ChangeDetectorRef } from '@angular/core';
import { ArticleSection, ArticleSectionContentGrid } from 'app/models/article';

import { AppService } from 'app/services/app';

@Component( {
	moduleId: module.id,
	selector: '.content-render-editor-grid',
	templateUrl: 'grid.component.html',
	styleUrls: [ 'grid.component.css' ]
} )
export class ContentRenderEditorGridComponent implements OnInit, AfterContentChecked {

	@Input()
	public section: ArticleSection = null;

	constructor( public app: AppService, public changeDetector: ChangeDetectorRef ) {
		this.updateFields();
	}

	public ngOnInit(): void {
		// console.log( 'ngOnInit', new Date().toISOString() );
		this.updateFields();
	}

	public ngAfterContentChecked(): void {
		// console.log( 'ngAfterContentChecked', new Date().toISOString() );
		this.updateFields();
	}

	public getContent(): ArticleSectionContentGrid {

		if ( this.section && this.section.content ) {
			return <ArticleSectionContentGrid> this.section.content;
		}

		return null;

	}

	public updateFields(): void {

		let content = this.getContent();
		if ( !content ) {
			// console.log( 'no content on update' );
			return;
		}

		while ( content.fields.length < content.rows ) {
			content.fields.push( [] );
		}
		// while ( content.fields.length > content.rows ) {
		// 	content.fields.pop();
		// }

		for ( let i = 0; i < content.rows; i++ ) {
			while ( content.fields[ i ].length < content.columns ) {
				content.fields[ i ].push( '' );
			}
			// while ( content.fields[ i ].length > content.columns ) {
			// 	content.fields[ i ].pop();
			// }
		}

		// console.log( 'grid changed', JSON.stringify( this.section, null, 4 ) );

	}

	public delete(): void {
		this.section.delete = true;
		this.changeDetector.markForCheck();
	}

}
