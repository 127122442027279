import { Component, ElementRef, OnInit, AfterViewChecked, EventEmitter, Input, Output } from '@angular/core';

type OptionItem = string[];

@Component( {
	moduleId: module.id,
	selector: '.input-type-dropdown',
	templateUrl: 'dropdown.component.html',
	styleUrls: [ 'dropdown.component.css' ]
} )
export class StandardInputDropdownComponent implements AfterViewChecked, OnInit {

	@Input()
	public id: string = null;

	@Input()
	public name: string = null;

	@Input()
	public sortOptions: ('none' | 'value' | 'displayValue' ) = 'none';

	@Input()
	public options: OptionItem[] = null;

	public optionsFiltered: OptionItem[] = null;

	@Input()
	public value: string = null;

	@Output()
	public valueChange: EventEmitter<string> = new EventEmitter<string>();

	@Input()
	public unselectedDisplayValue: string = null;

	public showSelector: boolean = false;

	public currentValue: OptionItem = null;
	public currentDisplayValue: string = 'Please Select';

	public filter: string = '';

	public $el: JQuery;
	public $input: JQuery;

	constructor( public el: ElementRef ) {
		this.$el = $( el.nativeElement );

	}

	public ngOnInit(): void {

		if ( typeof this.unselectedDisplayValue === 'string' ) {
			this.currentDisplayValue = this.unselectedDisplayValue;
		}

		this.$input = this.$el.find( 'select.hidden-select' );

		// build here instead of Angular template because angular keeps re-writing the HTML and killing selected values
		this.options.forEach( ( option ) => {
			this.$input.append( `<option value="${option[ 0 ]}">${option[ 1 ]}</option>` );
		} );

		this.$input.change( () => {

			let inputValue = this.$input.val();

			if ( typeof inputValue === 'string' ) {
				this.options.forEach( ( option ) => {
					if ( option[ 0 ] === inputValue ) {
						window.setTimeout( () => {
							this.currentValue = option;
							this.currentDisplayValue = option[ 1 ];
						} );
					}
				} );
			}
		} );

		this.filterChanged();

	}

	public ngAfterViewChecked(): void {
	}

	public blur(): void {

		// give other events time to settle, this is only a last resort
		window.setTimeout( () => {
			if ( this.showSelector ) {
				this.close();
			}
		}, 250 );

	}

	public close(): void {
		this.showSelector = false;
		this.filter = '';
	}

	public toggleOpen(): void {
		this.showSelector = !this.showSelector;
	}

	public clickOptionItem( option: OptionItem ): void {

		if ( option[ 0 ] !== this.$input.val() ) {
			this.$input.val( option[ 0 ] );
			this.$input.trigger( 'change' );
			this.valueChange.emit( option[ 0 ] );
		}

		this.close();

	}


	public filterChanged(): void {

		let options = this.options.filter( ( option: OptionItem ) => {

			if ( typeof this.filter !== 'string' ) {
				return true;
			}

			let filter = this.filter.toLowerCase().trim();

			if ( filter.length < 1 ) {
				return true;
			}

			return option[ 0 ].toLowerCase().includes( filter ) || option[ 1 ].toLowerCase().includes( filter );

		} );

		if ( this.sortOptions === 'value' || this.sortOptions === 'displayValue' ) {
			options = options.sort( ( option1: OptionItem, option2: OptionItem ) => {

				let field = 0;

				if ( this.sortOptions === 'displayValue' ) {
					field = 1;
				}

				let textA = typeof option1[ field ] === 'string' ? option1[ field ].trim().toUpperCase() : '';
				let textB = typeof option2[ field ] === 'string' ? option2[ field ].trim().toUpperCase() : '';

				if ( textA < textB ) {
					return -1;
				} else if ( textA > textB ) {
					return 1;
				} else {
					return 0;
				}

			} );
		}

		this.optionsFiltered = options;

	}

	public filteredOptions(): OptionItem[] {

		return this.optionsFiltered;

	}

}
