import {Injectable} from '@angular/core';
import {ApiService} from 'app/services/api';
import * as Bluebird from 'bluebird';

// Types
import {GenericModel} from './generic';
import {HashTree} from 'app/types/containers';

type SortItem = HashTree<'asc' | 'desc'>;
type Filter = HashTree<string | number | null | boolean>;
type Aggregation = HashTree<string | number | null | boolean>;

export type AnalyticsActions =
  'pageView' |
  'signedNDA' |
  'signedLOI' |
  'documentViewed' |
  'published' |
  'unpublished' |
  'upgradeRequestPrivate' |
  'upgradeRequestExclusive';

// Schema Type
export interface AnalyticsEvent {
  action: AnalyticsActions;
  city?: string;
  countryCode?: string;
  id?: string;
  ip?: string;
  latitude?: number;
  longitude?: number;
  state?: string;
  timestamp?: Date;
  url?: string;
  userId?: string;
  zipCode?: string;
  meta?: any;
}

@Injectable()
export class AnalyticsModel extends GenericModel<AnalyticsEvent> {

  constructor(api: ApiService) {
    super();
    this.init(api, 'analytics', {
      action: null,
      city: null,
      countryCode: null,
      id: null,
      ip: null,
      latitude: null,
      longitude: null,
      state: null,
      timestamp: null,
      url: null,
      userId: null,
      zipCode: null,
      meta: null
    }, 5000);

  }

  public logEvent(event: AnalyticsEvent): Bluebird<string> {

    if (!event.url) {
      event.url = (<any>window).location.pathname;
    }

    return this.api.call('analytics.logEvent', event);

  }

  public getEvents(params: { filter: Filter, from?: number, size?: number, sort?: SortItem[] }): Bluebird<AnalyticsEvent[]> {
    return this.api.call('analytics.getEvents', params);
  }

  public aggregateEvents(params: { filter: Filter, aggregation: Aggregation }): Bluebird<HashTree<string | number | null | boolean>> {
    return this.api.call('analytics.aggregateEvents', params);
  }

  public getInstance(instance: AnalyticsEvent): AnalyticsEvent {
    return instance; // NO-OP
  }

}
