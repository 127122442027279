import { Injectable } from '@angular/core';

// Types
import { HashTree } from 'app/types/containers';
import { GenericSchemaType } from './generic';

type ParamValues = string | number | boolean | null | GenericSchemaType;

export interface ApiParams extends HashTree<ParamValues> {
}

export type ApiResultContent = ApiParams | ParamValues;

export interface ApiErrorContent {
  code: number | null,
  message: string | null,
  data?: ApiParams | null
}

export interface ApiCall {
  jsonrpc: string,
  id: string,
  method: string,
  params: ApiParams
}

export interface ApiResult {
  jsonrpc: string,
  id: string,
  result?: ApiResultContent
  error?: ApiErrorContent
}

export interface ApiError {
  jsonrpc: string,
  id: string,
  error: ApiErrorContent
}

// required for gulp auto generated code framework
@Injectable()
export class ApiModel {

}



