// modules
import {Injectable, NgZone, enableProdMode, OnDestroy} from '@angular/core';
import {Location} from '@angular/common';
import {Router, ActivatedRoute, NavigationEnd, NavigationStart} from '@angular/router';
import {BehaviorSubject, Observer, Subscription} from 'rxjs';
import {Title} from '@angular/platform-browser';
import * as Bluebird from 'bluebird';
import * as Cookie from 'js-cookie';

import {environment} from 'app/../environments/environment';

type SignUpTypes = 'partner' | 'buyer' | 'seller' | null;

interface GoogleViewItem {
  id: string;
  category: string;
  name: string;
  list_name: string;
}

// set this false for commits
const developmentMode = true;

if (!developmentMode) {
  enableProdMode();
}

import {ApiService} from './api';
import {ExioService} from './exio';

// models
import {QuestionnaireModel} from 'app/models/questionnaire';
import {ArticleModel, Article, SubMenu} from 'app/models/article';
import {CdnModel} from 'app/models/cdn';
import {ProfileModel, Profile} from 'app/models/profile';
import {AnalyticsModel} from 'app/models/analytics';
import {LegalDocumentModel, LegalDocument} from 'app/models/legal.document';
import {ListingModel, Listing} from 'app/models/listing';
import {VimeoModel, Article as VimeoArticle} from 'app/models/vimeo';
import {WorkflowModel} from 'app/models/workflow';

// types
import {Hash, HashTree} from 'app/types/containers';

// union of all model types
type BehaviorSubjectContentTypes =
  boolean
  | string
  | number
  | null
  | Article
  | Listing
  | LegalDocument
  | Profile
  | VimeoArticle
  | WorkflowModel;

// get native window object;
function _window(): any {
  return window || null;
}

const $head = $('head');

@Injectable()
export class AppService implements OnDestroy {

  // default system colors
  public colors: string[] = [
    '#FFB835',
    '#D9534F',
    '#5CB85C',
    '#4797EE',
    '#000000',
    '#222222',
    '#333333',
    '#4D4D4D',
    '#949494',
    '#B3B3B3',
    '#F2F2F2',
    '#FFFFFF',
  ];

  public navigationHistory: string[] = [];

  public accessLevels: string[][] = [
    ['public', 'Public'],
    ['private', 'Private'],
    ['exclusive', 'Exclusive'],
    ['hidden', 'Hidden']
  ];

  public authenticatedProfile: Profile | boolean = null;

  public authenticatedUserWatch: number = null;
  public state: BehaviorSubject<string>;

  public toolbarMenuState: BehaviorSubject<string>;

  private queryParams: Hash<string> = {};

  public toolbar: {
    disabled: boolean;
    collapsed: boolean;
    whiteOverContent: boolean;
    backgroundColor: string | null;
    foregroundColor: string | null;
  };

  public footer: {
    hide: boolean;
    showMenu: boolean;
  };

  public loadingTimeout: number = null;
  public content: {
    firstLoad: boolean;
    loading: boolean;
    backgroundLight: boolean;
    dragging: boolean;
    nextScroll: number;
    nextScrollSubmenu: boolean;
    submenu: SubMenu[];
  };

  public subscriptions: any[] = [];
  public generalStorage: Hash<any> = {};

  // these are all the data points that can be observed system wide, indexed by type, type.id is
  // an observable for that particular type and corresponding id
  public subjects: Hash<Hash<BehaviorSubject<BehaviorSubjectContentTypes>>> = {};

  public config: {
    api: string;
    auth: string;
    cdn: string;
    cms: string;
    crm: string;
  } = {
    api: null,
    auth: null,
    cdn: null,
    cms: null,
    crm: null
  };

  constructor(public api: ApiService,
              public analytics: AnalyticsModel,
              public exio: ExioService,
              public articleModel: ArticleModel,
              public questionnaireModel: QuestionnaireModel,
              public cdnModel: CdnModel,
              public legalDocumentModel: LegalDocumentModel,
              public listingModel: ListingModel,
              public location: Location,
              public profileModel: ProfileModel,
              public router: Router,
              public activeRoute: ActivatedRoute,
              public titleService: Title,
              public vimeoModel: VimeoModel,
              public workflowModel: WorkflowModel,
              public zone: NgZone) {

    this.api.call('system.config', {}).then((config: {}) => {

      for (const field in this.config) {
        if (this.config.hasOwnProperty(field) && config.hasOwnProperty(field)) {
          this.config[field] = config[field];
        }
      }

      return null;

    });

    this.state = new BehaviorSubject<string>('normal');

    this.toolbarMenuState = new BehaviorSubject<string>('hide');

    this.subscriptions.push(this.activeRoute.queryParams.subscribe(params => this.queryParams = params));

    this.resetStateFields();
    this.subscriptions.push(this.router.events.subscribe((event) => {

      if (event instanceof NavigationStart) {

        this.toolbar.disabled = false;
        this.toolbar.collapsed = true;
        this.footer.hide = false;
        this.footer.showMenu = true;
        this.content.loading = true;
        this.content.backgroundLight = true;
        this.content.dragging = false;
        this.toolbar.foregroundColor = null;
        this.navigationHistory.push(this.location.path());
        this.titleService.setTitle(`Exio`);

        const urlFix = (url: string): string => {

          if (typeof url !== 'string') {
            return '';
          }

          return '/' + url.trim().toLowerCase().replace(/^\//, '');

        };

        if (this.content.submenu.length > 0) {
          // let
        }

      }

      if (event instanceof NavigationEnd) {

        // console.log( 'this.content', this.content );

        if (this.content.nextScroll < 0) {
          this.content.nextScroll = 0;
        }

        if (this.content.nextScrollSubmenu) {

          // console.log( 'navigate submenu' );
          // window.setTimeout( () => {
          // 	let nextScroll = 0;
          //
          // 	// let $submenu = $( '.submenu-container' );
          // 	//
          // 	// if ( $submenu.length > 0 ) {
          // 	// 	nextScroll = $submenu.offset().top;
          // 	// }
          //
          // 	// $( 'html, body' ).scrollTop( nextScroll );
          // 	this.content.nextScrollSubmenu = false;
          //
          // }, 100 );

        } else {

          // console.log( 'navigate general' );
          const $body = $('html, body');

          $body.scrollTop(this.content.nextScroll);

          $body.css({overflow: 'visible'});

        }

        this.content.nextScroll = 0;

      }


    }));

    // manage authenticated user state
    let missCount = 0;
    let firstCheck = true;

    this.setAuthenticatedProfile(false); // initial state is false, which means not yet checked
    const applyAuthenticatedUser = () => {

      const set = (profile: Profile | null) => {

        if (firstCheck) {
          this.setAuthenticatedProfile(profile);
        } else if (profile) {
          missCount = 0;
          this.setAuthenticatedProfile(profile); // set the user
        } else if (missCount >= 6) { // don't clear a profile until its been at least 6 misses
          missCount = 0;
          this.setAuthenticatedProfile(null); // clear the user
        } else {
          missCount++; // counting profile check intervals
        }

        firstCheck = false;

        this.authenticatedUserWatch = window.setTimeout(() => {
          this.zone.run(applyAuthenticatedUser);
        }, environment.production ? 10000 : 10000);

      };

      this.profileModel.me()
        .then((profile: Profile) => {
          set(profile || null);
          return null;
        })
        .catch(() => {
          set(null);
        });

    };

    applyAuthenticatedUser();

  }

  public contentLoading(loading: boolean, status: number = 200): void {

    if (loading) {

      if (loading === this.content.loading) {
        // console.log( 'content loading no change' );
        return;
      }

      if (this.loadingTimeout) {
        return;
      }

      (<any>window).prerenderReady = false;

      const go = () => {
        this.loadingTimeout = null;
        this.content.loading = true;
      };

      if (this.content.firstLoad) {
        this.content.firstLoad = false;
        go();
      } else {
        this.loadingTimeout = window.setTimeout(go, 200);
      }

    } else {

      if (this.loadingTimeout) {
        window.clearTimeout(this.loadingTimeout);
        this.loadingTimeout = null;
      }

      if (loading === this.content.loading) {
        return;
      }

      if (typeof status !== 'number' || isNaN(status)) {
        status = 200;
      }

      let $meta = $head.find('meta[name=prerender-status-code]');

      if ($meta.length < 1) {
        $meta = $('<meta name="prerender-status-code"/>');
        $head.append($meta);
      }

      $meta.attr('content', status);

      this.content.loading = false;
      (<any>window).prerenderReady = true;

    }

  }

  public runFreshSales(handle): void {

    const freshsales = (<any>window).freshsales;

    if (!freshsales || typeof handle !== 'function') {
      return;
    }

    window.setTimeout(() => {
      handle(freshsales);
    }, 100);

  }

  public runSmartLook(handle): void {

    const smartlook = (<any>window).smartlook;

    if (!smartlook || typeof handle !== 'function') {
      return;
    }

    window.setTimeout(() => {
      handle(smartlook);
    }, 100);

  }

  public runGoogleAnalytics(handle): void {

    const googleTag = (<any>window).gtag;

    if (!googleTag || typeof handle !== 'function') {
      return;
    }

    window.setTimeout(() => {
      handle(googleTag);
    }, 100);

  }

  public analyticsLog(data: HashTree<string | number | null | boolean | undefined>): void {

  }

  public googleAnalyticsViewItems(items: GoogleViewItem[]): void {

    this.runGoogleAnalytics((gtag) => {

      if (gtag) {

        gtag('event', 'view_item', {
          items: items
        });

      }

    });

  }

  public freshSalesEvent(eventName, properties: any): void {

    this.runFreshSales((freshsales) => {

      if (freshsales) {

        if (this.authenticatedProfile && (<Profile>this.authenticatedProfile).id) {

          properties.fs_contact = true;

          freshsales.trackEvent(eventName, properties, function (response) {
            // console.log( 'freshSalesEvent success', eventName, properties, response );
          }, function (response) {
            // console.log( 'freshSalesEvent failure', eventName, properties, response );
          });

        } else if (typeof properties.email === 'string' && properties.email.match(/^.+@.+$/)) {

          const freshSalesProperties: any = {
            fs_contact: true,
            Email: properties.email
          };

          if (properties.firstName) {
            freshSalesProperties['First Name'] = properties.firstName;
          }
          if (properties.lastName) {
            freshSalesProperties['Last Name'] = properties.firstName;
          }
          if (properties.company) {
            freshSalesProperties.company = {
              Name: properties.firstName
            };
          }

          freshsales.identify(properties.email, freshSalesProperties, () => {

            freshsales.trackEvent(eventName, properties, (response) => {
              // console.log( 'freshSalesEvent success anonymous', eventName, properties, response );
            }, function (response) {
              // console.log( 'freshSalesEvent failure anonymous', eventName, properties, response );
            });

          });


        }


      }

    });

  }

  public analyticsSync(): void {

    // we don't log employee hits
    if (this.authenticatedProfile && (<Profile>this.authenticatedProfile).isEmployee) {
      return;
    }

    this.zone.runOutsideAngular(() => {

      window.setTimeout(() => {

        this.analytics
          .logEvent({
            action: 'pageView'
          })
          .then((id) => {
            console.log('logged analytics event with id ' + id);
          })
          .catch((e) => {
            console.error('failed to log analytics event', e);
          });

        this.runFreshSales((freshsales) => {

          if (freshsales) {
            if (this.authenticatedProfile && (<Profile>this.authenticatedProfile).id) {

              const profile = <Profile>this.authenticatedProfile;

              freshsales.identify(profile.id,
                {
                  'First Name': profile.firstName,
                  'Last Name': profile.lastName,
                  Email: profile.email,
                  company: {
                    Name: profile.company
                  },
                  fs_contact: true
                },
                () => {

                  this.freshSalesEvent('Viewed web page', {url: (<any>window).location.href});

                });

            } else {
              this.freshSalesEvent('Viewed web page', {url: (<any>window).location.href});
            }

            freshsales.trackPageView((<any>window).location.href);

          }

        });

        this.runSmartLook((smartlook) => {

          if (smartlook) {


            const queryEmail = this.queryParams['sl_email'];

            if (typeof queryEmail === 'string' && queryEmail.trim().length > 0) {

              const email = queryEmail.trim();
              const fname = this.queryParams['sl_fname'];
              const lname = this.queryParams['sl_lname'];

              const profile = {
                email: email,
                name: undefined
              };

              if (typeof fname === 'string' && fname.trim().length > 0) {
                profile.name = fname.trim();
                if (typeof lname === 'string' && lname.trim().length > 0) {
                  profile.name += ' ' + lname.trim();
                }
              } else {
                delete profile.name;
              }


              smartlook('identify', email, profile);

              // create a new URL object from the current URL
              const url = new URL(window.location.href);

              // get the search params of the URL
              const searchParams = new URLSearchParams(url.search);

              // delete the parameters you wish to remove
              searchParams.delete('sl_email');
              searchParams.delete('sl_fname');
              searchParams.delete('sl_lname');

              // set the modified search parameters back on the URL
              url.search = searchParams.toString();

              // get the current state and title
              const currentState = window.history.state;
              const title = document.title;

              // replace the URL in the browser without adding a new browser history entry
              // and maintaining the current state and title
              window.history.replaceState(currentState || null, title, url.toString());

            } else if (this.authenticatedProfile && (<Profile>this.authenticatedProfile).id) {

              const profile: Profile = <Profile>this.authenticatedProfile;
              const smartlookProfile = {
                name: profile.firstName + ' ' + profile.lastName,
                email: profile.email
              };

              smartlook('identify', profile.id, smartlookProfile);

            }

          }

        });

        this.runGoogleAnalytics((gtag) => {

          if (gtag) {

            const config = {'page_path': (<any>window).location.pathname};

            if (this.authenticatedProfile && (<Profile>this.authenticatedProfile).email) {

              const profile: Profile = <Profile>this.authenticatedProfile;

              config['user_id'] = profile.email;

            }

            gtag('config', 'UA-68545220-1', config);
            gtag('event', 'page_view', {'send_to': 'UA-68545220-1'});

          }

        });

      }, 500);

    });

  }

  public browserEnv(): {
    isOpera: boolean;
    isFirefox: boolean;
    isSafari: boolean;
    isIE: boolean;
    isEdge: boolean;
    isChrome: boolean;
    isBlink: boolean;
  } {

    const thisWindow: any = window;

    const isOpera = (!!thisWindow.opr && !!thisWindow.opr.addons) || !!thisWindow.opera || navigator.userAgent.indexOf(' OPR/') >= 0;

    // Firefox 1.0+
    const isFirefox = typeof thisWindow.InstallTrigger !== 'undefined';

    // Safari 3.0+ "[object HTMLElementConstructor]"
    const isSafari = /constructor/i.test(thisWindow.HTMLElement) || (function (p) {
      return p.toString() === '[object SafariRemoteNotification]';
    })(!thisWindow['safari'] || thisWindow['safari'].pushNotification);

    // Internet Explorer 6-11
    const isIE = /*@cc_on!@*/false || !!(<any>document).documentMode;

    // Edge 20+
    const isEdge = !isIE && !!thisWindow.StyleMedia;

    // Chrome 1+
    const isChrome = !!thisWindow.chrome && !!thisWindow.chrome.webstore;

    // Blink engine detection
    const isBlink = (isChrome || isOpera) && !!thisWindow.CSS;

    return {
      isOpera: isOpera,
      isFirefox: isFirefox,
      isSafari: isSafari,
      isIE: isIE,
      isEdge: isEdge,
      isChrome: isChrome,
      isBlink: isBlink
    };

  }

  public ngOnDestroy(): void {
    this.subscriptions.forEach((subscription) => {
      subscription.unsubscribe();
    });
  }

  public lastRoute(): string {
    if (this.navigationHistory.length > 0) {
      return this.navigationHistory[this.navigationHistory.length - 1];
    }

    return null;

  }

  public printReady(): void {
    // console.log( 'trigger print ready callback' );
  }

  public signUp(reason?: SignUpTypes, targetUrl?: string): void {

    if (typeof targetUrl === 'string' && targetUrl.trim().length > 0) {
      Cookie.set('login-target', targetUrl);
    } else {
      Cookie.set('login-target', this.location.path(false));
    }

    if (typeof reason === 'string' && reason.trim().length > 0) {
      Cookie.set('signup-reason', reason);
      this.router.navigate(['/action/signup-' + reason + '/start']);
    } else {
      Cookie.set('signup-reason', null);
      this.router.navigate(['/action/signup/start']);
    }


  }

  resetStateFields(): void {

    this.content = {
      firstLoad: true,
      loading: true,
      backgroundLight: true,
      dragging: false,
      nextScroll: 0,
      nextScrollSubmenu: false,
      submenu: []
    };

    this.toolbar = {
      disabled: false,
      collapsed: true,
      whiteOverContent: true,
      backgroundColor: null,
      foregroundColor: null
    };

    this.footer = {
      hide: false,
      showMenu: true
    };

  }

  login(params: { email: string, password: string }): Bluebird<string> {

    const result = this.profileModel.login(params.email, params.password);

    result
      .then((userId: string) => {

        if (userId && userId.length > 0) {

          this.profileModel.me().then((profile: Profile) => {
            this.setAuthenticatedProfile(profile);
          })
            .catch((e) => {
              this.clearAuthenticatedProfile();
            });

        } else {
          this.clearAuthenticatedProfile();
        }

        return null;
      })
      .catch((e) => {
        this.clearAuthenticatedProfile();
      });

    return result;

  }

  logout(): void {

    this.toolbar.whiteOverContent = false;
    this.contentLoading(true);

    const done = () => {
      (<any>window).location.reload();
      return null;
    };

    this.profileModel.logout()
      .then(done)
      .catch(done);

  }

  // signup( profile: Profile ): Bluebird<boolean> {
  //
  //   let result = this.profileModel.create( profile );
  //
  //   result
  //     .then( () => {
  //       this.clearAuthenticatedProfile();
  //       this.login( { email: profile.email, password: profile.password } );
  //       return null;
  //     } )
  //     .catch( () => {
  //       this.clearAuthenticatedProfile();
  //     } );
  //
  //   return result;
  //
  // }

  public twoLineString(line: string): string {

    if (typeof line !== 'string') {
      return '';
    }

    const description = line.trim().replace(/\s\s*/g, ' ');
    const parts = description.split(/ /);
    const lines: string[][] = [[], parts];

    const countLine = (line: string[]): number => {
      return line.join(' ').length;
    };

    const shiftWord = (): void => {
      const word = lines[1].shift();
      lines[0].push(word);
    };

    const quit = false;

    while (!quit && (countLine(lines[0]) > 0 || countLine(lines[1]) > 0) && countLine(lines[1]) > countLine(lines[0])) {
      shiftWord();
    }

    return lines[0].join(' ') + '<br/>' + lines[1].join(' ');

  }

  public markdownToHtml(markdownStr: string): string {

    const window = _window();

    if (window && window.markdown) {
      return window.markdown.toHTML(markdownStr);
    }

    return markdownStr;

  }

  public _getSubject(field: string, id?: string | undefined): BehaviorSubject<BehaviorSubjectContentTypes> {

    if (typeof id === 'undefined') {
      id = '__id';
    }

    // lazy init with null default value
    if (!this.subjects[field]) {
      this.subjects[field] = {};
    }
    if (!this.subjects[field][id]) {
      this.subjects[field][id] = new BehaviorSubject<BehaviorSubjectContentTypes>(null);
    }

    return this.subjects[field][id];

  }

  public _clearSubject(field: string, id?: string | undefined): void {

    if (!this.subjects[field]) {
      return;
    }

    if (typeof id === 'undefined') {
      id = '__id';
    }

    if (this.subjects[field][id]) {
      this.subjects[field][id].complete();
      delete this.subjects[field][id];
    }

  }

  public setAuthenticatedProfile(authenticatedProfile: Profile | boolean): void {

    // console.log( 'setAuthenticatedProfile', authenticatedProfile );

    // if ( this.authenticatedProfile ) {
    //   Cookie.set( 'previous-login', 'true', { expires: 365 } );
    // }

    // don't push the profile if it hasn't changed
    if (this.authenticatedProfile === authenticatedProfile) {
      return;
    }

    // don't push the profile if it hasn't changed
    if (typeof this.authenticatedProfile !== 'boolean' && typeof authenticatedProfile !== 'boolean' &&
      this.authenticatedProfile && authenticatedProfile &&
      (<Profile>this.authenticatedProfile).id === authenticatedProfile.id) {

      return;

    }

    this.authenticatedProfile = authenticatedProfile;

    this._getSubject('authenticatedProfile').next(authenticatedProfile);

  }

  public previousLogin(): boolean {
    return false; // Cookie.get( 'previous-login' ) === 'true';
  }

  public getAuthenticatedProfile(observer: Observer<Profile>): Subscription {
    return this._getSubject('authenticatedProfile').subscribe(observer);
  }

  public clearAuthenticatedProfile(): void {

    this.setAuthenticatedProfile(null);

    // window.location.reload();
    // this.router.navigate( [ '/' ] );

  }

  public windowOpen(params: {
    url: string;
    target?: '_blank' | '_parent' | '_self' | '_top';
    features?: string;
    replace?: boolean;
    height?: number;
    width?: number;
  }): Window {

    function FindLeftWindowBoundary() {
      // In Internet Explorer window.screenLeft is the window's left boundary
      if (window.screenLeft) {
        return window.screenLeft;
      }

      // In Firefox window.screenX is the window's left boundary
      if (window.screenX) {
        return window.screenX;
      }

      return 0;
    }

    // Find Left Boundary of current Window
    function FindTopWindowBoundary() {
      // In Internet Explorer window.screenLeft is the window's left boundary
      if (window.screenTop) {
        return window.screenTop;
      }

      // In Firefox window.screenY is the window's left boundary
      if (window.screenY) {
        return window.screenY;
      }

      return 0;
    }

    if (typeof params.height !== 'number') {
      params.height = screen.height;
    }
    if (typeof params.width !== 'number') {
      params.width = screen.width;
    }

    if (params.height > 0 && params.height < 1) {
      params.height = Math.floor(params.height * screen.height);
    }

    if (params.width > 0 && params.width < 1) {
      params.width = Math.floor(params.width * screen.width);
    }

    params.height = Math.min(params.height, screen.height);
    params.width = Math.min(params.width, screen.width);

    const y = screen.height / 2 - params.height / 2 + FindTopWindowBoundary();
    const x = screen.width / 2 - params.width / 2 + FindLeftWindowBoundary();

    if (!params.features) {
      params.features = '';
    }

    params.features += `,height=${params.height},width=${params.width},top=${y},left=${x}`;
    params.features = params.features.replace(/^,/, '');

    return window.open(params.url, params.target || '_blank', params.features || undefined, params.replace || false);

  }

  public onToolbarMenuState(observer: (value: string) => void): Subscription {
    return this.toolbarMenuState.subscribe(observer);
  }

  public toolbarMenuExpand(): void {
    this.toolbarMenuState.next('expand');
  }

  public toolbarMenuCollapse(): void {
    this.toolbarMenuState.next('collapse');
  }


}


