import { Component, OnInit, OnDestroy } from '@angular/core';

import { AppService } from 'app/services/app';
import { Profile } from 'app/models/profile';
import { Router } from '@angular/router';
import { Hash } from 'app/types/containers';

@Component( {
  moduleId: module.id,
  selector: 'app-view-userreset-main',
  templateUrl: 'main.component.html',
  styleUrls: [ 'main.component.css' ]
} )
export class ViewsUserResetMainComponent implements OnInit, OnDestroy {

  public emails: string[] = [];
  public confirmUser: string = null;
  public processing: Hash<boolean> = {};
  public error: string = null;
  public subscriptions: any[] = [];

  constructor( public appService: AppService, public router: Router ) {
    this.appService.contentLoading( true );
    this.appService.toolbar.whiteOverContent = false;
    this.appService.titleService.setTitle( `Exio - User Reset` );
  }

  public ngOnDestroy(): void {

    this.subscriptions.forEach( ( subscription ) => {
      subscription.unsubscribe();
    } );

  }

  ngOnInit(): void {

    document.body.scrollTop = 0;

    this.subscriptions.push( this.appService.getAuthenticatedProfile( {
      next: ( profile ) => {
        this.appService.contentLoading( true );

        if ( typeof profile === 'boolean' && !profile ) {
          return;
        }

        if ( !profile || !profile.isEmployee ) {
          this.router.navigate( [ '/' ] );
        } else {
          this.init();
        }

      },
      error: () => {
        this.router.navigate( [ '/' ] );
      },
      complete: () => {
        this.router.navigate( [ '/' ] );
      }
    } ) );

  }

  init(): void {

    this.appService.exio.user.adminDestroyWhiteList()
      .then( ( list: string[] ) => {
        // console.log( 'list', list );
        this.emails = list;
        this.appService.contentLoading( false );
      } )
      .catch( () => {
      } );

  }

  clear(): void {
    this.confirmUser = null;
    this.error = null;
  }

  resetUser( email: string ): void {
    this.clear();
    this.confirmUser = email;
  }

  confirmResetUser(): void {

    let email = this.confirmUser;
    this.confirmUser = null;

    if ( !email ) {
      return;
    }

    let startTime = new Date().getTime();

    let done = ( success: boolean ) => {

      let finishTime = new Date().getTime();
      let duration = finishTime - startTime;

      let go = () => {
        this.confirmUser = null;
        this.processing[ email ] = false;
      };

      // minimum 2 seconds to show user progress
      if ( success && duration < 2000 ) {
        window.setTimeout( go, 2000 - duration );
      } else {
        go();
      }

    };

    this.processing[ email ] = true;

    // window.setTimeout( done, 60000 );

    this.appService.exio.user.adminDestroy( email )
      .then( () => {
        done( true );
      } )
      .catch( ( err ) => {
        this.error = err.message;
        done( false );
      } );
  }

}
