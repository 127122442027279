import * as tslib_1 from "tslib";
// import { sError } from 'app/types/standard';
import * as Bluebird from 'bluebird';
var GenericSchemaType = /** @class */ (function () {
    function GenericSchemaType(ref) {
        if (ref) {
            for (var field in ref) {
                if (ref.hasOwnProperty(field)) {
                    this[field] = ref[field];
                }
            }
        }
    }
    return GenericSchemaType;
}());
export { GenericSchemaType };
var GenericMasterSchemaType = /** @class */ (function (_super) {
    tslib_1.__extends(GenericMasterSchemaType, _super);
    function GenericMasterSchemaType(ref) {
        var _this = _super.call(this, ref) || this;
        _this.id = null;
        if (ref.hasOwnProperty('id')) {
            _this.id = ref.id;
        }
        else if (ref.hasOwnProperty('_id')) {
            _this.id = ref._id;
        }
        if (_this.hasOwnProperty('_id')) {
            delete _this._id;
        }
        return _this;
    }
    return GenericMasterSchemaType;
}(GenericSchemaType));
export { GenericMasterSchemaType };
var GenericModel = /** @class */ (function () {
    function GenericModel() {
        this.name = null;
    }
    GenericModel.prototype.init = function (api, name, defaultInstance, cacheTimeout) {
        this.api = api;
        this.name = name;
        this.defaultInstance = defaultInstance;
    };
    GenericModel.prototype.newInstance = function () {
        return this.clone(this.defaultInstance);
    };
    GenericModel.prototype.getVersions = function (id) {
        return this.api.call(this.name + ".versionsById", { id: id });
    };
    GenericModel.prototype.get = function (id, noCache) {
        var _this = this;
        return this.api
            .call(this.name + ".byId", { id: id })
            .then(function (instance) {
            if (instance) {
                instance = _this.getInstance(instance);
            }
            else {
                instance = null;
            }
            return instance;
        });
    };
    GenericModel.prototype.query = function (query) {
        if (query === void 0) { query = {}; }
        return this.api.call(this.name + ".list", query);
    };
    GenericModel.prototype.save = function (instance) {
        return this.api.call(this.name + ".save", instance);
    };
    GenericModel.prototype.delete = function (id) {
        var _this = this;
        return new Bluebird(function (resolve, reject) {
            _this.api.call(_this.name + ".delete", { id: id })
                .then(function () {
                resolve(true);
            })
                .catch(reject);
        });
    };
    GenericModel.prototype.clone = function (instance) {
        return JSON.parse(JSON.stringify(instance));
    };
    return GenericModel;
}());
export { GenericModel };
