import * as tslib_1 from "tslib";
import { OnInit, OnDestroy } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { Location } from '@angular/common';
import { AppService } from 'app/services/app';
import { Article, ArticleModel } from 'app/models/article';
import { MailchimpModel } from 'app/models/mailchimp';
import * as async from 'async';
import { MatTableDataSource, MatSort, MatPaginator } from '@angular/material';
import { ApiService } from '../../../services/api';
var ViewsListingManagementComponent = /** @class */ (function () {
    function ViewsListingManagementComponent(appService, articleModel, apiService, route, router, location, sanitizer, mailchimp) {
        this.appService = appService;
        this.articleModel = articleModel;
        this.apiService = apiService;
        this.route = route;
        this.router = router;
        this.location = location;
        this.sanitizer = sanitizer;
        this.mailchimp = mailchimp;
        this.pageSize = 20;
        this.pageSizeOptions = [5, 10, 20, 40];
        this.profileFilter = '';
        this.subscriptions = [];
        this.article = null;
        this.mailchimpLists = null;
        this.selectedMailchimpListInvites = { id: null, name: 'Default' };
        this.selectedMailchimpListActiveSubscriptions = { id: null, name: 'Default' };
        this.adminUserProfilesFilteredDataSource = null;
        this.adminUserProfilesFiltered = [];
        this.adminUserProfiles = [];
        this.activeSubscriptionProfilesFilteredDataSource = null;
        this.activeSubscriptionProfilesFiltered = [];
        this.activeSubscriptionProfilesRaw = [];
        this.subscriptionProfilesDeleted = [];
        this.adminUserProfilesDeleted = [];
        this.invitedSubscriptionsFilteredDataSource = null;
        this.invitedSubscriptionsFiltered = []; // Subscriptions after applying filter
        this.invitedSubscriptionsRaw = []; // All subscriptions from API
        this.pendingApprovalsEditFilteredDataSource = null;
        this.pendingApprovalsEditFiltered = [];
        this.pendingApprovals = [];
        this.pendingApprovalsEdit = [];
        this.pendingApprovalsDeleted = [];
        this.authenticatedProfile = null;
        this.savingSubscriptionPermissions = false;
        this.savingAdminPermissions = false;
        this.addingAdminUser = false;
        this.addingUser = false;
        this.invitingUser = false;
        this.accessLevels = {};
        this.accessLevelsEdit = {};
        this.adminUsers = [];
        this.adminUsersEdit = [];
        this.addSubscriptionEmails = '';
        this.addAdminEmails = '';
        this.inviteEmails = '';
        this.inviteAccessLevel = 'public';
        this.activeAccessLevel = 'private';
        this.appService.contentLoading(true);
        this.appService.toolbar.whiteOverContent = false;
        this.appService.titleService.setTitle("Exio - Listing Management");
        this.updateDataSourceLists();
    }
    ViewsListingManagementComponent.prototype.ngOnInit = function () {
        var _this = this;
        var getMailchimpLists = function () {
            var approvedEmails = [
                'anthony.hildoer@goexio.com',
                'bill.littlefield@goexio.com',
                'tera.poirier@goexio.com'
            ];
            if (!_this.authenticatedProfile || approvedEmails.indexOf(_this.authenticatedProfile.email) < 0) {
                return;
            }
            _this.mailchimp
                .getLists({
                fields: ['id', 'name']
            })
                .then(function (lists) {
                // sort alphabetically
                lists = lists.sort(function (a, b) {
                    var aName = a.name.toLowerCase();
                    var bName = b.name.toLowerCase();
                    if (aName < bName) {
                        return -1;
                    }
                    else if (aName > bName) {
                        return 1;
                    }
                    else {
                        return 0;
                    }
                });
                // add default method to start of list
                lists.unshift({ id: null, name: 'Default' });
                _this.mailchimpLists = lists;
            })
                .catch(function (e) {
                console.error('failed to load mailchimp lists', e);
            });
        };
        this.subscriptions.push(this.appService.getAuthenticatedProfile({
            next: function (profile) {
                if (typeof profile === 'boolean' && !profile) {
                    _this.appService.contentLoading(true);
                }
                else if (!profile) {
                    _this.router.navigate(['/']);
                }
                else {
                    _this.authenticatedProfile = profile;
                    // already have a subscription for params
                    if (_this.subscriptions.length > 1) {
                        return;
                    }
                    // init with all articles
                    _this.subscriptions.push(_this.route.params.subscribe(function (params) {
                        _this.loadListing(params.id);
                    }));
                    getMailchimpLists();
                }
            },
            error: function () {
                _this.router.navigate(['/']);
            },
            complete: function () {
                _this.router.navigate(['/']);
            }
        }));
    };
    ViewsListingManagementComponent.prototype.updateDataSourceLists = function () {
        var _this = this;
        this.invitedSubscriptionsFilteredDataSource =
            new MatTableDataSource(this.invitedSubscriptionsFiltered
                .map(function (record) {
                record.name = _this.displayName(record);
                record.accessLevel = _this.getInviteAccessLevel(record);
                return record;
            }));
        this.invitedSubscriptionsFilteredDataSource.sort = this.invitedSubscriptionsFilteredDataSourceSort;
        this.invitedSubscriptionsFilteredDataSource.paginator = this.invitedSubscriptionsFilteredDataSourcePaginator;
        this.activeSubscriptionProfilesFilteredDataSource =
            new MatTableDataSource(this.activeSubscriptionProfilesFiltered
                .map(function (record) {
                record.name = _this.displayName(record);
                return record;
            }));
        this.activeSubscriptionProfilesFilteredDataSource.sort = this.activeSubscriptionProfilesFilteredDataSourceSort;
        this.activeSubscriptionProfilesFilteredDataSource.paginator = this.activeSubscriptionProfilesFilteredDataSourcePaginator;
        this.pendingApprovalsEditFilteredDataSource =
            new MatTableDataSource(this.pendingApprovalsEditFiltered
                .map(function (record) {
                record.name = _this.displayName(record);
                record.accessLevel = _this.requestedAccessLevel(record);
                return record;
            }));
        this.pendingApprovalsEditFilteredDataSource.sort = this.pendingApprovalsEditFilteredDataSourceSort;
        this.adminUserProfilesFilteredDataSource =
            new MatTableDataSource(this.adminUserProfilesFiltered
                .map(function (record) {
                record.name = _this.displayName(record);
                record.accessLevel = null;
                return record;
            }));
        this.adminUserProfilesFilteredDataSource.sort = this.adminUserProfilesFilteredDataSourceSort;
        this.adminUserProfilesFilteredDataSource.paginator = this.adminUserProfilesFilteredDataSourcePaginator;
    };
    ViewsListingManagementComponent.prototype.showMailchimpLists = function () {
        return Array.isArray(this.mailchimpLists);
    };
    ViewsListingManagementComponent.prototype.openProfile = function (profile) {
        var url = this.location.prepareExternalUrl('/profile/' + profile.id);
        window.open(url, '_blank');
    };
    ViewsListingManagementComponent.prototype.ngOnDestroy = function () {
        this.subscriptions.forEach(function (subscription) {
            subscription.unsubscribe();
        });
    };
    ViewsListingManagementComponent.prototype.listingName = function () {
        if (!this.article) {
            return 'Loading...';
        }
        if (!this.article.data) {
            this.article.data = {};
        }
        if (this.article.data.listingCompanyName) {
            return this.article.data.listingCompanyName.replace(/\n/g, ' ').replace(/ +/g, ' ');
        }
        return this.article.title;
    };
    ViewsListingManagementComponent.prototype.getAccessLevel = function (profileId) {
        if (!profileId || typeof profileId !== 'string') {
            return 'public';
        }
        if (this.accessLevelsEdit &&
            this.accessLevelsEdit.hasOwnProperty(profileId) &&
            typeof this.accessLevelsEdit[profileId] === 'string') {
            return this.accessLevelsEdit[profileId];
        }
        if (this.accessLevels &&
            this.accessLevels.hasOwnProperty(profileId) &&
            typeof this.accessLevels[profileId] === 'string') {
            return this.accessLevels[profileId];
        }
        return 'public';
    };
    ViewsListingManagementComponent.prototype.accessLevelsChanged = function () {
        return Object.keys(this.accessLevelsEdit).length > 0 ||
            Object.keys(this.pendingApprovalsEdit).length > 0 ||
            this.subscriptionProfilesDeleted.length > 0;
    };
    ViewsListingManagementComponent.prototype.adminUsersChanged = function () {
        var oldValue = JSON.stringify(this.adminUsers.sort());
        var newValue = JSON.stringify(this.adminUsersEdit.sort());
        return oldValue !== newValue;
    };
    ViewsListingManagementComponent.prototype.initPage = function () {
        var _this = this;
        this.pendingApprovalsDeleted = [];
        this.activeSubscriptionProfilesRaw = [];
        this.adminUserProfiles = [];
        this.adminUsers = JSON.parse(JSON.stringify(this.article.adminUsers)); // clone
        this.adminUsersEdit = JSON.parse(JSON.stringify(this.article.adminUsers)); // clone
        this.subscriptionProfilesDeleted = [];
        this.adminUserProfilesDeleted = [];
        this.accessLevels = {};
        this.accessLevelsEdit = {};
        this.selectedMailchimpListInvites = { id: null, name: 'Default' };
        this.selectedMailchimpListActiveSubscriptions = { id: null, name: 'Default' };
        if (!this.article) {
            return;
        }
        var article = this.article;
        var subscriptionProfileIds = [];
        var pushSubscriptionProfileId = function (profileId, level) {
            _this.accessLevels[profileId] = level;
            if (subscriptionProfileIds.indexOf(profileId) < 0) {
                subscriptionProfileIds.push(profileId);
            }
        };
        var pushProfileIdGeneric = function (level) {
            return function (profileId) {
                pushSubscriptionProfileId(profileId, level);
            };
        };
        this.article.accessLevelPrivate.forEach(pushProfileIdGeneric('private'));
        this.article.accessLevelExclusive.forEach(pushProfileIdGeneric('exclusive'));
        async.waterfall([
            function (done) {
                var ids = [];
                if (_this.article.accessLevelInvites && typeof _this.article.accessLevelInvites === 'object') {
                    ids = ids.concat(Object.keys(_this.article.accessLevelInvites));
                }
                [
                    'adminUsers',
                    'accessLevelPrivate',
                    'accessLevelExclusive',
                    'accessLevelExclusivePending',
                    'accessLevelPrivatePending'
                ].forEach(function (field) {
                    if (Array.isArray(article[field])) {
                        ids = ids.concat(article[field]);
                    }
                });
                done(null, ids.length);
            },
            function (profileCount, done) {
                var pendingApprovals = [];
                _this.article.accessLevelPrivatePending.concat(_this.article.accessLevelExclusivePending).forEach(function (pendingId) {
                    pendingApprovals.push(pendingId);
                });
                async.mapLimit(pendingApprovals, 5, function (profileId, done) {
                    _this.appService.profileModel.get(profileId).asCallback(done);
                }, done);
            },
            function (pendingApprovals, done) {
                _this.pendingApprovals = _this.sortProfiles(pendingApprovals);
                _this.pendingApprovalsEdit = JSON.parse(JSON.stringify(_this.pendingApprovals));
                done();
            },
            function (done) {
                if (!_this.article.accessLevelInvites) {
                    return done(null, []);
                }
                async.mapLimit(Object.keys(_this.article.accessLevelInvites), 5, function (profileId, done) {
                    _this.appService.profileModel.get(profileId).asCallback(done);
                }, done);
            },
            function (inviteProfiles, done) {
                _this.invitedSubscriptionsRaw = [];
                inviteProfiles.forEach(function (profile) {
                    if (profile) {
                        _this.invitedSubscriptionsRaw.push(profile);
                    }
                });
                _this.invitedSubscriptionsRaw = _this.sortProfiles(_this.invitedSubscriptionsRaw);
                done();
            },
            function (done) {
                async.mapLimit(subscriptionProfileIds, 5, function (profileId, done) {
                    _this.appService.profileModel.get(profileId).asCallback(done);
                }, done);
            },
            function (subscriptionProfiles, done) {
                subscriptionProfiles.forEach(function (subscriptionProfile) {
                    _this.pushSubscriptionProfile(subscriptionProfile);
                });
                done();
            },
            function (done) {
                async.mapLimit(_this.article.adminUsers, 5, function (profileId, done) {
                    _this.appService.profileModel.get(profileId).asCallback(done);
                }, done);
            },
            function (adminUserProfiles, done) {
                adminUserProfiles.forEach(function (profile) {
                    _this.pushAdminProfile(profile);
                });
                done();
            },
            function (done) {
                _this.applyProfileFilter();
                done();
            }
        ]);
    };
    ViewsListingManagementComponent.prototype.sortProfiles = function (profiles) {
        var _this = this;
        return profiles.sort(function (a, b) {
            var aName = _this.displayNameAndEmail(a);
            var bName = _this.displayNameAndEmail(b);
            if (aName < bName) {
                return -1;
            }
            if (aName > bName) {
                return 1;
            }
            return 0;
        });
    };
    ViewsListingManagementComponent.prototype.pushProfileGeneric = function (profile, list) {
        if (!profile) {
            return;
        }
        var index = this.profileIndexOf(profile, list);
        if (index !== null) {
            return;
        }
        list.push(profile);
        var temp = this.sortProfiles(list);
        this.applyProfileFilter();
        return temp;
    };
    ViewsListingManagementComponent.prototype.pushSubscriptionProfile = function (profile) {
        this.pushProfileGeneric(profile, this.activeSubscriptionProfilesRaw);
    };
    ViewsListingManagementComponent.prototype.pushAdminProfile = function (profile) {
        this.pushProfileGeneric(profile, this.adminUserProfiles);
    };
    ViewsListingManagementComponent.prototype.loadListing = function (id) {
        var _this = this;
        if (!id) {
            if (!this.article || !this.article.id) {
                this.appService.contentLoading(false);
                console.error('Can not load article without an id');
                return;
            }
            id = this.article.id;
        }
        return this.articleModel.get(id, true)
            .then(function (article) {
            _this.article = article || null;
            if (_this.article) {
                _this.appService.contentLoading(false);
            }
            else {
                _this.appService.contentLoading(false, 404);
            }
            if (!_this.authenticatedProfile || !_this.authenticatedProfile.isEmployee) {
                var adminUsers = Array.isArray(article.adminUsers) ? article.adminUsers : [];
                if (!_this.authenticatedProfile.id || adminUsers.indexOf(_this.authenticatedProfile.id) < 0) {
                    _this.router.navigate(['/']);
                }
            }
            return null;
        })
            .catch(function (e) {
            console.error('failed to load listing article', e);
            return null;
        })
            .finally(function () {
            _this.initPage();
        });
    };
    ViewsListingManagementComponent.prototype.setAccessLevel = function (profileId, accessLevel) {
        if (this.accessLevels[profileId] === accessLevel) {
            delete this.accessLevelsEdit[profileId];
        }
        else {
            this.accessLevelsEdit[profileId] = accessLevel;
        }
        this.removeFromDeleted(profileId);
    };
    ViewsListingManagementComponent.prototype.saveSubscriptions = function () {
        var _this = this;
        if (!this.accessLevelsChanged()) {
            return;
        }
        var editedAccessLevels = this.accessLevelsEdit;
        this.savingSubscriptionPermissions = true;
        // this.article.accessLevelPrivatePending.concat( this.article.accessLevelExclusivePending ).forEach( ( pendingId ) => {
        // 	pendingApprovals.push( pendingId );
        // } );
        var article = new Article({
            id: this.article.id,
            type: this.article.type,
            deleteAccess: [],
            accessLevelPrivate: [],
            accessLevelExclusive: []
        });
        var _loop_1 = function (profileId) {
            if (!editedAccessLevels.hasOwnProperty(profileId)) {
                return "continue";
            }
            var editedAccessLevel = editedAccessLevels[profileId];
            var accessLevelChanged = !this_1.accessLevels.hasOwnProperty(profileId) || this_1.accessLevels[profileId] !== editedAccessLevel;
            if (editedAccessLevel === 'exclusive') {
                // add exclusive access
                if (article.accessLevelExclusive.indexOf(profileId) < 0) {
                    article.accessLevelExclusive.push(profileId);
                }
                // remove private access
                var privateIndex = article.accessLevelPrivate.indexOf(profileId);
                if (privateIndex > -1) {
                    article.accessLevelPrivate.splice(privateIndex, 1);
                }
            }
            else if (editedAccessLevel === 'private') {
                // add private access
                if (article.accessLevelPrivate.indexOf(profileId) < 0) {
                    article.accessLevelPrivate.push(profileId);
                }
                // remove exclusive access
                var exclusiveIndex = article.accessLevelExclusive.indexOf(profileId);
                if (exclusiveIndex > -1) {
                    article.accessLevelExclusive.splice(exclusiveIndex, 1);
                }
            }
            // TODO: move this to the server
            if (accessLevelChanged) {
                this_1.appService.profileModel
                    .get(profileId)
                    .asCallback(function (err, profile) {
                    if (err) {
                        console.error('failed to get profile to email user', err);
                        return;
                    }
                    if (!profile.id) {
                        profile.id = profileId;
                    }
                    _this.emailLink(profile, editedAccessLevel, false, _this.selectedMailchimpListActiveSubscriptions);
                });
            }
        };
        var this_1 = this;
        for (var profileId in editedAccessLevels) {
            _loop_1(profileId);
        }
        var deletedIds = this.subscriptionProfilesDeleted.map(function (profile) { return profile.id; });
        return this.articleModel.save(tslib_1.__assign({}, article, { deleteAccess: deletedIds }))
            .catch(function (e) {
            console.error('failed to save article', e);
            return null;
        })
            .finally(function () {
            _this.savingSubscriptionPermissions = false;
            return _this.loadListing();
        });
    };
    ViewsListingManagementComponent.prototype.mailchimpListDisplay = function (list) {
        return list ? list.name : undefined;
    };
    ViewsListingManagementComponent.prototype.displayNameAndEmail = function (profile) {
        if (!profile) {
            return null;
        }
        return this.displayName(profile) + '<' + this.displayEmail(profile) + '>';
    };
    ViewsListingManagementComponent.prototype.displayName = function (profile) {
        if (!profile) {
            return null;
        }
        var name = 'Name Missing <' + profile.id + '>';
        var firstName = (typeof profile.firstName === 'string' && profile.firstName.trim().length > 1)
            ? profile.firstName.trim() : null;
        var lastName = (typeof profile.lastName === 'string' && profile.lastName.trim().length > 1)
            ? profile.lastName.trim() : null;
        if (firstName && lastName) {
            name = lastName + ", " + firstName;
        }
        else if (firstName) {
            name = "<missing>, " + firstName;
        }
        else if (lastName) {
            name = lastName + ", <missing>";
        }
        if (profile.id === this.authenticatedProfile.id) {
            name += ' (your account)';
        }
        return name.trim();
    };
    ViewsListingManagementComponent.prototype.displayEmail = function (profile) {
        if (!profile) {
            return null;
        }
        var email = profile.email;
        if (typeof email !== 'string' || email.trim().length < 3) {
            email = '<missing>';
        }
        return email.trim();
    };
    ViewsListingManagementComponent.prototype.profileIndexOf = function (profile, profiles) {
        return this.profileIndexOfId(profile.id, profiles);
    };
    ViewsListingManagementComponent.prototype.profileIndexOfId = function (profileId, profiles) {
        var index = null;
        profiles.forEach(function (searchProfile, i) {
            if (searchProfile && searchProfile.id === profileId) {
                index = i;
            }
        });
        return index;
    };
    ViewsListingManagementComponent.prototype.resendInvite = function (profile) {
        var accessLevel = this.getInviteAccessLevel(profile);
        this._sendInvites([profile.email], accessLevel);
    };
    ViewsListingManagementComponent.prototype.emailLink = function (profile, accessLevel, needNDA, mailchimpList, done) {
        var _this = this;
        if (typeof done !== 'function') {
            done = function () {
                // NO-OP
            };
        }
        var accessLevelDisplay = 'Public';
        if (accessLevel === 'exclusive') {
            accessLevelDisplay = 'Exclusive';
        }
        else if (accessLevel === 'private') {
            accessLevelDisplay = 'Private';
        }
        var title = needNDA ? this.article.title : this.listingName();
        // default to sending them to the article
        var redirect = this.article.path;
        var body = "<p>You have been granted " + accessLevelDisplay + " Access to the listing " + title + "." +
            " Click 'View Listing' below to view the listing now.</p>";
        async.waterfall([
            function (done) {
                if (needNDA) {
                    body += "<p>An NDA is required to view this listing. If you have not already signed one you will be" +
                        " prompted to sign an NDA to protect the confidentiality of the listing.</p>";
                    _this.appService
                        .legalDocumentModel
                        .upsertArticleNdaInstance({
                        signerId: profile.id,
                        legalDocumentId: _this.article.nda,
                        articleId: _this.article.id,
                        accessLevel: accessLevel
                    })
                        .then(function (legalDocumentInstance) {
                        if (!legalDocumentInstance || !legalDocumentInstance.id) {
                            throw new Error('nda instance not found');
                        }
                        // route through document sign instead
                        redirect = "/sign/non-disclosure-agreement/" + legalDocumentInstance.id + ";redirect=" + encodeURIComponent(redirect);
                    })
                        .return()
                        .asCallback(done);
                }
                else {
                    done();
                }
            },
            function (done) {
                var from = null;
                if (_this.authenticatedProfile) {
                    if (_this.authenticatedProfile.email) {
                        from = _this.authenticatedProfile.email;
                        if (_this.authenticatedProfile.firstName && _this.authenticatedProfile.lastName) {
                            from = _this.authenticatedProfile.firstName + " " + _this.authenticatedProfile.lastName + "<" + from + ">";
                        }
                    }
                }
                _this.appService.profileModel
                    .sendLoginLink({
                    from: from,
                    email: profile.email,
                    redirect: redirect,
                    subject: accessLevelDisplay + ' Access Granted to listing ' + title,
                    body: body,
                    headlines: [
                        accessLevelDisplay + ' Access Granted'
                    ],
                    cta: 'View Listing',
                    mailChimpList: {
                        listId: mailchimpList ? mailchimpList.id : null,
                        access: accessLevelDisplay
                    }
                })
                    .return()
                    .asCallback(done);
            }
        ], done);
    };
    ViewsListingManagementComponent.prototype.declineAccessRequest = function (profile) {
        if (!profile || !profile.id) {
            return;
        }
        var index = this.profileIndexOf(profile, this.pendingApprovalsEdit);
        if (index !== null) {
            this.subscriptionProfilesDeleted.push(this.pendingApprovalsEdit[index]);
            this.pendingApprovalsEdit.splice(index, 1);
        }
        this.pendingApprovalsDeleted.push(profile.id);
        this.applyProfileFilter();
    };
    ViewsListingManagementComponent.prototype.removeSubscriptionAccess = function (profile) {
        if (!profile || !profile.id) {
            return;
        }
        var index = this.profileIndexOf(profile, this.activeSubscriptionProfilesRaw);
        if (index !== null) {
            this.subscriptionProfilesDeleted.push(this.activeSubscriptionProfilesRaw[index]);
            this.activeSubscriptionProfilesRaw.splice(index, 1);
        }
        this.applyProfileFilter();
    };
    ViewsListingManagementComponent.prototype.removeAdminAccess = function (profile) {
        if (profile && profile.id) {
            var index = this.adminUsersEdit.indexOf(profile.id);
            if (index !== null) {
                this.adminUsersEdit.splice(index, 1);
            }
            index = this.profileIndexOf(profile, this.adminUserProfiles);
            if (index !== null) {
                this.adminUserProfilesDeleted.push(this.adminUserProfiles[index]);
                this.adminUserProfiles.splice(index, 1);
            }
            this.applyProfileFilter();
        }
    };
    ViewsListingManagementComponent.prototype.cancelSubscriptions = function () {
        if (!this.accessLevelsChanged()) {
            return;
        }
        // while ( this.subscriptionProfilesDeleted.length > 0 ) {
        // 	this.pushSubscriptionProfile( this.subscriptionProfilesDeleted.pop() );
        // }
        //
        // // poor man's clone
        // this.accessLevelsEdit = JSON.parse( JSON.stringify( this.accessLevels ) );
        this.initPage();
    };
    ViewsListingManagementComponent.prototype.downloadCSV = function () {
        this.apiService.download("/downloads/listing/" + this.article.id + "/activity");
    };
    ViewsListingManagementComponent.prototype.cancelAdmins = function () {
        if (!this.adminUsersChanged()) {
            return;
        }
        // while ( this.adminUserProfilesDeleted.length > 0 ) {
        // 	this.pushAdminProfile( this.adminUserProfilesDeleted.pop() );
        // }
        //
        // this.adminUsersEdit = JSON.parse( JSON.stringify( this.adminUsers ) );
        this.initPage();
    };
    ViewsListingManagementComponent.prototype.addSubscriptionUsers = function () {
        var _this = this;
        this.addingUser = true;
        var emails = this.parseEmailList(this.addSubscriptionEmails);
        this.addSubscriptionEmails = '';
        var activeAccessLevel = this.activeAccessLevel;
        this.activeAccessLevel = 'private';
        async.each(emails, function (email, done) {
            _this.appService.profileModel.upsertByEmail(email)
                .then(function (profile) {
                var existingProfile = _this.profileIndexOf(profile, _this.activeSubscriptionProfilesRaw);
                if (existingProfile === null) {
                    _this.activeSubscriptionProfilesRaw.push(profile);
                    _this.setAccessLevel(profile.id, activeAccessLevel);
                }
                else {
                    console.error('profile already exists', profile);
                }
            })
                .return()
                .asCallback(done);
        }, function () {
            _this.addingUser = false;
            _this.applyProfileFilter();
        });
    };
    ViewsListingManagementComponent.prototype.removeFromDeleted = function (profileId) {
        var index = this.pendingApprovalsDeleted.indexOf(profileId);
        if (index > -1) {
            this.pendingApprovalsDeleted.splice(index, 1);
        }
        index = this.subscriptionProfilesDeleted.findIndex(function (profile) { return profile.id === profileId; });
        if (index > -1) {
            this.subscriptionProfilesDeleted.splice(index, 1);
        }
    };
    ViewsListingManagementComponent.prototype.addAdminUsers = function () {
        var _this = this;
        this.addingAdminUser = true;
        var emails = this.parseEmailList(this.addAdminEmails);
        this.addAdminEmails = '';
        async.each(emails, function (email, done) {
            _this.appService.profileModel.upsertByEmail(email)
                .then(function (profile) {
                if (profile && profile.id) {
                    if (_this.adminUsersEdit.indexOf(profile.id) < 0) {
                        _this.adminUsersEdit.push(profile.id);
                    }
                    _this.pushAdminProfile(profile);
                }
            })
                .return()
                .asCallback(done);
        }, function () {
            _this.addingAdminUser = false;
            _this.updateDataSourceLists();
        });
    };
    ViewsListingManagementComponent.prototype.saveAdmins = function () {
        var _this = this;
        if (!this.adminUsersChanged()) {
            return;
        }
        this.savingAdminPermissions = true;
        var article = new Article({
            id: this.article.id,
            type: this.article.type,
            adminUsers: this.adminUsersEdit
        });
        this.articleModel
            .save(article)
            .then(function () {
            _this.savingAdminPermissions = false;
            return null;
        })
            .catch(function () {
            _this.savingAdminPermissions = false;
            return null;
        })
            .finally(function () {
            return _this.loadListing();
        });
    };
    ViewsListingManagementComponent.prototype.sendNewInvites = function () {
        var emails = this.parseEmailList(this.inviteEmails);
        this.inviteEmails = '';
        var accessLevel = this.inviteAccessLevel;
        this.inviteAccessLevel = 'public';
        this._sendInvites(emails, accessLevel);
    };
    ViewsListingManagementComponent.prototype._sendInvites = function (emails, accessLevel) {
        // console.log( '_sendInvites', emails, accessLevel );
        var _this = this;
        this.invitingUser = true;
        emails = emails
            .filter(function (email) {
            return typeof email === 'string' && email.trim().length >= 3 && email.match(/@/);
        })
            .map(function (email) {
            return email.trim().toLowerCase();
        });
        var usersToMail = [];
        var mailChimpList = this.selectedMailchimpListInvites || null;
        async.waterfall([
            function (done) {
                // const userIds = [];
                async.eachLimit(emails, 2, function (email, done) {
                    async.waterfall([
                        function (done) {
                            _this.appService.profileModel.upsertByEmail(email).asCallback(done);
                        },
                        function (profile, done) {
                            if (!profile || !profile.id) {
                                return done();
                            }
                            // userIds.push( profile.id );
                            if (!_this.article.accessLevelInvites) {
                                _this.article.accessLevelInvites = {};
                            }
                            _this.article.accessLevelInvites[profile.id] = accessLevel;
                            usersToMail.push(profile);
                            done();
                        }
                    ], done);
                }, done);
            },
            function (done) {
                _this.saveInvites(done);
            },
            function (done) {
                // const userIds = [];
                async.eachLimit(usersToMail, 2, function (user, done) {
                    _this.emailLink(user, accessLevel, false, mailChimpList, done);
                }, done);
            }
        ], function (err) {
            _this.invitingUser = false;
        });
    };
    ViewsListingManagementComponent.prototype.parseEmailList = function (emailList) {
        var emails = emailList.split(',');
        emails.forEach(function (email, i) {
            emails[i] = email.trim().toLowerCase();
        });
        return emails;
    };
    ViewsListingManagementComponent.prototype.approveAccessRequest = function (profile) {
        // add profile as if manually added to active subscriptions
        this.accessLevelsEdit[profile.id] = this.requestedAccessLevel(profile);
        this.pushSubscriptionProfile(profile);
        var index = this.profileIndexOf(profile, this.pendingApprovalsEdit);
        if (index !== null) {
            this.pendingApprovalsEdit.splice(index, 1);
        }
        this.applyProfileFilter();
    };
    ViewsListingManagementComponent.prototype.requestedAccessLevel = function (profile) {
        var accessLevel = 'public';
        if (this.article.accessLevelExclusivePending.indexOf(profile.id) > -1) {
            accessLevel = 'exclusive';
        }
        else if (this.article.accessLevelPrivatePending.indexOf(profile.id) > -1) {
            accessLevel = 'private';
        }
        return accessLevel;
    };
    ViewsListingManagementComponent.prototype.requestedAccessLevelDisplay = function (profile) {
        switch (this.requestedAccessLevel(profile)) {
            case 'public':
                return 'Public';
            case 'private':
                return 'Private';
            case 'exclusive':
                return 'Exclusive';
            default:
                return 'Public';
        }
    };
    ViewsListingManagementComponent.prototype.archiveInvites = function () {
        var _this = this;
        var article = new Article({
            id: this.article.id,
            type: this.article.type,
            accessLevelInvites: {}
        });
        this.invitedSubscriptionsFiltered.map(function (profile) { return profile; }).forEach(function (profile) {
            article.accessLevelInvites[profile.id] = null;
            _this._archiveInvite(profile);
        });
        this.articleModel.save(article)
            .then(function () {
            _this.invitingUser = false;
            return null;
        })
            .catch(function () {
            _this.invitingUser = false;
            return null;
        })
            .finally(function () {
            return _this.loadListing();
        });
    };
    ViewsListingManagementComponent.prototype._archiveInvite = function (profile) {
        var index = this.profileIndexOf(profile, this.invitedSubscriptionsFiltered);
        if (index !== null) {
            this.invitedSubscriptionsFiltered.splice(index, 1);
            this.updateDataSourceLists();
        }
        delete this.article.accessLevelInvites[profile.id];
    };
    ViewsListingManagementComponent.prototype.applyProfileFilter = function () {
        var _this = this;
        var filter = this.profileFilter
            .trim()
            .toLowerCase()
            .replace(/,/g, ' ')
            .replace(/\s+/g, ' ')
            .split(/\s+/);
        var matches = function (profile) {
            if (!profile) {
                return false;
            }
            var name = _this.displayNameAndEmail(profile).toLowerCase();
            var result = true; // profile matches until one bit misses
            filter.forEach(function (bit) {
                if (result === false) { // we already missed, skip this bit check for this profile
                    return;
                }
                if (name.indexOf(bit) < 0) { // if bit misses, skip profile
                    result = false;
                }
            });
            return result;
        };
        this.invitedSubscriptionsFiltered = []; // clear the array
        this.invitedSubscriptionsRaw.forEach(function (profile) {
            if (matches(profile)) {
                _this.invitedSubscriptionsFiltered.push(profile);
            }
        });
        // this.pendingApprovalsEdit = JSON.parse( JSON.stringify( this.pendingApprovals ) );
        this.pendingApprovalsEditFiltered = [];
        this.pendingApprovalsEdit.forEach(function (profile) {
            if (matches(profile)) {
                _this.pendingApprovalsEditFiltered.push(profile);
            }
        });
        this.activeSubscriptionProfilesFiltered = [];
        this.activeSubscriptionProfilesRaw.forEach(function (profile) {
            if (matches(profile)) {
                _this.activeSubscriptionProfilesFiltered.push(profile);
            }
        });
        this.adminUserProfilesFiltered = [];
        this.adminUserProfiles.forEach(function (profile) {
            if (matches(profile)) {
                _this.adminUserProfilesFiltered.push(profile);
            }
        });
        this.updateDataSourceLists();
    };
    ViewsListingManagementComponent.prototype.tabSectionChanged = function () {
        this.loadListing();
    };
    ViewsListingManagementComponent.prototype.archiveInvite = function (profile) {
        var _this = this;
        this._archiveInvite(profile);
        var article = new Article({
            id: this.article.id,
            type: this.article.type,
            accessLevelInvites: {}
        });
        article.accessLevelInvites[profile.id] = null;
        this.articleModel.save(article)
            .then(function () {
            _this.invitingUser = false;
            return null;
        })
            .catch(function () {
            _this.invitingUser = false;
        })
            .finally(function () {
            _this.loadListing();
        });
    };
    ViewsListingManagementComponent.prototype.saveInvites = function (done) {
        var _this = this;
        var article = new Article({
            id: this.article.id,
            type: this.article.type,
            author: this.article.author,
            accessLevelInvites: this.article.accessLevelInvites
        });
        this.articleModel
            .save(article)
            .then(function () {
            return _this.loadListing();
        })
            .return()
            .asCallback(done);
    };
    ViewsListingManagementComponent.prototype.getInviteAccessLevel = function (profile) {
        if (profile && profile.id &&
            this.article && this.article.accessLevelInvites &&
            this.article.accessLevelInvites.hasOwnProperty(profile.id)) {
            return this.article.accessLevelInvites[profile.id];
        }
        return 'public';
    };
    ViewsListingManagementComponent.prototype.inviteAccessLevelDisplay = function (profile) {
        var accessLevel = this.getInviteAccessLevel(profile);
        if (accessLevel === 'private') {
            return 'Private';
        }
        if (accessLevel === 'exclusive') {
            return 'Exclusive';
        }
        return 'Public';
    };
    return ViewsListingManagementComponent;
}());
export { ViewsListingManagementComponent };
