import { Component, OnInit, OnDestroy, AfterViewChecked, ElementRef } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { Location } from '@angular/common';

import { AppService } from 'app/services/app';
import {
	LegalDocument,
	LegalDocumentInstance,
	LegalDocumentInstanceSigner,
	LegalDocumentSection,
	SearchResponse
} from 'app/models/legal.document';
import { Profile } from 'app/models/profile';
import { Hash } from 'app/types/containers';
import * as Bluebird from 'bluebird';

type OptionItem = string[];

@Component( {
	moduleId: module.id,
	selector: '.app-view-contracts-instance-editor',
	templateUrl: 'editor.component.html',
	styleUrls: [ 'editor.component.css' ]
} )
export class ViewsContractsInstanceEditorComponent implements OnInit, OnDestroy, AfterViewChecked {

	public document: LegalDocument = null;
	public instance: LegalDocumentInstance = null;
	public instanceEdited: LegalDocumentInstance = null;
	public profilesById: Hash<Profile> = {};
	public profiles: Profile[] = null;
	public employeeProfiles: Profile[] = null;
	public signerIds: string[] = [];
	public signers: LegalDocumentInstanceSigner[] = [];

	public dirty: boolean = false;

	public instanceLoading: boolean = true;

	// see if a delete was requested for a instance
	public deletingDocument: number = null;

	public $el: JQuery = null;

	constructor( public app: AppService, public router: Router, public route: ActivatedRoute, public el: ElementRef, public location: Location ) {

		this.instanceLoading = true;
		this.app.contentLoading( true );
		this.app.toolbar.whiteOverContent = false;
		this.app.toolbar.backgroundColor = null;
		this.app.footer.showMenu = false;

		this.$el = $( this.el.nativeElement );

	}

	public subscriptions: any[] = [];

	public ngOnDestroy(): void {
		this.subscriptions.forEach( ( subscription ) => {
			subscription.unsubscribe();
		} );
	}

	ngOnInit(): void {

		document.body.scrollTop = 0;

		this.subscriptions.push( this.route.params.subscribe( ( params: { id: string; legalDocumentId: string; } ) => {
			this.setDocumentId( params.legalDocumentId )
				.then( () => {
					this.setInstanceId( params.id );
				} );
		} ) );

		this.profilesById = {};

		if ( this.profiles === null ) {
			this.profiles = [];

			this.app.profileModel.list()
				.then( ( profiles: Profile[] ) => {
					this.profiles = profiles;
					profiles.forEach( ( profile ) => {
						this.profilesById[ profile.id ] = profile;
					} );
				} );

		}

		if ( this.employeeProfiles === null ) {
			this.employeeProfiles = [];

			this.app.profileModel.listEmployees()
				.then( ( profiles: Profile[] ) => {

					this.employeeProfiles = profiles;

					profiles.forEach( ( profile ) => {
						this.profilesById[ profile.id ] = profile;
					} );
				} );

		}

	}

	public ngAfterViewChecked(): void {

		[
			'input',
			'textarea',
			'select'
		].forEach( ( type: string ) => {

			let $el = this.$el.find( `${type}:not(.change-detection-tracking)` );

			if ( $el.length < 1 ) {
				return;
			}

			$el.addClass( 'change-detection-tracking' );

			$el.change( () => {
				this.dirtyCheck();
			} );

		} );


	}

	public setInstanceId( id: string ): void {

		if ( id === 'new' ) {

			let date = new Date().toISOString();
			let name = "New Legal Document Instance " + date;

			let base = <LegalDocumentInstance>{
				id: null,
				name: null,
				legalDocumentId: this.document.id,
				created: null,
				complete: false,
				signers: [],
				notifications: []
			};

			let edited = <LegalDocumentInstance>{
				id: null,
				name: name,
				legalDocumentId: this.document.id,
				created: null,
				complete: false,
				signers: [],
				notifications: []
			};

			this.setInstance( base, edited );
			this.instanceLoading = false;
			this.app.contentLoading( false );

		} else {

			this.app.legalDocumentModel
				.instanceById( id )
				.then( ( instance: LegalDocumentInstance ) => {
					this.setInstance( instance );
					this.instanceLoading = false;
					this.app.contentLoading( false );
				} )
				.catch( () => {
					this.setInstance( null );
					this.instanceLoading = false;
					this.app.contentLoading( false );
				} );
		}


	}

	public setInstance( instance: LegalDocumentInstance, instanceEdited?: LegalDocumentInstance ): void {

		if ( instance.id && (!this.instance || this.instance.id !== instance.id) ) {
			this.location.replaceState( '/contracts/' + this.document.id + '/instance/' + instance.id );
		}

		this.instance = null;
		this.instanceEdited = null;
		this.signerIds = [];
		this.signers = [];

		if ( !instance ) {
			this.dirtyCheck();
			return;
		}

		this.signers = instance.signers;
		instance.signers = [];

		this.instance = instance;
		if ( instanceEdited ) {
			this.instanceEdited = instanceEdited;
		} else {
			this.instanceEdited = JSON.parse( JSON.stringify( instance ) );
		}

		this.signers.forEach( ( signer ) => {
			this.pushSigner( signer );
		} );

		this.dirtyCheck();

	}

	public pushSigner( signer: LegalDocumentInstanceSigner ): void {

		if ( this.signerIds.indexOf( signer.userId ) < 0 ) {
			this.signerIds.push( signer.userId );
		}

		let instancePush = ( instance: LegalDocumentInstance ): void => {
			let found = false;

			this.signers.forEach( ( instanceSigner ) => {
				if ( instanceSigner.userId === signer.userId ) {
					found = true;
				}
			} );

			if ( !found ) {
				this.signers.push( signer );
			}

		};

		instancePush( this.instanceEdited );

	}

	public setDocumentId( id: string ): Bluebird<any> {

		return new Bluebird<any>( ( resolve, reject ) => {
			this.app.legalDocumentModel
				.get( id )
				.then( ( document: LegalDocument ) => {
					this.document = document;
					resolve();
				} )
				.catch( ( e ) => {
					this.document = null;
					reject( e );
				} );

		} );

	}

	public markdownToHtml( markdownStr: string ): string {

		if ( typeof markdownStr !== 'string' ) {
			markdownStr = '';
		}

		markdownStr = markdownStr.replace( /\n/g, ' ' ).replace( /\s\s*/g, ' ' ).trim();

		return this.app.markdownToHtml( markdownStr );

	}

	public getProfile( id: string ): Profile {

		if ( typeof id !== 'string' || id.length < 1 ) {
			return null;
		}

		if ( !this.profilesById.hasOwnProperty( id ) ) {

			this.profilesById[ id ] = null;

			this.app.profileModel.get( id )
				.then( ( profile: Profile ) => {
					this.profilesById[ id ] = profile;
				} );

		}

		return this.profilesById[ id ];

	}

	public getContactOptions(): OptionItem[] {
		let optionItems: OptionItem[] = [];

		this.profiles.forEach( ( profile ) => {

			if ( this.signerIds.indexOf( profile.id ) < 0 ) {
				optionItems.push( [ profile.id, `${profile.lastName}, ${profile.firstName} <${profile.email}>` ] )
			}

		} );

		return optionItems.sort( ( a, b ) => {

			let aName = a[ 1 ];
			let bName = b[ 1 ];

			if ( aName < bName ) {
				return -1;
			}

			if ( aName > bName ) {
				return 1;
			}

			return 0;
		} );

	}

	public dirtyCheck(): void {

		let existing = JSON.stringify( this.instance );
		let edited = JSON.stringify( this.instanceEdited );

		this.dirty = existing !== edited;

	}

	public getSigners(): Profile[] {

		let signers: Profile[] = [];

		if ( !this.signers ) {
			return signers;
		}

		this.signers.forEach( ( signer ) => {

			let profile = this.getProfile( signer.userId );

			if ( profile ) {
				signers.push( profile );
			}

		} );

		return signers;

	}

	public getNotifications(): Profile[] {

		let notifications: Profile[] = [];

		if ( !this.instanceEdited.notifications ) {
			return notifications;
		}

		this.instanceEdited.notifications.forEach( ( userId ) => {

			let profile = this.getProfile( userId );

			if ( profile ) {
				notifications.push( profile );
			}

		} );

		return notifications.sort( ( a, b ) => {

			let aName = `${a.lastName} ${a.firstName} ${a.email}`;
			let bName = `${b.lastName} ${b.firstName} ${b.email}`;

			if ( aName < bName ) {
				return -1;
			}

			if ( aName > bName ) {
				return 1;
			}

			return 0;

		} );

	}

	public getNotificationOptions(): OptionItem[] {

		let notificationOptions: OptionItem[] = [];

		if ( !this.instanceEdited.notifications ) {
			return notificationOptions;
		}

		this.employeeProfiles.forEach( ( profile ) => {

			if ( this.instanceEdited.notifications.indexOf( profile.id ) > -1 ) {
				return;
			}

			notificationOptions.push( [
				profile.id,
				`${profile.lastName}, ${profile.firstName} <${profile.email}>`
			] );

		} );

		return notificationOptions.sort( ( a, b ) => {

			let aName = a[ 1 ];
			let bName = b[ 1 ];

			if ( aName < bName ) {
				return -1;
			}

			if ( aName > bName ) {
				return 1;
			}

			return 0;

		} );

	}

	public save(): void {

		this.app.legalDocumentModel
			.saveInstance( this.instanceEdited )
			.then( ( instance: LegalDocumentInstance ) => {
				this.setInstance( instance );
			} );

	}

	public reset(): void {
		this.instance.signers = this.signers;
		this.setInstance( this.instance );
	}

	public addSigner(): void {

		let userId = this.$el.find( 'select.signers-selector' ).val();

		if ( this.signerIds.indexOf( userId ) > -1 ) {
			return;
		}

		this.app.legalDocumentModel
			.addInstanceSigner( this.instanceEdited.id, userId )
			.then( ( signer: LegalDocumentInstanceSigner ) => {
				this.pushSigner( signer );
				this.dirtyCheck();
			} );


	}

	public removeSigner( userId: string ): void {

		this.app.legalDocumentModel
			.removeInstanceSigner( this.instanceEdited.id, userId )
			.then( () => {

				let index = this.signerIds.indexOf( userId );
				this.signerIds.splice( index, 1 );

				index = null;
				this.signers.forEach( ( signer, i ) => {
					if ( signer.userId === userId ) {
						index = i;
					}
				} );

				if ( index !== null ) {
					this.signers.splice( index, 1 );
				}

				this.dirtyCheck();
			} );

	}

	public addNotification(): void {

		let userId = this.$el.find( 'select.notifications-selector' ).val();

		if ( this.instanceEdited.notifications.indexOf( userId ) > -1 ) {
			return;
		}

		this.instanceEdited.notifications.push( userId );

		this.dirtyCheck();

	}

	public removeNotification( userId: string ): void {

		let index = this.instanceEdited.notifications.indexOf( userId );

		if ( index < 0 ) {
			return;
		}

		this.instanceEdited.notifications.splice( index, 1 );

		this.dirtyCheck();

	}


}
