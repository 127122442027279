/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./sign.component.less.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../content/render/legal.document.sign.component.ngfactory";
import * as i3 from "../../content/render/legal.document.sign.component";
import * as i4 from "../../../services/app";
import * as i5 from "@angular/router";
import * as i6 from "@angular/common";
import * as i7 from "./sign.component";
var styles_ViewsLegalDocumentSignComponent = [i0.styles];
var RenderType_ViewsLegalDocumentSignComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_ViewsLegalDocumentSignComponent, data: {} });
export { RenderType_ViewsLegalDocumentSignComponent as RenderType_ViewsLegalDocumentSignComponent };
function View_ViewsLegalDocumentSignComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-content-render-legal-document-sign", [], null, null, null, i2.View_ContentRenderLegalDocumentSignComponent_0, i2.RenderType_ContentRenderLegalDocumentSignComponent)), i1.ɵdid(1, 245760, null, 0, i3.ContentRenderLegalDocumentSignComponent, [i4.AppService, i5.Router, i1.ElementRef], { path: [0, "path"], redirect: [1, "redirect"], instanceId: [2, "instanceId"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.path; var currVal_1 = _co.redirect; var currVal_2 = _co.instanceId; _ck(_v, 1, 0, currVal_0, currVal_1, currVal_2); }, null); }
export function View_ViewsLegalDocumentSignComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵand(16777216, null, null, 1, null, View_ViewsLegalDocumentSignComponent_1)), i1.ɵdid(1, 16384, null, 0, i6.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = (_co.path || _co.instanceId); _ck(_v, 1, 0, currVal_0); }, null); }
export function View_ViewsLegalDocumentSignComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-view-legal-document-sign", [], null, null, null, View_ViewsLegalDocumentSignComponent_0, RenderType_ViewsLegalDocumentSignComponent)), i1.ɵdid(1, 245760, null, 0, i7.ViewsLegalDocumentSignComponent, [i4.AppService, i5.ActivatedRoute], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var ViewsLegalDocumentSignComponentNgFactory = i1.ɵccf("app-view-legal-document-sign", i7.ViewsLegalDocumentSignComponent, View_ViewsLegalDocumentSignComponent_Host_0, {}, {}, []);
export { ViewsLegalDocumentSignComponentNgFactory as ViewsLegalDocumentSignComponentNgFactory };
