import * as tslib_1 from "tslib";
import { ApiService } from 'app/services/api';
// Types
import { GenericModel } from './generic';
var VimeoModel = /** @class */ (function (_super) {
    tslib_1.__extends(VimeoModel, _super);
    function VimeoModel(api) {
        var _this = _super.call(this) || this;
        _this.init(api, 'vimeo', {
            id: null,
            type: null,
            name: null,
            created: null,
            modified: null,
            released: null,
            sortHint: null,
            description: null,
            duration: null,
            tags: [],
            height: null,
            width: null,
            thumbnail: null,
            thumbnailWithPlayButton: null
        }, 60000);
        return _this;
    }
    VimeoModel.prototype.getInstance = function (ref) {
        return ref;
    };
    VimeoModel.prototype.list = function () {
        return this.api.call('vimeo.list', {});
    };
    return VimeoModel;
}(GenericModel));
export { VimeoModel };
