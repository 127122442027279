import {Injectable} from '@angular/core';
import {ApiService} from 'app/services/api';
import * as Bluebird from 'bluebird';

// Types
import {GenericModel, GenericSchemaType} from 'app/models/generic';
import {Cache, HashTree} from 'app/types/containers';

// type MetaData = Hash<string | number | boolean>;

type UserActivityType = 'seller' | 'buyer' | 'partner' | 'vendor' | 'other';
type UserAccessLevels = 'public' | 'private' | 'exclusive';

export class ProfileFileUploadUrl {
  public url: string;
  public mimeType: string;
}

export class ProfileFileOwner {
  kind: string;
  displayName: string;
  me: boolean;
  permissionId: string;
  emailAddress: string;
}

export class ProfileFile {
  kind?: string;
  name: string;
  mimeType?: string;
  webViewLink?: string;
  downloadLink?: string;
  createdTime?: string;
  modifiedTime?: string;
  owners?: ProfileFileOwner[];
  lastModifyingUser?: ProfileFileOwner;
  viewersCanCopyContent?: boolean;
  writersCanShare?: boolean;
  uploadProgress?: number;
}

// Schema Type
export interface Profile extends GenericSchemaType {
  id: string;
  email: string;
  activityType?: UserActivityType;
  accessLevel?: 'public' | 'private' | 'exclusive';
  lastName?: string;
  firstName?: string;
  address?: {
    street1?: string;
    street2?: string;
    street3?: string;
    city?: string;
    state?: string;
    zip?: string;
    country?: string;
  };
  favoriteListings?: string[],
  startedAccess?: string[],
  liveId?: string;
  twitterId?: string;
  linkedInId?: string;
  facebookId?: string;
  googleId?: string;
  signatureStyle?: string;
  contactMethod?: string;
  mobilePhone?: string;
  workPhone?: string;
  vdr?: string;
  company?: string;
  title?: string;
  linkedinProfileUrl?: string;
  twitterProfileUrl?: string;
  facebookProfileUrl?: string;
  isEmployee?: boolean,
  isFeaturedEmployee?: boolean,
  website?: string;
  sortHint?: number,
  headshotURL?: string;
  avatarURL?: string;
  bio?: string;
  data?: HashTree<string | number | null | boolean>;
}

@Injectable()
export class ProfileModel extends GenericModel<Profile> {

  constructor(api: ApiService) {
    super();

    this.init(api, 'profile', {
      id: null,
      email: null,
      lastName: null,
      firstName: null,
      activityType: null,
      address: {
        street1: null,
        street2: null,
        street3: null,
        city: null,
        state: null,
        zip: null,
        country: null
      },
      favoriteListings: [],
      startedAccess: [],
      liveId: null,
      twitterId: null,
      linkedInId: null,
      facebookId: null,
      googleId: null,
      signatureStyle: null,
      contactMethod: null,
      mobilePhone: null,
      workPhone: null,
      vdr: null,
      company: null,
      title: null,
      linkedinProfileUrl: null,
      twitterProfileUrl: null,
      facebookProfileUrl: null,
      isEmployee: false,
      isFeaturedEmployee: false,
      website: null,
      sortHint: 0,
      headshotURL: null,
      bio: null,
      data: {}
    }, 5000);
  }

  public getInstance(ref: any): Profile {
    return ref;
  }

  public login(email: string, password: string): Bluebird<string> {
    return this.api.call('authenticate.login', {
      email: email,
      password: password
    });
  }

  public logout(): Bluebird<boolean> {
    return this.api.call('authenticate.logout', {});
  }

  public create(profile: Profile): Bluebird<boolean> {
    return this.api.call('profile.create', profile);
  }

  public upsertByEmail(email: string): Bluebird<Profile> {
    return this.api.call('profile.upsertByEmail', {email: email});
  }

  public me(): Bluebird<Profile> {
    return this.api.call('profile.me', {});
  }

  public byId(id: string): Bluebird<Profile> {
    return this.api.call('profile.byId', {id: id});
  }

  public list(): Bluebird<Profile[]> {
    return this.api.call('profile.list', {});
  }

  public listEmployees(): Bluebird<Profile[]> {
    return this.api.call('profile.listEmployees', {});
  }

  public listFeaturedEmployees(): Bluebird<Profile[]> {
    return this.api.call('profile.listFeaturedEmployees', {});
  }

  public getUploadUrl(params: { profileId: string; path: string; name: string; size: number }): Bluebird<ProfileFileUploadUrl> {
    return this.api.call('profile.getUploadUrl', params);
  }

  public listUploads(params: { profileId: string; path: string }): Bluebird<ProfileFile[]> {
    return this.api.call('profile.listUploads', params);
  }

  public archiveUpload(params: { profileId: string; path: string; }): Bluebird<ProfileFile[]> {
    return this.api.call('profile.archiveUpload', params);
  }

  public loginByToken(params: { token: string; }): Bluebird<Profile> {
    return this.api.call('profile.loginByToken', params);
  }

  public getByLoginToken(params: { token: string; }): Bluebird<{ profile: Profile, redirect?: string }> {
    return this.api.call('profile.getByLoginToken', params);
  }

  public sendLoginLink(params: {
    from?: string | null;
    email: string;
    redirect?: string;
    subject?: string;
    body?: string;
    headlines?: string[];
    cta?: string;
    mailChimpList?: {
      listId: string,
      access?: 'Public' | 'Private' | 'Exclusive'
    }
  }): Bluebird<boolean> {
    return this.api.call('profile.sendLoginLink', params);
  }

}



