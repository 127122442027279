import { Component, Input, Output, EventEmitter, OnInit } from '@angular/core';

import { AccessLevels } from 'app/models/article';
import { AppService } from 'app/services/app';

@Component( {
	moduleId: module.id,
	selector: '.input-type-select-access-level',
	templateUrl: 'select-access-level.component.html'
} )
export class StandardInputSelectAccessLevelComponent implements OnInit {

	@Input()
	public accessLevel: string = null;

	@Output()
	public accessLevelChange: EventEmitter<AccessLevels> = new EventEmitter<AccessLevels>();

	public options: string[][] = [];

	constructor( public app: AppService ) {

	}

	public ngOnInit(): void {

		if ( this.app.accessLevels ) {
			this.options = this.app.accessLevels;
		}

	}

	public accessLevelChanged( $event: any ): void {

		if ( !$event ) {
			return;
		}

		this.accessLevelChange.emit( $( $event.srcElement ).val() );
	}


}
