/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./footer.component.css.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/router";
import * as i3 from "@angular/common";
import * as i4 from "./footer.component";
import * as i5 from "../../services/app";
import * as i6 from "./footer.border.component.css.shim.ngstyle";
var styles_MainFooterComponent = [i0.styles];
var RenderType_MainFooterComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_MainFooterComponent, data: {} });
export { RenderType_MainFooterComponent as RenderType_MainFooterComponent };
function View_MainFooterComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 0, "div", [["class", "links-hide"]], null, null, null, null, null))], null, null); }
function View_MainFooterComponent_4(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 6, "a", [["routerLinkActive", "active"]], [[1, "target", 0], [8, "href", 4]], [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 1).onClick($event.button, $event.ctrlKey, $event.metaKey, $event.shiftKey) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(1, 671744, [[2, 4]], 0, i2.RouterLinkWithHref, [i2.Router, i2.ActivatedRoute, i3.LocationStrategy], { routerLink: [0, "routerLink"] }, null), i1.ɵdid(2, 1720320, null, 2, i2.RouterLinkActive, [i2.Router, i1.ElementRef, i1.Renderer2, i1.ChangeDetectorRef], { routerLinkActiveOptions: [0, "routerLinkActiveOptions"], routerLinkActive: [1, "routerLinkActive"] }, null), i1.ɵqud(603979776, 1, { links: 1 }), i1.ɵqud(603979776, 2, { linksWithHrefs: 1 }), i1.ɵpod(5, { exact: 0 }), (_l()(), i1.ɵted(6, null, ["", ""]))], function (_ck, _v) { var currVal_2 = _v.context.$implicit.url; _ck(_v, 1, 0, currVal_2); var currVal_3 = _ck(_v, 5, 0, true); var currVal_4 = "active"; _ck(_v, 2, 0, currVal_3, currVal_4); }, function (_ck, _v) { var currVal_0 = i1.ɵnov(_v, 1).target; var currVal_1 = i1.ɵnov(_v, 1).href; _ck(_v, 0, 0, currVal_0, currVal_1); var currVal_5 = _v.context.$implicit.name; _ck(_v, 6, 0, currVal_5); }); }
function View_MainFooterComponent_5(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "a", [["class", "seo-link"]], [[8, "href", 4]], null, null, null, null)), (_l()(), i1.ɵted(1, null, ["", ""]))], null, function (_ck, _v) { var currVal_0 = _v.context.$implicit.url; _ck(_v, 0, 0, currVal_0); var currVal_1 = _v.context.$implicit.name; _ck(_v, 1, 0, currVal_1); }); }
function View_MainFooterComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 6, "div", [["class", "section"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "div", [["class", "title"]], null, null, null, null, null)), (_l()(), i1.ɵted(2, null, ["", ""])), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_MainFooterComponent_4)), i1.ɵdid(4, 278528, null, 0, i3.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_MainFooterComponent_5)), i1.ɵdid(6, 278528, null, 0, i3.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var currVal_1 = _v.context.$implicit.items; _ck(_v, 4, 0, currVal_1); var currVal_2 = _v.context.$implicit.items; _ck(_v, 6, 0, currVal_2); }, function (_ck, _v) { var currVal_0 = _v.context.$implicit.name; _ck(_v, 2, 0, currVal_0); }); }
function View_MainFooterComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "div", [["class", "links"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_MainFooterComponent_3)), i1.ɵdid(2, 278528, null, 0, i3.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.getMenuItems(); _ck(_v, 2, 0, currVal_0); }, null); }
export function View_MainFooterComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵand(16777216, null, null, 1, null, View_MainFooterComponent_1)), i1.ɵdid(1, 16384, null, 0, i3.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_MainFooterComponent_2)), i1.ɵdid(3, 16384, null, 0, i3.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(4, 0, null, null, 0, "div", [["class", "break"]], null, null, null, null, null)), (_l()(), i1.ɵeld(5, 0, null, null, 18, "div", [["class", "legal"]], null, null, null, null, null)), (_l()(), i1.ɵeld(6, 0, null, null, 1, "div", [["class", "copyright"]], null, null, null, null, null)), (_l()(), i1.ɵted(7, null, ["Copyright \u00A9 ", " Exio. All rights reserved."])), (_l()(), i1.ɵeld(8, 0, null, null, 15, "div", [["class", "documents"]], null, null, null, null, null)), (_l()(), i1.ɵeld(9, 0, null, null, 6, "a", [["routerLink", "/privacy-policy"], ["routerLinkActive", "active"]], [[1, "target", 0], [8, "href", 4]], [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 10).onClick($event.button, $event.ctrlKey, $event.metaKey, $event.shiftKey) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(10, 671744, [[4, 4]], 0, i2.RouterLinkWithHref, [i2.Router, i2.ActivatedRoute, i3.LocationStrategy], { routerLink: [0, "routerLink"] }, null), i1.ɵdid(11, 1720320, null, 2, i2.RouterLinkActive, [i2.Router, i1.ElementRef, i1.Renderer2, i1.ChangeDetectorRef], { routerLinkActiveOptions: [0, "routerLinkActiveOptions"], routerLinkActive: [1, "routerLinkActive"] }, null), i1.ɵqud(603979776, 3, { links: 1 }), i1.ɵqud(603979776, 4, { linksWithHrefs: 1 }), i1.ɵpod(14, { exact: 0 }), (_l()(), i1.ɵted(-1, null, ["Privacy Policy"])), (_l()(), i1.ɵted(-1, null, ["\u00A0|\u00A0"])), (_l()(), i1.ɵeld(17, 0, null, null, 6, "a", [["routerLink", "/terms-and-conditions"], ["routerLinkActive", "active"]], [[1, "target", 0], [8, "href", 4]], [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 18).onClick($event.button, $event.ctrlKey, $event.metaKey, $event.shiftKey) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(18, 671744, [[6, 4]], 0, i2.RouterLinkWithHref, [i2.Router, i2.ActivatedRoute, i3.LocationStrategy], { routerLink: [0, "routerLink"] }, null), i1.ɵdid(19, 1720320, null, 2, i2.RouterLinkActive, [i2.Router, i1.ElementRef, i1.Renderer2, i1.ChangeDetectorRef], { routerLinkActiveOptions: [0, "routerLinkActiveOptions"], routerLinkActive: [1, "routerLinkActive"] }, null), i1.ɵqud(603979776, 5, { links: 1 }), i1.ɵqud(603979776, 6, { linksWithHrefs: 1 }), i1.ɵpod(22, { exact: 0 }), (_l()(), i1.ɵted(-1, null, ["Terms & Conditions"]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = !_co.app.footer.showMenu; _ck(_v, 1, 0, currVal_0); var currVal_1 = _co.app.footer.showMenu; _ck(_v, 3, 0, currVal_1); var currVal_5 = "/privacy-policy"; _ck(_v, 10, 0, currVal_5); var currVal_6 = _ck(_v, 14, 0, true); var currVal_7 = "active"; _ck(_v, 11, 0, currVal_6, currVal_7); var currVal_10 = "/terms-and-conditions"; _ck(_v, 18, 0, currVal_10); var currVal_11 = _ck(_v, 22, 0, true); var currVal_12 = "active"; _ck(_v, 19, 0, currVal_11, currVal_12); }, function (_ck, _v) { var _co = _v.component; var currVal_2 = _co.copyRightYear; _ck(_v, 7, 0, currVal_2); var currVal_3 = i1.ɵnov(_v, 10).target; var currVal_4 = i1.ɵnov(_v, 10).href; _ck(_v, 9, 0, currVal_3, currVal_4); var currVal_8 = i1.ɵnov(_v, 18).target; var currVal_9 = i1.ɵnov(_v, 18).href; _ck(_v, 17, 0, currVal_8, currVal_9); }); }
export function View_MainFooterComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-footer", [], null, null, null, View_MainFooterComponent_0, RenderType_MainFooterComponent)), i1.ɵdid(1, 49152, null, 0, i4.MainFooterComponent, [i5.AppService], null, null)], null, null); }
var MainFooterComponentNgFactory = i1.ɵccf("app-footer", i4.MainFooterComponent, View_MainFooterComponent_Host_0, {}, {}, []);
export { MainFooterComponentNgFactory as MainFooterComponentNgFactory };
var styles_MainFooterBorderComponent = [i6.styles];
var RenderType_MainFooterBorderComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_MainFooterBorderComponent, data: {} });
export { RenderType_MainFooterBorderComponent as RenderType_MainFooterBorderComponent };
export function View_MainFooterBorderComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "div", [["class", "to-top"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "div", [["class", "to-top-container"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.scrollUp() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 0, "div", [["class", "component-icon scroll-up"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 2, "div", [["class", "to-top floater"]], null, null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 1, "div", [["class", "to-top-container"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.scrollUp() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(5, 0, null, null, 0, "div", [["class", "component-icon scroll-up"]], null, null, null, null, null)), (_l()(), i1.ɵeld(6, 0, null, null, 0, "div", [["class", "break"]], null, null, null, null, null))], null, null); }
export function View_MainFooterBorderComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-footer-border", [], null, null, null, View_MainFooterBorderComponent_0, RenderType_MainFooterBorderComponent)), i1.ɵdid(1, 245760, null, 0, i4.MainFooterBorderComponent, [i5.AppService, i1.NgZone, i1.ElementRef], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var MainFooterBorderComponentNgFactory = i1.ɵccf("app-footer-border", i4.MainFooterBorderComponent, View_MainFooterBorderComponent_Host_0, { top: "top", floatAfter: "floatAfter" }, {}, []);
export { MainFooterBorderComponentNgFactory as MainFooterBorderComponentNgFactory };
