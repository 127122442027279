/**
 * This is generated file. You can edit the template in the Gulp plugin that generated it:
 *
 * lib/gulp/app.module.tmp.ts
 *
 */
import * as $ from 'jquery'; // I don't know why this can't go in each module, but without it jquery type checking fails
import 'hammerjs';

import {NgModule} from '@angular/core';

import {AppRoutingModule} from 'app/app-routing.module';
import {BrowserModule, Title} from '@angular/platform-browser';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {HttpClientModule, HttpClientJsonpModule} from '@angular/common/http';

import {HighchartsChartModule} from 'highcharts-angular';

import {WindowComponent} from 'app/components/window.component';
import {FormsLoginComponent} from 'app/components/forms/login.component';
import {FormsSignupComponent} from 'app/components/forms/signup.component';
import {ListingMainComponent} from 'app/components/listing/main.component';
import {MainFooterComponent, MainFooterBorderComponent} from 'app/components/main/footer.component';
import {SocialShareComponent} from 'app/components/main/social.share.component';
import {MainPreloadComponent} from 'app/components/main/preload.component';
import {MainToolbarComponent} from 'app/components/main/toolbar.component';
import {MainSubmenuComponent} from 'app/components/main/submenu.component';
import {ImageGalleryComponent} from 'app/components/content/img.component';
import {FullNestedMenuComponent} from 'app/components/main/full.nested.menu.component';
import {ContentBannerCarouselComponent} from 'app/components/content/banner.carousel.component';
import {ContentBannerComponent} from 'app/components/content/banner.component';
import {ContentLoaderBarComponent} from 'app/components/content/loader.bar.component';
import {ContentLoaderSpinnerComponent} from 'app/components/content/loader.spinner.component';
import {ContentMainComponent} from 'app/components/content/main.component';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';

import {
  MatAutocompleteModule,
  MatButtonModule,
  MatCardModule,
  MatDatepickerModule,
  MatDialogModule,
  MatDividerModule,
  MatExpansionModule,
  MatFormFieldModule,
  MatGridListModule,
  MatInputModule,
  MatListModule,
  MatNativeDateModule,
  MatPaginatorModule,
  MatProgressBarModule,
  MatProgressSpinnerModule,
  MatRadioModule,
  MatChipsModule,
  MatSelectModule,
  MatSlideToggleModule,
  MatSortModule,
  MatTableModule,
  MatTabsModule
} from '@angular/material';

// import { MatMomentDateModule } from '@angular/material-moment-adapter';

import {
  ContentTilesBlogTileComponent,
  ContentTilesComponent,
  ContentTilesPostTileComponent,
  ContentTilesServiceTileComponent,
  ContentTilesVideoTileComponent
} from 'app/components/content/tiles.component';

import {ContentChatComponent} from 'app/components/content/chat.component';
import {ContentRenderDocumentsComponent} from 'app/components/content/render/documents.component';
import {ContentRenderEditorBrComponent} from 'app/components/content/render/editor/br.component';
import {ContentRenderEditorCarouselComponent} from 'app/components/content/render/editor/carousel.component';
import {ContentRenderEditorExioScoreComponent} from 'app/components/content/render/editor/exioscore.component';
import {ContentRenderEditorFeatureComponent} from 'app/components/content/render/editor/feature.component';
import {ContentRenderEditorGridComponent} from 'app/components/content/render/editor/grid.component';
import {ContentRenderEditorHeadingComponent} from 'app/components/content/render/editor/heading.component';
import {ContentRenderEditorHrComponent} from 'app/components/content/render/editor/hr.component';
import {ContentRenderEditorImgComponent} from 'app/components/content/render/editor/img.component';
import {ContentRenderEditorLeadershipComponent} from 'app/components/content/render/editor/leadership.component';
import {ContentRenderEditorSwotComponent} from 'app/components/content/render/editor/swot.component';
import {ContentRenderEditorTextComponent} from 'app/components/content/render/editor/text.component';
import {ContentRenderEditorVimeoComponent} from 'app/components/content/render/editor/vimeo.component';
import {ContentRenderEditorVrComponent} from 'app/components/content/render/editor/vr.component';
import {ContentRenderGridComponent} from 'app/components/content/render/grid.component';
import {ContentRenderLeadershipComponent} from 'app/components/content/render/leadership.component';
import {ContentRenderLegalDocumentComponent} from 'app/components/content/render/legal.document.component';
import {ContentRenderLegalDocumentSignatureComponent} from 'app/components/content/render/legal.document.signature.component';
import {ContentRenderLegalDocumentSignComponent} from 'app/components/content/render/legal.document.sign.component';
import {ContentRenderLegalDocumentViewComponent} from 'app/components/content/render/legal.document.view.component';
import {ContentRenderListingUploadComponent} from 'app/components/content/render/listing.upload.component';
import {ContentRenderMainComponent} from 'app/components/content/render/main.component';
import {ContentRenderMyLegalDocumentsComponent} from 'app/components/content/render/my.legal.documents.component';
import {ContentRenderMyListingsComponent} from 'app/components/content/render/my.listings.component';
import {ContentRenderProfileUploadComponent} from 'app/components/content/render/profile.upload.component';
import {ContentRenderSectionsComponent} from 'app/components/content/render/sections.component';
import {ContentVimeoComponent} from 'app/components/content/vimeo.component';
import {ContentVimeoServiceComponent} from 'app/components/content/vimeo.service.component';
import {NewsletterFormsComponent} from 'app/components/forms/newsletter.component';
import {PendingUpgradeDialogueComponent} from 'app/components/views/content/pending.upgrade.dialogue.component';
import {PopUpServiceComponent} from 'app/components/popup/popup.service.component';
import {StandardInputButtonComponent} from 'app/components/standard/input/button.component';
import {StandardInputCheckboxComponent} from 'app/components/standard/input/checkbox.component';
import {StandardInputDropdownComponent} from 'app/components/standard/input/dropdown.component';
import {StandardInputEmailComponent} from 'app/components/standard/input/email.component';
import {StandardInputFileComponent} from 'app/components/standard/input/file.component';
import {StandardInputFilesComponent} from 'app/components/standard/input/files.component';
import {StandardInputPasswordComponent} from 'app/components/standard/input/password.component';
import {StandardInputRadioComponent} from 'app/components/standard/input/radio.component';
import {StandardInputRangeComponent} from 'app/components/standard/input/range.component';
import {StandardInputRecaptcha2Component} from 'app/components/standard/input/recaptcha2.component';
import {StandardInputSelectAccessLevelComponent} from 'app/components/standard/input/select.access.level.component';
import {StandardInputSelectIconComponent} from 'app/components/standard/input/select.icon.component';
import {StandardInputTelephoneComponent} from 'app/components/standard/input/telephone.component';
import {StandardInputTextareaComponent} from 'app/components/standard/input/textarea.component';
import {StandardInputTextComponent} from 'app/components/standard/input/text.component';
import {StandardInputUrlComponent} from 'app/components/standard/input/url.component';
import {ViewContractsInstanceConfirmEmailMainComponent} from 'app/components/views/contracts/instance.confirm.email/main.component';
import {ViewsArticleEditorComponent} from 'app/components/views/article/editor.component';
import {ViewsArticleMainComponent} from 'app/components/views/article/main.component';
import {ViewsBlogEntryComponent} from 'app/components/views/blog/entry.component';
import {ViewsBlogMainComponent} from 'app/components/views/blog/main.component';
import {ViewsCdnbrowserMainComponent} from 'app/components/views/assets/main.component';
import {ViewsContactMainComponent} from 'app/components/views/contact/main.component';
import {ViewsContentMainComponent} from 'app/components/views/content/main.component';
import {ViewsContentProfileComponent} from 'app/components/views/content/profile.component';
import {ViewsContentQuestionnaireAddressComponent} from 'app/components/views/content/questionnaire/address.component';
import {ViewsContentQuestionnaireComponent} from 'app/components/views/content/questionnaire.component';
import {ViewsContractsEditorComponent} from 'app/components/views/contracts/editor.component';
import {ViewsContractsInstanceEditorComponent} from 'app/components/views/contracts/instance/editor.component';
import {ViewsContractsInstanceMainComponent} from 'app/components/views/contracts/instance/main.component';
import {ViewsContractsMainComponent} from 'app/components/views/contracts/main.component';
import {ViewsHomeEmailVerificationComponent} from 'app/components/views/home/email.verification.component';
import {ViewsHomeMainComponent} from 'app/components/views/home/main.component';
import {ViewsHomeTilesComponent} from 'app/components/views/home/tiles.component';
import {ViewsLearnLeadershipProfileComponent} from 'app/components/views/learn/leadership.profile.component';
import {ViewsLegalDocumentPrintComponent} from 'app/components/views/legaldocument/print.component';
import {ViewsLegalDocumentSignComponent} from 'app/components/views/legaldocument/sign.component';
import {ViewsLegalDocumentViewComponent} from 'app/components/views/legaldocument/view.component';
import {ViewsListingDocumentComponent} from 'app/components/views/listing/document.component';
import {ViewsListingManagementAnalyticsComponent} from 'app/components/views/listing/analytics.component';
import {ViewsListingManagementComponent} from 'app/components/views/listing/management.component';
import {ViewsListingManagementListComponent} from 'app/components/views/listing/management-list.component';
import {ViewsLoginEmailComponent} from 'app/components/views/login/email.component';
import {ViewsLoginLinkComponent} from 'app/components/views/login/link.component';
import {ViewsMarketplaceMainComponent} from 'app/components/views/marketplace/main.component';
import {ViewsNewsMainComponent} from 'app/components/views/news/main.component';
import {ViewsProfileMainComponent} from 'app/components/views/profile/main.component';
import {ViewsSignupMainComponent} from 'app/components/views/signup/main.component';
import {ViewsUserResetMainComponent} from 'app/components/views/userreset/main.component';
import {ViewsVideoEntryComponent} from 'app/components/views/video/entry.component';
import {ViewsVideoMainComponent} from 'app/components/views/video/main.component';
import {ViewsWorkflowMainComponent} from 'app/components/views/workflow/main.component';
import {ViewsWorkflowSectionReviewComponent} from 'app/components/views/workflow/section.review.component';

import {AnalyticsModel} from 'app/models/analytics';
import {ApiModel} from 'app/models/api';
import {ApiService} from 'app/services/api';
import {AppService} from 'app/services/app';
import {ArticleModel} from 'app/models/article';
import {CdnModel} from 'app/models/cdn';
import {ChatService} from 'app/services/chat';
import {ExioImageService} from 'app/services/exio';
import {ExioService} from 'app/services/exio';
import {ExioUserService} from 'app/services/exio';
import {LegalDocumentModel} from 'app/models/legal.document';
import {ListingModel} from 'app/models/listing';
import {LogicService} from 'app/services/logic';
import {MailchimpModel} from 'app/models/mailchimp';
import {PopUpService} from 'app/services/popup';
import {ProfileModel} from 'app/models/profile';
import {QuestionnaireModel} from 'app/models/questionnaire';
import {VideoService} from 'app/services/video';
import {VimeoModel} from 'app/models/vimeo';
import {WorkflowModel} from 'app/models/workflow';

@NgModule({
  providers: [
    AnalyticsModel,
    ApiModel,
    ApiService,
    AppService,
    ArticleModel,
    CdnModel,
    ChatService,
    ExioImageService,
    ExioService,
    ExioUserService,
    LegalDocumentModel,
    ListingModel,
    LogicService,
    MailchimpModel,
    PopUpService,
    ProfileModel,
    QuestionnaireModel,
    Title,
    VideoService,
    VimeoModel,
    WorkflowModel,
  ],
  imports: [
    AppRoutingModule,
    BrowserAnimationsModule,
    BrowserModule,
    FormsModule,
    HighchartsChartModule,
    HttpClientModule,
    HttpClientJsonpModule,
    MatAutocompleteModule,
    MatButtonModule,
    MatCardModule,
    MatDatepickerModule,
    MatDialogModule,
    MatDividerModule,
    MatExpansionModule,
    MatFormFieldModule,
    MatGridListModule,
    MatInputModule,
    MatListModule,
    MatNativeDateModule,
    MatPaginatorModule,
    MatProgressBarModule,
    MatProgressSpinnerModule,
    MatRadioModule,
    MatSelectModule,
    MatChipsModule,
    MatSlideToggleModule,
    MatSortModule,
    MatTableModule,
    MatTabsModule,
    ReactiveFormsModule,
  ],
  declarations: [
    ContentBannerCarouselComponent,
    ContentBannerComponent,
    ContentChatComponent,
    ContentLoaderBarComponent,
    ContentLoaderSpinnerComponent,
    ContentMainComponent,
    ContentRenderDocumentsComponent,
    ContentRenderEditorBrComponent,
    ContentRenderEditorCarouselComponent,
    ContentRenderEditorExioScoreComponent,
    ContentRenderEditorFeatureComponent,
    ContentRenderEditorGridComponent,
    ContentRenderEditorHeadingComponent,
    ContentRenderEditorHrComponent,
    ContentRenderEditorImgComponent,
    ContentRenderEditorLeadershipComponent,
    ContentRenderEditorSwotComponent,
    ContentRenderEditorTextComponent,
    ContentRenderEditorVimeoComponent,
    ContentRenderEditorVrComponent,
    ContentRenderGridComponent,
    ContentRenderLeadershipComponent,
    ContentRenderLegalDocumentComponent,
    ContentRenderLegalDocumentSignatureComponent,
    ContentRenderLegalDocumentSignComponent,
    ContentRenderLegalDocumentViewComponent,
    ContentRenderListingUploadComponent,
    ContentRenderMainComponent,
    ContentRenderMyLegalDocumentsComponent,
    ContentRenderMyListingsComponent,
    ContentRenderProfileUploadComponent,
    ContentRenderSectionsComponent,
    ContentTilesBlogTileComponent,
    ContentTilesComponent,
    ContentTilesPostTileComponent,
    ContentTilesServiceTileComponent,
    ContentTilesVideoTileComponent,
    ContentVimeoComponent,
    ContentVimeoServiceComponent,
    FormsLoginComponent,
    FormsSignupComponent,
    FullNestedMenuComponent,
    ImageGalleryComponent,
    ListingMainComponent,
    MainFooterBorderComponent,
    MainFooterComponent,
    MainPreloadComponent,
    MainSubmenuComponent,
    MainToolbarComponent,
    NewsletterFormsComponent,
    PendingUpgradeDialogueComponent,
    PopUpServiceComponent,
    SocialShareComponent,
    StandardInputButtonComponent,
    StandardInputCheckboxComponent,
    StandardInputDropdownComponent,
    StandardInputEmailComponent,
    StandardInputFileComponent,
    StandardInputFilesComponent,
    StandardInputPasswordComponent,
    StandardInputRadioComponent,
    StandardInputRangeComponent,
    StandardInputRecaptcha2Component,
    StandardInputSelectAccessLevelComponent,
    StandardInputSelectIconComponent,
    StandardInputTelephoneComponent,
    StandardInputTextareaComponent,
    StandardInputTextComponent,
    StandardInputUrlComponent,
    ViewContractsInstanceConfirmEmailMainComponent,
    ViewsArticleEditorComponent,
    ViewsArticleMainComponent,
    ViewsBlogEntryComponent,
    ViewsBlogMainComponent,
    ViewsCdnbrowserMainComponent,
    ViewsContactMainComponent,
    ViewsContentMainComponent,
    ViewsContentProfileComponent,
    ViewsContentQuestionnaireAddressComponent,
    ViewsContentQuestionnaireComponent,
    ViewsContractsEditorComponent,
    ViewsContractsInstanceEditorComponent,
    ViewsContractsInstanceMainComponent,
    ViewsContractsMainComponent,
    ViewsHomeEmailVerificationComponent,
    ViewsHomeMainComponent,
    ViewsHomeTilesComponent,
    ViewsLearnLeadershipProfileComponent,
    ViewsLegalDocumentPrintComponent,
    ViewsLegalDocumentSignComponent,
    ViewsLegalDocumentViewComponent,
    ViewsListingDocumentComponent,
    ViewsListingManagementAnalyticsComponent,
    ViewsListingManagementComponent,
    ViewsListingManagementListComponent,
    ViewsLoginEmailComponent,
    ViewsLoginLinkComponent,
    ViewsMarketplaceMainComponent,
    ViewsNewsMainComponent,
    ViewsProfileMainComponent,
    ViewsSignupMainComponent,
    ViewsUserResetMainComponent,
    ViewsVideoEntryComponent,
    ViewsVideoMainComponent,
    ViewsWorkflowMainComponent,
    ViewsWorkflowSectionReviewComponent,
    WindowComponent,
  ],
  entryComponents: [
    PendingUpgradeDialogueComponent
  ],
  bootstrap: [
    WindowComponent
  ]
})
export class AppModule {
}
