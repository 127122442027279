import {Component, OnInit} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {Location} from '@angular/common';
import {DomSanitizer, SafeStyle} from '@angular/platform-browser';

import {AppService} from 'app/services/app';
import {VideoService} from 'app/services/video';
import {Article, SearchParams} from 'app/models/article';
import {Profile} from 'app/models/profile';

@Component({
  moduleId: module.id,
  selector: '.app-content-homepage-tiles',
  templateUrl: 'tiles.component.pug',
  styleUrls: ['tiles.component.less']
})
export class ViewsHomeTilesComponent implements OnInit {

  public authenticatedProfile: Profile = null;

  public listings: Article[] = [];
  public buyers: Article[] = [];

  public listingsBuyersFour: Article[] = [];
  public listingsBuyersSix: Article[] = [];

  public featuredVideo: Article = null;
  public recentVideo: Article = null;
  public recentBlog: Article = null;
  public featuredPost: Article = null;
  public bottomArticles: Article[] = [];

  public loadingCount: number = 0;

  constructor(public appService: AppService, public route: ActivatedRoute, public location: Location, public videoService: VideoService, public sanitize: DomSanitizer) {

    this.appService.toolbar.whiteOverContent = true;
    this.appService.toolbar.backgroundColor = null;


  }

  public updateListingsBuyers(): void {

    let update = (count: number): Article[] => {

      let result: Article[] = [];
      let listings: Article[] = this.listings.slice(0, count);
      let buyerCount = count >= 6 ? 2 : 1;
      let buyers = this.buyers.slice(0, buyerCount);

      if (listings.length + buyers.length < count) {
        result = listings.concat(buyers);
      } else {
        let i = count;
        result = listings;
        while (buyers.length > 0) {
          i--;
          result[i] = buyers.pop();
        }
      }

      return result;

    };

    this.listingsBuyersFour = update(4);
    this.listingsBuyersSix = update(6);

  }

  public ngOnInit(): void {

    document.body.scrollTop = 0;

    this.subscriptions.push(this.appService.getAuthenticatedProfile({
      next: (profile) => {

        if (profile && profile.email) {
          this.authenticatedProfile = profile;
        } else {
          this.authenticatedProfile = null;
        }

      },
      error: () => {
        this.authenticatedProfile = null;
      },
      complete: () => {
        this.authenticatedProfile = null;
      }
    }));

    // listings
    this.loadContent({
      query: {
        categories: ['featured-content', 'featured content'],
        type: 'buyer'
      },
      limit: 6,
      sort: {
        sortHint: 1
      }
    }, 'buyers');
    this.loadContent({
      query: {
        categories: ['featured-content', 'featured content'],
        type: 'listing'
      },
      limit: 6,
      sort: {
        sortHint: 1
      }
    }, 'listings');

    // maine video
    this.loadContent({
      query: {
        categories: ['featured-video', 'featured video'],
        type: 'video'
      },
      limit: 1,
      sort: {
        created: -1
      }
    }, 'featuredVideo');

    // bottom tile left
    this.loadContent({
      query: {
        categories: ['featured-content', 'featured content'],
        type: 'post'
      },
      limit: 1,
      sort: {
        created: -1
      }
    }, 'featuredPost');

    // bottom tile middle
    this.loadContent({
      query: {
        categories: ['blog'],
        type: 'post'
      },
      limit: 1,
      sort: {
        created: -1
      }
    }, 'recentBlog');

    // bottom tile right
    this.loadContent({
      query: {
        type: 'video'
      },
      limit: 1,
      sort: {
        created: -1
      }
    }, 'recentVideo');


  }

  public subscriptions: any[] = [];

  public ngOnDestroy(): void {
    this.subscriptions.forEach((subscription) => {
      subscription.unsubscribe();
    });
  }

  public getFeaturedVideo(): Article {

    if (this.hasFeaturedVideo()) {
      return this.featuredVideo;
    }

    return null;

  }

  public getRecentVideo(): Article {

    if (this.hasRecentVideo()) {
      return this.recentVideo;
    }

    return null;

  }

  public hasRecentVideo(): boolean {

    return !!(this.recentVideo && typeof this.recentVideo.path === 'string' && this.recentVideo.path.match(/^\/?video-library\/[0-9]+$/) !== null);

  }

  public recentVideoId(): string {

    if (!this.hasRecentVideo()) {
      return null;
    }

    return this.recentVideo.path.replace(/^\/?video-library\//, '');

  }

  public playVideo(videoId: string): void {
    this.videoService.load({videoId});
    this.videoService.play({videoId});
  }

  public recentVideoFillType(): string {
    let width = $(window).width();

    if (width < 1020) {
      return 'width';
    } else {
      return 'height';
    }

  }

  public recentVideoThumbnail(big: boolean): SafeStyle {

    if (!this.hasRecentVideo()) {
      return null;
    }

    let url = null;

    if (big) {
      url = this.recentVideo.tileImgLargeUrl || this.recentVideo.tileImgUrl || this.recentVideo.bannerImgUrl || null;
    } else {
      url = this.recentVideo.tileImgUrl || this.recentVideo.bannerImgUrl || this.recentVideo.tileImgLargeUrl || null;
    }

    if (url) {

      url = this.appService.exio.image.getUrl(url, <any>{
        format: 'jpg',
        actions: [
          {
            type: 'shrink',
            width: 960
          },
          {
            type: 'quality',
            quality: 100
          }
        ]
      });

      return this.sanitize.bypassSecurityTrustStyle(`url(${url})`);
    }

    return null;

  }

  public pathUrl(url: string) {

    url = url.replace(/#.*$/, '').trim();

    if (url.length < 1) {
      return null;
    }

    return `/${url}`;

  }

  public pathFragment(url: string) {

    let fragment = url.replace(/^[^#]*#?/, '').trim();

    if (fragment.length < 1) {
      return null;
    }

    return fragment;

  }

  public getArticleLink(article: Article): string {

    if (!article) {
      return null;
    }

    if (Array.isArray(article.actions) && article.actions.length > 0 && article.actions[0].actionType === 'url') {
      return (<string>article.actions[0].actionParams.href).trim();
    }

    if (typeof article.path === 'string' && article.path.trim().length > 0) {
      return article.path.trim();
    }

    return null;

  }

  public hasFeaturedVideo(): boolean {
    return this.featuredVideo && typeof this.featuredVideo.path === 'string' && this.featuredVideo.path.match(/^\/?video-library\/[0-9]+$/) !== null;
  }

  public featuredVideoId(): string {

    if (!this.hasFeaturedVideo()) {
      return null;
    }

    return this.featuredVideo.path.replace(/^\/?video-library\//, '');

  }

  public featuredVideoThumbnail(): SafeStyle {

    if (!this.hasFeaturedVideo()) {
      return null;
    }

    let url = this.featuredVideo.tileImgLargeUrl || this.featuredVideo.tileImgUrl || this.featuredVideo.bannerImgUrl || null;

    if (url) {

      url = this.appService.exio.image.getUrl(url, <any>{
        format: 'jpg',
        actions: [
          {
            type: 'shrink',
            width: 960
          },
          {
            type: 'quality',
            quality: 100
          }
        ]
      });

      return this.sanitize.bypassSecurityTrustStyle(`url(${url})`);
    }

    return null;

  }

  public loadContent(query: SearchParams, field: string): void {

    let always = () => {

      this.loadingCount--;

      if (this.loadingCount <= 0) {
        this.loadingCount = 0;

        if (this.recentBlog && this.featuredPost) {
          this.bottomArticles = [
            this.featuredPost,
            this.recentBlog
          ]
        } else {
          this.bottomArticles = []
        }

        this.updateListingsBuyers();

      }

    };

    this.loadingCount++;

    this.appService
      .articleModel
      .search(query)
      .then((result) => {

        if (Array.isArray(result.articles)) {

          if (field === 'listings') {
            result.articles = result.articles.concat(result.articles, result.articles, result.articles);
          }

          // result.articles.forEach( ( article ) => {
          // 	if ( article && article.type === 'listing' ) {
          // 		article.tagline = 'PRIVATE LISTING';
          // 	} else if ( article && article.type === 'buyer' ) {
          // 		article.tagline = 'PREMIER BUYER';
          // 	}
          // } );

        }

        if (query.limit === 1) {
          this[field] = result.articles.length > 0 ? result.articles[0] : null;
        } else {
          this[field] = result.articles;
        }

        always();

      })
      .catch(always);

  }

  public showFavoriteToggle(): boolean {

    return this.authenticatedProfile !== null && typeof this.authenticatedProfile.id === 'string' && this.authenticatedProfile.id.length > 0;

  }

  public isFavorite(listing: Article,): boolean {

    if (!this.authenticatedProfile || !Array.isArray(this.authenticatedProfile.favoriteListings) || !listing) {
      return false;
    }

    return this.authenticatedProfile.favoriteListings.indexOf(listing.id) > -1;

  }

  public toggleFavorite(listing: Article, e: Event): void {

    e.preventDefault();
    e.stopPropagation();

    if (!this.authenticatedProfile || !listing || !listing.id) {
      return;
    }

    if (!Array.isArray(this.authenticatedProfile.favoriteListings)) {
      this.authenticatedProfile.favoriteListings = [];
    }

    if (this.isFavorite(listing)) {

      let index = this.authenticatedProfile.favoriteListings.indexOf(listing.id);

      if (index > -1) {
        this.authenticatedProfile.favoriteListings.splice(index, 1);
      }

    } else {
      this.authenticatedProfile.favoriteListings.push(listing.id);
    }

    this.appService.profileModel.save(this.authenticatedProfile)
      .then(() => {
        // NO-OP
        // console.log( 'done syncing favorites', this.authenticatedProfile.favoriteListings );
      })
      .catch((e) => {
        // NO-OP
        console.error('failed to save favorite listing', e);
      });

  }

  public getTitle(listing: Article, filter?: (str: string) => string): string {

    let title = (<Article>listing).title;

    let parts = title.split(/\n/);

    if (typeof filter === 'function') {
      parts.forEach((part, i) => {
        parts[i] = filter.apply(this, [part]);
      });
    }
    title = parts.join('\n');

    return title.replace(/\n/g, '<br/>');

  }

  public formatNewlines(text: string): string {

    if (typeof text !== 'string') {
      return null;
    }

    return text.trim().split(/\n/).join('<br/>');
  }

}
