import { Component, Input } from '@angular/core';
import { AppService } from 'app/services/app';
import { Profile } from 'app/models/profile';
import { FormControl, Validators } from '@angular/forms';

@Component( {
  moduleId: module.id,
  selector: 'app-forms-signup',
  templateUrl: 'signup.component.pug',
  styleUrls: [ 'signup.component.less' ]
} )
export class FormsSignupComponent {

  @Input()
  profile = <Profile>null;

  @Input()
  showSignature: boolean = null;

  @Input()
  onChange: () => void = null;

  inputCompany = new FormControl( '', [ Validators.required, Validators.minLength( 3 ) ] );
  inputFirstName = new FormControl( '', [ Validators.required, Validators.minLength( 3 ) ] );
  inputLastName = new FormControl( '', [ Validators.required, Validators.minLength( 3 ) ] );
  inputTitle = new FormControl( '', [ Validators.required, Validators.minLength( 3 ) ] );
  inputAddress1 = new FormControl( '', [ Validators.required, Validators.minLength( 5 ) ] );
  inputCity = new FormControl( '', [ Validators.required, Validators.minLength( 3 ) ] );
  inputState = new FormControl( '', [ Validators.required ] );
  inputZip = new FormControl( '', [ Validators.required, Validators.minLength( 5 ), Validators.maxLength( 5 ) ] );
  inputPhone = new FormControl( '', [ Validators.required, Validators.minLength( 10 ) ] );
  inputEmail = new FormControl( '', [ Validators.required, Validators.email ] );

  public states = [
    'AL',
    'AK',
    'AZ',
    'AR',
    'CA',
    'CO',
    'CT',
    'DE',
    'FL',
    'GA',
    'HI',
    'ID',
    'IL',
    'IN',
    'IA',
    'KS',
    'KY',
    'LA',
    'ME',
    'MD',
    'MA',
    'MI',
    'MN',
    'MS',
    'MO',
    'MT',
    'NE',
    'NV',
    'NH',
    'NJ',
    'NM',
    'NY',
    'NC',
    'ND',
    'OH',
    'OK',
    'OR',
    'PA',
    'RI',
    'SC',
    'SD',
    'TN',
    'TX',
    'UT',
    'VT',
    'VA',
    'WA',
    'WV',
    'WI',
    'WY'
  ];

  constructor( public app: AppService ) {
  }

  public getError( input: FormControl ): string {

    if ( !input ) {
      return 'Form Error';
    }

    if ( input.hasError( 'required' ) ) {
      return 'Input is required';
    } else if ( input.hasError( 'email' ) ) {
      return 'Input is not a valid email';
    } else if ( input.hasError( 'minlength' ) ) {
      return 'Input is too short';
    } else if ( input.hasError( 'maxlength' ) ) {
      return 'Input is too long';
    }

    return 'Input is not valid format';

  }

  public signatureAvailable(): boolean {
    const profile = this.profile;

    return profile &&
           typeof profile.firstName === 'string' &&
           typeof profile.lastName === 'string' &&
           profile.firstName.trim().length > 0 &&
           profile.lastName.trim().length > 0;

  }

  public onChangeHandler(): void {

    if ( typeof this.onChange === 'function' ) {
      this.onChange();
    }
  }

}
