import {Component, Input, OnInit, OnDestroy, NgZone} from '@angular/core';
import {Router} from '@angular/router';
import {DomSanitizer, SafeStyle} from '@angular/platform-browser';

import {AppService} from 'app/services/app';
import {VideoService} from 'app/services/video';
import {Article, ArticleAction} from 'app/models/article';
import {Profile} from 'app/models/profile';

@Component({
  moduleId: module.id,
  selector: 'app-content-banner',
  templateUrl: 'banner.component.pug',
  styleUrls: ['banner.component.less']
})
export class ContentBannerComponent implements OnInit, OnDestroy {

  @Input()
  public article: Article;

  @Input()
  public upgrade: string = null;

  @Input()
  public topPadding: number = 68; // default to miss toolbar

  @Input()
  public blur: boolean = false;

  @Input()
  public lockedCallback: () => void = null;

  public contentContainerClasses: any = {};
  public contentClasses: any = {};

  public profile: Profile = null;

  public layoutTypes = [
    'blank',
    'standard',
    'centeredNoSubTitle',
    'centeredNoPreTitle',
    'testimonial',
    'testimonialCentered',
    'listingPublic',
    'listingPendingEmail',
    'listingPendingPrivate',
    'listingPendingExclusive',
    'listingPrivate'
  ];

  public subscriptions: any[] = [];

  constructor(public appService: AppService,
              public sanitizer: DomSanitizer,
              public router: Router,
              public videoService: VideoService,
              public zone: NgZone) {
  }

  ngOnInit(): void {

    this.subscriptions.push(this.appService.getAuthenticatedProfile({
      next: (profile: Profile) => {
        this.profile = profile || null;
      },
      error: () => {
        this.profile = null;
      },
      complete: () => {
        this.profile = null;
      }
    }));

  }

  public ngOnDestroy(): void {
    this.subscriptions.forEach((subscription) => {
      subscription.unsubscribe();
    });
  }

  public markdownToHtml(str: string): string {
    return this.appService.markdownToHtml(str);
  }

  public format(str: string, newlines?: boolean): string {


    const parts = str.split(/[\n\r]/);
    parts.forEach((part, i) => {
      parts[i] = this.markdownToHtml(part).replace(/^\s*<p>/, '').replace(/<\/p>\s*$/, '');
    });

    return parts.join(newlines ? '<br/>\n' : ' ');

  }

  public isDemo(): boolean {
    return this.article && Array.isArray(this.article.categories) && this.article.categories.indexOf('demo') > -1;
  }

  public layout(): string {

    // console.log( 'banner article', this.article );

    if (this.article && typeof this.article.bannerLayout === 'string' && this.layoutTypes.indexOf(this.article.bannerLayout) > -1) {
      // console.log( 'banner this.article.bannerLayout', this.article.bannerLayout );
      return this.article.bannerLayout;
    }

    return 'blank';

  }

  public articleAction(): ArticleAction {

    let result = null;

    if (this.article && this.article.actions) {
      this.article.actions.forEach((action) => {
        if (result !== null) {
          return;
        }

        if (action.actionType === 'url') {
          result = action;
        }
      });
    }

    return result;

  }

  protected actionUrl(): string {

    const action = this.articleAction();

    if (!action || !action.actionParams || !action.actionParams.href) {
      return this.article.path;
    }

    return <string>action.actionParams.href;

  }

  public bannerImgUrl() {

    let url = this.article.bannerImgUrl || this.article.bannerImgUrlMobile;

    if (typeof url !== 'string' || url.trim().length < 1) {
      return null;
    }

    const actions = [
      {
        type: 'shrink',
        width: 1440
      },
      {
        type: 'quality',
        quality: 100
      }
    ];

    if (this.blur) {
      actions.push(<any>{
        type: 'blur',
        radius: 50
      })
    }

    url = this.appService.exio.image.getUrl(url, <any>{
      format: 'jpg',
      actions: actions
    });

    return this.sanitizer.bypassSecurityTrustStyle(`url("${url}")`);

  }

  public bannerImgUrlMobile() {

    let url = this.article.bannerImgUrlMobile || this.article.bannerImgUrl;

    if (typeof url !== 'string' || url.trim().length < 1) {
      return null;
    }

    const actions = [
      {
        type: 'shrink',
        width: 800
      },
      {
        type: 'quality',
        quality: 100
      }
    ];

    if (this.blur) {
      actions.push(<any>{
        type: 'blur',
        radius: 50
      })
    }

    url = this.appService.exio.image.getUrl(url, <any>{
      format: 'jpg',
      actions: actions
    });

    return this.sanitizer.bypassSecurityTrustStyle(`url("${url}")`);

  }

  public bannerImgFit() {
    return this.sanitizer.bypassSecurityTrustStyle('cover');
  }

  public getContentContainerClasses(): Object {

    const contentContainerClasses = this.contentContainerClasses;

    for (let i = 0; i <= 100; i++) {
      const opacityDesktop = `opacity-desktop-${i}`;
      const opacityMobile = `opacity-mobile-${i}`;
      contentContainerClasses[opacityDesktop] = false;
      contentContainerClasses[opacityMobile] = false;
    }

    contentContainerClasses['blue-fade'] = false;

    if (this.isLayout(['listingPublic', 'listingPendingPrivate', 'listingPendingEmail'])) {
      contentContainerClasses['blue-fade'] = true;
    } else {

      if (parseInt(this.article.bannerOpacity, 10) > 0) {
        const opacity = `opacity-desktop-${this.article.bannerOpacity}`;
        contentContainerClasses[opacity] = true;
      }

      if (parseInt(this.article.bannerOpacityMobile, 10) > 0) {
        const opacity = `opacity-mobile-${this.article.bannerOpacityMobile}`;
        contentContainerClasses[opacity] = true;
      }

    }

    return contentContainerClasses;

  }

  public getContentClasses(): Object {

    const classes = this.contentClasses;

    // default all to false
    this.layoutTypes.forEach((layout) => {
      classes[`layout-${this.toDashed(layout)}`] = false;
    });

    // only set current layout to true
    classes[`layout-${this.toDashed(this.layout())}`] = true;

    return classes;

  }

  public toDashed(str: string): string {
    return str.replace(/([A-Z])/g, function ($1) {
      return '-' + $1.toLowerCase();
    });
  }

  public getTitleQuote(): string {

    if (!this.article || !this.article.title) {
      return '';
    }

    return this.format(this.article.title.replace(/"/g, ''));

  }

  /**
   * 'blank',
   'standard', // variation 1
   'testimonial', // variation 2
   'centeredNoSubTitle', // variation 3
   'testimonialCentered', // variation 4
   'centeredNoPreTitle' // variation 5
   */

  public isEmptyOrNotString(str: string): boolean {
    return typeof str !== 'string' || str.trim().length < 1;
  }

  public showPreTitle(): boolean {

    if (this.isEmptyOrNotString(this.article.tagline)) {
      return false;
    }

    return this.isLayout([
      'standard', // variation 1
      'testimonial', // variation 2
      'centeredNoSubTitle', // variation 3
      'testimonialCentered', // variation 4
      'listingPublic',
      'listingPrivate',
      'listingPendingEmail',
      'listingPendingPrivate',
      'listingPendingExclusive'
    ]);
  }

  public showTitle(): boolean {

    if (this.isEmptyOrNotString(this.article.title)) {
      return false;
    }

    return this.isLayout([
      'standard', // variation 1
      'centeredNoSubTitle', // variation 3
      'centeredNoPreTitle', // variation 5
      'listingPublic',
      'listingPrivate',
      'listingPendingEmail',
      'listingPendingPrivate',
      'listingPendingExclusive'
    ]);
  }

  public showQuoteLeft(): boolean {

    if (this.isEmptyOrNotString(this.article.title)) {
      return false;
    }

    return this.isLayout([
      'testimonial' // variation 2
    ]);
  }

  public showQuoteCenter(): boolean {

    if (this.isEmptyOrNotString(this.article.title)) {
      return false;
    }

    return this.isLayout([
      'testimonialCentered' // variation 4
    ]);

  }

  public showSubTitle(): boolean {

    if (this.isEmptyOrNotString(this.article.description)) {
      return false;
    }

    return this.isLayout([
      'standard', // variation 1
      'testimonial', // variation 2
      'testimonialCentered', // variation 4
      'centeredNoPreTitle' // variation 5
    ]);
  }

  public goAction(): void {
    const url = this.actionUrl();

    if (typeof url !== 'string') {
      // console.log( "action url not a string" );
      return;
    }

    if (url.match(/^#/)) {

      const top = $(url).offset().top;
      this.zone.runOutsideAngular(() => {
        requestAnimationFrame(() => {
          $('html, body').stop().animate({scrollTop: top}, 1000);
        });
      });

    } else if (url.match(/^https?:\/\//)) {
      window.open(url, '_blank');
    } else if (url.match(/^\/?video-library\/[A-Za-z0-9]+$/)) {

      const videoId = url.replace(/^\/?video-library\//, '');

      this.videoService.play({videoId});

    } else {
      this.router.navigate([url]);
    }

  }

  public showArticleAction(): boolean {
    return !!this.articleAction() && this.isLayout([
      'standard', // variation 1
      'testimonial', // variation 2
      'centeredNoSubTitle' // variation 3
    ]);
  }

  public showUpgradeAccess(): boolean {
    return !this.isDemo() && this.isLayout([
      'listingPublic'
    ]);
  }

  public unSaveListing(): void {

    if (this.profile) {
      if (!Array.isArray(this.profile.favoriteListings)) {
        this.profile.favoriteListings = [];
      }

      const index = this.profile.favoriteListings.indexOf(this.article.id);

      if (index > -1) {
        this.profile.favoriteListings.splice(index, 1);
        this.appService.profileModel.save(this.profile);
      }
    }

  }

  public saveListing(): void {

    if (this.profile) {
      if (!Array.isArray(this.profile.favoriteListings)) {
        this.profile.favoriteListings = [];
      }

      this.profile.favoriteListings.push(this.article.id);

      this.appService.profileModel.save(this.profile);

    }

  }

  public upgradeAccess(): void {

    if (typeof this.lockedCallback === 'function') {
      return this.lockedCallback();
    }

    const $el = $('.matrix-row.action-row');

    if ($el.length < 1) {
      return;
    }

    const top = $el.offset().top - 100; // offset to account for nav bar

    this.zone.runOutsideAngular(() => {
      requestAnimationFrame(() => {
        $('html, body').stop().animate({scrollTop: top}, 1000);
      });
    });

  }

  public privateUpgradePendingEmail(): boolean {
    return !this.isDemo() && this.isLayout([
      'listingPendingEmail'
    ]);
  }

  public privateUpgradePending(): boolean {
    return !this.isDemo() && this.isLayout([
      'listingPendingPrivate'
    ]);
  }

  public exclusiveUpgradePending(): boolean {
    return !this.isDemo() && this.isLayout([
      'listingPendingExclusive'
    ]);
  }

  public showSaveListing(): boolean {
    return !this.isCanoListing() && !this.isDemo() && this.isLayout([
        'listingPendingExclusive',
        'listingPrivate'
      ]) && this.article && this.profile &&
      this.profile.favoriteListings &&
      this.profile.favoriteListings.indexOf(this.article.id) < 0;
  }

  public showUnSaveListing(): boolean {
    return !this.isCanoListing() && !this.isDemo() && this.isLayout([
      'listingPendingExclusive',
      'listingPrivate'
    ]) && !this.showSaveListing();
  }

  private isCanoListing(): boolean {
    return !!(this.article && this.article.categories && this.article.categories.includes('cano_mode'));
  }

  // public isPendingPrivateAccess(): boolean {
  // 	return this.isLayout( [
  // 		'listingPendingPrivate'
  // 	] );
  // }

  public isLayout(layouts: string[]): boolean {
    return layouts.indexOf(this.layout()) > -1;
  }

  public getTopPadding(): SafeStyle {
    return this.sanitizer.bypassSecurityTrustStyle(this.topPadding + 'px');
  }

  public _privateAccessLabel(): string {
    return this.article.categories.includes('cano_mode') ? 'Show Me More' : 'Get Private Access';
  }

  public _pendingExclussiveAccessLabel(): string {
    return this.article.categories.includes('cano_mode') ? 'Your Practice Information Has Been Received' : 'Your Exclusive Upgrade Is Pending';
  }

}
