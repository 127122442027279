import { Injectable } from '@angular/core';
import { ApiService } from 'app/services/api';
import * as Bluebird from 'bluebird';

// Types
import { GenericModel, GenericSchemaType } from './generic';

// Schema Type
export class Listing extends GenericSchemaType {
  id: string;
  userId: string;
  name: string;
  type?: 'buyer' | 'seller';
  created: string;
  updated: string;
  deleted: boolean;
  data: any;
}

export class ListingFileUploadUrl {
  public url: string;
  public mimeType: string;
}

export class ListingFileOwner {
  kind: string;
  displayName: string;
  me: boolean;
  permissionId: string;
  emailAddress: string;
}

export class ListingFile {
  kind?: string;
  accessLevel?: 'public' | 'private' | 'exclusive' | 'hidden';
  name: string;
  mimeType?: string;
  webViewLink?: string;
  downloadLink?: string;
  createdTime?: string;
  modifiedTime?: string;
  owners?: ListingFileOwner[];
  lastModifyingUser?: ListingFileOwner;
  viewersCanCopyContent?: boolean;
  writersCanShare?: boolean;
  uploadProgress?: number;
}

@Injectable()
export class ListingModel extends GenericModel<Listing> {

  constructor( api: ApiService ) {
    super();
    this.init( api, 'listing', {
      id: null,
      name: null,
      type: null,
      userId: null,
      created: null,
      updated: null,
      deleted: false,
      data: null
    }, 0 );
  }

  public getInstance( ref: any ): Listing {
    return ref;
  }

  public myRecords(): Bluebird<Listing[]> {
    return this.api.call( 'listing.list', {} );
  }

  public getUploadUrl( params: { listingId: string; path: string; name: string; size: number } ): Bluebird<ListingFileUploadUrl> {
    return this.api.call( 'listing.getUploadUrl', params );
  }

  public listUploads( params: { listingId: string; path: string } ): Bluebird<ListingFile[]> {
    return this.api.call( 'listing.listUploads', params );
  }

  public listPublishedUploads( params: { listingId: string; path: string } ): Bluebird<ListingFile[]> {
    return this.api.call( 'listing.listPublishedUploads', params );
  }

  public deleteUpload( params: { listingId: string; path: string; } ): Bluebird<ListingFile[]> {
    return this.api.call( 'listing.deleteUpload', params );
  }

  public archiveUpload( params: { listingId: string; path: string; } ): Bluebird<ListingFile[]> {
    return this.api.call( 'listing.archiveUpload', params );
  }

}



