import { Injectable } from '@angular/core';
import * as Bluebird from 'bluebird';
import { ApiService } from 'app/services/api';


@Injectable()
export class ExioUserService {

  constructor( public api: ApiService ) {
  }

  public note( params: {
    firstName?: string;
    lastName?: string;
    email?: string;
    company?: string;
    subject?: string;
    comments?: string;
    to?: string[  ]
  } ): Bluebird<string> {
    return this.api.call( 'user.note', params );
  }

  public adminDestroyWhiteList(): Bluebird<string[]> {
    return this.api.call( 'user.adminDestroyWhiteList', {} );
  }

  public adminDestroy( email: string ): Bluebird<boolean> {
    return this.api.call( 'user.adminDestroy', { email: email } );
  }

}

export type ExioImageServiceOptionsActionBlur = {
  type: 'blur';
  radius?: number;
};

export type ExioImageServiceOptionsActionShrink = {
  type: 'shrink';
  height?: number;
  width?: number;
};

export type ExioImageServiceOptionsActionResize = {
  type: 'resize';
  height?: number;
  width?: number;
};

export type ExioImageServiceOptionsActionQuality = {
  type: 'quality';
  quality?: number;
};

export type ExioImageServiceOptionsActions =
  ExioImageServiceOptionsActionBlur
  | ExioImageServiceOptionsActionShrink
  | ExioImageServiceOptionsActionResize
  | ExioImageServiceOptionsActionQuality;

export type ExioImageServiceOptions = {
  url?: string;
  format?: 'png' | 'jpg';
  cacheBust?: boolean | number;
  actions?: ExioImageServiceOptionsActions[];
}

@Injectable()
export class ExioImageService {

  constructor( public api: ApiService ) {
  }

  public getUrl( url: string, options?: ExioImageServiceOptions ): string {

    if ( !options ) {
      options = {};
    }
    options = JSON.parse( JSON.stringify( options ) ); // clone
    options.url = url;

    // default to jpg images because they compress well
    if ( !options.format ) {
      options.format = 'jpg';
    }

    if ( !Array.isArray( options.actions ) ) {
      options.actions = [];
    }

    let foundQuality = false;
    options.actions.forEach( ( action ) => {
      if ( action && action.type === 'quality' ) {
        foundQuality = true;

        // there doesn't seem to be a big difference in visible quality above 90, but file sizes get MUCH larger
        // similarly, quality under 25 really looks terrible
        (<ExioImageServiceOptionsActionQuality>action).quality = Math.max( 25, Math.min( action.quality, 90 ) );

      }
    } );

    if ( !foundQuality ) {
      options.actions.unshift( {
        type: 'quality',
        quality: 75
      } );
    }

    let foundSize = false;

    options.actions.forEach( ( action ) => {
      if ( action && (action.type === 'resize' || action.type === 'shrink') ) {
        foundSize = true;

        // not really a point to an image greater than 3000 pixels or less than 5 pixels
        [ 'width', 'height' ].forEach( ( field ) => {
          action[ field ] = Math.max( 5, Math.min( action[ field ], 3000 ) );
        } );

      }
    } );

    if ( !foundSize ) {

      // console.log( 'image getUrl without size constraint' );
      // console.trace();

      // no existing resize/shrink operations, clamp to 3000 pixels wide
      options.actions.unshift( {
        type: 'shrink',
        width: 3000
      } );

    }

    if ( options.cacheBust === true ) {
      options.cacheBust = new Date().getTime() + Math.random();
    }

    let sortValues = {
      shrink: 1,
      resize: 1,
      blur: 2,
      quality: 3
    };

    options.actions.sort( ( actionA, actionB ) => {
      return sortValues[ actionA.type ] - sortValues[ actionB.type ];
    } );

    let encode = ( s: string ) => {
      return btoa( s ).replace( /\+/g, '-' ).replace( /\//g, '_' ).replace( /\=+$/, '' );
    };

    return this.api.getFullUrl( '/images/' + encode( JSON.stringify( options ) ) );

  }

}


@Injectable()
export class ExioService {

  constructor( public user: ExioUserService, public image: ExioImageService ) {

  }

}
