import {ChatWorkFlow} from 'app/services/chat'


export const chatDefCanoPublicCommon: ChatWorkFlow = {
  authorEmail: 'doug.miller@goexio.com',
  pages: [
    {
      title: 'Show Me More',
      items: [
        {
          type: 'paragraph',
          params: {
            content: 'To view more inside information (including candid video interviews with other physicians who have sold to Cano Health), please sign the following Confidentiality Agreement.'
          }
        }
      ],
      continueText: 'Review Confidentiality Agreement',
      continueDisabled: false
    },
    {
      title: 'Sign Confidentiality Agreement',
      items: [
        {
          type: 'contract',
          params: {
            path: 'non-disclosure-agreement',
            placeholder: {
              company: 'Practice Name *',
              email: 'Personal Email *',
              phone: 'Personal Cell *',
            },
            hideInputs: {
              address: true
            },
            postInput: 'Note: Personal contact info prevents office staff knowledge.',
            checked: true
          }
        }
      ],
      continueText: 'Submit & Gain Access',
      continueDisabled: false
    },
    {
      title: 'Check Your Email',
      items: [
        {
          type: 'paragraph',
          params: {
            content: 'Your request is not yet complete.\n'
          }
        },
        {
          type: 'paragraph',
          params: {
            content: 'You must click the link in the verification email in order to finish submitting your request.'
          }
        }
      ],
      continueText: 'I Will Check My Email',
      continueDisabled: false
    }
  ]
};
