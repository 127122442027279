import { Component, Input, OnInit, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { Location } from '@angular/common';
import { SubMenu } from 'app/models/article';
import { AppService } from 'app/services/app';
import * as async from 'async';

@Component( {
	moduleId: module.id,
	selector: 'app-submenu',
	templateUrl: 'submenu.component.html',
	styleUrls: [ 'submenu.component.css' ]
} )
export class MainSubmenuComponent implements OnInit, OnDestroy {

	@Input()
	public items: SubMenu[] = [];

	constructor( public appService: AppService, public router: Router, public location: Location ) {
	}

	public ngOnInit(): void {

		this.appService.content.submenu = this.items;
		window.setTimeout( () => {
			// prime cache
			async.each( this.items, ( item, done ) => {

				this.appService
					.articleModel
					.getByPath( {
						path: item.url
					} )
					.then( () => {

						done();

					} )
					.catch( () => {
						done();
					} );

			} );
		}, 1000 );

	}

	public ngOnDestroy(): void {
		this.appService.content.submenu = [];
	}

	public isActive( submenuItem: SubMenu ): boolean {

		if ( !submenuItem || typeof submenuItem.url !== 'string' ) {
			return false;
		}

		let contentPath = this.location.path().trim().toLowerCase().replace( /^\/?/, '' );
		let itemPath = submenuItem.url.trim().toLowerCase().replace( /^\/?/, '' );

		return contentPath === itemPath;

	}

	public clickLink( submenuItem: SubMenu, $event: Event ): void {

		$event.preventDefault();
		$event.stopPropagation();

		if ( !submenuItem || typeof submenuItem.url !== 'string' ) {
			return;
		}

		if ( submenuItem.url.trim().toLowerCase().match( /^https?:\/\// ) ) {
			window.open( submenuItem.url, '_blank' );
		} else {
			this.appService.content.nextScrollSubmenu = true;
			this.router.navigateByUrl( submenuItem.url );
		}

	}
}
