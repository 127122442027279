import {OnInit, Component, Input, ChangeDetectorRef} from '@angular/core';
import {Article, ArticleFile} from 'app/models/article';
import {AppService} from 'app/services/app';

import {Hash} from 'app/types/containers';

@Component({
  moduleId: module.id,
  selector: 'app-content-render-documents',
  templateUrl: 'documents.component.pug',
  styleUrls: ['documents.component.less']
})
export class ContentRenderDocumentsComponent implements OnInit {

  @Input()
  public article: Article = null;

  @Input()
  public lockedCallback: () => void = null;

  public loadingDocumentName: string = null;

  public files: ArticleFile[] = null;

  constructor(public appService: AppService, public changeDetector: ChangeDetectorRef) {
  }

  public ngOnInit(): void {

    const files: ArticleFile[] = [];

    if (this.article && this.article.data && this.article.data.documents) {
      Object.keys(this.article.data.documents).forEach((documentId: string) => {

        const document: ArticleFile = <ArticleFile>this.article.data.documents[documentId];
        if (document && document.accessLevel !== 'hidden' && document.name) {
          files.push(document);
        }

      });
    }

    this.files = files.sort((a, b) => {
      if (a.name < b.name) {
        return -1;
      }
      if (a.name > b.name) {
        return 1;
      }
      return 0;
    });

    this.changeDetector.markForCheck();

  }

  public isLoading(document: ArticleFile): boolean {
    return this.loadingDocumentName === document.name;
  }

  public openDocument(document: ArticleFile): void {

    if (this.isLoading(document)) {
      return;
    }

    if (document.locked) {

      if (typeof this.lockedCallback === 'function') {
        this.lockedCallback();
      }

      return;
    }

    this.appService.googleAnalyticsViewItems([
      {
        id: document.id,
        category: 'document',
        name: document.name,
        list_name: this.article.path
      }
    ]);

    this.appService.windowOpen({
      url: this.appService.config.cms.replace(/\/$/, '') + '/listing-document/' + encodeURIComponent(this.article.path.toLowerCase()) + '/' + encodeURIComponent(document.name.toLowerCase()),
      target: '_blank',
      features: 'location=no,menubar=no,titlebar=no,toolbar=no',
      replace: true,
      height: 0.8,
      width: 0.8
    });

  }

}
