import { Component, Input } from '@angular/core';
import { AnswerAddress } from 'app/models/questionnaire';

@Component( {
  moduleId: module.id,
  selector: 'app-view-content-questionnaire-address',
  templateUrl: 'address.component.pug',
  styleUrls: [ 'address.component.less' ]
} )
export class ViewsContentQuestionnaireAddressComponent {

  @Input()
  public answer: AnswerAddress = null;

  constructor() {

  }

  private getStateFormat(): string {

    if ( !this.answer || !this.answer.answer || !this.answer.answer.state ) {
      return '';
    }

    return this.answer.answer.state.replace( / .*$/, '' );

  }

}
