import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AppService } from 'app/services/app';
import { Subscription } from 'rxjs';

@Component( {
	moduleId: module.id,
	selector: '.app-view-login-main',
	templateUrl: 'email.component.html',
	styleUrls: [ 'email.component.css' ]
} )
export class ViewsLoginEmailComponent implements OnInit {

	public email: string = null;
	public redirect: string = null;

	public sending: boolean = false;
	public sent: boolean = false;

	public subscriptions: Subscription[] = [];

	constructor( public app: AppService, public router: Router, public route: ActivatedRoute ) {

		this.app.contentLoading( false );
		this.app.content.backgroundLight = false;
		this.app.toolbar.whiteOverContent = true;
		this.app.toolbar.backgroundColor = '#4797EE';
		this.app.footer.showMenu = false;

		this.app.titleService.setTitle( `Exio - Login By Email` );

	}

	public ngOnDestroy(): void {
		this.subscriptions.forEach( ( subscription ) => {
			subscription.unsubscribe();
		} );
	}

	public ngOnInit(): void {
		this.subscriptions.push( this.route.params.subscribe( ( params: { redirect?: string } ) => {
			this.redirect = params.redirect || null;
		} ) );
	}

	public isValidEmail(): boolean {

		if ( typeof this.email !== 'string' || this.email.trim().length < 1 ) {
			return false;
		}

		return !!this.email.match( /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/ );

	}

	public sendLink(): void {

		this.sending = true;
		this.app.profileModel.sendLoginLink( {
			email: this.email,
			redirect: this.redirect || null
		} )
			.then( () => {
				this.sent = true;
				this.sending = false;
			} )
			.catch( ( e ) => {
				console.error( 'send login link failed', e );
				this.sending = false;
			} );

	}


}
