/**
 * This is generated file. You can edit the template in the Gulp plugin that generated it:
 *
 * lib/gulp/routing.module.js
 *
 */

import { NgModule } from '@angular/core';

import { RouterModule, Routes } from '@angular/router';

import { ViewsHomeMainComponent } from 'app/components/views/home/main.component';
import { ViewsMarketplaceMainComponent } from 'app/components/views/marketplace/main.component';
import { ViewsListingManagementListComponent } from 'app/components/views/listing/management-list.component';
import { ViewsListingManagementComponent } from 'app/components/views/listing/management.component';
import { ViewsLearnLeadershipProfileComponent } from 'app/components/views/learn/leadership.profile.component';
import { ViewsContentProfileComponent } from 'app/components/views/content/profile.component';
import { ViewsContactMainComponent } from 'app/components/views/contact/main.component';
import { ViewsProfileMainComponent } from 'app/components/views/profile/main.component';
import { ViewsContentQuestionnaireComponent } from 'app/components/views/content/questionnaire.component';
import { ViewsListingDocumentComponent } from 'app/components/views/listing/document.component';
import { ViewsSignupMainComponent } from 'app/components/views/signup/main.component';
import { ViewsNewsMainComponent } from 'app/components/views/news/main.component';
import { ViewsBlogMainComponent } from 'app/components/views/blog/main.component';
import { ViewsBlogEntryComponent } from 'app/components/views/blog/entry.component';
import { ViewsLoginLinkComponent } from 'app/components/views/login/link.component';
import { ViewsLoginEmailComponent } from 'app/components/views/login/email.component';
import { ViewsUserResetMainComponent } from 'app/components/views/userreset/main.component';
import { ViewsHomeEmailVerificationComponent } from 'app/components/views/home/email.verification.component';
import { ViewsVideoMainComponent } from 'app/components/views/video/main.component';
import { ViewsVideoEntryComponent } from 'app/components/views/video/entry.component';
import { ViewsArticleMainComponent } from 'app/components/views/article/main.component';
import { ViewsArticleEditorComponent } from 'app/components/views/article/editor.component';
import { ViewsCdnbrowserMainComponent } from 'app/components/views/assets/main.component';
import { ViewsContractsMainComponent } from 'app/components/views/contracts/main.component';
import { ViewsContractsEditorComponent } from 'app/components/views/contracts/editor.component';
import { ViewsContractsInstanceMainComponent } from 'app/components/views/contracts/instance/main.component';
import { ViewContractsInstanceConfirmEmailMainComponent } from 'app/components/views/contracts/instance.confirm.email/main.component';
import { ViewsContractsInstanceEditorComponent } from 'app/components/views/contracts/instance/editor.component';
import { ViewsLegalDocumentPrintComponent } from 'app/components/views/legaldocument/print.component';
import { ViewsLegalDocumentSignComponent } from 'app/components/views/legaldocument/sign.component';
import { ViewsLegalDocumentViewComponent } from 'app/components/views/legaldocument/view.component';
import { ViewsWorkflowMainComponent } from 'app/components/views/workflow/main.component';
import { ViewsContentMainComponent } from 'app/components/views/content/main.component';

const appRoutes: Routes = [
  { 'path': '', 'component': ViewsHomeMainComponent, 'pathMatch': 'full' },
  { 'path': 'home', 'redirectTo': '/', 'pathMatch': 'full' },
  { 'path': 'marketplace', 'component': ViewsMarketplaceMainComponent, 'pathMatch': 'full' },
  { 'path': 'marketplace/:filter', 'component': ViewsMarketplaceMainComponent, 'pathMatch': 'full' },
  { 'path': 'listings', 'redirectTo': '/marketplace', 'pathMatch': 'full' },
  { 'path': 'listing-management', 'component': ViewsListingManagementListComponent, 'pathMatch': 'full' },
  {
    'path': 'listing-management/listing/:id',
    'component': ViewsListingManagementComponent,
    'pathMatch': 'full'
  },
  {
    'path': 'listing-document/:articlePath/:documentName',
    'component': ViewsListingDocumentComponent,
    'pathMatch': 'full'
  },
  { 'path': 'learn/leadership/:id', 'component': ViewsLearnLeadershipProfileComponent, 'pathMatch': 'full' },
  { 'path': 'profile/:id', 'component': ViewsContentProfileComponent, 'pathMatch': 'full' },
  { 'path': 'questionnaire/:submissionId', 'component': ViewsContentQuestionnaireComponent, 'pathMatch': 'full' },
  { 'path': 'contact', 'component': ViewsContactMainComponent, 'pathMatch': 'full' },
  { 'path': 'profile', 'component': ViewsProfileMainComponent, 'pathMatch': 'full' },
  { 'path': 'login/email', 'component': ViewsLoginEmailComponent, 'pathMatch': 'full' },
  { 'path': 'login/email/:redirect', 'component': ViewsLoginEmailComponent, 'pathMatch': 'full' },
  { 'path': 'login/link/:email/:redirect/:token', 'component': ViewsLoginLinkComponent, 'pathMatch': 'full' },
  { 'path': 'login/link/:email/:token', 'component': ViewsLoginLinkComponent, 'pathMatch': 'full' },
  { 'path': 'signup', 'component': ViewsSignupMainComponent, 'pathMatch': 'full' },
  { 'path': 'signup/:type', 'component': ViewsSignupMainComponent, 'pathMatch': 'full' },
  { 'path': 'news', 'component': ViewsNewsMainComponent, 'pathMatch': 'full' },
  { 'path': 'blog', 'component': ViewsBlogMainComponent, 'pathMatch': 'full' },
  { 'path': 'blog/:id', 'component': ViewsBlogEntryComponent, 'pathMatch': 'full' },
  {
    'path': 'email-verification/:emailVerificationId',
    'component': ViewsHomeEmailVerificationComponent,
    'pathMatch': 'full'
  },
  { 'path': 'video-library', 'component': ViewsVideoMainComponent, 'pathMatch': 'full' },
  { 'path': 'video-library/:videoId', 'component': ViewsVideoEntryComponent, 'pathMatch': 'full' },
  { 'path': 'articles', 'component': ViewsArticleMainComponent, 'pathMatch': 'full' },
  { 'path': 'articles-editor/:articleId', 'component': ViewsArticleEditorComponent, 'pathMatch': 'full' },
  {
    'path': 'articles-editor/:articleId/:copyArticleId',
    'component': ViewsArticleEditorComponent,
    'pathMatch': 'full'
  },
  { 'path': 'assets', 'component': ViewsCdnbrowserMainComponent, 'pathMatch': 'full' },
  { 'path': 'contracts', 'component': ViewsContractsMainComponent, 'pathMatch': 'full' },
  { 'path': 'contracts/:id', 'component': ViewsContractsEditorComponent, 'pathMatch': 'full' },
  { 'path': 'contracts/:id/instance', 'component': ViewsContractsInstanceMainComponent, 'pathMatch': 'full' },
  { 'path': 'confirm-nda/:instanceId/:authorId', 'component': ViewContractsInstanceConfirmEmailMainComponent, 'pathMatch': 'full' },
  {
    'path': 'contracts/:legalDocumentId/instance/:id',
    'component': ViewsContractsInstanceEditorComponent,
    'pathMatch': 'full'
  },
  { 'path': 'user-reset', 'component': ViewsUserResetMainComponent, 'pathMatch': 'full' },
  { 'path': 'sign/:path', 'component': ViewsLegalDocumentSignComponent, 'pathMatch': 'full' },
  { 'path': 'sign/:path/:instanceId', 'component': ViewsLegalDocumentSignComponent, 'pathMatch': 'full' },
  { 'path': 'view/:path/:instanceId', 'component': ViewsLegalDocumentViewComponent, 'pathMatch': 'full' },
  { 'path': 'print/:path/:instanceId', 'component': ViewsLegalDocumentPrintComponent, 'pathMatch': 'full' },
  { 'path': 'action/:workflow', 'component': ViewsWorkflowMainComponent, 'pathMatch': 'full' },
  { 'path': 'action/:workflow/:section', 'component': ViewsWorkflowMainComponent, 'pathMatch': 'full' },
  {
    'path': 'action/:workflow/:section/target-init/:params',
    'component': ViewsWorkflowMainComponent,
    'pathMatch': 'full'
  },
  { 'path': 'action/:workflow/:section/:page', 'component': ViewsWorkflowMainComponent, 'pathMatch': 'full' },
  { 'path': '**', 'component': ViewsContentMainComponent, 'pathMatch': 'full' }
];

@NgModule( {
  imports: [
    RouterModule.forRoot( appRoutes )
  ],
  exports: [
    RouterModule
  ]
} )
export class AppRoutingModule {
}
