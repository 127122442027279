import {Injectable} from '@angular/core';


import * as Bluebird from 'bluebird';

import {ApiResult, ApiResultContent} from 'app/models/api';
import {HttpClient, HttpResponse, HttpHeaders} from '@angular/common/http';

import {environment} from 'app/../environments/environment';

@Injectable()
export class ApiService {

  public nextId: number = 1;

  constructor(public http: HttpClient) {
  }

  public getFullUrl(path?: string, query?: string): string {

    let url = `${environment.api.protocol}://${environment.api.host}`;

    if ((environment.api.protocol === 'https' &&
        environment.api.port !== 443) ||
      (environment.api.protocol === 'http' &&
        environment.api.port !== 80)) {
      url += `:${environment.api.port}`;
    }

    if (typeof path === 'string' && path.trim().length > 0) {
      url += `${path.trim()}`;
    }

    if (typeof query === 'string' && query.trim().length > 0) {
      url += `?${query.trim()}`;
    }

    return url;

  }


  public download(path: string): void {
    const url = this.getFullUrl(path);
    window.open(url, '_blank');
  }

  public call(method: string, params: Object): Bluebird<any> {

    const myId: string = (this.nextId++).toString();

    const headers = new HttpHeaders({'Content-Type': 'application/json'});

    const _throwError = (error: string) => {
      throw new Error(error);
    };

    const promise = this.http.post<ApiResultContent>(this.getFullUrl('/rpc', method), {
      jsonrpc: '2.0',
      id: myId,
      method: method,
      params: params
    }, {
      observe: 'response',
      headers: headers,
      withCredentials: true
    })
      .toPromise()
      .then((res: HttpResponse<ApiResult>): any => {

        if (!res || !res.body) {
          _throwError('no response object');
        }

        const body: ApiResult = res.body;

        if (!body.hasOwnProperty('id')) {
          _throwError('missing RPC id in response');
        }

        if (body.id !== myId) {
          _throwError('RPC id ' + body.id + ' does not match ' + myId);
        }

        if (body.hasOwnProperty('error')) {

          if (typeof body.error.message !== 'string' || body.error.message.length < 1) {
            _throwError('response had an error, but was missing a description message');
          }

          _throwError(body.error.message);

        }

        if (!body.hasOwnProperty('result')) {
          _throwError('response missing result field');
        }

        return body.result;

      });

    return Bluebird.resolve<any>(promise);

  }

}
