/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./view.component.less.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../content/render/legal.document.view.component.ngfactory";
import * as i3 from "../../content/render/legal.document.view.component";
import * as i4 from "../../../services/app";
import * as i5 from "@angular/common";
import * as i6 from "./print.component";
import * as i7 from "@angular/router";
var styles_ViewsLegalDocumentPrintComponent = [i0.styles];
var RenderType_ViewsLegalDocumentPrintComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_ViewsLegalDocumentPrintComponent, data: {} });
export { RenderType_ViewsLegalDocumentPrintComponent as RenderType_ViewsLegalDocumentPrintComponent };
function View_ViewsLegalDocumentPrintComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "div", [["class", "content-render-legal-document-view"]], null, null, null, i2.View_ContentRenderLegalDocumentViewComponent_0, i2.RenderType_ContentRenderLegalDocumentViewComponent)), i1.ɵdid(1, 114688, null, 0, i3.ContentRenderLegalDocumentViewComponent, [i4.AppService], { path: [0, "path"], instanceId: [1, "instanceId"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.path; var currVal_1 = _co.instanceId; _ck(_v, 1, 0, currVal_0, currVal_1); }, null); }
export function View_ViewsLegalDocumentPrintComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵand(16777216, null, null, 1, null, View_ViewsLegalDocumentPrintComponent_1)), i1.ɵdid(1, 16384, null, 0, i5.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.path; _ck(_v, 1, 0, currVal_0); }, null); }
export function View_ViewsLegalDocumentPrintComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "div", [["class", "app-view-legal-document-view"]], null, null, null, View_ViewsLegalDocumentPrintComponent_0, RenderType_ViewsLegalDocumentPrintComponent)), i1.ɵdid(1, 245760, null, 0, i6.ViewsLegalDocumentPrintComponent, [i4.AppService, i7.ActivatedRoute], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var ViewsLegalDocumentPrintComponentNgFactory = i1.ɵccf(".app-view-legal-document-view", i6.ViewsLegalDocumentPrintComponent, View_ViewsLegalDocumentPrintComponent_Host_0, {}, {}, []);
export { ViewsLegalDocumentPrintComponentNgFactory as ViewsLegalDocumentPrintComponentNgFactory };
