import { Component, Input, OnDestroy, OnInit, ChangeDetectorRef } from '@angular/core';
import { Article, SearchResponse } from 'app/models/article';
import { AppService } from 'app/services/app';

type NavigationModes = 'dots' | 'arrows' | 'gallery';

@Component( {
	moduleId: module.id,
	selector: '.app-content-banner-carousel',
	templateUrl: 'banner.carousel.component.pug',
	styleUrls: [ 'banner.carousel.component.less' ]
} )
export class ContentBannerCarouselComponent implements OnDestroy, OnInit {

	@Input()
	public articles: Article[] = [];

	@Input()
	public categories: string[] = [];

	@Input()
	public navigationMode: NavigationModes = 'dots';

	@Input()
	public topPadding: number = 68; // default to miss toolbar

	@Input()
	public locked: boolean = false;

	@Input()
	public lockedCallback: () => void = null;

	public previousPages: boolean[] = [];

	public currentPage: number = 0;
	public rotate: number = 0;

	constructor( public appService: AppService, public changeDetector: ChangeDetectorRef ) {
		this.resetChanger();
	}

	public clickedLock(): void {
		if ( typeof this.lockedCallback === 'function' ) {
			this.lockedCallback();
		}
	}

	public ngOnInit(): void {

		if ( Array.isArray( this.categories ) && this.categories.length > 0 ) {

			this.appService
				.articleModel
				.search( {
					query: {
						categories: this.categories
					}
				} )
				.then( ( result: SearchResponse ) => {

					if ( result && result.articles ) {
						this.articles = result.articles;
					}

					this.changeDetector.markForCheck();

				} )
				.catch( () => {
					this.articles = [];
				} );

		}
	}

	public ngOnDestroy(): void {
		if ( this.rotate ) {
			window.clearInterval( this.rotate );
		}
	}

	public resetChanger(): void {
		if ( this.rotate ) {
			window.clearInterval( this.rotate );
		}

		this.rotate = window.setInterval( () => {
			this.currentPage++;
			if ( this.currentPage >= this.articles.length ) {
				this.currentPage = 0;
			}
			this.previousPages[ this.currentPage ] = true;
		}, 30000 );
	}

	public getArticleCount(): number {
		return this.getArticles().length;
	}

	public getArticles(): Article[] {

		if ( !Array.isArray( this.articles ) ) {
			return [];
		}

		return this.articles.filter( ( article ) => {
			return !!article;
		} );

	}

	public selectPage( i: number ): void {

		if ( i < 0 ) {
			i = this.getArticles().length - 1;
		}

		if ( i >= this.getArticles().length ) {
			i = 0;
		}

		if ( i < 0 || i >= this.getArticles().length ) {
			return;
		}

		this.previousPages[ this.currentPage ] = true;

		this.currentPage = i;
		this.resetChanger();

	}

	public pageClasses: any = {};

	public getPageClasses( i: number ): any {

		let index = '_' + i;

		if ( !this.pageClasses.hasOwnProperty( index ) ) {
			this.pageClasses[ index ] = {};
		}

		this.pageClasses[ index ].first = i === this.currentPage && this.previousPages.length < 1;
		this.pageClasses[ index ].active = i === this.currentPage && this.previousPages.length > 0;
		this.pageClasses[ index ].previous = i !== this.currentPage && this.previousPages[ i ];

		return this.pageClasses[ index ];

	}

	public selectPreviousPage(): void {
		this.selectPage( this.currentPage - 1 );
	}

	public selectNextPage(): void {
		this.selectPage( this.currentPage + 1 );
	}

	public isLastPage(): boolean {
		return this.currentPage >= this.getArticleCount() - 1;
	}

}
