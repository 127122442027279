
<div class="my-listings-container" *ngIf="!arraysReady()">
  <!--.action-container-->
  <!--.action-link.add-listing((click)="addListing('seller')") Add Market Maker-->
  <!--br-->
  <!--.action-link.add-listing((click)="addListing('buyer')") Add Deal Maker-->
  <div class="description">Intake Forms</div>
  <div class="my-listings-row my-listings-header">
    <div class="my-listings-field left col-xs-6 col-md-8">Name</div>
    <div class="my-listings-field center col-xs-6 col-md-1">Type</div>
    <div class="my-listings-field right col-xs-6 col-md-3">Action</div>
  </div>
  <div class="my-listings-row">
    <div class="my-listings-field left col-xs-12">Loading...</div>
  </div>
  <div class="break"></div>
  <div class="description">Published</div>
  <div class="my-listings-row my-listings-header">
    <div class="my-listings-field left col-xs-6 col-md-8">Name</div>
    <div class="my-listings-field center col-xs-6 col-md-1">Type</div>
    <div class="my-listings-field right col-xs-6 col-md-3">Action</div>
  </div>
  <div class="my-listings-row">
    <div class="my-listings-field left col-xs-12">Loading...</div>
  </div>
</div>
<div class="my-listings-container" *ngIf="arraysReady()">
  <div class="action-container">
    <div class="action-link add-listing" (click)="addListing('seller')">New Market Maker Listing</div><br>
    <div class="action-link add-listing" (click)="addListing('buyer')">New Deal Maker Listing</div>
  </div>
  <div class="description">Intake Forms</div>
  <div class="my-listings-row my-listings-header">
    <div class="my-listings-field left col-xs-6 col-md-8">Name</div>
    <div class="my-listings-field center col-xs-6 col-md-1">Type</div>
    <div class="my-listings-field right col-xs-6 col-md-3">Action</div>
  </div>
  <div class="my-listings-row" *ngIf="listings.length &lt; 1">
    <div class="my-listings-field left col-xs-12">No Listing In-Take Forms Found</div>
  </div>
  <div class="my-listings-row" *ngFor="let listing of listings">
    <div class="my-listings-field left col-xs-6 col-md-8">
      <div class="view-document">{{ listing.name }}</div>
    </div>
    <div class="my-listings-field center col-xs-6 col-md-1">{{ getType(listing) }}</div>
    <div class="my-listings-field right col-xs-6 col-md-3">
      <div class="action-link edit-listing" (click)="editListing(listing)">Edit</div>
      <!--.action-link.edit-listing((click)="removeListing(listing)") Remove-->
    </div>
  </div>
  <div class="break"></div>
  <div class="description">Published</div>
  <div class="my-listings-row my-listings-header">
    <div class="my-listings-field left col-xs-6 col-md-8">Name</div>
    <div class="my-listings-field center col-xs-6 col-md-1">Type</div>
    <div class="my-listings-field right col-xs-6 col-md-3">Action</div>
  </div>
  <div class="my-listings-row" *ngIf="articles.length &lt; 1">
    <div class="my-listings-field left col-xs-12">No Published Listings Found</div>
  </div>
  <div class="my-listings-row" *ngFor="let article of articles">
    <div class="my-listings-field left col-xs-6 col-md-8">
      <div class="listing-name">
        <div class="title">{{ article.title }} /</div>
        <div class="path" (click)="viewListing(article)">{{ article.path }}</div>
      </div>
    </div>
    <div class="my-listings-field center col-xs-6 col-md-1">{{ getArticleType(article) }}</div>
    <div class="my-listings-field right col-xs-6 col-md-3">
      <div class="action-link edit-listing" (click)="editArticlePermissions(article)">Manage</div>
    </div>
  </div>
</div>