/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./br.component.css.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../standard/input/select.access.level.component.ngfactory";
import * as i3 from "../../../standard/input/select.access.level.component";
import * as i4 from "../../../../services/app";
import * as i5 from "@angular/common";
import * as i6 from "./br.component";
var styles_ContentRenderEditorBrComponent = [i0.styles];
var RenderType_ContentRenderEditorBrComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_ContentRenderEditorBrComponent, data: {} });
export { RenderType_ContentRenderEditorBrComponent as RenderType_ContentRenderEditorBrComponent };
function View_ContentRenderEditorBrComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 0, "div", [["class", "select-background-color-block"]], [[4, "background-color", null]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = ((_co.section.contentBackgroundColor = _v.context.$implicit) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null))], null, function (_ck, _v) { var currVal_0 = _v.context.$implicit; _ck(_v, 0, 0, currVal_0); }); }
export function View_ContentRenderEditorBrComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "h4", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Horizontal Rule"])), (_l()(), i1.ɵeld(2, 0, null, null, 0, "br", [], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 0, "br", [], null, null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 1, "div", [["class", "select-access-level"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Access Level:"])), (_l()(), i1.ɵeld(6, 0, null, null, 1, "div", [["class", "input-type-select-access-level"]], null, [[null, "accessLevelChange"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("accessLevelChange" === en)) {
        var pd_0 = ((_co.section.accessLevel = $event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i2.View_StandardInputSelectAccessLevelComponent_0, i2.RenderType_StandardInputSelectAccessLevelComponent)), i1.ɵdid(7, 114688, null, 0, i3.StandardInputSelectAccessLevelComponent, [i4.AppService], { accessLevel: [0, "accessLevel"] }, { accessLevelChange: "accessLevelChange" }), (_l()(), i1.ɵeld(8, 0, null, null, 0, "br", [], null, null, null, null, null)), (_l()(), i1.ɵeld(9, 0, null, null, 0, "br", [], null, null, null, null, null)), (_l()(), i1.ɵeld(10, 0, null, null, 1, "div", [["class", "select-background-color"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Color"])), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ContentRenderEditorBrComponent_1)), i1.ɵdid(13, 278528, null, 0, i5.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null), (_l()(), i1.ɵeld(14, 0, null, null, 1, "a", [["class", "reset-background active"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = ((_co.section.contentBackgroundColor = null) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, ["Reset"])), (_l()(), i1.ɵeld(16, 0, null, null, 0, "br", [], null, null, null, null, null)), (_l()(), i1.ɵeld(17, 0, null, null, 0, "br", [], null, null, null, null, null))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.section.accessLevel; _ck(_v, 7, 0, currVal_0); var currVal_1 = _co.app.colors; _ck(_v, 13, 0, currVal_1); }, null); }
export function View_ContentRenderEditorBrComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "div", [["class", "content-render-editor-br"]], null, null, null, View_ContentRenderEditorBrComponent_0, RenderType_ContentRenderEditorBrComponent)), i1.ɵdid(1, 49152, null, 0, i6.ContentRenderEditorBrComponent, [i4.AppService, i1.ChangeDetectorRef], null, null)], null, null); }
var ContentRenderEditorBrComponentNgFactory = i1.ɵccf(".content-render-editor-br", i6.ContentRenderEditorBrComponent, View_ContentRenderEditorBrComponent_Host_0, { section: "section" }, {}, []);
export { ContentRenderEditorBrComponentNgFactory as ContentRenderEditorBrComponentNgFactory };
