import {Injectable} from '@angular/core';
import {ApiService} from 'app/services/api';

// Types
import {GenericModel, GenericMasterSchemaType, GenericSchemaType} from './generic';
import {HashTree} from 'app/types/containers';
import {LogicParams} from 'app/services/logic';

export type WorkflowState = HashTree<string | number | null | boolean>;
export type WorkflowItemTypes =
  'checkbox'
  | 'dropdown'
  | 'text'
  | 'textarea'
  | 'legal-document'
  | 'listing-upload'
  | 'datepicker';
export type WorkflowItemParams = HashTree<string | number | null | boolean>;

export type WorkflowTargetTypeValues = 'service' | 'model' | 'console';
export type WorkflowTargetOnValues = 'item' | 'complete' | 'page';

export type WorkflowTarget = {
  type: WorkflowTargetTypeValues; // determines where to find the function to call for this target event general component level service passed into constructor, or as a *Model object on this.app
  method: string; // The dot-notation for the service/model.function method call
  on?: WorkflowTargetOnValues; // the different types of events that can trigger this event
  default?: HashTree<string | number | null | boolean>; // any values here are copied into the state before being sent as params to the service/model method
  params?: HashTree<string | number | null | boolean> | string; // if set, these params will be sent to the service/model method instead of the workflow state
};

export class WorkFlowItemValidationCheck {
  public logic?: LogicParams;
  public errorMessage?: string; // if set, will display on error, otherwise a generic error will be displayed
}

// Schema Type
export class WorkflowItem extends GenericSchemaType {
  public title?: string;
  public label?: string;
  public labelAlign?: ('left' | 'center' | 'right');
  public show?: LogicParams;
  public type?: WorkflowItemTypes;
  public target?: string;
  public cells?: number;
  public allowBlank?: boolean;
  public placeholder?: string;
  public params?: WorkflowItemParams;
  public completeCheck?: LogicParams;
  public required?: boolean;
  public completeHint?: string; // an optional message to display if the user entered data but it is not satisfy completeCheck logic
}

export class WorkflowPage extends GenericSchemaType {
  public id?: string;
  public title?: string;
  public show?: LogicParams;
  public continueDisabled?: boolean;
  public continueText?: string;
  public completionText?: string;
  public width?: number;
  public items?: WorkflowItem[];
  public target?: WorkflowTarget;
}

export class WorkflowSection extends GenericSchemaType {
  public id: string;
  public title: string;
  public hideComplete?: boolean;
  public icon: string;
  public link?: string | [string | number | {}];
  public show?: LogicParams;
  public continueDisabled?: boolean;
  public nextDisabled?: boolean;
  public menuShow?: boolean;
  public continueText?: string;
  public completionText?: string;
  public pages?: WorkflowPage[]
}

export type WorkFlowTypes = 'linear' | 'menu';

export class Workflow extends GenericMasterSchemaType {
  public title: string; // not yet used
  public type: WorkFlowTypes; // determines if this is a menu work flow or a linear process work flow
  public stats?: string; // if set will log user activity stats as they move through the workflow in this field
  public continueText: string; // sets the continue button text for the entire workflow
  public completionText: string; // sets the continue button text for the last page of the last section
  public completionRedirect: string; // determines if clicking the complete button should yield a redirect and if so, to what URL
  public targetInit: WorkflowTarget; // if set, is used to initialize the workflow state
  public targets: WorkflowTarget[]; // a list of all event targets to register
  public sections: WorkflowSection[]; // all sections within the workflow
}

@Injectable()
export class WorkflowModel extends GenericModel<Workflow> {

  constructor(api: ApiService) {
    super();
    this.init(api, 'workflow', {
      id: null,
      title: null,
      type: null,
      stats: null,
      completionText: null,
      continueText: null,
      completionRedirect: null,
      targetInit: null,
      targets: [],
      sections: []
    }, 60000);
  }

  public getInstance(ref: any): Workflow {
    return new Workflow(ref);
  }

}
