import { OnInit, OnDestroy, ElementRef, NgZone } from '@angular/core';
import { Router } from '@angular/router';
import { Location } from '@angular/common';
import { ExioService } from 'app/services/exio';
import { AppService } from 'app/services/app';
import { ApiService } from 'app/services/api';
import { ChatService } from 'app/services/chat';
import { ProfileModel } from 'app/models/profile';
import { DomSanitizer } from '@angular/platform-browser';
var ContentChatComponent = /** @class */ (function () {
    function ContentChatComponent(appService, location, exioService, router, profileModel, api, chatService, dSanitizer, el, zone) {
        this.appService = appService;
        this.location = location;
        this.exioService = exioService;
        this.router = router;
        this.profileModel = profileModel;
        this.api = api;
        this.chatService = chatService;
        this.dSanitizer = dSanitizer;
        this.el = el;
        this.zone = zone;
        this.authenticatedProfile = null;
        this.profile = null;
        this.profileFullName = '';
        this.loginErrorMessage = null;
        this.profiles = [];
        this.isBouncing = false;
        this.workflowId = null;
        this.workflowPageId = 0;
        this.workflowDef = null;
        this.workflowReplacements = null;
        this.workflowOptions = null;
        this.loading = true;
        this.showing = false;
        this.userHidden = false;
        this.loadedLegalDocuments = false;
        this.legalDocument = null;
        this.legalDocumentInstance = null;
        this.legalDocumentInstanceSigner = null;
        this.legalDocumentElectronicSignatureAgree = false;
        this.legalDocumentTermsOfServiceAgree = false;
        this.socialNetworks = ['linkedIn', 'google', 'email'];
        this.loginWaitNetwork = null;
        this.loginPopUp = null;
        this.loginWait = null;
        this.legalDocumentState = 'display';
        this.processing = false;
        this.idleWaitWatch = null;
        this.idleWaitTimeout = null;
        this.subscriptions = [];
        this.$host = null;
        this.$lastInputFocus = null;
        this.init();
    }
    ContentChatComponent.prototype.resetLegalDocuments = function () {
        this.legalDocumentState = 'display';
        this.loadedLegalDocuments = false;
        this.legalDocument = null;
        this.legalDocumentInstance = null;
        this.legalDocumentInstanceSigner = null;
        this.legalDocumentTermsOfServiceAgree = false;
        this.legalDocumentElectronicSignatureAgree = false;
    };
    ContentChatComponent.prototype.init = function () {
        var _this = this;
        this.resetChat();
        this.resetLegalDocuments();
        this.$host = $(this.el.nativeElement);
        this.profileModel.listEmployees()
            .then(function (profiles) {
            _this.profiles = profiles;
            _this.findProfile();
        })
            .catch(function () {
            _this.profiles = [];
            _this.findProfile();
        });
        this.subscriptions.push(this.appService.getAuthenticatedProfile({
            next: function (profile) {
                if (profile) {
                    // there is a profile
                    _this.authenticatedProfile = profile;
                    if (profile.firstName && profile.lastName) {
                        _this.profileFullName = profile.firstName + " " + profile.lastName;
                    }
                }
                else if (!profile) {
                    _this.authenticatedProfile = _this.profileModel.newInstance();
                }
            },
            error: function () {
                _this.authenticatedProfile = _this.profileModel.newInstance();
            },
            complete: function () {
                _this.authenticatedProfile = _this.profileModel.newInstance();
            }
        }));
        this.hide();
    };
    ContentChatComponent.prototype.hasSignature = function () {
        return typeof this.profileFullName === 'string' && this.profileFullName.trim().length > 0;
    };
    ContentChatComponent.prototype.contractReady = function (path) {
        // this.log('\n\n');
        var hasContract = this.hasContract(path);
        var hasContractInstance = this.hasContractInstance(path);
        // this.log('hasContract', hasContract);
        // this.log('this.hasContractInstance(path)', hasContractInstance);
        return hasContract && hasContractInstance;
    };
    ContentChatComponent.prototype.hasContract = function (path) {
        return this.getContract(path) !== null;
    };
    ContentChatComponent.prototype.getContract = function (path) {
        // this.log('debug 1');
        if (!this.getContractInstance(path)) {
            // this.log('debug 2');
            return null;
        }
        // this.log('debug 3', !!this.legalDocument);
        return this.legalDocument;
    };
    ContentChatComponent.prototype.getContractInstanceSigner = function (path) {
        if (!this.getContractInstance(path)) {
            return null;
        }
        return this.legalDocumentInstanceSigner;
    };
    ContentChatComponent.prototype.isValidEmail = function (email) {
        if (typeof email !== 'string' || email.trim().length < 1) {
            return false;
        }
        return !!email.match(/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/);
    };
    ContentChatComponent.prototype.sendLink = function (email, link, done) {
        this.appService.profileModel.sendLoginLink({
            email: email.trim().toLowerCase(),
            redirect: link || null
        })
            .asCallback(done);
    };
    ContentChatComponent.prototype.emailLogin = function () {
        var _this = this;
        this.loginErrorMessage = null;
        if (this.isValidEmail(this.workflowOptions.email)) {
            this.processing = true;
            this.sendLink(this.workflowOptions.email, window.location.pathname, function (err) {
                window.setTimeout(function () {
                    _this.processing = false;
                    if (err) {
                        _this.loginErrorMessage = 'Something went wrong, please try again in a few minutes.';
                        return;
                    }
                    else {
                        _this.continue(true);
                    }
                }, 1000);
            });
        }
        else {
            this.loginErrorMessage = 'Please enter a valid email address.';
            var $inputs_1 = this.$host.find('.login-item input');
            $inputs_1.addClass('focused');
            if (!this.isBouncing) {
                this.isBouncing = true;
                requestAnimationFrame(function () {
                    $inputs_1.effect('bounce', { times: 3, distance: 5 }, 'slow', function () {
                        _this.isBouncing = false;
                    });
                });
            }
        }
    };
    ContentChatComponent.prototype.getContractInstance = function (path) {
        // this.log('DEBUG 4');
        var _this = this;
        var loadDocument = function () {
            _this.appService
                .legalDocumentModel
                .get(_this.legalDocumentInstance.legalDocumentId)
                .then(function (document) {
                if (!document) {
                    _this.legalDocument = null;
                    console.error('missing document');
                    return null;
                }
                _this.legalDocument = _this.appService
                    .legalDocumentModel
                    .applyMergeCodes(document, _this.authenticatedProfile, _this.legalDocumentInstance.data);
                return null;
            })
                .catch(function (e) {
                console.error('failed to load document', e);
                _this.resetLegalDocuments();
                _this.legalDocument = null;
                _this.legalDocumentInstance = null;
            });
        };
        // this.log('DEBUG 5');
        var loadDocumentInstance = function () {
            var handleError = function (e) {
                console.error('failed to load instance', e);
                _this.resetLegalDocuments();
                _this.loading = false;
                _this.legalDocument = null;
                _this.legalDocumentInstance = null;
            };
            var handleInstance = function (instance) {
                if (instance) {
                    _this.legalDocumentInstance = instance;
                    loadDocument();
                    if (instance && instance.complete) {
                        _this.legalDocumentState = 'complete';
                    }
                }
                else {
                    _this.legalDocumentInstance = null;
                    _this.resetLegalDocuments();
                }
                return null;
            };
            _this.appService
                .legalDocumentModel
                .getArticleNdaAnonymousInstance({
                legalDocumentId: _this.workflowOptions.contract.id,
                articleId: _this.workflowOptions.contract.articleId
            })
                .then(handleInstance)
                .catch(handleError);
        };
        // this.log('this.loadedLegalDocuments', !this.loadedLegalDocuments);
        // this.log('this.legalDocumentInstance === null', this.legalDocumentInstance === null);
        // this.log('this.legalDocument === null', this.legalDocument === null);
        if (!this.loadedLegalDocuments && (this.legalDocumentInstance === null || this.legalDocument === null)) {
            this.loadedLegalDocuments = true;
            loadDocumentInstance();
        }
        return this.legalDocumentInstance;
    };
    ContentChatComponent.prototype.hasContractInstance = function (path) {
        return this.getContractInstance(path) !== null;
    };
    ContentChatComponent.prototype.sectionContent = function (i, section) {
        return i + 1 + "\\.  **" + section.title + "**. " + section.contents;
    };
    ContentChatComponent.prototype.log = function (message) {
        var optionalParams = [];
        for (var _i = 1; _i < arguments.length; _i++) {
            optionalParams[_i - 1] = arguments[_i];
        }
        var args = arguments;
        this.zone.runOutsideAngular(function () {
            console.log.apply(console, args);
        });
    };
    ContentChatComponent.prototype.ngOnInit = function () {
        // this.log('ngOnInit');
        var _this = this;
        var apply = function (workflowLoad) {
            if (!workflowLoad) {
                workflowLoad = {
                    id: null
                };
            }
            _this.resetLegalDocuments();
            _this.workflowReplacements = workflowLoad.replacements || {};
            _this.workflowOptions = workflowLoad.options || {};
            var workflowId = workflowLoad.id;
            var workflowIdleWait = workflowLoad.idleWait;
            if (_this.workflowId === workflowId && _this.userHidden && !workflowLoad.force) {
                _this.clearIdleWait();
                return;
            }
            _this.legalDocumentState = 'display';
            var bounce = _this.showing && _this.workflowId === workflowId;
            // if new workflow allow show again
            _this.userHidden = false;
            _this.hide();
            _this.workflowId = workflowId || null;
            _this.workflowPageId = 0;
            _this.loading = true;
            if (workflowId) {
                var workFlowDef = _this.getWorkFlow(workflowId);
                if (workFlowDef) {
                    var doReplacements_1 = function (text) {
                        if (typeof text !== 'string') {
                            return text;
                        }
                        Object.keys(_this.workflowReplacements).forEach(function (key) {
                            text = text.replace("[[" + key + "]]", _this.workflowReplacements[key]);
                        });
                        return text;
                    };
                    workFlowDef.pages.forEach(function (page) {
                        page.title = doReplacements_1(page.title);
                        page.items.forEach(function (item) {
                            if (item.type === 'paragraph') {
                                item.params.content = doReplacements_1(item.params.content);
                            }
                            if (item.type === 'checklist') {
                                item.params.forEach(function (param) {
                                    param.name = doReplacements_1(param.name);
                                });
                            }
                        });
                    });
                    _this.workflowDef = workFlowDef;
                    _this.loading = false;
                    _this.workflowShow(workflowIdleWait);
                    if (bounce && !_this.isBouncing) {
                        _this.isBouncing = true;
                        requestAnimationFrame(function () {
                            _this.$host.effect('bounce', { times: 3, distance: 20 }, 'slow', function () {
                                _this.isBouncing = false;
                            });
                        });
                    }
                }
                else {
                    apply(null);
                }
            }
            else {
                _this.hide();
                _this.clearIdleWait();
                _this.clearSocialLogin();
                _this.loading = false;
                _this.workflowDef = null;
            }
            // this is a hack to fix a rendering issue where changes are not detected
            _this.zone.runTask(function () {
                // NO-OP
            });
        };
        this.subscriptions.push(this.chatService.onLoad({
            next: apply,
            complete: function () {
                apply(null);
            },
            error: function () {
                apply(null);
            }
        }));
    };
    ContentChatComponent.prototype.setIdleWait = function (idleWait) {
        var _this = this;
        var throttle = function (func, wait) {
            var timeout = null;
            var queued = false;
            var waitQueue = function () {
                // no queue
                if (timeout === null) {
                    // if  one was queued, fire it
                    if (queued) {
                        queued = false;
                        func();
                    }
                }
                else {
                    // already queue waiting
                    return;
                }
                timeout = window.setTimeout(function () {
                    timeout = null;
                    // if func called while waiting, trigger that and wait again
                    if (queued) {
                        waitQueue();
                    }
                }, wait);
            };
            return function () {
                // always queue one
                queued = true;
                waitQueue();
            };
        };
        this.clearIdleWait();
        var animationFrameRequested = false;
        var handler = function () {
            if (animationFrameRequested) {
                return;
            }
            animationFrameRequested = true;
            requestAnimationFrame(function () {
                animationFrameRequested = false;
                // each time there is activity,
                if (_this.idleWaitTimeout) {
                    window.clearTimeout(_this.idleWaitTimeout);
                    _this.idleWaitTimeout = null;
                }
                _this.idleWaitTimeout = window.setTimeout(function () {
                    _this.clearIdleWait();
                    _this.show();
                }, idleWait * 1000);
            });
        };
        var $window = $(window);
        this.idleWaitWatch = throttle(handler, 100);
        $window.on('scroll', this.idleWaitWatch);
        $window.on('mousemove', this.idleWaitWatch);
        $window.on('keypress', this.idleWaitWatch);
    };
    ContentChatComponent.prototype.clearIdleWait = function () {
        if (this.idleWaitWatch) {
            var $window = $(window);
            $window.off('scroll', this.idleWaitWatch);
            $window.off('mousemove', this.idleWaitWatch);
            $window.off('keypress', this.idleWaitWatch);
            this.idleWaitWatch = null;
        }
        if (this.idleWaitTimeout) {
            window.clearTimeout(this.idleWaitTimeout);
            this.idleWaitTimeout = null;
        }
    };
    ContentChatComponent.prototype.workflowShow = function (idleWait) {
        if (idleWait < 1) {
            this.clearIdleWait();
            this.show();
            return;
        }
        this.setIdleWait(idleWait);
    };
    ContentChatComponent.prototype.ngOnDestroy = function () {
        this.subscriptions.forEach(function (subscription) {
            subscription.unsubscribe();
        });
        this.subscriptions = [];
        this.clearIdleWait();
        this.clearSocialLogin();
    };
    ContentChatComponent.prototype.goProfile = function () {
        if (this.profile && typeof this.profile.id === 'string') {
            this.hide();
            this.router.navigate(['/learn/leadership/', this.profile.id]);
        }
    };
    ContentChatComponent.prototype.profilePicture = function () {
        if (this.profile && this.profile.headshotURL) {
            var url = this.exioService.image.getUrl(this.profile.headshotURL, {
                format: 'jpg',
                actions: [
                    {
                        type: 'shrink',
                        width: 30
                    },
                    {
                        type: 'quality',
                        quality: 100
                    }
                ]
            });
            return "url(" + url + ")";
        }
        return null;
    };
    ContentChatComponent.prototype.findProfile = function () {
        var _this = this;
        this.profile = null;
        if (this.profiles && this.workflowDef && this.workflowDef.authorEmail) {
            this.profiles.forEach(function (profile) {
                if (profile && profile.email === _this.workflowDef.authorEmail) {
                    _this.profile = profile;
                }
            });
        }
    };
    ContentChatComponent.prototype.show = function () {
        this.findProfile();
        this.showing = true;
        this.$host.removeClass('hide-chat');
    };
    ContentChatComponent.prototype.resetChat = function () {
        // this.log('resetChat');
        this.workflowId = null;
        this.workflowPageId = 0;
        this.workflowDef = null;
        this.workflowOptions = null;
        this.loading = true;
        this.showing = false;
        this.userHidden = false;
        this.loadedLegalDocuments = false;
        this.legalDocument = null;
        this.legalDocumentInstance = null;
        this.legalDocumentInstanceSigner = null;
        this.legalDocumentTermsOfServiceAgree = false;
        this.legalDocumentElectronicSignatureAgree = false;
        this.socialNetworks = ['linkedIn', 'google', 'email'];
        this.loginWaitNetwork = null;
        this.loginPopUp = null;
        this.loginWait = null;
        this.legalDocumentState = 'display';
        this.processing = false;
        this.idleWaitWatch = null;
        if (this.idleWaitTimeout) {
            window.clearTimeout(this.idleWaitTimeout);
        }
        this.idleWaitTimeout = null;
    };
    ContentChatComponent.prototype.closed = function () {
        this.userHidden = true;
        this.hide();
        this.resetChat();
        this.chatService.complete(false);
    };
    ContentChatComponent.prototype.hide = function () {
        this.showing = false;
        this.$host.addClass('hide-chat');
    };
    ContentChatComponent.prototype.getWorkFlow = function (id) {
        return this.chatService.getWorkFlowDef(id);
    };
    ContentChatComponent.prototype.hasCurrentPage = function () {
        return !!this.currentPage();
    };
    ContentChatComponent.prototype.currentTitle = function () {
        if (!this.hasCurrentPage()) {
            return null;
        }
        var title = this.currentPage().title;
        if (typeof title === 'string') {
            title = title.trim();
        }
        else {
            title = '';
        }
        return title.length > 0 ? title : null;
    };
    ContentChatComponent.prototype.currentContinue = function () {
        if (!this.hasCurrentPage()) {
            return 'Continue';
        }
        var result = this.currentPage().continueText;
        if (this.hasLegalDocument()) {
            if (this.legalDocumentState === 'display') {
                result = 'Continue';
            }
            else if (this.isContractFormComplete()) {
                result = 'Send Email Verification';
            }
            else if (!result) {
                result = 'Continue';
            }
        }
        return result;
    };
    ContentChatComponent.prototype.isLastPage = function () {
        if (!this.hasCurrentPage()) {
            return true;
        }
        return this.workflowPageId >= this.workflowDef.pages.length - 1;
    };
    ContentChatComponent.prototype.currentContinueDisabled = function () {
        if (!this.hasCurrentPage()) {
            return null;
        }
        if (this.legalDocumentInstance && this.legalDocumentInstance.complete) {
            return true;
        }
        return this.currentPage().continueDisabled;
    };
    ContentChatComponent.prototype.continueDisabled = function (continueDisabled) {
        this.currentPage().continueDisabled = continueDisabled;
    };
    ContentChatComponent.prototype.currentPage = function () {
        if (this.workflowDef && this.workflowDef.pages && this.workflowPageId > -1 && this.workflowPageId < this.workflowDef.pages.length) {
            return this.workflowDef.pages[this.workflowPageId];
        }
        return null;
    };
    ContentChatComponent.prototype.pageItems = function () {
        if (!this.hasCurrentPage()) {
            return [];
        }
        return this.currentPage().items;
    };
    ContentChatComponent.prototype.markdown = function (markdown) {
        return this.appService.markdownToHtml(markdown);
    };
    ContentChatComponent.prototype.getIncompleteContractFields = function () {
        var $inputs = this.$host.find('.contract-signature-container input').filter('[required]');
        var results = [];
        $inputs.each(function (i, el) {
            var $el = $(el);
            var val = $el.val();
            if (typeof val === 'string' && val.trim().length < 1) {
                results.push($el);
            }
        });
        var $checkInput = this.$host.find('.contract-signature-container .check-group input.input-check:checkbox:not(:checked)');
        if ($checkInput.length > 0) {
            results.push($checkInput);
        }
        return results;
    };
    ContentChatComponent.prototype.isContractFormComplete = function () {
        // if (!this.legalDocumentElectronicSignatureAgree || !this.legalDocumentTermsOfServiceAgree) {
        //   return false;
        // }
        var fields = this.getIncompleteContractFields();
        return fields.length < 1;
    };
    ContentChatComponent.prototype.getLegalDocumentPath = function () {
        if (!this.workflowDef || !this.workflowDef.pages || this.workflowDef.pages.length < 1) {
            return null;
        }
        var contractToSign = null;
        var page = this.workflowDef.pages[this.workflowPageId] || null;
        if (!page) {
            return null;
        }
        page.items.forEach(function (item) {
            if (contractToSign) {
                return;
            }
            if (item && item.type === 'contract') {
                contractToSign = item.params.path;
            }
        });
        return contractToSign;
    };
    ContentChatComponent.prototype.hasLegalDocument = function () {
        var path = this.getLegalDocumentPath();
        return typeof path === 'string' && path.trim().length > 0;
    };
    ContentChatComponent.prototype.highlightNextLegalDocumentInputField = function () {
        var _this = this;
        window.setTimeout(function () {
            var inputs = _this.getIncompleteContractFields();
            if (inputs.length < 1) {
                return;
            }
            var $highlight = inputs[0];
            var done = false;
            inputs.forEach(function ($input, i) {
                $input.removeClass('focused');
                if (!done && _this.$lastInputFocus && _this.$lastInputFocus.get(0) === $input.get(0)) {
                    i++;
                    if (i > inputs.length - 1) {
                        i = 0;
                    }
                    $highlight = inputs[i];
                    done = true;
                }
            });
            $highlight.addClass('focused');
            if (_this.$lastInputFocus) {
                _this.inputLostFocus(_this.$lastInputFocus.get(0));
            }
            _this.$lastInputFocus = $highlight;
            _this.inputGotFocus(_this.$lastInputFocus.get(0));
            var $chatBody = _this.$host.find('.chat-body');
            var $chatBodyContainer = _this.$host.find('.chat-body-container');
            _this.zone.runOutsideAngular(function () {
                requestAnimationFrame(function () {
                    var top = $highlight.position().top;
                    top -= $chatBodyContainer.position().top;
                    $chatBody.stop().animate({ scrollTop: top }, 1000);
                });
            });
        });
    };
    ContentChatComponent.prototype.inputLostFocus = function (el) {
        var $el = $(el);
        $el.data('has-focus', false);
        $el.removeClass('focused');
    };
    ContentChatComponent.prototype.inputGotFocus = function (el) {
        var $el = $(el);
        this.$lastInputFocus = $el;
        $el.data('has-focus', true);
        $el.addClass('focused');
    };
    ContentChatComponent.prototype.trackLinkedInConversion = function () {
        var pixel = $('<img height="1" width="1" style="display:none;" alt="" ' +
            'src="https://dc.ads.linkedin.com/collect/?pid=165700&conversionId=241193&fmt=gif" />');
        $('body').append(pixel);
        window.setTimeout(function () {
            pixel.remove();
        }, 5000);
    };
    ContentChatComponent.prototype.processLegalDocument = function (done) {
        if (!this.workflowDef || !this.workflowDef.pages || this.workflowDef.pages.length < 1) {
            return done();
        }
        var contractToSign = this.getLegalDocumentPath();
        if (!contractToSign) {
            return done();
        }
        if (this.isContractFormComplete() && this.legalDocumentState === 'next') {
            this.legalDocumentState = 'sign';
        }
        if (this.legalDocumentState === 'display') {
            this.$host.find('.chat-body').scrollTop(0);
            this.legalDocumentState = 'next';
            this.processing = false;
        }
        else if (this.legalDocumentState === 'next') {
            this.highlightNextLegalDocumentInputField();
            this.processing = false;
        }
        else {
            if (typeof this.profileFullName !== 'string') {
                this.profileFullName = '';
            }
            var nameParts = this.profileFullName.trim().split(/\s+/);
            if (nameParts.length < 1) {
                this.processing = false;
                return;
            }
            else if (nameParts.length < 2) {
                nameParts.push('');
            }
            this.authenticatedProfile.firstName = nameParts[0];
            this.authenticatedProfile.lastName = nameParts[nameParts.length - 1];
            this.appService.legalDocumentModel
                .sendNDAConfirmationEmail({
                profile: this.authenticatedProfile,
                instanceId: this.legalDocumentInstance.id,
                author: this.legalDocumentInstance.author,
                access: this.workflowId === 'cano-public-common' ? 'private' : null
            })
                .then(function (result) {
                done();
                return null;
            })
                .catch(function (e) {
                console.error('failed to send NDA confirmation email', e);
                done(e);
            });
        }
    };
    ContentChatComponent.prototype.isPageLink = function () {
        return !!this.getPageLink();
    };
    ContentChatComponent.prototype.goPageLink = function () {
        var link = this.getPageLink();
        var loader = {
            id: link.id,
            force: true
        };
        if (link.hasOwnProperty('page')) {
            loader.page = link.page;
        }
        loader.options = this.workflowOptions || {};
        this.chatService.load(loader);
    };
    ContentChatComponent.prototype.getPageLink = function () {
        if (!this.hasCurrentPage()) {
            return null;
        }
        var currentPage = this.currentPage();
        if (currentPage.link) {
            return currentPage.link;
        }
        return null;
    };
    ContentChatComponent.prototype.isRedirect = function () {
        return !!this.getRedirect();
    };
    ContentChatComponent.prototype.goRedirect = function () {
        var redirect = this.getRedirect();
        if (!redirect || typeof redirect.url !== 'string') {
            this.hide();
            return;
        }
        var url = redirect.url;
        if (typeof redirect.return === 'string') {
            url = url + "?" + redirect.return + "=" + encodeURIComponent(this.location.path(false).replace(/;.*$/, '').replace(/\?.*$/, ''));
        }
        this.router.navigateByUrl(url);
    };
    ContentChatComponent.prototype.getRedirect = function () {
        if (!this.hasCurrentPage()) {
            return null;
        }
        var currentPage = this.currentPage();
        if (currentPage.redirect) {
            return currentPage.redirect;
        }
        return null;
    };
    ContentChatComponent.prototype.continue = function (force) {
        var _this = this;
        if (this.isLastPage()) {
            this.closed();
            return;
        }
        if (!force && this.currentContinueDisabled()) {
            return;
        }
        this.processing = true;
        this.processLegalDocument(function () {
            _this.resetLegalDocuments();
            _this.workflowPageId++;
            _this.chatService.pageAdvance(_this.currentPage());
            if (_this.isRedirect()) {
                _this.goRedirect();
            }
            else if (_this.isPageLink()) {
                _this.goPageLink();
            }
            else if (_this.isLastPage()) {
                _this.chatService.complete(true);
                // } else if (this.hasContract() ) {
                //   this.loadContract();
            }
            _this.processing = false;
        });
    };
    // public loadContract() : void {
    //
    // }
    ContentChatComponent.prototype.lostLoginWindow = function () {
        if (this.loginWaitNetwork) {
            this.socialLogin(this.loginWaitNetwork);
        }
    };
    ContentChatComponent.prototype.isLoggingIn = function () {
        return this.loginWait !== null;
    };
    ContentChatComponent.prototype.clearSocialLogin = function () {
        if (this.loginPopUp) {
            if (this.loginPopUp.close) {
                this.loginPopUp.close();
            }
            this.loginPopUp = null;
        }
        this.loginWaitNetwork = null;
        if (this.loginWait) {
            window.clearInterval(this.loginWait);
            this.loginWait = null;
        }
    };
    ContentChatComponent.prototype.socialLogin = function (network) {
        var _this = this;
        network = network.toLowerCase();
        if (network === 'email') {
            if (!this.workflowOptions || !this.workflowOptions.contract || !this.workflowOptions.contract.id) {
                // if no contract passed to chat window, just login normal
                this.router.navigate([
                    'login/email', {
                        redirect: this.location.path()
                    }
                ]);
                return;
            }
            // contract passed to chat window, so route login through that contract before coming back to the listing
            this.appService.legalDocumentModel
                .get(this.workflowOptions.contract.id)
                .then(function (legalDocument) {
                // route through document signature, and then back to the current page
                var listingUrl = encodeURIComponent(_this.location.path() + ';ndaComplete=true');
                var ndaSignatureUrl = "/sign/" + legalDocument.url + ";redirect=" + listingUrl;
                _this.router.navigate([
                    'login/email', {
                        redirect: ndaSignatureUrl
                    }
                ]);
            })
                .catch(function (e) {
                console.error('failed to get legal document', e);
            });
            return;
        }
        this.clearSocialLogin();
        // The user is about to login, clear the FullStory session
        var FS = window.FS;
        if (FS) {
            FS.clearUserCookie();
        }
        var queryStr = "login-target=" + encodeURIComponent('close');
        var goUrl = this.appService.api.getFullUrl('/auth/social/' + network, queryStr);
        this.loginWaitNetwork = network;
        this.loginPopUp = this.appService.windowOpen({
            url: goUrl,
            target: '_blank',
            features: 'menubar=no,titlebar=no',
            height: 700,
            width: 600
        });
        this.loginWait = window.setInterval(function () {
            if (_this.loginPopUp.closed) {
                window.clearInterval(_this.loginWait);
                _this.loginWait = null;
                _this.appService.profileModel.me()
                    .then(function (profile) {
                    _this.clearSocialLogin();
                    if (profile) {
                        _this.appService.setAuthenticatedProfile(profile);
                        _this.continue(true);
                    }
                })
                    .catch(function (e) {
                    _this.clearSocialLogin();
                });
            }
        }, 500);
    };
    return ContentChatComponent;
}());
export { ContentChatComponent };
