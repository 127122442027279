/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./link.component.css.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../content/loader.bar.component.ngfactory";
import * as i3 from "../../content/loader.bar.component";
import * as i4 from "@angular/forms";
import * as i5 from "@angular/common";
import * as i6 from "./link.component";
import * as i7 from "../../../services/app";
import * as i8 from "@angular/router";
var styles_ViewsLoginLinkComponent = [i0.styles];
var RenderType_ViewsLoginLinkComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_ViewsLoginLinkComponent, data: {} });
export { RenderType_ViewsLoginLinkComponent as RenderType_ViewsLoginLinkComponent };
function View_ViewsLoginLinkComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "div", [["class", "show-incomplete-profile"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Incomplete Profile"]))], null, null); }
function View_ViewsLoginLinkComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 7, "div", [["class", "show-resent"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "div", [["class", "title"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Login Link Re-sent"])), (_l()(), i1.ɵeld(3, 0, null, null, 4, "div", [["class", "description"]], null, null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 1, "p", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["You will receive a new login link in your email shortly."])), (_l()(), i1.ɵeld(6, 0, null, null, 1, "p", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Remember, don't share the secure link in the email with others."]))], null, null); }
function View_ViewsLoginLinkComponent_4(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "button", [], [[2, "active", null]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.doResend() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, ["Resend"]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.isValidResend(); _ck(_v, 0, 0, currVal_0); }); }
function View_ViewsLoginLinkComponent_5(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "div", [["class", "progress-bar"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "div", [["class", "loader-bar"]], null, null, null, i2.View_ContentLoaderBarComponent_0, i2.RenderType_ContentLoaderBarComponent)), i1.ɵdid(2, 49152, null, 0, i3.ContentLoaderBarComponent, [], null, null)], null, null); }
function View_ViewsLoginLinkComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 20, "div", [["class", "show-resend"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "div", [["class", "title"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Link Expired"])), (_l()(), i1.ɵeld(3, 0, null, null, 4, "div", [["class", "description"]], null, null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 1, "p", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Your login link has expired. You can resend a fresh link by confirming your email and clicking below."])), (_l()(), i1.ɵeld(6, 0, null, null, 1, "P", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Links are only intended for use by the owner of the email address entered here."])), (_l()(), i1.ɵeld(8, 0, null, null, 7, "label", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Confirm Your Email "])), (_l()(), i1.ɵeld(10, 0, null, null, 5, "input", [["class", "email"]], [[2, "ng-untouched", null], [2, "ng-touched", null], [2, "ng-pristine", null], [2, "ng-dirty", null], [2, "ng-valid", null], [2, "ng-invalid", null], [2, "ng-pending", null]], [[null, "ngModelChange"], [null, "input"], [null, "blur"], [null, "compositionstart"], [null, "compositionend"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("input" === en)) {
        var pd_0 = (i1.ɵnov(_v, 11)._handleInput($event.target.value) !== false);
        ad = (pd_0 && ad);
    } if (("blur" === en)) {
        var pd_1 = (i1.ɵnov(_v, 11).onTouched() !== false);
        ad = (pd_1 && ad);
    } if (("compositionstart" === en)) {
        var pd_2 = (i1.ɵnov(_v, 11)._compositionStart() !== false);
        ad = (pd_2 && ad);
    } if (("compositionend" === en)) {
        var pd_3 = (i1.ɵnov(_v, 11)._compositionEnd($event.target.value) !== false);
        ad = (pd_3 && ad);
    } if (("ngModelChange" === en)) {
        var pd_4 = ((_co.email = $event) !== false);
        ad = (pd_4 && ad);
    } return ad; }, null, null)), i1.ɵdid(11, 16384, null, 0, i4.DefaultValueAccessor, [i1.Renderer2, i1.ElementRef, [2, i4.COMPOSITION_BUFFER_MODE]], null, null), i1.ɵprd(1024, null, i4.NG_VALUE_ACCESSOR, function (p0_0) { return [p0_0]; }, [i4.DefaultValueAccessor]), i1.ɵdid(13, 671744, null, 0, i4.NgModel, [[8, null], [8, null], [8, null], [6, i4.NG_VALUE_ACCESSOR]], { model: [0, "model"] }, { update: "ngModelChange" }), i1.ɵprd(2048, null, i4.NgControl, null, [i4.NgModel]), i1.ɵdid(15, 16384, null, 0, i4.NgControlStatus, [[4, i4.NgControl]], null, null), (_l()(), i1.ɵeld(16, 0, null, null, 4, "div", [["class", "action-container"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ViewsLoginLinkComponent_4)), i1.ɵdid(18, 16384, null, 0, i5.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ViewsLoginLinkComponent_5)), i1.ɵdid(20, 16384, null, 0, i5.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_7 = _co.email; _ck(_v, 13, 0, currVal_7); var currVal_8 = !_co.resending; _ck(_v, 18, 0, currVal_8); var currVal_9 = _co.resending; _ck(_v, 20, 0, currVal_9); }, function (_ck, _v) { var currVal_0 = i1.ɵnov(_v, 15).ngClassUntouched; var currVal_1 = i1.ɵnov(_v, 15).ngClassTouched; var currVal_2 = i1.ɵnov(_v, 15).ngClassPristine; var currVal_3 = i1.ɵnov(_v, 15).ngClassDirty; var currVal_4 = i1.ɵnov(_v, 15).ngClassValid; var currVal_5 = i1.ɵnov(_v, 15).ngClassInvalid; var currVal_6 = i1.ɵnov(_v, 15).ngClassPending; _ck(_v, 10, 0, currVal_0, currVal_1, currVal_2, currVal_3, currVal_4, currVal_5, currVal_6); }); }
export function View_ViewsLoginLinkComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 0, "div", [["class", "window-toolbar"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 8, "div", [["class", "window-container"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 7, "div", [["class", "window-container-content"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 6, "div", [["class", "window-container-content-container"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ViewsLoginLinkComponent_1)), i1.ɵdid(5, 16384, null, 0, i5.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ViewsLoginLinkComponent_2)), i1.ɵdid(7, 16384, null, 0, i5.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ViewsLoginLinkComponent_3)), i1.ɵdid(9, 16384, null, 0, i5.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.showProfileInComplete(); _ck(_v, 5, 0, currVal_0); var currVal_1 = _co.showResent(); _ck(_v, 7, 0, currVal_1); var currVal_2 = _co.showResend(); _ck(_v, 9, 0, currVal_2); }, null); }
export function View_ViewsLoginLinkComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "div", [["class", "app-view-login-main"]], null, null, null, View_ViewsLoginLinkComponent_0, RenderType_ViewsLoginLinkComponent)), i1.ɵdid(1, 245760, null, 0, i6.ViewsLoginLinkComponent, [i7.AppService, i8.Router, i8.ActivatedRoute], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var ViewsLoginLinkComponentNgFactory = i1.ɵccf(".app-view-login-main", i6.ViewsLoginLinkComponent, View_ViewsLoginLinkComponent_Host_0, {}, {}, []);
export { ViewsLoginLinkComponentNgFactory as ViewsLoginLinkComponentNgFactory };
