import * as tslib_1 from "tslib";
import { ApiService } from 'app/services/api';
// Types
import { GenericModel } from 'app/models/generic';
import { HashTree } from 'app/types/containers';
var ProfileFileUploadUrl = /** @class */ (function () {
    function ProfileFileUploadUrl() {
    }
    return ProfileFileUploadUrl;
}());
export { ProfileFileUploadUrl };
var ProfileFileOwner = /** @class */ (function () {
    function ProfileFileOwner() {
    }
    return ProfileFileOwner;
}());
export { ProfileFileOwner };
var ProfileFile = /** @class */ (function () {
    function ProfileFile() {
    }
    return ProfileFile;
}());
export { ProfileFile };
var ProfileModel = /** @class */ (function (_super) {
    tslib_1.__extends(ProfileModel, _super);
    function ProfileModel(api) {
        var _this = _super.call(this) || this;
        _this.init(api, 'profile', {
            id: null,
            email: null,
            lastName: null,
            firstName: null,
            activityType: null,
            address: {
                street1: null,
                street2: null,
                street3: null,
                city: null,
                state: null,
                zip: null,
                country: null
            },
            favoriteListings: [],
            startedAccess: [],
            liveId: null,
            twitterId: null,
            linkedInId: null,
            facebookId: null,
            googleId: null,
            signatureStyle: null,
            contactMethod: null,
            mobilePhone: null,
            workPhone: null,
            vdr: null,
            company: null,
            title: null,
            linkedinProfileUrl: null,
            twitterProfileUrl: null,
            facebookProfileUrl: null,
            isEmployee: false,
            isFeaturedEmployee: false,
            website: null,
            sortHint: 0,
            headshotURL: null,
            bio: null,
            data: {}
        }, 5000);
        return _this;
    }
    ProfileModel.prototype.getInstance = function (ref) {
        return ref;
    };
    ProfileModel.prototype.login = function (email, password) {
        return this.api.call('authenticate.login', {
            email: email,
            password: password
        });
    };
    ProfileModel.prototype.logout = function () {
        return this.api.call('authenticate.logout', {});
    };
    ProfileModel.prototype.create = function (profile) {
        return this.api.call('profile.create', profile);
    };
    ProfileModel.prototype.upsertByEmail = function (email) {
        return this.api.call('profile.upsertByEmail', { email: email });
    };
    ProfileModel.prototype.me = function () {
        return this.api.call('profile.me', {});
    };
    ProfileModel.prototype.byId = function (id) {
        return this.api.call('profile.byId', { id: id });
    };
    ProfileModel.prototype.list = function () {
        return this.api.call('profile.list', {});
    };
    ProfileModel.prototype.listEmployees = function () {
        return this.api.call('profile.listEmployees', {});
    };
    ProfileModel.prototype.listFeaturedEmployees = function () {
        return this.api.call('profile.listFeaturedEmployees', {});
    };
    ProfileModel.prototype.getUploadUrl = function (params) {
        return this.api.call('profile.getUploadUrl', params);
    };
    ProfileModel.prototype.listUploads = function (params) {
        return this.api.call('profile.listUploads', params);
    };
    ProfileModel.prototype.archiveUpload = function (params) {
        return this.api.call('profile.archiveUpload', params);
    };
    ProfileModel.prototype.loginByToken = function (params) {
        return this.api.call('profile.loginByToken', params);
    };
    ProfileModel.prototype.getByLoginToken = function (params) {
        return this.api.call('profile.getByLoginToken', params);
    };
    ProfileModel.prototype.sendLoginLink = function (params) {
        return this.api.call('profile.sendLoginLink', params);
    };
    return ProfileModel;
}(GenericModel));
export { ProfileModel };
