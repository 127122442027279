
<div class="profile-content" *ngIf="profile">
  <form #profileModel="ngForm" (ngSubmit)="save(profileModel)">
    <div class="row break">
      <div class="col-xs-12 profile-title">My Profile</div>
    </div>
    <div class="row">
      <div class="col-xs-6">
        <input type="text" id="firstName" name="firstName" placeholder="First Name *" [(ngModel)]="profile.firstName" required>
      </div>
      <div class="col-xs-6">
        <input type="text" id="lastName" name="lastName" placeholder="Last Name *" [(ngModel)]="profile.lastName" required>
      </div>
    </div>
    <div class="row">
      <div class="col-xs-6">
        <input type="text" id="company" name="company" placeholder="Company *" [(ngModel)]="profile.company">
      </div>
      <div class="col-xs-6">
        <input type="text" id="title" name="title" placeholder="Title *" [(ngModel)]="profile.title">
      </div>
    </div>
    <div class="row">
      <div class="col-xs-6">
        <input type="text" id="email" name="email" placeholder="Email *" [(ngModel)]="profile.email" required>
      </div>
      <div class="col-xs-6">
        <input type="text" id="phone" name="phone" placeholder="Mobile Phone *" [(ngModel)]="profile.mobilePhone" required>
      </div>
    </div>
    <div class="row break">
      <div class="col-xs-6 address-title">My Address</div>
      <div class="col-xs-6 social-title">My Social Accounts</div>
    </div>
    <div class="row">
      <div class="col-xs-6">
        <div class="row">
          <div class="col-xs-12">
            <input type="text" id="street" name="street" placeholder="Street Address *" [(ngModel)]="profile.address.street1" required>
          </div>
        </div>
        <div class="row">
          <div class="col-xs-12">
            <input type="text" id="city" name="city" placeholder="City *" [(ngModel)]="profile.address.city" required>
          </div>
        </div>
        <div class="row">
          <div class="col-xs-6">
            <input type="text" id="state" name="state" placeholder="State *" [(ngModel)]="profile.address.state" required>
          </div>
          <div class="col-xs-6">
            <input type="text" id="postalCode" name="postalCode" placeholder="Postal Code *" [(ngModel)]="profile.address.zip" required>
          </div>
        </div>
      </div>
      <div class="col-xs-6">
        <div class="row social-link" *ngFor="let network of ['facebook', 'google', 'linkedIn', 'twitter']">
          <div class="col-xs-6">
            <div class="social-icon-container">
              <div class="social-icon" [class]="'social-icon social-icon-' + network.toLowerCase()"></div>
            </div>
          </div>
          <div class="col-xs-6 social-action" *ngIf="profile[network + 'Id']"><span class="social-profile" (click)="addSocialLink(network)">Go to Profile</span>|<span class="remove-social-link" (click)="removeSocialLink(network)">Remote Link</span></div>
          <div class="col-xs-6 social-action" *ngIf="!profile[network + 'Id']"><span class="add-social-link" (click)="addSocialLink(network)">Add Link</span></div>
        </div>
      </div>
    </div>
    <div class="row" *ngIf="errorMessage">
      <div class="col-xs-12">
        <div class="error-message">{{ errorMessage }}</div>
      </div>
    </div>
    <div class="row">
      <div class="col-xs-12">
        <button type="submit" *ngIf="profileModel.form.dirty &amp;&amp; profileModel.form.valid">Save</button>
        <button type="button" *ngIf="profileModel.form.dirty" (click)="cancel()">Cancel</button>
      </div>
    </div>
  </form>
</div>