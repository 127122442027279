import { Component, ElementRef, OnInit, OnDestroy, AfterViewChecked, NgZone } from '@angular/core';
import { DomSanitizer, SafeStyle } from '@angular/platform-browser';
import { Hash } from 'app/types/containers';
import { CDNFile } from 'app/models/cdn';
import { AppService } from 'app/services/app';
import { Router } from '@angular/router';
import * as Clipboard from 'clipboard';

@Component( {
  moduleId: module.id,
  selector: '.app-view-cdn-browser-main',
  templateUrl: 'main.component.html',
  styleUrls: [ 'main.component.css' ]
} )
export class ViewsCdnbrowserMainComponent implements OnInit, OnDestroy, AfterViewChecked {

  public files: CDNFile[] = [];

  public fileUploads: Hash<CDNFile> = {};

  public deletingFile: number = null;

  public $el: JQuery = null;
  public $uploadInput: JQuery = null;
  public $inputHandle: JQuery = null;
  public $fileUploadDrop: JQuery = null;

  public assetFilter: string = '';
  public assetSort: string = 'filename';

  public refreshProcessingCount: number = 0;
  public cacheBust: string = null;

  constructor( public appService: AppService, public el: ElementRef, public sanitizer: DomSanitizer, public zone: NgZone, public router: Router ) {

    this.appService.contentLoading( true );
    this.appService.toolbar.whiteOverContent = false;
    this.appService.toolbar.backgroundColor = null;

    this.$el = $( el.nativeElement );

  }

  public pageIsLoading(): boolean {
    return this.appService.content.loading;
  }

  public uploadFilenames(): string[] {
    return Object.keys( this.fileUploads );
  }

  public resetCacheBust(): void {
    this.cacheBust = `${new Date().getTime()}`;
  }

  public subscriptions: any[] = [];

  public ngOnDestroy(): void {
    this.subscriptions.forEach( ( subscription ) => {
      subscription.unsubscribe();
    } );
  }

  public ngOnInit(): void {

    this.subscriptions.push( this.appService.getAuthenticatedProfile( {
      next: ( profile ) => {

        if ( typeof profile === 'boolean' && !profile ) {
          this.appService.contentLoading( true );
        } else if ( !profile || !profile.isEmployee ) {
          this.router.navigate( [ '/' ] );
        } else {
          this.refresh();
        }

      },
      error: () => {
        this.router.navigate( [ '/' ] );
      },
      complete: () => {
        this.router.navigate( [ '/' ] );
      }
    } ) );

    document.body.scrollTop = 0;

    this.$inputHandle = this.$el.find( 'input.hidden-file-input' );
    this.$inputHandle.change( () => {

      this.deletingFile = null;

      this.handleFileUpload( (<any>this.$inputHandle.get( 0 )).files );

    } );

  }

  public handleFileUpload( files: File[] ): void {

    // console.log( 'handle file uploads', files.length );

    this.resetCacheBust();
    for ( let i = 0; i < Math.min( 10, files.length ); i++ ) {

      let file = files[ i ];

      let filename = file.name.toLowerCase().trim().replace( /[^a-z_\.0-9]+/g, '' );

      if ( filename.length < 1 || file.size > 20000000 ) {
        continue;
      }

      let uploadId = this.addUpload( file );

      // console.log( 'processing file', j, filename );

      window.setTimeout( () => {

        this.appService.cdnModel
          .getSignedUploadRequest( {
            filename: 'assets/' + filename
          } )
          .then( ( response: any ) => {

            // console.log( 'signed upload for file', j, response.requestUrl );

            $.ajax( response.requestUrl, {
              type: 'PUT',
              contentType: response.requestContentType,
              crossDomain: true,
              processData: false,
              data: file
            } ).then( () => {
              this.finishUpload( uploadId );
            } );


          } );

      }, 1000 );

    }

  }

  public finishUpload( uploadId: string ): void {

    this.zone.run( () => {

      let file = this.fileUploads[ uploadId ];
      delete this.fileUploads[ uploadId ];
      file.preview = null;

    } );

  }

  public addUpload( file: File ): string {

    // console.log( 'addUpload', file.name );

    let uploadId = file.name;

    let uploadRecord: CDNFile = {
      filename: file.name,
      contentType: file.type,
      modified: new Date().toISOString(),
      preview: '/assets/images/icons/page.svg',
      progress: -1,
      size: file.size
    };

    this.zone.run( () => {
      this.fileUploads[ uploadId ] = uploadRecord;
      this.addUploadsToFileList();
    } );

    if ( file.type.match( /^image/ ) && file.size < 1000000 ) {

      window.setTimeout( () => {

        let reader = new FileReader();

        reader.onload = ( e: any ) => {
          this.zone.run( () => {
            uploadRecord.preview = e.target.result;
          } );
        };

        reader.readAsDataURL( file );

      }, 100 );

    }

    return uploadId;

  }

  public ngAfterViewChecked(): void {

    // console.log( '$el 2', this.$el.find( '.cdn-browser-upload-input' ).length );

    // if ( this.$el ) {
    // 	console.log( '$el', this.$el.find( '.cdn-browser-upload-input' ) );
    // }

    this.zone.runOutsideAngular( () => {

      let $fileNameContents = this.$el.find( '.cdn-browser-file-contents .filename-content' );

      $fileNameContents.each( ( i, el ) => {

        let $el = $( el );

        if ( $el.hasClass( 'scroll-hover-set' ) || $el.html().trim().length < 1 ) {
          return;
        }
        $el.addClass( 'scroll-hover-set' );

        let $parent = $el.parent();

        let elWidth = $el.get( 0 ).scrollWidth;
        let parentWidth = Math.floor( $parent.width() + 0.5 );
        let scrollOffset = Math.max( 0, Math.floor( elWidth - parentWidth ) );

        if ( scrollOffset < 1 ) {
          return;
        }

        let $scrollTrigger = $parent.parent();
        let nextScrollLeftTrigger = false; // initially text should be scrolled to the left side showing
        let alreadyScrolling = false;
        let scrolling = false;

        let animationControl = ( startScrolling: boolean ) => {

          // console.log( 'animationControl', $el.html(), startScrolling );

          if ( startScrolling ) {
            scrolling = true;
            animate();
          } else {
            scrolling = false;
          }

        };

        let animate = () => {

          if ( alreadyScrolling ) {
            // console.log( 'already scrolling' );
            return;
          }

          alreadyScrolling = true;

          let scrollLeftTo = nextScrollLeftTrigger ? 0 : scrollOffset;

          // console.log( 'animate', $el.html() );
          // console.log( 'scrolling?', scrolling );
          // console.log( 'nextScrollLeftTrigger?', nextScrollLeftTrigger );
          // console.log( 'scrollLeftTrigger?', scrollLeftTrigger );
          // console.log( 'scrollLeftTo?', scrollLeftTo );
          // console.log( "\n\n" );

          nextScrollLeftTrigger = !nextScrollLeftTrigger;

          $el.animate( {
            scrollLeft: scrollLeftTo
          }, {
            duration: 2000,
            easing: 'swing',
            complete: () => {

              // if we are not scrolling, or need to scroll back to left, go again
              if ( scrolling || scrollLeftTo > 0 ) {
                window.setTimeout( () => {
                  alreadyScrolling = false;
                  animate();
                }, 500 );
              } else {
                alreadyScrolling = false;
              }

            }
          } );

        };

        $scrollTrigger.first().bind( 'mouseenter', () => {
          animationControl( true );
        } );
        $scrollTrigger.first().bind( 'mouseleave', () => {
          animationControl( false );
        } );

      } );

    } );


    this.$fileUploadDrop = this.$el.find( '.cdn-browser-upload-input-border' );
    if ( this.$fileUploadDrop.length > 0 && !this.$fileUploadDrop.hasClass( 'file-drop-enabled' ) ) {

      this.$fileUploadDrop.addClass( 'file-drop-enabled' );

      let nested = 0;

      this.$fileUploadDrop.on( 'dragenter', ( e ) => {
        e.stopPropagation();
        e.preventDefault();

        nested++;
        this.$fileUploadDrop.addClass( 'active' );
      } );
      // this.$fileUploadDrop.on( 'dragover', ( e ) => {
      // 	e.stopPropagation();
      // 	e.preventDefault();
      // } );
      this.$fileUploadDrop.on( 'dragleave', ( e ) => {
        e.stopPropagation();
        e.preventDefault();
        nested--;

        if ( nested <= 0 ) {
          nested = 0;
          this.$fileUploadDrop.removeClass( 'active' );
        }

      } );

      this.$fileUploadDrop.on( 'drop', ( e ) => {
        e.stopPropagation();
        e.preventDefault();
        nested = 0;
        this.$fileUploadDrop.removeClass( 'active' );

        this.handleFileUpload( (<any>e.originalEvent).dataTransfer.files );

      } );

    }


    this.$uploadInput = this.$el.find( '.cdn-browser-upload-input' );

    if ( this.$uploadInput.length > 0 && !this.$uploadInput.hasClass( 'cdn-upload-input-setup' ) ) {
      this.$uploadInput.addClass( 'cdn-upload-input-setup' );

      this.$uploadInput.click( () => {
        this.$inputHandle.trigger( 'click' );
      } );

    }

    this.$el
      .find( '.cdn-browser-file-contents .actions span.copy-link' )
      .each( function () {
        let $el = $( this );

        if ( $el.hasClass( 'copy-link-configured' ) ) {
          return;
        }
        $el.addClass( 'copy-link-configured' );

        let clipboard = new Clipboard( $el.get( 0 ) );

        clipboard.on( 'success', () => {

          this.deletingFile = null;
          $el.removeClass( 'glyphicon-copy' );
          $el.addClass( 'glyphicon-ok' );

          window.setTimeout( () => {
            $el.addClass( 'glyphicon-copy' );
            $el.removeClass( 'glyphicon-ok' );
          }, 3000 );

        } );

      } );

  }

  public filesList(): CDNFile[] {

    return this.files
      .filter( ( file: CDNFile ) => {

        if ( typeof this.assetFilter !== 'string' ) {
          return true;
        }

        let assetFilter = this.assetFilter.toLowerCase().trim();

        if ( assetFilter.length < 1 ) {
          return true;
        }

        let filename = typeof file.filename === 'string' ? file.filename.toLowerCase().trim() : '';
        let modified = typeof file.modified === 'string' ? file.modified.toLowerCase().trim() : '';
        let contentType = typeof file.contentType === 'string' ? file.contentType.toLowerCase().replace( /^.*\//, '' ).trim() : '';

        return filename.includes( assetFilter ) || modified.includes( assetFilter ) || contentType.includes( assetFilter );

      } )
      .sort( ( file1: CDNFile, file2: CDNFile ) => {

        let textA = typeof file1[ this.assetSort ] === 'string' ? file1[ this.assetSort ].toUpperCase() : '';
        let textB = typeof file2[ this.assetSort ] === 'string' ? file2[ this.assetSort ].toUpperCase() : '';

        return (textA < textB) ? -1 : (textA > textB) ? 1 : 0;

      } );
  }

  public addUploadsToFileList(): void {

    this.uploadFilenames().forEach( ( filename ) => {

      let found = false;
      this.files.forEach( ( file ) => {
        // console.log( 'file.filename, filename', JSON.stringify( file.filename ), JSON.stringify( filename ), file.filename === filename );
        if ( file.filename === filename ) {
          found = true;
        }
      } );

      if ( !found ) {
        // console.log( 'pushing upload file', filename );
        this.files.push( this.fileUploadByKey( filename ) );
      }

    } );

  }

  public refresh(): void {

    this.refreshProcessingCount++;

    this.appService.cdnModel
      .list( { path: '/assets/' } )
      .then( ( files: CDNFile[] ) => {

        this.resetCacheBust();
        this.refreshProcessingCount--;

        if ( this.refreshProcessingCount > 0 ) {
          // console.log( 'other refreshes running, let last one apply update' );
          return;
        }
        this.refreshProcessingCount = 0;
        // console.log( 'this was the last refresh, apply', new Date().toISOString() );

        this.appService.contentLoading( false );

        if ( Array.isArray( files ) ) {
          this.files = files;
        } else {
          this.files = [];
        }

        this.addUploadsToFileList();

      } );

  }

  public fileIsUploading( file: CDNFile ): boolean {
    return file.hasOwnProperty( 'progress' ) && typeof file.progress === 'number' && file.progress > -1;
  }

  public previewImage( file: CDNFile ): SafeStyle {

    if ( !file || !file.contentType ) {
      return null;
    }

    let url = this.appService.config.cms + `/assets/images/icons/page-inactive.svg`;

    let contentType = file.contentType.toLowerCase();

    if ( contentType.match( /^image\// ) ) {
      if ( typeof file.preview === 'string' && file.preview.length > 0 ) {
        url = file.preview;
      } else {

        url = this.appService.exio.image.getUrl( this.downloadLink( file ), <any>{
          actions: [
            {
              type: 'shrink',
              width: 228
            }
          ],
          format: 'png'
        } );

        file.preview = url;

      }

      // url = `https://rsz.io/${url.replace( /^https?:\/\//, '' )}`;
      // url = `https://images1-focus-opensocial.googleusercontent.com/gadgets/proxy?url=${encodeURIComponent( url )}&container=focus&refresh=10&resize_w=228`;

    }

    return this.sanitizer.bypassSecurityTrustStyle( `url("${url}")` );

  }

  public downloadLink( file: CDNFile, processed?: boolean ): string {
    let url = this.appService.config.cdn + '/assets/' + file.filename;

    // apply default
    if ( processed ) {
      url = this.appService.exio.image.getUrl( url, { format: 'png' } );
    }

    return url;
  }

  public downloadLinkBlur( file: CDNFile, radius?: number ): string {
    let url = this.downloadLink( file );

    if ( typeof radius !== 'number' ) {
      radius = 40;
    }

    radius = <number>Math.max( 0, radius );

    url = this.appService.exio.image.getUrl( url, <any>{
      format: 'jpg',
      actions: [
        {
          type: 'blur',
          radius: radius
        },
        {
          type: 'quality',
          quality: 90
        }
      ]
    } );

    return url;
  }

  public deleteFile( searchFile: CDNFile ): void {

    let removeIndex = null;
    this.files.forEach( ( file, i ) => {
      if ( file.filename === searchFile.filename ) {
        removeIndex = i;
      }
    } );

    if ( removeIndex !== null ) {
      this.files.splice( removeIndex, 1 );
    }

    this.deletingFile = null;

    this.appService.cdnModel.delete( [ searchFile.filename ] );

  }

  public fileUploadByKey( key: string ): CDNFile {

    if ( this.fileUploads.hasOwnProperty( key ) ) {
      return this.fileUploads[ key ];
    }

    return null;

  }

  public fileSize( file: CDNFile ): string {

    let size = file.size;

    if ( typeof size !== 'number' || size < 0 ) {
      return 'Unknown Size';
    }

    let units = 'MB';

    if ( size >= 1000000 ) {
      size = Math.floor( size / 100000 ) / 10;
      units = 'MB';
    } else if ( size >= 1000 ) {
      size = Math.floor( size / 100 ) / 10;
      units = 'KB';
    } else if ( size > 0 ) {
      units = 'Bytes';
    }

    return `${size} ${units}`;

  }

  // public selectFileClick() {
  //
  // 	console.log( 'this.$el', this.$el );
  // 	if ( this.$el ) {
  // 		console.log( "this.$el.find( 'input.hidden-file-input' )", this.$el.find( 'input.hidden-file-input' ) );
  // 		this.$el.find( 'input.hidden-file-input' ).trigger( 'click' );
  // 	}
  // }

}
