import { Injectable } from '@angular/core';
import { ApiService } from 'app/services/api';
import { Article } from './article';
import { Profile } from './profile';

import * as Bluebird from 'bluebird';

// Types
import { GenericModel, GenericMasterSchemaType } from './generic';

export interface AnswerData {
  name: string;
  text: string;
  order: number;
  type: string;
  answer: any;
  prettyFormat?: string;
}

export interface AnswerEmail extends AnswerData {
  type: 'control_email';
  answer: string;
}

export interface AnswerAddress extends AnswerData {
  type: 'control_address';
  prettyFormat: string;
  answer: {
    addr_line1: string;
    addr_line2?: string;
    city: string;
    country: string;
    postal: string;
    state: string;
  };
}

export interface AnswerPhone extends AnswerData {
  type: 'control_phone';
  prettyFormat: string;
  answer: {
    phone: string;
    area: string;
  };
}

export interface AnswerDate extends AnswerData {
  type: 'control_datetime';
  prettyFormat: string;
  answer: {
    year: string;
    day: string;
    month: string;
  };
}

export interface AnswerTime extends AnswerData {
  type: 'control_time';
  prettyFormat: string;
  range: 'Yes' | 'No';
  answer: {
    ampm: string;
    minuteSelect: string;
    hourSelect: string;
  };
}

export interface AnswerShortText extends AnswerData {
  type: 'control_textbox';
  answer: string;
}

export interface AnswerLongText extends AnswerData {
  type: 'control_textarea';
  answer: string;
}

export interface AnswerDropDown extends AnswerData {
  type: 'control_dropdown';
  answer: string;
}

export interface AnswerRadio extends AnswerData {
  type: 'control_radio';
  answer: string;
}

export interface AnswerCheckBox extends AnswerData {
  type: 'control_checkbox';
  answer: string[];
  prettyFormat: string;
}

export interface AnswerNumber extends AnswerData {
  type: 'control_number';
  answer: string;
}

export interface AnswerFileUpload extends AnswerData {
  type: 'control_fileupload';
  answer: string[];
  prettyFormat: string;
}

export interface AnswerSignature extends AnswerData {
  type: 'control_signature';
  answer: string;
}

export type SubmissionAnswer =
  AnswerEmail |
  AnswerAddress |
  AnswerPhone |
  AnswerDate |
  AnswerTime |
  AnswerShortText |
  AnswerLongText |
  AnswerDropDown |
  AnswerRadio |
  AnswerCheckBox |
  AnswerNumber |
  AnswerFileUpload |
  AnswerSignature;

interface SubmissionData {
  answers: SubmissionAnswer[];
  ip: string;
}

export interface QuestionnaireSession {
  _id: string;
  listingId: string;
  userId: string;
  formId: string;
  submissionId: string;
  submissionData: SubmissionData;
  url: string;
  created: string;
  updated: string;
  article?: Article;
}

// Schema Type
export class Questionnaire extends GenericMasterSchemaType {
  title: string;
  url: string;
}

@Injectable()
export class QuestionnaireModel extends GenericModel<Questionnaire> {

  constructor( public api: ApiService ) {
    super();

    this.init( api, 'questionnaire', {
      id: null,
      title: null,
      url: null
    }, 0 );

  }

  public getInstance( ref: any ): Questionnaire {
    return ref;
  }

  public upsertSession( listingId: string ): Bluebird<QuestionnaireSession> {
    return this.api.call( 'questionnaire.upsertSession', { listingId: listingId } );
  }

  public getSessionsByUserId( userId: string ): Bluebird<QuestionnaireSession[]> {
    return this.api.call( 'questionnaire.getSessionsByUserId', { userId: userId } );
  }

  public getSessionBySubmissionId( submissionId: string ): Bluebird<{ session: QuestionnaireSession, article: Article, user: Profile }> {
    return this.api.call( 'questionnaire.getSessionBySubmissionId', { submissionId: submissionId } );
  }

}



