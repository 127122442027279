import { Injectable } from '@angular/core';

import { HashTree } from 'app/types/containers';

export type LogicParamValues = string | number | null | undefined | boolean | {};

export type LogicParams = LogicParamValues | HashTree<LogicParamValues>;

@Injectable()
export class LogicService {

	constructor() {
	}

	private _expandStringValue( data: any, value: string ): LogicParamValues {

		let result: LogicParamValues;

		if ( value.match( /^=/ ) ) {
			result = value.replace( /^=/, '' ); // literal string
		} else {
			result = this.getDataValue( data, value ); // value from data
		}

		return result;

	}

	private _evalArray( data: any, params: Array<LogicParams> ): boolean {

		// array is true until one returns false,
		// this means empty array is always true
		let result = true;

		params.forEach( ( param ) => {

			if ( result === false ) {
				return;
			}

			result = this._eval( data, param );

		} );

		return result;

	}

	private _evalString( data: any, params: string ): boolean {

		let value = this._expandStringValue( data, params );

		if ( typeof value !== 'string' ) {
			return !!value;
		}

		return value.trim().length > 0;

	}

	private _evalObjectKey( data: any, key: string, params: LogicParams ): boolean {

		let logicArgs = params[ key ];

		let result = false;

		switch ( key ) {
			case '$regex':

				if ( !Array.isArray( logicArgs ) || logicArgs.length !== 2 ) {
					break;
				}

				if ( typeof logicArgs[ 1 ] !== 'string' ) {
					break;
				}

				let regExParts = [];
				try {
					// JS doesn't have negative look behind assertions, so fake it by reversing the string and using
					// negative look ahead assertion, then reverse everything again
					let regExRaw = logicArgs[ 0 ].split( '' ).reverse().join( '' );
					regExParts = regExRaw.split( /\/(?!\\)/ ); // don't split on escaped front slashes
					if ( regExParts.length !== 3 ) {
						break;
					}
					regExParts[ 1 ] = regExParts[ 1 ].split( '' ).reverse().join( '' );
					regExParts[ 2 ] = regExParts[ 2 ].split( '' ).reverse().join( '' );
					regExParts = regExParts.reverse();

					// let regExParts = logicArgs[ 0 ].split( /\// );

				} catch ( e ) {
					console.error( '_evalObjectKey Error', e );
					break;
				}

				let regPattern = regExParts[ 1 ];
				let regOpts = regExParts[ 2 ];

				let regEx = null;

				if ( regOpts ) {
					regEx = new RegExp( regPattern, regOpts );
				} else {
					regEx = new RegExp( regPattern );
				}

				let value = this._expandStringValue( data, logicArgs[ 1 ] );

				if ( typeof value !== 'string' ) {
					break;
				}

				result = !!value.match( regEx );

				break;
			case '$nregex':
				result = !this._evalObjectKey( data, '$regex', { $regex: logicArgs } );
				break;
			case '$nor':
				result = !this._evalObjectKey( data, '$or', { $or: logicArgs } );
				break;
			case '$nand':
				result = !this._evalObjectKey( data, '$and', { $and: logicArgs } );
				break;
			case '$not':
				result = !this._eval( data, logicArgs );
				break;
			case '$neq':
				result = !this._evalObjectKey( data, '$eq', { $eq: logicArgs } );
				break;
			case '$lt':
			case '$gt':
			case '$lte':
			case '$gte':
			case '$eq':

				if ( Array.isArray( logicArgs ) && logicArgs.length >= 2 ) {

					let previous = null;
					let first = true;
					result = true;

					logicArgs.forEach( ( logicArg ) => {

						if ( result === false ) {
							return;
						}

						if ( typeof logicArg === 'string' ) {
							logicArg = this._expandStringValue( data, logicArg );
						}

						if ( first ) {
							first = false;
							previous = logicArg;
							return;
						}

						if ( key === '$eq' ) {
							result = previous === logicArg;
						} else if ( key === '$lt' ) {
							result = previous < logicArg;
						} else if ( key === '$lte' ) {
							result = previous <= logicArg;
						} else if ( key === '$gt' ) {
							result = previous > logicArg;
						} else if ( key === '$gte' ) {
							result = previous >= logicArg;
						}

						previous = logicArg;

					} );
				}

				break;
			case '$and':

				if ( Array.isArray( logicArgs ) && logicArgs.length > 0 ) {

					result = true;

					logicArgs.forEach( ( logicArg ) => {

						if ( result === false ) {
							return;
						}

						result = this._eval( data, logicArg );

					} );

				}

				break;
			case '$or':

				if ( Array.isArray( logicArgs ) && logicArgs.length > 0 ) {

					result = false;

					logicArgs.forEach( ( param ) => {

						if ( result === true ) {
							return;
						}

						result = this._eval( data, param );

					} );

				}

				break;
			default:
				result = false;
				break;
		}

		return result;

	}

	private _evalObject( data: any, params: LogicParams ): boolean {

		let result = true;

		Object.keys( params ).forEach( ( key ) => {

			if ( result === false ) {
				return;
			}

			result = this._evalObjectKey( data, key, params );

		} );

		return result;

	}

	private _eval( data: any, params: LogicParams ): boolean {

		if ( params === null ) {
			return false;
		}

		let typeOfParam = typeof params;

		if ( typeOfParam === 'undefined' ) {
			return false;
		}

		if ( typeOfParam === 'boolean' ) {
			return <boolean>params;
		}

		if ( typeOfParam === 'number' ) {
			return params !== 0;
		}

		if ( typeOfParam === 'string' ) {
			return this._evalString( data, <string>params );
		}

		if ( Array.isArray( params ) ) {
			return this._evalArray( data, params );
		}

		if ( typeOfParam === 'object' ) {
			return this._evalObject( data, params );
		}


		return false;
	}

	public eval( data: any, params: LogicParams ): boolean {

		let result = false;

		if ( typeof params === 'boolean' ) {
			result = <boolean>params;
		} else {
			result = this._eval( data, params );
		}

		return result;

	}

	public getDataValue( data: any, target: string ): LogicParamValues {

		if ( !target ) {
			return;
		}

		let targetArr = target.trim().split( '.' );
		let targetObj = data;
		// console.log( 'targetArr', targetArr );
		// console.log( 'targetObj', targetObj );

		if ( targetArr.length < 1 ) {
			return;
		}
		let finalTarget = targetArr.slice( -1 )[ 0 ].trim();

		if ( finalTarget.length < 1 ) {
			return;
		}

		let emptyField = false;
		targetArr.slice( 0, -1 ).forEach( ( field ) => {

			field = field.trim();

			if ( !targetObj || field.length < 1 ) {
				emptyField = true;
				return;
			}

			if ( !targetObj.hasOwnProperty( field ) ) {
				targetObj[ field ] = {};
			}
			targetObj = targetObj[ field ];
		} );

		if ( emptyField || !targetObj ) {
			return;
		}

		return targetObj[ finalTarget ];

	}

	public setDataValue( data: any, target: string, value: LogicParamValues ): void {

		if ( !target ) {
			return;
		}

		let targetArr = target.trim().split( '.' );
		let targetObj = data;

		if ( targetArr.length < 1 ) {
			return;
		}
		let finalTarget = targetArr.slice( -1 )[ 0 ].trim();

		if ( finalTarget.length < 1 ) {
			return;
		}

		let emptyField = false;
		targetArr.slice( 0, -1 ).forEach( ( field ) => {

			field = field.trim();

			if ( !targetObj || field.length < 1 ) {
				emptyField = true;
				return;
			}

			if ( !targetObj.hasOwnProperty( field ) || !targetObj[ field ] || typeof targetObj[ field ] !== 'object' ) {
				targetObj[ field ] = {};
			}
			targetObj = targetObj[ field ];
		} );

		if ( emptyField || !targetObj ) {
			return;
		}

		targetObj[ finalTarget ] = value;

	}

}
