import * as tslib_1 from "tslib";
import { ApiService } from 'app/services/api';
// Types
import { GenericModel, GenericMasterSchemaType, GenericSchemaType } from './generic';
var WorkFlowItemValidationCheck = /** @class */ (function () {
    function WorkFlowItemValidationCheck() {
    }
    return WorkFlowItemValidationCheck;
}());
export { WorkFlowItemValidationCheck };
// Schema Type
var WorkflowItem = /** @class */ (function (_super) {
    tslib_1.__extends(WorkflowItem, _super);
    function WorkflowItem() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    return WorkflowItem;
}(GenericSchemaType));
export { WorkflowItem };
var WorkflowPage = /** @class */ (function (_super) {
    tslib_1.__extends(WorkflowPage, _super);
    function WorkflowPage() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    return WorkflowPage;
}(GenericSchemaType));
export { WorkflowPage };
var WorkflowSection = /** @class */ (function (_super) {
    tslib_1.__extends(WorkflowSection, _super);
    function WorkflowSection() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    return WorkflowSection;
}(GenericSchemaType));
export { WorkflowSection };
var Workflow = /** @class */ (function (_super) {
    tslib_1.__extends(Workflow, _super);
    function Workflow() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    return Workflow;
}(GenericMasterSchemaType));
export { Workflow };
var WorkflowModel = /** @class */ (function (_super) {
    tslib_1.__extends(WorkflowModel, _super);
    function WorkflowModel(api) {
        var _this = _super.call(this) || this;
        _this.init(api, 'workflow', {
            id: null,
            title: null,
            type: null,
            stats: null,
            completionText: null,
            continueText: null,
            completionRedirect: null,
            targetInit: null,
            targets: [],
            sections: []
        }, 60000);
        return _this;
    }
    WorkflowModel.prototype.getInstance = function (ref) {
        return new Workflow(ref);
    };
    return WorkflowModel;
}(GenericModel));
export { WorkflowModel };
