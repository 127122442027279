import {Component, ElementRef, OnInit, AfterViewChecked, NgZone} from '@angular/core';
import {AppService} from 'app/services/app';
import {Router} from '@angular/router';

@Component({
  moduleId: module.id,
  selector: 'app-exio',
  templateUrl: 'window.component.pug',
  styleUrls: ['window.component.less']
})
export class WindowComponent implements OnInit, AfterViewChecked {

  public $el: JQuery = null;

  constructor(public appService: AppService, public el: ElementRef, public router: Router, public zone: NgZone) {

    this.appService.toolbar.whiteOverContent = true;
    this.appService.toolbar.backgroundColor = null;

    this.$el = $(el.nativeElement);

  }

  public ngAfterViewChecked(): void {

    const className = 'background-dark';

    if (this.appService.content.backgroundLight) {
      this.$el.parent().removeClass(className);
    } else {
      this.$el.parent().addClass(className);
    }

    const internalLinks: { $el: JQuery, href: string }[] = [];
    this.$el.find('a').each((i, el) => {

      const $anchor = $(el);

      const href = $anchor.attr('href');

      if ($anchor.attr('exioRouterLink') || typeof href !== 'string' || $anchor.hasClass('seo-link')) {
        return;
      }

      if (href.match(/^\//)) {

        // handle internal links
        internalLinks.push({
          $el: $anchor,
          href: href
        });

      } else if (href.match(/^#/)) {

        // scroll to anchor tags
        $anchor.click((event) => {

          event.preventDefault();
          event.stopPropagation();

          const $el = $(href);

          if ($el.length > 0) {

            const top = $el.offset().top - 100; // offset to account for nav bar

            this.zone.runOutsideAngular(() => {
              requestAnimationFrame(() => {
                $('html, body').stop().animate({scrollTop: top}, 3000);
              });
            });

          }

        });

      } else if (href.match(/^mailto:/)) {

        // all other tags open in special window
        $anchor.click((event) => {
          window.location.href = href;
        });

      } else {

        // all other tags open in special window
        $anchor.click((event) => {

          event.preventDefault();
          event.stopPropagation();

          this.appService.windowOpen({
            url: href,
            target: '_blank',
            features: 'menubar=no,titlebar=no',
            height: 0.8,
            width: 0.8
          });

        });

      }

      // ensure we only process once
      $anchor.attr('exioRouterLink', href);
      // $anchor.attr( 'href', null );

    });

    if (internalLinks.length > 0) {

      window.setTimeout(() => {

        const getQueryVariable = (query) => {

          if (typeof query !== 'string') {
            return null;
          }

          query = query.replace(/^\?/, '').trim();

          if (query.length < 1) {
            return null;
          }

          const vars = query.split('&');
          const params = {};
          for (let i = 0; i < vars.length; i++) {
            const pair = vars[i].split('=');

            params[decodeURIComponent(pair[0])] = decodeURIComponent(pair[1]);

          }

          return params;
        };

        internalLinks.forEach((update) => {


          const parser = document.createElement('a');
          parser.href = update.href;

          const query = getQueryVariable(parser.search);

          const target: any[] = [parser.pathname];

          update.$el.attr('exioRouterLink', update.href);
          update.$el.attr('href', null);

          update.$el.click((e) => {
            e.stopPropagation();
            e.preventDefault();

            window.setTimeout(() => {
              if (query) {
                this.router.navigate(target, {queryParams: query});
              } else {
                this.router.navigate(target);
              }
            });

          });

        });

      });

    }

  }

  public ngOnInit(): void {

    // prevent dragging files from triggering events unexpectedly
    this.$el.on('dragenter', function (e) {
      e.stopPropagation();
      e.preventDefault();
    });
    this.$el.on('dragleave', function (e) {
      e.stopPropagation();
      e.preventDefault();
    });
    this.$el.on('dragover', function (e) {
      e.stopPropagation();
      e.preventDefault();
    });

    // prevent mis-dropped files redirecting the page
    this.$el.on('drop', function (e) {
      e.stopPropagation();
      e.preventDefault();
    });

  }

}
