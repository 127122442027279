import { Component } from '@angular/core';

@Component( {
  moduleId: module.id,
  selector: 'app-content-pending-upgrade-dialogue',
  templateUrl: 'pending.upgrade.dialogue.component.pug',
  styleUrls: [ 'pending.upgrade.dialogue.component.less' ],
} )
export class PendingUpgradeDialogueComponent {
  constructor() {

  }
}
