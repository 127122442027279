import {
  Component,
  ElementRef,
  OnInit,
  NgZone,
  OnDestroy
} from '@angular/core';
import {Router, NavigationEnd, ActivatedRoute} from '@angular/router';
import {Location} from '@angular/common';
import {AppService} from 'app/services/app';
import {ChatService, ChatWorkFlowLoad, ChatWorkFlowPage} from 'app/services/chat';
import {Article, ArticleSection, ArticleFile} from 'app/models/article';
import {Profile} from 'app/models/profile';
import * as Cookie from 'js-cookie';
import {Subscription} from 'rxjs';
import * as Bluebird from 'bluebird';
import {PendingUpgradeDialogueComponent} from './pending.upgrade.dialogue.component';
import {MatDialog} from '@angular/material';

interface TableOfContentsItem {
  nameHTML: string;
  nameRaw: string,
  icon: string,
  classes: string[]
}

interface UpgradeRow {
  name: string;
  public: boolean;
  private: boolean;
  exclusive: boolean;
}

type UpgradeRows = UpgradeRow[];

@Component({
  moduleId: module.id,
  selector: 'app-view-content-main',
  templateUrl: 'main.component.pug',
  styleUrls: ['main.component.less'],
  // changeDetection: ChangeDetectionStrategy.OnPush
})
export class ViewsContentMainComponent implements OnInit, OnDestroy {

  public authenticatedProfile: Profile = null;
  public article: Article;

  public urlParams: any = {};
  public queryParams: any = {};
  public stateParams: any = {};


  public triggerStart: boolean = false;

  public tableScrolled: boolean = false;

  public currentContentsSection: string = null;

  public scrollItemElByName: any = {};
  public scrollItemElByOffset: any = {};
  public scrollItemOffsetByName: any = {};
  public scrollItemIndicatorByName: any = {};
  public scrollNavigationSelects: JQuery = null;

  public $el: JQuery = null;
  public windowResize = null;
  public scrollOffsetCheckInterval = null;
  public windowWidth: number = 0;
  public windowScroll = null;

  public listingTableItems: TableOfContentsItem[] = null;

  public subscriptions: Subscription[] = [];
  public chatPopUpSubscription: Subscription = null;
  // public chatPopUpPageAdvanceSubscription: Subscription = null;

  public contentPath: string = null;

  public lockedCallback: () => void = null;

  public signedNDA: boolean = false;

  public previousAuthenticatedEmail: string = null;

  public upgradeFeatures: UpgradeRows = [];

  constructor(public appService: AppService,
              public activatedRoute: ActivatedRoute,
              public chatService: ChatService,
              public elementRef: ElementRef,
              public router: Router,
              public location: Location,
              public zone: NgZone,
              public matDialog: MatDialog
  ) {

    this.stateParams = {
      chatCode: window.history.state.chatCode || null,
      chatOptions: window.history.state.chatOptions || {}
    };

    this.appService.toolbar.whiteOverContent = false;
    this.appService.toolbar.backgroundColor = null;

    this.appService.contentLoading(true);
    this.appService.titleService.setTitle(`Exio`);

    this.$el = $(this.elementRef.nativeElement);

  }

  ngOnInit(): void {

    const params = (urlParams: any): void => {
      this.urlParams = urlParams;
      this.updateContent();
    };
    this.subscriptions.push(this.activatedRoute.params.subscribe(params));

    const queryParams = (queryParams: any): void => {
      this.queryParams = queryParams;
      this.updateContent();
    };
    this.subscriptions.push(this.activatedRoute.queryParams.subscribe(queryParams));

    let firstProfileEvent = true;
    const profileEvent = (profile?: Profile | boolean) => {

      // profile === null means we haven't made our first check for identity yet
      if (profile === false) {
        // NO-OP
        return;
      }

      // profile === false means we are not logged in
      if (!profile) {

        this.previousAuthenticatedEmail = Cookie.get('previous-authenticated-email');

        if (this.previousAuthenticatedEmail) {
          this.stateParams.chatCode = 'logged-out';
          this.stateParams.chatOptions = {
            email: this.previousAuthenticatedEmail
          };
        }

        if (typeof this.previousAuthenticatedEmail !== 'string' || this.previousAuthenticatedEmail.trim().length < 1) {
          this.previousAuthenticatedEmail = null;
        } else {
          this.previousAuthenticatedEmail = this.previousAuthenticatedEmail.trim();
        }

        this.authenticatedProfile = this.appService.profileModel.newInstance();

        this.updateContent();
        return;
      }

      this.previousAuthenticatedEmail = null;
      this.authenticatedProfile = <Profile>profile;
      Cookie.set('previous-authenticated-email', this.authenticatedProfile.email, {
        expires: 14,
        sameSite: 'strict'
      });

      if (firstProfileEvent) {
        this.setChatWait();
      }
      firstProfileEvent = false;

      this.updateContent();

    };

    this.subscriptions.push(this.appService.getAuthenticatedProfile({
      next: profileEvent,
      error: () => {
        profileEvent(null);
      },
      complete: () => {
        profileEvent(null);
      }
    }));

    document.body.scrollTop = 0;

    this.subscriptions.push(this.router.events.subscribe((event) => {

      if (!(event instanceof NavigationEnd)) {
        return;
      }

      this.updateContent();
    }));

    this.updateContent();

  }

  ngOnDestroy(): void {

    // this.resetScrollHandlers();

    this.clearChatWait();

    this.hideUnlock();

    this.subscriptions.forEach((subscription) => {
      subscription.unsubscribe();
    });

  }

  // public handleChatPage(page: ChatWorkFlowPage): void {
  //
  //   if (!page) {
  //     return;
  //   }
  //
  //   this.setStartAccess();
  //
  // }

  public handleChatComplete(success: boolean): void {

    if (this.chatPopUpSubscription) {
      this.chatPopUpSubscription.unsubscribe();
      this.chatPopUpSubscription = null;
    }

    const workflowId = this.chatService.currentWorkflowId();

    if (success) {

      switch (workflowId) {
        case 'cano-public-common':
        case 'listing-public-common':
        case 'buyer-public-common':

          this.article.bannerLayout = 'listingPendingEmail';
          this.setPendingEmail(); // remember banner layout for next load

          const startedIndex = this.authenticatedProfile.startedAccess.indexOf(this.article.id);

          if (startedIndex < 0) {

            this.authenticatedProfile.startedAccess.push(this.article.id);

            // only save the profile if they are logged in, otherwise skip
            if (this.authenticatedProfile.id) {

              this.appService.profileModel.save(this.authenticatedProfile)
                .then(() => {
                  // NO-OP
                })
                .catch(() => {
                  // NO-OP
                })

            }

          }

          break;
      }


      if (workflowId === 'listing-private-start' ||
        workflowId === 'buyer-private-start') {
        this.checkExclusiveUpgradeWorkflowCallback();
      }

    }

  }

  public showUnlock(): void {

    [
      '.content-render',
      'app-content-render-documents'
    ].forEach((cssClass) => {
      this.$el.find(cssClass).addClass('unlocking-content');
    });

    this.$el.find('.content-unlocking').show();

    $('html body').css({overflow: 'hidden'});

    (<any>this.$el.find('.content-unlocking-container').position)({
      my: 'center center',
      at: 'center center',
      of: $(window)
    });

  }

  public upgradeCallToActionText(): string {
    return 'Start';
  }

  public handleDataLoaded(): void {

    const access = this.getAccessLevel(false);

    if (this.article) {

      if (this.article.type === 'listing' || this.article.type === 'buyer') {

        if (this.article.categories.indexOf('demo') > -1) {
          this.article.bannerLayout = 'listingPrivate';
        } else if (access === 'public') {

          if (this.isPendingPrivateAccess()) {
            this.clearPendingEmail();
            this.article.bannerLayout = 'listingPendingPrivate';
          } else if (this.isPendingEmail()) {
            this.article.bannerLayout = 'listingPendingEmail';
          } else {
            this.article.bannerLayout = 'listingPublic';
          }

        } else if (access === 'private' && this.isPendingExclusiveAccess()) {
          this.clearPendingEmail();
          this.article.bannerLayout = 'listingPendingExclusive';
        } else {
          this.clearPendingEmail();
        }

      }

    }

  }

  public showPopUp(id: string, options = {}): void {

    const workFlowLoad: ChatWorkFlowLoad = {
      id: id,
      force: true,
      options: options
    };

    this.showChat(workFlowLoad);

  }

  public hideUnlock(): void {

    [
      '.content-render',
      'app-content-render-documents'
    ].forEach((cssClass) => {
      this.$el.find(cssClass).removeClass('unlocking-content');
    });

    this.$el.find('.content-unlocking').hide();

    $('html body').css({overflow: 'visible'});

  }

  public clearChatWait(): void {

    if (this.chatPopUpSubscription) {
      this.chatPopUpSubscription.unsubscribe();
      this.chatPopUpSubscription = null;
    }
    // if (this.chatPopUpPageAdvanceSubscription) {
    //   this.chatPopUpPageAdvanceSubscription.unsubscribe();
    //   this.chatPopUpPageAdvanceSubscription = null;
    // }
    this.chatService.unload();

  }

  public getChatWorkFlowLoader(): ChatWorkFlowLoad {

    const workFlowLoad: ChatWorkFlowLoad = {
      id: null,
      replacements: {
        BUYER_FEE: 0
      },
      options: {
        contract: {
          id: this.article.nda,
          articleId: this.article.id,
          accessLevel: 'public'
        }
      }
    };

    if (this.isPendingAccess()) {
      workFlowLoad.id = 'access-pending';
    } else {
      switch (this.getAccessLevel()) {
        case 'public':

          workFlowLoad.id = 'listing-public-common';

          if (this.article.categories.includes('cano_mode')) {
            workFlowLoad.id = 'cano-public-common';
          }

          if (this.article.data.listingBuyerFee > 0) {
            workFlowLoad.replacements.BUYER_FEE = this.article.data.listingBuyerFee;
            workFlowLoad.id = 'listing-buyer-fee-public-common';
          }

          (<any>workFlowLoad.options.contract).accessLevel = 'privatePending';

          break;
        case 'private':

          workFlowLoad.id = 'listing-private-start';

          (<any>workFlowLoad.options.contract).accessLevel = 'exclusivePending';

          break;
        default:
          workFlowLoad.id = null;
          break;
      }
    }

    if (workFlowLoad.id && this.article.type === 'buyer') {
      workFlowLoad.id = workFlowLoad.id.replace(/^listing-/, 'buyer-');
    }

    // workFlowLoad.id = 'access-pending';
    return workFlowLoad.id ? workFlowLoad : null;

  }

  public checkExclusiveUpgradeWorkflowCallback(): void {

    const cleanUrl = () => {
      const url = this.location.path().replace(/;.*$/, '');
      this.location.replaceState(url);
    };

    if (!this.urlParams || this.urlParams.complete !== 'true' || !this.article || !this.authenticatedProfile) {
      cleanUrl();
      return;
    }

    if (!Array.isArray(this.article.accessLevelExclusivePending)) {
      this.article.accessLevelExclusivePending = [];
    }

    if (this.article.accessLevelExclusivePending.indexOf(this.authenticatedProfile.id) < 0) {
      this.article.accessLevelExclusivePending.push(this.authenticatedProfile.id);

      const listingUrl = (<any>window.location).href.replace(/;.*$/, '');

      this.appService.articleModel.addPermission({
        articleId: this.article.id,
        userId: this.authenticatedProfile.id,
        accessLevel: 'exclusivePending'
      });

      this.appService.exio.user.note({
        firstName: this.authenticatedProfile.firstName,
        lastName: this.authenticatedProfile.lastName,
        email: this.authenticatedProfile.email,
        company: this.authenticatedProfile.company,
        subject: 'PopUp Request for Exclusive Access',
        comments: `User requested exclusive access to: ${listingUrl}`,
        to: this.article.adminUsers
      })
        .then(() => {

          // if the request posts, then save the log that the user already made the request
          this.appService.profileModel.save(this.authenticatedProfile);

          return this.appService.analytics.logEvent({
            action: 'upgradeRequestExclusive'
          });

        })
        .catch((e) => {
          console.error('error submitting exclusive level access request', e);
        });

    }

    cleanUrl();

  }

  public checkPrivateUpgradeWorkflowCallback(): void {

    const cleanUrl = () => {
      const url = this.location.path().replace(/;.*$/, '');
      this.location.replaceState(url);
    };

    if (!this.urlParams || this.urlParams.ndaComplete !== 'true' || !this.article || !this.authenticatedProfile) {
      cleanUrl();
      return;
    }

    if (!Array.isArray(this.authenticatedProfile.startedAccess)) {
      this.authenticatedProfile.startedAccess = [];
    }

    if (this.authenticatedProfile.startedAccess.indexOf(this.article.id) < 0) {
      this.authenticatedProfile.startedAccess.push(this.article.id);
    }

  }

  public setChatWait(): void {

    this.checkExclusiveUpgradeWorkflowCallback();

    if (!this.showTableOfContents()) {
      return;
    }

    if (this.isPendingAccess()) {
      this.getChatWorkFlowLoader()
      return;
    }

    this.clearChatWait();

  }

  public updateContent(cacheBust: boolean = false): void {

    const contentPath = this.location.path().replace(/^\/?/, '').replace(/;.*$/, '').replace(/\?.*$/, '');

    const always = (status: number) => {

      if (this.article) {

        if ((this.article.type === 'listing' || this.article.type === 'buyer')) {

          if (this.isAdvisor()) {
            this.clearChatWait();
          } else {
            this.setChatWait();
          }

        } else {
          this.clearChatWait();
        }

        this.appService.titleService.setTitle(`Exio - ${this.article.title || this.article.path}`);

      } else {
        this.clearChatWait();
      }

      this.checkPrivateUpgradeWorkflowCallback();

      this.showAutoChat();

    };

    if (contentPath === this.contentPath && !cacheBust) {
      always(200);
      return;
    }

    this.contentPath = contentPath;

    this.appService.titleService.setTitle(`Exio - loading...`);

    this.appService.contentLoading(true);
    this.article = null;

    this
      .appService
      .articleModel
      .getByPath({
        path: contentPath
      }, cacheBust)
      .then((article: Article) => {

        if (article) {

          this.article = article;

          if (contentPath !== article.path) {
            (<any>window.location).href = article.path;
            return null;
          }

          this.handleDataLoaded();

          this.setUpgradeFeatures();

          if (this.article.type === 'buyer' || this.article.type === 'listing') {
            this.setupLockedClickCallback();
          }

          this.appService.toolbar.whiteOverContent = this.article && this.article.bannerImgUrl && this.article.bannerImgUrl.length > 0;

          if (article.path === '404') {
            this.appService.contentLoading(false, 404);
            always(404);
          } else {
            this.appService.contentLoading(false, 200);
            always(200);
          }

          if (!this.authenticatedProfile || // log anonymous
            (!this.authenticatedProfile.isEmployee && // don't log employees
              article.adminUsers.indexOf(this.authenticatedProfile.id) < 0)) {
            this.appService.analyticsSync();
          }


        } else {
          this.router.navigate(['/404']);
        }

        return null;

      })
      .catch(() => {
        this.appService.contentLoading(false, 500);
        this.setUpgradeFeatures();
        this.article = null;
        always(500);
      });

  }

  public showAutoChat(): void {

    if (this.article && (this.authenticatedProfile || this.authenticatedProfile === null) && this.stateParams.chatCode) {
      const temp = this.stateParams;
      this.stateParams = {};

      if (temp.chatCode === 'thank-you' && this.article.categories.includes('cano_mode')) {
        return;
      }

      this.showPopUp(temp.chatCode, temp.chatOptions || {});
    }

  }

  public setupLockedClickCallback(): void {

    this.lockedCallback = () => {
      this.upgradeNow();
    };

  }

  public tableOfContentsNavHandler(item: TableOfContentsItem): void {

    const $targetEl = this.$el.find('[data-section-name=\'' + item.nameRaw + '\']');

    this.scrollTo($targetEl);

  }

  public scrollTo($el: JQuery, done?: () => void): void {

    if (typeof done !== 'function') {
      done = () => {
        // NO-OP;
      };
    }

    const $body = $('html, body');

    if (!$el || !$el.hasOwnProperty('length') || $el.length < 1) {
      requestAnimationFrame(done);
      return;
    }

    const top = $el.offset().top - 50; // offset to account for nav bar
    const distance = top - $body.scrollTop();

    const speed = 3000; // pixels / second

    const duration = Math.max(1, Math.floor(distance / speed)); // no faster than 1 second to get there

    this.zone.runOutsideAngular(() => {
      requestAnimationFrame(() => {
        $body.stop().animate({scrollTop: top}, duration * 1000, done);
      });
    });

  }

  public getListingTableItems(): TableOfContentsItem[] {

    let items = this.listingTableItems;

    if (items !== null) {
      return items;
    }

    items = this.listingTableItems = [];

    if (!this.article || !Array.isArray(this.article.sections)) {
      return items;
    }

    const foundTableSections = {};

    this.article.sections.forEach((section) => {

      if (!section ||
        typeof section.contentsName !== 'string' || section.contentsName.trim().length < 1 ||
        typeof section.contentsIcon !== 'string' || section.contentsIcon.trim().length < 1 ||
        section.type === 'feature'
      ) {
        return;
      }

      const name = section.contentsName.trim();
      const iconClass = section.contentsIcon.trim();

      if (foundTableSections[name]) {
        return;
      }
      foundTableSections[name] = true;

      const classes: any = {};

      classes[iconClass] = true;

      items.push({
        nameHTML: name.replace(/\n/g, '<br/>'),
        nameRaw: name,
        icon: section.contentsIcon.trim(),
        classes: classes
      });

    });

    if (this.hasDocuments() && this.getDocuments().length > 0) {

      const name = `Diligence\nDocuments`;
      const iconClass = 'folder-gray';

      const classes: any = {};

      classes[iconClass] = true;

      items.push({
        nameHTML: name.replace(/\n/g, '<br/>'),
        nameRaw: name,
        icon: iconClass,
        classes: classes
      });
    }


    return items;

  }

  public showUpgradeFollow(): boolean {
    return !!this.article &&
      !!this.authenticatedProfile &&
      (this.article.type === 'listing' || this.article.type === 'buyer') &&
      !this.isPendingExclusiveAccess() &&
      !this.isPendingPrivateAccess() &&
      this.getAccessLevel() === 'private';
  }

  public followUpgradeCallToActionText(): string {
    if (this.article && this.article.type === 'buyer') {
      if (this.article.categories.includes('cano_mode')) {
        return 'I\'m Interested';
      } else {
        return 'Gauge Buyer Interest';
      }
    } else {
      return 'Access Financials';
    }
  }

  public hasDocuments(): boolean {

    if (!this.article) {
      return false;
    }

    if (this.isDemo() && !this.authenticatedProfile) {
      return false;
    }

    if (!this.article.data) {
      return false;
    }

    if (!Array.isArray(this.article.data.documents)) {
      return false;
    }

    return (<any[]>this.article.data.documents).length > 0;

  }

  public isDemo(): boolean {
    return !!this.article && Array.isArray(this.article.categories) && this.article.categories.indexOf('demo') > -1;
  }

  public getFilteredSections(): ArticleSection[] {
    return this.article.sections;
  }


  public getDocuments(): ArticleFile[] {
    const files: ArticleFile[] = [];

    if (this.article && this.article.data && this.article.data.documents) {
      Object.keys(this.article.data.documents).forEach((documentId: string) => {

        const document: ArticleFile = <ArticleFile>this.article.data.documents[documentId];

        if (document && document.name) {
          files.push(document);
        }

      });
    }

    return files.sort((a, b) => {
      if (a.name < b.name) {
        return -1;
      }
      if (a.name > b.name) {
        return 1;
      }

      return 0;
    });

  }

  public showTableOfContents(): boolean {
    if (!this.article) {
      return false;
    }

    return !this.hasCategory('cano_mode') &&
      !this.hasCategory('coming soon') &&
      (this.article.type === 'listing' || this.article.type === 'buyer');
  }

  public hasCategory(name: string): boolean {

    if (!this.article || !Array.isArray(this.article.categories)) {
      return false;
    }

    return this.article.categories.indexOf(name) > -1;

  }

  public inquireAboutPurchase(): void {

    let signatureName = `Interested Buyer`;

    if (this.authenticatedProfile) {
      if (this.authenticatedProfile.firstName && this.authenticatedProfile.lastName) {
        signatureName = `${this.authenticatedProfile.firstName} ${this.authenticatedProfile.lastName}`;
      } else if (this.authenticatedProfile.firstName) {
        signatureName = `${this.authenticatedProfile.firstName}`;
      } else if (this.authenticatedProfile.lastName) {
        signatureName = `Mr. ${this.authenticatedProfile.lastName}`;
      }
    }

    const listingUrl = (<any>window.location).href;
    const companyName = this.article.data.listingCompanyName || this.article.title;
    const subject = `Purchase Inquiry: ${companyName}`;
    const comments = `Greetings,\n\nI am interested in pursuing a discussion concerning the purchase of ${companyName},` +
      ` the listing for which is located at ${listingUrl}.\n\nPlease contact me with next steps.\n\n` +
      `Cordially,\n${signatureName}`;

    const returnPath = this.router.url;

    const url = `/contact?subject=${encodeURIComponent(subject)}` +
      `&comments=${encodeURIComponent(comments)}\` +
                \`&return=${encodeURIComponent(returnPath)}\` +
                \`&scroll=true`;

    this.router.navigateByUrl(url);

  }

  public contactAdvisor(): void {

    let signatureName = `Business Owner`;

    if (this.authenticatedProfile) {
      if (this.authenticatedProfile.firstName && this.authenticatedProfile.lastName) {
        signatureName = `${this.authenticatedProfile.firstName} ${this.authenticatedProfile.lastName}`;
      } else if (this.authenticatedProfile.firstName) {
        signatureName = `${this.authenticatedProfile.firstName}`;
      } else if (this.authenticatedProfile.lastName) {
        signatureName = `Mr. ${this.authenticatedProfile.lastName}`;
      }
    }

    const listingUrl = (<any>window.location).href;
    const advisorName = this.article.data.listingCompanyName || this.article.title;
    const subject = `Advisor Inquiry: ${advisorName}`;
    const comments = `Greetings,\n\nI am interested in contacting ${advisorName}, the profile for whom is\` +
                \` located at ${listingUrl}.\n\nCordially,\n${signatureName}`;
    const returnPath = this.router.url;

    const url = `/contact?subject=${encodeURIComponent(subject)}` +
      `&comments=${encodeURIComponent(comments)}\` +
                \`&return=${encodeURIComponent(returnPath)}\` +
                \`&scroll=true`;

    this.router.navigateByUrl(url);

  }

  public followListingComingSoon(): void {

    let signatureName = `Interested Buyer`;

    if (this.authenticatedProfile) {
      if (this.authenticatedProfile.firstName && this.authenticatedProfile.lastName) {
        signatureName = `${this.authenticatedProfile.firstName} ${this.authenticatedProfile.lastName}`;
      } else if (this.authenticatedProfile.firstName) {
        signatureName = `${this.authenticatedProfile.firstName}`;
      } else if (this.authenticatedProfile.lastName) {
        signatureName = `Mr. ${this.authenticatedProfile.lastName}`;
      }
    }

    const listingUrl = (<any>window.location).href;
    const companyName = this.article.data.listingCompanyName || this.article.title;
    const subject = `Coming Soon Inquiry: ${companyName}`;
    const comments = `Greetings,\n\nPlease const me know when ${companyName} is available for purchase, \` +
                \`the listing for which is located at ${listingUrl}.\n\nCordially,\n${signatureName}`;
    const returnPath = this.router.url;

    const url = `/contact?subject=${encodeURIComponent(subject)}\` +
                \`&comments=${encodeURIComponent(comments)}\` +
                \`&return=${encodeURIComponent(returnPath)}\` +
                \`&scroll=true`;

    this.router.navigateByUrl(url);

  }

  public inquireAboutSelling(): void {

    let signatureName = `Interested Seller`;

    if (this.authenticatedProfile) {
      if (this.authenticatedProfile.firstName && this.authenticatedProfile.lastName) {
        signatureName = `${this.authenticatedProfile.firstName} ${this.authenticatedProfile.lastName}`;
      } else if (this.authenticatedProfile.firstName) {
        signatureName = `${this.authenticatedProfile.firstName}`;
      } else if (this.authenticatedProfile.lastName) {
        signatureName = `Mr. ${this.authenticatedProfile.lastName}`;
      }
    }

    const listingUrl = (<any>window.location).href;
    const buyerName = this.article.data.listingCompanyName || this.article.title;
    const subject = `Sale Inquiry: ${buyerName}`;
    const comments = `Greetings,\n\nI am interested in pursuing a discussion concerning the sale of my company \` +
                \`to ${buyerName}, the profile for whom is located at ${listingUrl}.\n\nPlease contact me with \` +
                \`next steps.\n\nCordially,\n${signatureName}`;
    const returnPath = this.router.url;

    const url = `/contact?subject=${encodeURIComponent(subject)}\` +
                \`&comments=${encodeURIComponent(comments)}\` +
                \`&return=${encodeURIComponent(returnPath)}\` +
                \`&scroll=true`;

    this.router.navigateByUrl(url);

  }

  public upgradeExclusiveNow(): void {

    if (this.isPendingExclusiveAccess()) {
      this.showPopUp('access-pending-3days');
      return;
    }

    // if default route through workflow questionnaire
    if (typeof this.article.formId !== 'string' || this.article.formId.trim().length < 1 || this.article.formId === 'null') {

      // correct, viewers of a buyer listing get the seller questionnaire, and viewers of a seller listing get the buyer questionnaire
      const url = this.article.type === 'buyer' ?
        '/action/seller-questionnaire' :
        '/action/buyer-questionnaire';

      const listingUrl = this.location.path(false)
        .replace(/;.*$/, '')
        .replace(/\?.*$/, '');

      this.router.navigateByUrl(`${url}?completionRedirect=${encodeURIComponent(listingUrl)}`);

      return;

    }

    this.appService.questionnaireModel.upsertSession(this.article.id)
      .then(questionnaireSession => {

        if (!questionnaireSession || !questionnaireSession.url) {
          console.error('failed to get questionnaire session');
          return;
        }

        // this.router.navigateByUrl( questionnaireSession.url );
        window.location.href = questionnaireSession.url;

      })
      .catch(e => {
        console.error('failed to get questionnaire session');
      });


  }

  public displayPendingDialogue(): void {

    // have to run in zone per this bug: https://github.com/angular/components/issues/7550#issuecomment-345250406
    this.zone.run(() => {

      this.matDialog.open(PendingUpgradeDialogueComponent, {
        width: '300px'
      });

    });


  }

  public upgradeNow(): void {

    if (this.getAccessLevel() === 'private') {
      this.upgradeExclusiveNow();
      return;
    }

    const workflowLoad = this.getChatWorkFlowLoader();

    if (workflowLoad) {
      workflowLoad.force = true;
    }

    this.showChat(workflowLoad);

  }

  public showChat(workflowLoad: ChatWorkFlowLoad): void {

    if (workflowLoad) {

      if (!this.chatPopUpSubscription) {
        this.chatPopUpSubscription = this.chatService.onComplete({
          next: (success) => {

            if (success === null) {
              return;
            }

            this.handleChatComplete(success);

          },
          complete: () => {
            this.handleChatComplete(false);
          },
          error: () => {
            this.handleChatComplete(false);
          }
        });
      }

      this.chatService.load(workflowLoad);

    }

  }

  public setUpgradeFeatures(): void {

    this.upgradeFeatures = [];

    if (!this.article || !Array.isArray(this.article.sections)) {
      return;
    }

    const recorded = {};

    const formatName = (name: string): string => {
      if (typeof name !== 'string') {
        return '';
      }

      return name.trim().replace(/[\n\r\t]+/, ' ').replace(/  +/g, ' ');
    };

    this.article.sections.forEach((section) => {

      // skip headers, they are always public
      if (section.type.match(/^h[0-9]+$/i)) {
        return;
      }

      const name = formatName(section.contentsName);

      if (name.length < 1) {
        return;
      }

      if (recorded[name]) {
        return;
      }
      recorded[name] = true;

      const record = {
        name: name,
        public: false,
        private: false,
        exclusive: false
      };

      switch (section.accessLevel) {
        case 'public':
          record.public = true;
          record.private = true;
          record.exclusive = true;
          break;
        case 'private':
          record.public = false;
          record.private = true;
          record.exclusive = true;
          break;
        case 'exclusive':
          record.public = false;
          record.private = false;
          record.exclusive = true;
          break;
      }

      this.upgradeFeatures.push(record);

    });

  }

  public getUpgradeFeatures(): UpgradeRows {

    return this.upgradeFeatures;

  }

  public isAdvisor(): boolean {

    if (!this.article) {
      return false;
    }

    return this.article.type === 'advisor';

  }

  public isPendingAccess(): boolean {
    return this.isPendingExclusiveAccess() || this.isPendingPrivateAccess();
  }

  public isPendingExclusiveAccess(): boolean {

    if (!this.article || !this.authenticatedProfile || !this.authenticatedProfile.id) {
      return false;
    }

    if (!Array.isArray(this.article.accessLevelExclusivePending)) {
      this.article.accessLevelExclusivePending = [];
    }

    return this.article.accessLevelExclusivePending.indexOf(this.authenticatedProfile.id) > -1;

  }

  public isPendingEmail(): boolean {

    if (!this.article) {
      return false;
    }

    const path = this.encodePendingEmailCookie(this.article.path);

    return Cookie.get(`pending-email-verification-${path}`) === 'true';

  }

  public setPendingEmail(): void {

    if (!this.article) {
      return;
    }

    const path = this.encodePendingEmailCookie(this.article.path);

    Cookie.set(`pending-email-verification-${path}`, 'true', {
      expires: 14,
      sameSite: 'strict'
    });

  }

  public clearPendingEmail(): void {

    if (!this.article) {
      return;
    }

    const path = this.encodePendingEmailCookie(this.article.path);

    Cookie.remove(`pending-email-verification-${path}`);

  }

  public encodePendingEmailCookie(path: string): string {
    return encodeURIComponent(path);
  }

  public isPendingPrivateAccess(): boolean {

    if (!this.article || !this.authenticatedProfile || !this.authenticatedProfile.id) {
      return this.isPendingPrivateAccessAnonymous();
    }

    if (!Array.isArray(this.article.accessLevelPrivatePending)) {
      this.article.accessLevelPrivatePending = [];
    }

    return this.article.accessLevelPrivatePending.indexOf(this.authenticatedProfile.id) > -1;

  }

  public isPendingPrivateAccessAnonymous(): boolean {

    if (!this.article || !this.article.id || !this.authenticatedProfile) {
      return false;
    }

    if (!Array.isArray(this.authenticatedProfile.startedAccess)) {
      this.authenticatedProfile.startedAccess = [];
    }

    return this.authenticatedProfile.startedAccess.indexOf(this.article.id) > -1;

  }

  public isStartedAnyAccess(access: string): boolean {

    const currentAccessLevel = access === 'private' ? 'public' : 'private';

    if (this.authenticatedProfile && Array.isArray(this.authenticatedProfile.startedAccess) &&
      this.article && this.article.id) {
      const started = this.authenticatedProfile.startedAccess.indexOf(this.article.id) > -1;

      return started && this.getAccessLevel() === currentAccessLevel;
    }

    return false;

  }

  public isStartedExclusiveAccess(): boolean {

    if (!this.authenticatedProfile) {
      return false;
    }

    return this.isStartedAnyAccess('exclusive');
  }

  public isStartedPrivateAccess(): boolean {

    if (!this.authenticatedProfile) {
      return false;
    }

    return this.isStartedAnyAccess('private');

  }

  public getAccessLevel(pendingAccessOk: boolean = false): string {

    if (this.isDemo()) {
      return 'exclusive';
    }

    if (!this.authenticatedProfile || !this.authenticatedProfile.id || !this.article) {
      return 'public';
    }


    if (pendingAccessOk) {

      if (Array.isArray(this.article.accessLevelPrivatePending) &&
        this.article.accessLevelPrivatePending.indexOf(this.authenticatedProfile.id) > -1) {
        return 'private';
      }

      if (Array.isArray(this.article.accessLevelExclusivePending) &&
        this.article.accessLevelExclusivePending.indexOf(this.authenticatedProfile.id) > -1) {
        return 'exclusive';
      }

    }

    if (Array.isArray(this.article.accessLevelPrivate) &&
      this.article.accessLevelPrivate.indexOf(this.authenticatedProfile.id) > -1) {
      return 'private';
    }

    if (Array.isArray(this.article.accessLevelExclusive) &&
      this.article.accessLevelExclusive.indexOf(this.authenticatedProfile.id) > -1) {
      return 'exclusive';
    }

    if (Array.isArray(this.article.adminUsers) &&
      this.article.adminUsers.indexOf(this.authenticatedProfile.id) > -1) {
      return 'exclusive';
    }

    if (this.authenticatedProfile.accessLevel) {
      return this.authenticatedProfile.accessLevel;
    }

    return 'public';
  }

  public signupRedirectLinkParams(): { link: string; params: any } {

    let articleUrl = '/' + this.article.path.replace(/^\//, '');

    const result = {
      link: <string>null,
      params: {}
    };

    if (this.authenticatedProfile) {

      result.link = '/action/setup';
      result.params = {
        completionRedirect: articleUrl
      };

    } else {

      articleUrl = `/action/setup?completionRedirect=` + encodeURIComponent(articleUrl);

      Cookie.set('login-target', articleUrl);

      result.link = '/action/signup-buyer';

    }

    return result;

  }

  public privateUpgradeLink(): string {

    return this.signupRedirectLinkParams().link;

  }

  public privateUpgradeLinkParams(): {} {

    return this.signupRedirectLinkParams().params;

  }

  public exclusiveUpgradeLink(): string {

    return '/contact';

  }

  public exclusiveUpgradeLinkParams(): {} {

    const title = this.article && this.article.title ? ' to ' + this.article.title.replace(/[\s\t \n]+/g, ' ') : '';

    return {
      subject: 'Request for Exclusive Access: ' + title,
      comments: 'Hi,\n\nI would like to obtain exclusive access to ' +
        (<any>window.location).href +
        '. Please contact me to let me with next steps.',
      scroll: true
    };

  }

  public showNewsLetter(): boolean {

    if (!this.article || typeof this.article.path !== 'string') {
      return false;
    }

    const url = this.article.path.replace(/^\/*/, '');

    const match = url.match(/^selling$/) ||
      url.match(/^selling\//) ||
      url.match(/^principles\//) ||
      url.match(/^case-study\//) ||
      url.match(/^video-library\//) ||
      url.match(/^learn$/);

    return !!match;

  }

}
