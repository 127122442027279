import * as tslib_1 from "tslib";
import { SimpleChanges, ElementRef, OnInit, OnDestroy, AfterViewChecked, OnChanges } from '@angular/core';
import { AppService } from 'app/services/app';
import { DomSanitizer } from '@angular/platform-browser';
import { Router } from '@angular/router';
var ContentTilesBaseTileComponent = /** @class */ (function () {
    function ContentTilesBaseTileComponent(app, sanitizer, router) {
        var _this = this;
        this.app = app;
        this.sanitizer = sanitizer;
        this.router = router;
        this.authenticatedProfile = null;
        this.article = null;
        this.mode = 'normal';
        this.subscriptions = [];
        this.subscriptions.push(this.app.getAuthenticatedProfile({
            next: function (profile) {
                if (profile && profile.email) {
                    _this.authenticatedProfile = profile;
                }
                else {
                    _this.authenticatedProfile = null;
                }
            },
            error: function () {
                _this.authenticatedProfile = null;
            },
            complete: function () {
                _this.authenticatedProfile = null;
            }
        }));
    }
    ContentTilesBaseTileComponent.prototype.ngOnDestroy = function () {
        this.subscriptions.forEach(function (subscription) {
            subscription.unsubscribe();
        });
    };
    ContentTilesBaseTileComponent.prototype.hasCategory = function (category) {
        if (!this.article || !Array.isArray(this.article.categories)) {
            return false;
        }
        if (typeof category !== 'string') {
            category = '';
        }
        category = category.trim().toLowerCase();
        return this.article.categories.indexOf(category) > -1;
    };
    ContentTilesBaseTileComponent.prototype.showRibbon = function () {
        return this.hasCategory('coming-soon') ||
            this.hasCategory('coming soon') ||
            this.hasCategory('sample');
    };
    ContentTilesBaseTileComponent.prototype.ribbonText = function () {
        if (!this.showRibbon()) {
            return null;
        }
        if (this.hasCategory('sample')) {
            return 'Sample';
        }
        else if (this.hasCategory('coming-soon') ||
            this.hasCategory('coming soon')) {
            return 'Coming Soon';
        }
    };
    ContentTilesBaseTileComponent.prototype.isPlatinum = function () {
        return this.hasCategory('platinum');
    };
    ContentTilesBaseTileComponent.prototype.isComingSoon = function () {
        return this.hasCategory('coming soon') || this.hasCategory('coming-soon');
    };
    ContentTilesBaseTileComponent.prototype.markdownToHtml = function (markdown) {
        return this.app.markdownToHtml(markdown).replace(/^\s*<p>\s*/, '').replace(/\s*<\/p>\s*$/, '');
    };
    ContentTilesBaseTileComponent.prototype.tileImgUrl = function (article, large) {
        var url = large ? (article.tileImgLargeUrl || article.tileImgUrl) : (article.tileImgUrl || article.tileImgLargeUrl);
        if (!url) {
            return null;
        }
        url = this.app.exio.image.getUrl(url, {
            format: 'jpg',
            actions: [
                {
                    type: 'shrink',
                    height: large ? 417 : 212
                },
                {
                    type: 'quality',
                    quality: 100
                }
            ]
        });
        return "url(\"" + url + "\")";
    };
    ContentTilesBaseTileComponent.prototype.bannerImgUrl = function (article) {
        return "url(\"" + article.bannerImgUrl + "\")";
    };
    ContentTilesBaseTileComponent.prototype.iconUrl = function (article) {
        var fragment = null;
        if (article.path) {
            fragment = article.path.replace(/^.*\//, '');
        }
        if (typeof fragment !== 'string') {
            return '';
        }
        fragment = fragment.replace(/-/g, '_');
        return "/assets/images/icons/" + fragment + "_icon.svg";
    };
    ContentTilesBaseTileComponent.prototype.imageUrl = function (url) {
        return "url('" + url + "')";
    };
    ContentTilesBaseTileComponent.prototype.getAction = function () {
        var action = null;
        if (this.article && Array.isArray(this.article.actions) && this.article.actions.length > 0) {
            action = this.article.actions[0];
        }
        return action;
    };
    ContentTilesBaseTileComponent.prototype.actionUrl = function (action, article) {
        return this.pathUrl(action.actionParams && action.actionParams.href || article.path);
    };
    ContentTilesBaseTileComponent.prototype.actionFragment = function (action, article) {
        return this.pathFragment(action.actionParams && action.actionParams.href || article.path);
    };
    ContentTilesBaseTileComponent.prototype.pathUrl = function (url) {
        url = url.replace(/#.*$/, '').trim();
        if (url.length < 1) {
            return null;
        }
        return "/" + url;
    };
    ContentTilesBaseTileComponent.prototype.pathFragment = function (url) {
        var fragment = url.replace(/^[^#]*#?/, '').trim();
        if (fragment.length < 1) {
            return null;
        }
        return fragment;
    };
    ContentTilesBaseTileComponent.prototype.getTitle = function (filter) {
        var _this = this;
        var title = this.article.title;
        var parts = title.split(/\n/);
        if (typeof filter === 'function') {
            parts.forEach(function (part, i) {
                parts[i] = filter.apply(_this, [part]);
            });
        }
        title = parts.join('\n');
        return title.replace(/\n/g, '<br/>');
    };
    ContentTilesBaseTileComponent.prototype.go = function (article) {
        if (article && article.path) {
            this.router.navigate([article.path]);
        }
    };
    ContentTilesBaseTileComponent.prototype.getArticleLink = function (article) {
        var _this = this;
        if (!article) {
            return null;
        }
        if (typeof article.path === 'string' && article.path.trim().length > 0) {
            return article.path.trim();
        }
        if (Array.isArray(article.actions)) {
            var actionLink_1 = null;
            article.actions.forEach(function (action) {
                if (!actionLink_1 && action && action.actionType === 'url') {
                    actionLink_1 = _this.actionUrl(action, article);
                }
            });
            if (actionLink_1) {
                return actionLink_1;
            }
        }
        return null;
    };
    ContentTilesBaseTileComponent.prototype.contentContainerClasses = function () {
        var classes = {};
        if (parseInt(this.article.bannerOpacity, 10) > 0) {
            var opacity = "opacity-" + this.article.bannerOpacity;
            classes[opacity] = true;
        }
        return classes;
    };
    return ContentTilesBaseTileComponent;
}());
var ContentTilesPostTileComponent = /** @class */ (function (_super) {
    tslib_1.__extends(ContentTilesPostTileComponent, _super);
    function ContentTilesPostTileComponent(app, sanitizer, router) {
        var _this = _super.call(this, app, sanitizer, router) || this;
        _this.app = app;
        _this.sanitizer = sanitizer;
        _this.router = router;
        _this.article = null;
        _this.mode = 'normal';
        _this.index = null;
        return _this;
    }
    ContentTilesPostTileComponent.prototype.classes = function () {
        var classes = {
            'is-even': this.isEven(),
            'is-odd': !this.isEven()
        };
        // classes[ 'nth-child-' + this.index ] = true;
        var twoColumnTallChild = ((this.index + 1) % 4) === 0 || (this.index % 4) === 0;
        var twoColumnShortChild = ((this.index + 2) % 4) === 0 || ((this.index + 3) % 4) === 0;
        classes['two-column-tall-child'] = twoColumnTallChild;
        classes['two-column-short-child'] = twoColumnShortChild;
        return classes;
    };
    ContentTilesPostTileComponent.prototype.isEven = function () {
        return this.index !== null && (Math.floor(this.index / 2) * 2) === this.index;
    };
    ContentTilesPostTileComponent.prototype.showTallClass = function () {
        return this.index !== null && (Math.floor(this.index / 2) * 2) === this.index;
    };
    ContentTilesPostTileComponent.prototype.getMarketPlaceTagLine = function () {
        if (this.article && Array.isArray(this.article.categories) && this.article.categories.indexOf('incubator') > -1) {
            return 'Incubator';
        }
        return 'Market Maker';
    };
    ContentTilesPostTileComponent.prototype.showFavoriteToggle = function () {
        return this.authenticatedProfile !== null &&
            typeof this.authenticatedProfile.id === 'string' &&
            this.authenticatedProfile.id.length > 0 &&
            this.article !== null &&
            (this.article.type === 'listing' || this.article.type === 'buyer');
    };
    ContentTilesPostTileComponent.prototype.isFavorite = function () {
        if (!this.authenticatedProfile || !Array.isArray(this.authenticatedProfile.favoriteListings) || !this.article) {
            return false;
        }
        return this.authenticatedProfile.favoriteListings.indexOf(this.article.id) > -1;
    };
    ContentTilesPostTileComponent.prototype.toggleFavorite = function (e) {
        e.preventDefault();
        e.stopPropagation();
        if (!this.authenticatedProfile || !this.article || !this.article.id) {
            return;
        }
        if (!Array.isArray(this.authenticatedProfile.favoriteListings)) {
            this.authenticatedProfile.favoriteListings = [];
        }
        if (this.isFavorite()) {
            var index = this.authenticatedProfile.favoriteListings.indexOf(this.article.id);
            if (index > -1) {
                this.authenticatedProfile.favoriteListings.splice(index, 1);
            }
        }
        else {
            this.authenticatedProfile.favoriteListings.push(this.article.id);
        }
        this.app.profileModel.save(this.authenticatedProfile)
            .then(function () {
            // NO-OP
            // console.log( 'done syncing favorites', this.authenticatedProfile.favoriteListings );
        })
            .catch(function (e) {
            // NO-OP
            console.error('failed to save favorite listing', e);
        });
    };
    return ContentTilesPostTileComponent;
}(ContentTilesBaseTileComponent));
export { ContentTilesPostTileComponent };
var ContentTilesBlogTileComponent = /** @class */ (function (_super) {
    tslib_1.__extends(ContentTilesBlogTileComponent, _super);
    function ContentTilesBlogTileComponent(app, sanitizer, router) {
        var _this = _super.call(this, app, sanitizer, router) || this;
        _this.app = app;
        _this.sanitizer = sanitizer;
        _this.router = router;
        _this.article = null;
        _this.mode = 'normal';
        return _this;
    }
    return ContentTilesBlogTileComponent;
}(ContentTilesBaseTileComponent));
export { ContentTilesBlogTileComponent };
var ContentTilesVideoTileComponent = /** @class */ (function (_super) {
    tslib_1.__extends(ContentTilesVideoTileComponent, _super);
    function ContentTilesVideoTileComponent(app, sanitizer, router) {
        var _this = _super.call(this, app, sanitizer, router) || this;
        _this.app = app;
        _this.sanitizer = sanitizer;
        _this.router = router;
        _this.article = null;
        _this.mode = 'normal';
        return _this;
    }
    return ContentTilesVideoTileComponent;
}(ContentTilesBaseTileComponent));
export { ContentTilesVideoTileComponent };
var ContentTilesServiceTileComponent = /** @class */ (function (_super) {
    tslib_1.__extends(ContentTilesServiceTileComponent, _super);
    function ContentTilesServiceTileComponent(app, sanitizer, router) {
        var _this = _super.call(this, app, sanitizer, router) || this;
        _this.app = app;
        _this.sanitizer = sanitizer;
        _this.router = router;
        _this.article = null;
        _this.mode = 'normal';
        return _this;
    }
    return ContentTilesServiceTileComponent;
}(ContentTilesBaseTileComponent));
export { ContentTilesServiceTileComponent };
var ContentTilesComponent = /** @class */ (function () {
    function ContentTilesComponent(app, el, sanitizer, router) {
        var _this = this;
        this.app = app;
        this.el = el;
        this.sanitizer = sanitizer;
        this.router = router;
        this.authenticatedProfile = null;
        // public articlesShow: ContentType[];
        this.mode = 'normal';
        this.layoutComplete = null;
        this.articlesLoaded = false;
        this.gridImagesLoaded = false;
        this.placeHolderText = 'Please enter your email address';
        this.subscribeEmail = null;
        this.subscribing = false;
        this.subscribeComplete = false;
        this.grid = null;
        this.span2AssignedCount = 0;
        this.span2CheckCount = 0;
        this.span2AssignCache = {};
        this.subscriptions = [];
        this.subscriptions.push(this.app.getAuthenticatedProfile({
            next: function (profile) {
                if (profile && profile.email) {
                    _this.authenticatedProfile = profile;
                    _this.subscribeEmail = profile.email;
                }
                else {
                    _this.authenticatedProfile = null;
                    _this.subscribeEmail = null;
                }
            },
            error: function () {
                _this.authenticatedProfile = null;
                _this.subscribeEmail = null;
            },
            complete: function () {
                _this.authenticatedProfile = null;
                _this.subscribeEmail = null;
            }
        }));
    }
    ContentTilesComponent.prototype.ngOnInit = function () {
        // clone
        // this.articlesShow = this.articles.slice( 0 );
    };
    ContentTilesComponent.prototype.ngOnChanges = function (changes) {
        // this.articlesLoaded = false;
        // this.gridImagesLoaded = false;
        if (this.grid) {
            this.grid.masonry('layout');
        }
    };
    ContentTilesComponent.prototype.ngOnDestroy = function () {
        this.subscriptions.forEach(function (subscription) {
            subscription.unsubscribe();
        });
    };
    ContentTilesComponent.prototype.signUpSeller = function () {
        this.router.navigate(['/selling']);
    };
    ContentTilesComponent.prototype.signUpBuyer = function () {
        this.router.navigate(['/buying']);
    };
    ContentTilesComponent.prototype.getRandomIntInclusive = function (min, max) {
        min = Math.ceil(min);
        max = Math.floor(max);
        return Math.floor(Math.random() * (max - min + 1)) + min;
    };
    ContentTilesComponent.prototype.ngAfterViewChecked = function () {
        var _this = this;
        if (this.articles.length < 1) {
            return;
        }
        if (!this.gridImagesLoaded) {
            window.setTimeout(function () {
                var $grid = $(_this.el.nativeElement).find('.grid');
                if ($grid.find('.grid-item').length > 0) {
                    _this.gridImagesLoaded = true;
                    _this.grid = $grid.masonry({
                        columnWidth: 300,
                        horizontalOrder: true,
                        itemSelector: '.grid-item',
                        fitWidth: false,
                        gutter: 30,
                        transitionDuration: '0.5s'
                    });
                    if (_this.layoutComplete) {
                        _this.grid.on('layoutComplete', _this.layoutComplete);
                    }
                    window.setTimeout(function () {
                        if (_this.grid) {
                            _this.grid.masonry('layout');
                        }
                    }, 500);
                    $grid.imagesLoaded({ background: true }).always(function () {
                        window.setTimeout(function () {
                            if (_this.grid) {
                                _this.grid.masonry('layout');
                            }
                        }, 500);
                    });
                }
            }, 100);
        }
        if (!this.articlesLoaded) {
            this.articlesLoaded = true;
            window.setTimeout(function () {
                _this.grid.masonry('layout');
            }, 500);
            // window.setTimeout( () => {
            //
            // 	if ( this.disableAd || true ) {
            //
            // 		if ( this.grid ) {
            //
            // 			window.setTimeout( () => {
            // 				this.grid.masonry( 'layout' );
            // 			}, 500 );
            // 		}
            //
            // 	} else {
            //
            // 		let subscribe: Article = {
            // 			type: 'subscribe'
            // 		};
            //
            // 		if ( this.articles.length >= 5 ) {
            //
            // 			let low = 4;
            // 			let high = Math.min( this.articles.length, 6 );
            //
            // 			let insertSpot = this.getRandomIntInclusive( low, high );
            //
            // 			this.articles.splice( insertSpot, 0, subscribe );
            //
            // 		} else if ( this.articles.length > 0 ) {
            // 			this.articles.push( subscribe );
            // 		}
            //
            // 		if ( this.grid ) {
            // 			window.setTimeout( () => {
            // 				console.log( 'ad layout!' );
            // 				this.grid.masonry( 'layout' );
            // 			}, 5000 );
            // 		}
            //
            // 	}
            // } );
        }
    };
    ContentTilesComponent.prototype.subscribe = function () {
        var _this = this;
        this.subscribing = true;
        window.setTimeout(function () {
            _this.subscribing = false;
            _this.subscribeComplete = true;
            window.setTimeout(function () {
                _this.subscribeEmail = null;
                _this.subscribeComplete = false;
            }, 3000);
        }, 3000);
        return false;
    };
    return ContentTilesComponent;
}());
export { ContentTilesComponent };
