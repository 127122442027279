import { Component, OnInit, OnDestroy, ElementRef } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';

import { AppService } from 'app/services/app';
import { ArticleFile } from 'app/models/article';

type RouteParams = { articlePath: string, documentName: string };

@Component( {
  moduleId: module.id,
  selector: '.app-view-listing-document',
  templateUrl: 'document.component.pug',
  styleUrls: [ 'document.component.less' ]
} )
export class ViewsListingDocumentComponent implements OnInit, OnDestroy {

  public $el: JQuery = null;

  public document: ArticleFile = null;
  public documentLink: SafeResourceUrl = null;
  public subscriptions: any[] = [];

  constructor( public appService: AppService, public route: ActivatedRoute, public sanitizer: DomSanitizer, public elementRef: ElementRef ) {

    this.appService.contentLoading( true );
    this.appService.toolbar.disabled = true;
    this.appService.footer.hide = true;
    this.appService.toolbar.backgroundColor = null;
    this.appService.titleService.setTitle( 'Exio' );
    this.$el = $( this.elementRef.nativeElement );

    // this.$el.find( 'iframe' ).style( { 'margin-top': '-62px' } );

  }

  public ngOnDestroy(): void {
    this.subscriptions.forEach( ( subscription ) => {
      subscription.unsubscribe();
    } );
  }

  ngOnInit(): void {

    this.subscriptions.push( this.route.params.subscribe( ( params: RouteParams ) => {
      // console.log( 'params', params );
      this.init( params );
    } ) );

  }

  public init( params: RouteParams ): void {

    // console.log( 'init', params );

    // $( 'html' ).height( '100%' ).width( '100%' ).css( { overflow: 'hidden' } );
    // $( 'body' ).height( '100%' ).width( '100%' ).css( { overflow: 'hidden' } );

    this.appService
      .articleModel
      .documentByNameAndPath( {
        name: params.documentName.toLowerCase(),
        path: params.articlePath.toLowerCase()
      } )
      .then( ( document: ArticleFile ) => {

        window.location.href = document.webViewLink;

      } )
      .catch( ( err ) => {
        this.appService.contentLoading( false );
        console.error( 'err loading document: ', err );
        this.document = null;
        this.documentLink = null;
        // window.close();
      } );

  }


}
