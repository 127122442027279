import {Component, OnDestroy, OnInit} from '@angular/core';
import {AppService} from 'app/services/app';
import {Profile} from 'app/models/profile';
import {HttpClient, HttpParams} from '@angular/common/http';

interface MailChimpResponse {
  result: string;
  msg: string;
}

@Component({
  moduleId: module.id,
  selector: 'app-forms-newsletter',
  templateUrl: 'newsletter.component.pug',
  styleUrls: ['newsletter.component.less']
})
export class NewsletterFormsComponent implements OnInit, OnDestroy {

  public subscribeComplete: boolean = false;
  public subscribing: boolean = false;

  public subscribeEmail: string = null;

  public subscriptions: any[] = [];

  public mailChimpAction = 'https://goexio.us12.list-manage.com/subscribe/post-json?u=50d0d57739f2b02c497b3533e&id=6b5cc75e05&';
  public mailChimpKey = 'b_50d0d57739f2b02c497b3533e_6b5cc75e05';

  constructor(public app: AppService, public http: HttpClient) {
  }

  public ngOnDestroy(): void {
    this.subscriptions.forEach((subscription) => {
      subscription.unsubscribe();
    });
  }

  public ngOnInit(): void {

    this.subscriptions.push(this.app.getAuthenticatedProfile({
      next: (profile: Profile) => {
        this.subscribeEmail = profile && profile.email ? profile.email : null;
      },
      error: () => {
        this.subscribeEmail = null;
      },
      complete: () => {
        this.subscribeEmail = null;
      }
    }));

  }

  public validEmail(): boolean {
    return typeof this.subscribeEmail === 'string' &&
      !!this.subscribeEmail.match(/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/);
  }

  public subscribe(): void {
    if (!this.validEmail()) {
      return;
    }

    this.subscribing = true;

    const params = new HttpParams()
      .set('EMAIL', this.subscribeEmail)
      .set(this.mailChimpKey, '');

    this.http.jsonp<MailChimpResponse>(this.mailChimpAction + params.toString(), 'c')
      .toPromise()
      .then((response: MailChimpResponse) => {
        this.subscribing = false;
        this.subscribeComplete = true;
      })
      .catch(e => {
        this.subscribing = false;
      });


  }

}
