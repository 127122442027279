import {Component, OnInit, OnDestroy} from '@angular/core';
import {Router, ActivatedRoute} from '@angular/router';

import {AppService} from 'app/services/app';
import {
  LegalDocument,
  LegalDocumentInstance,
  LegalDocumentInstanceSigner,
  LegalDocumentSection,
  SearchResponse
} from 'app/models/legal.document';
import {Profile} from 'app/models/profile';
import {Hash} from 'app/types/containers';

@Component({
  moduleId: module.id,
  selector: 'app-view-contracts-instance-confirm-email-main',
  templateUrl: 'main.component.pug',
  styleUrls: ['main.component.less']
})
export class ViewContractsInstanceConfirmEmailMainComponent implements OnDestroy {

  public removeInstanceCheck: number = null;
  public document: LegalDocument = null;
  public instances: LegalDocumentInstance[] = [];

  public authenticatedProfile: Profile = null;

  public profiles: Hash<Profile> = {};

  public instancesLoading: boolean = true;

  // see if a delete was requested for a instance
  public deletingDocument: number = null;

  public subscriptions: any[] = [];

  constructor(private appService: AppService, public router: Router, public route: ActivatedRoute) {
    this.instancesLoading = true;
    this.appService.contentLoading(true);
    this.appService.toolbar.whiteOverContent = false;
    this.appService.toolbar.backgroundColor = null;
    this.appService.footer.showMenu = false;

    const profileEvent = (profile?: Profile) => {

      if (profile === null) {
        this.router.navigate(['/404']);
        return;
      } else if (profile) {

        this.authenticatedProfile = profile;

        this.subscriptions.push(this.route.params.subscribe((params: { instanceId: string, authorId: string }) => {
          this._load(params);
        }));

      }

    };

    this.subscriptions.push(this.appService.getAuthenticatedProfile({
      next: profileEvent,
      error: () => {
        profileEvent(null);
      },
      complete: () => {
        profileEvent(null);
      }
    }));

  }

  public ngOnDestroy(): void {
    this.subscriptions.forEach((subscription) => {
      subscription.unsubscribe();
    });
  }

  private _load(params: { instanceId: string, authorId: string }): void {

    this.appService
      .legalDocumentModel
      .confirmEmail(params)
      .then(result => {

        this.router.navigate([result.url], {state: {chatCode: result.code || null}});

        return null;
      })
      .catch(e => {

        console.error('failed to confirm email', e);
        this.router.navigate(['/404'])

      });

  }

}
