
<h4>Image Content</h4><br><br>
<div class="select-access-level">Access Level</div>
<div class="input-type-select-access-level" [(accessLevel)]="section.accessLevel"></div><br><br>
<div class="input-title">Contents Name</div>
<textarea class="contents-entry-name" placeholder="A short name" [(ngModel)]="section.contentsName"></textarea><br><br>
<div class="input-title">Contents Icon</div>
<div class="input-type-select-icon" [(icon)]="section.contentsIcon"></div><br><br>
<div class="select-layout">Layout</div>
<select [(ngModel)]="section.content.layout">
  <option value="carousel">Carousel</option>
  <option value="1by2">1 by 2</option>
  <option value="1by2-reverse">1 by 2 Reverse</option>
  <option value="3across">3 Across</option>
  <option value="2/3split">2/3 Split</option>
  <option value="2/3split-reverse">2/3 Split Reverse</option>
</select><br><br>
<div class="select-padding-top">Padding Top</div>
<input type="text" placeholder="Enter an integer" [(ngModel)]="section.contentPaddingTop"><br>
<div class="select-padding-bottom">Padding Bottom</div>
<input type="text" placeholder="Enter an integer" [(ngModel)]="section.contentPaddingBottom"><br><br>
<div class="select-background-color">Color</div>
<div class="select-background-color-block" *ngFor="let color of app.colors;" [style.background-color]="color" (click)="section.contentBackgroundColor = color"></div><a class="reset-background active" (click)="section.contentBackgroundColor = null">Reset</a><br><br>