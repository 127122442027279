
<div class="window-toolbar"></div>
<div class="window-container">
  <div class="window-container-content">
    <div class="window-container-content-container">
      <div class="show-incomplete-profile" *ngIf="showProfileInComplete()">Incomplete Profile</div>
      <div class="show-resent" *ngIf="showResent()">
        <div class="title">Login Link Re-sent</div>
        <div class="description">
          <p>You will receive a new login link in your email shortly.</p>
          <p>Remember, don't share the secure link in the email with others.</p>
        </div>
      </div>
      <div class="show-resend" *ngIf="showResend()">
        <div class="title">Link Expired</div>
        <div class="description">
          <p>Your login link has expired. You can resend a fresh link by confirming your email and clicking below.</p>
          <P>Links are only intended for use by the owner of the email address entered here.</P>
        </div>
        <label>Confirm Your Email
          <input class="email" [(ngModel)]="email">
        </label>
        <div class="action-container">
          <button *ngIf="!resending" (click)="doResend()" [class.active]="isValidResend()">Resend</button>
          <div class="progress-bar" *ngIf="resending">
            <div class="loader-bar"></div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>