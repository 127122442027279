import { Component, OnInit, OnDestroy } from '@angular/core';
import { Profile } from 'app/models/profile';
import { Router } from '@angular/router';
import { AppService } from 'app/services/app';

@Component( {
  moduleId: module.id,
  selector: 'app-view-profile-main',
  templateUrl: 'main.component.html',
  styleUrls: [ 'main.component.css' ]
} )
export class ViewsProfileMainComponent implements OnInit, OnDestroy {

  public profile: Profile = null;
  public errorMessage: string = null;

  public subscriptions: any[] = [];

  constructor( public appService: AppService, public router: Router ) {

    this.appService.toolbar.whiteOverContent = false;
    this.appService.toolbar.backgroundColor = null;

  }

  public ngOnDestroy(): void {
    this.subscriptions.forEach( ( subscription ) => {
      subscription.unsubscribe();
    } );
  }

  ngOnInit(): void {

    this.subscriptions.push( this.appService.getAuthenticatedProfile( {
      next: ( profile ) => {

        this.appService.contentLoading( false );

        if ( typeof profile === 'boolean' && !profile ) {
          this.appService.contentLoading( true );
        } else if ( !profile ) {
          this.router.navigate( [ '/' ] );
        } else {
          this.profile = profile;
          profile.googleId = '3';
          profile.twitterId = '3';
        }

      },
      error: () => {
        this.router.navigate( [ '/' ] );
        this.profile = null;
      },
      complete: () => {
        this.router.navigate( [ '/' ] );
        this.profile = null;
      }
    } ) );

  }

  cancel(): void {

  }

}
