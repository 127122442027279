
<div class="my-legal-documents-container">
  <div class="my-legal-documents-row my-legal-documents-header">
    <div class="my-legal-documents-field left col-xs-6 col-md-10">Name</div>
    <div class="my-legal-documents-field right col-xs-6 col-md-2">Status</div>
  </div>
  <div class="my-legal-documents-row" *ngFor="let record of records">
    <div class="my-legal-documents-field left col-xs-6 col-md-10">
      <div class="view-document" (click)="viewDocument(record)">{{ record.document.name }}</div>
    </div>
    <div class="my-legal-documents-field right col-xs-6 col-md-2" *ngIf="record.signed">
      <div class="component-icon-container">
        <div class="component-icon check active"></div>
      </div>
    </div>
    <div class="my-legal-documents-field right col-xs-6 col-md-2" *ngIf="!record.signed">
      <div class="sign-document" (click)="signDocument(record)">Sign Now</div>
    </div>
  </div>
</div>