import {ChatWorkFlow} from 'app/services/chat'

export const chatDefThankYou: ChatWorkFlow = {
  authorEmail: 'doug.miller@goexio.com',
  pages: [
    {
      title: 'Thank You!',
      items: [
        {
          type: 'paragraph',
          params: {
            content: 'Your request is now pending.'
          }
        },
        {
          type: 'paragraph',
          params: {
            content: 'Allow 1 business day for review of your request for private access.'
          }
        },
        {
          type: 'paragraph',
          params: {
            content: 'The Exio Team'
          }
        }
      ],
      continueText: 'OK',
      continueDisabled: false
    }
  ]
};
