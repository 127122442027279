
<div class="back-mobile" routerLink="/leadership"><&nbsp;&nbsp;Back</div>
<div class="content" *ngIf="profile">
  <div class="content-container">
    <div class="profile-img-container">
      <div class="profile-img" *ngIf="profile.headshotURL">
        <div class="profile-img-background" [style.background-image]="sanitizer.bypassSecurityTrustStyle(imgUrl(profile.headshotURL))"></div>
      </div>
    </div>
    <div class="content-text">
      <div class="name" *ngIf="profile.firstName || profile.lastName">{{profile.firstName}} {{profile.lastName}}</div>
      <div class="title" *ngIf="profile.title">{{ profile.title }}</div>
      <div class="bio" *ngIf="profile.bio" [innerHTML]="markdownToHtml(profile.bio)"></div>
    </div>
    <div class="controls-container">
      <div class="controls">
        <div class="back" routerLink="/leadership" [class.no-next]="!profileNext"><&nbsp;&nbsp;Back</div>
        <div class="next" *ngIf="profileNext" [routerLink]="nextLink(profileNext)">Next - {{ profileNext.firstName }} {{ profileNext.lastName }}</div><a class="seo-link" *ngIf="profileNext" [href]="'/learn/leadership/' + profileNext.id">{{profile.firstName}} {{profile.lastName}}</a>
      </div>
    </div>
  </div>
</div>