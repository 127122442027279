import {AppService} from 'app/services/app';
import {ArticleSection} from 'app/models/article';
import {Component, Input, OnInit, OnDestroy, ElementRef, NgZone, ChangeDetectorRef} from '@angular/core';
import {Profile} from 'app/models/profile';


@Component({
  moduleId: module.id,
  selector: 'app-content-render-sections',
  templateUrl: 'sections.component.pug',
  styleUrls: ['sections.component.less']
})
export class ContentRenderSectionsComponent implements OnInit, OnDestroy {

  @Input()
  public fixedMenu: boolean = false;

  @Input()
  public sections: ArticleSection[] = [];

  @Input()
  public mode: 'normal' | 'edit' = 'normal';

  @Input()
  public exclusiveUserIds: string[] = [];

  @Input()
  public parentChangeDetection: () => void = null;

  @Input()
  public lockedCallback: () => void = null;

  @Input()
  public accessLevel: 'public' | 'private' | 'exclusive' = 'public';

  public dragActive: boolean = false;

  public hiddenSectionsCache: number[][][] = [];

  public authenticatedUser: Profile = null;

  public $el: JQuery = null;


  public bannerPositionValues: string[] = [
    'top left',
    'top center',
    'top right',
    'center left',
    'center center',
    'center right',
    'bottom left',
    'bottom center',
    'bottom right'
  ];

  constructor(public appService: AppService, public el: ElementRef, public zone: NgZone, public cdRef: ChangeDetectorRef) {

    if (!this.sections) {
      this.sections = [];
    }

    this.$el = $(this.el.nativeElement);

    // let MutationObserver: (watch: ) => void = (<any>window).MutationObserver || (<any>window).WebKitMutationObserver;

    let observer = new MutationObserver(() => {
      this.watchDom();
    });

    observer.observe(this.el.nativeElement, {
      childList: true,
      attributes: false,
      characterData: false,
      subtree: true,
      attributeOldValue: false,
      characterDataOldValue: false
    });

  }

  public onLockedClick(): void {

    if (typeof this.lockedCallback === 'function') {
      this.lockedCallback();
    }

  }

  public showUpgradeAccess(): void {

    let $el = $('.content-listing-upgrade-access');

    $('html, body').scrollTop($el.offset().top - 100);

  }

  public watchDom(): void {

    if (this.mode !== 'edit') {
      return;
    }

    let self = this;

    let $editModeContainer: JQuery = this.$el.find('.sections-container.edit-mode');

    let $editSectionDropTargets: JQuery = $editModeContainer.find('.section-drop-target:not(.ui-droppable)');

    if ($editSectionDropTargets.length > 0) {

      self.zone.runOutsideAngular(() => {

        $editSectionDropTargets
          .droppable({
            accept: '.editor-component.content-type',
            tolerance: 'pointer'
          })
          .on('dropdeactivate', function () {
            self.zone.run(() => {
              self.dragActive = false;
            });
          })
          .on('dropactivate', function (event, ui) {
            self.zone.run(() => {
              self.dragActive = true;

              if (!event || !event.target) {
                // self.changeDetector.detectChanges();
                return;
              }

              let target = $(event.target).find('span');

              let contentName = ui.draggable.attr('data-component-name') || null;

              if (contentName) {
                target.html(`Drop ${contentName} Here`);
              } else {
                target.html('Drop New Content');
              }

            });

          })
          .on('drop', function (event, ui) {

            if (!event || !event.target) {
              return;
            }

            let target = $(event.target);

            let targetSectionId = target.parent().attr('data-section-id') || null;
            let targetPosition = target.attr('data-target-position') || null;
            let contentType = ui.draggable.attr('data-component-type') || null;

            if (!targetSectionId || !targetPosition || !contentType) {
              return;
            }

            let targetSectionIdValue = parseInt(<string>targetSectionId, 10);

            if (targetPosition === 'bottom') {
              targetSectionIdValue++;
            } else if (targetPosition !== 'top') {
              return;
            }

            if (targetSectionIdValue < 0) {
              targetSectionIdValue = 0;
            } else if (targetSectionIdValue > self.sections.length) {
              targetSectionIdValue = self.sections.length;
            }

            let defaultContent: string | any[] | {} = '';
            let defaultContentHorizontalAlign = 'left';

            switch (contentType) {
              case 'heading':
                contentType = 'h1';
                break;
              case 'grid':
                defaultContent = {
                  rows: 2,
                  columns: 3,
                  fields: []
                };
                defaultContentHorizontalAlign = 'center';
                break;
              case 'section':
              case 'carousel':
              case 'tiles':
                defaultContent = [];
                break;
              case 'swot':
                defaultContent = {
                  strength: '',
                  weakness: '',
                  opportunity: '',
                  threats: ''
                };
                break;
              case 'exio-score':
                defaultContent = {
                  metrics: []
                };
                break;
            }

            self.zone.run(() => {

              let newSection = new ArticleSection({
                type: contentType,
                accessLevel: 'public',
                content: defaultContent,
                contentHorizontalAlign: defaultContentHorizontalAlign
              });

              self.sections.splice(targetSectionIdValue, 0, newSection);

              self.zone.run(() => {
                self.cdRef.markForCheck();
                window.setTimeout(() => {
                  self.watchDom();
                })
              });
            });
          });

      });

    }

    // let inputElements = this.$el.find( '.section-content' ).find( 'input[type="text"]' );
    // let textareaElements = this.$el.find( '.section-content' ).find( 'textarea' );
    //
    // if ( inputElements.length > 0 ) {
    // 	inputElements.focus();
    // } else if ( textareaElements.length > 0 ) {
    // 	inputElements.focus();
    // }

  }

  public subscriptions: any[] = [];

  public ngOnDestroy(): void {
    this.subscriptions.forEach((subscription) => {
      subscription.unsubscribe();
    });
  }

  public ngOnInit(): void {

    this.subscriptions.push(this.appService.getAuthenticatedProfile({
      next: (profile) => {
        this.authenticatedUser = profile || null;
        this.zone.run(() => {
          this.cdRef.markForCheck();
        });
      },
      error: () => {
        this.authenticatedUser = null;
        this.zone.run(() => {
          this.cdRef.markForCheck();
        });
      },
      complete: () => {
        this.authenticatedUser = null;
        this.zone.run(() => {
          this.cdRef.markForCheck();
        });
      }
    }));

  }

  public authenticatedUserAccessLevel(): string {

    if (this.authenticatedUser) {

      if (typeof this.authenticatedUser.id === 'string' && this.exclusiveUserIds.indexOf(this.authenticatedUser.id) > -1) {
        return 'exclusive';
      }

      if (typeof this.authenticatedUser.accessLevel === 'string' && ['public', 'private', 'exclusive'].indexOf(this.authenticatedUser.accessLevel) > -1) {
        return this.authenticatedUser.accessLevel;
      }

    }

    return 'public';

  }

  public applyNewLines(str: string): string {
    return str.split(/\n/).join('<br/>');
  }

  public markdownToHtml(markdownStr: string, keepNewLines: boolean = false): string {

    if (typeof markdownStr !== 'string') {
      markdownStr = '';
    }

    if (keepNewLines) {

      let parts = markdownStr.split(/\n/);

      parts.forEach((part, i) => {
        parts[i] = this.appService.markdownToHtml(part);
      });

      return parts.join('<br/>\n');


    } else {

      return this.appService.markdownToHtml(markdownStr);
    }

  }

  public isHeading(type: string): boolean {


    if (typeof type !== 'string') {
      type = '';
    }

    return !!type.match(/^h[1-5]$/);
  }

  public ngClassSet(section: ArticleSection): {} {

    let classes = {};

    if (!section.class) {
      return classes;
    }

    if (typeof section.class === 'string') {
      section.class = (<string>section.class).split(/, /);
    }

    if (Array.isArray(section.class)) {
      (<Array<string>>section.class).forEach((aClass: string) => {
        classes[aClass] = true;
      })
    }

    return classes;

  }

  public backgroundImgUrl(url: string): string {
    if (typeof url !== 'string') {
      url = '';
    }
    return `url("${url}")`;
  }

  public backgroundImgFit(fit: string): string {

    if (typeof fit !== 'string') {
      fit = '';
    }

    if (fit === 'cover') {
      return 'cover';
    }

    if (fit === 'height') {
      return 'auto 100%';
    }

    return '100% auto';

  }

  public isArray(arg: any): boolean {
    return Array.isArray(arg);
  }

  public editMode(): boolean {
    return this.mode === 'edit';
  }

  public keyUpCheck(code: string): void {

    if (code === 'Escape') {
      this.clearSectionEditing();
    }

  }

  public clearSectionEditing(): void {

    this.sections.forEach((section) => {
      section.editing = false;
    });

    this.zone.run(() => {
      this.cdRef.markForCheck();
    });

  }

  public carouselCategories(section: ArticleSection): string[] {

    let results = [];

    if (!section || typeof section.content !== 'string') {
      return results;
    }

    return (<string>section.content).split(',')
      .map((category) => {
        return category.trim();
      })
      .filter((category) => {
        return category.length > 0;
      });

  }

  public setSectionEditing(id: number): void {

    this.sections.forEach((section, i) => {
      section.editing = id === i;
    });

    this.zone.run(() => {
      this.cdRef.markForCheck();
    });

  }

  public deleteSection(section: ArticleSection, sectionId: number) {

    section.delete = true;

    if (sectionId > -1 && sectionId < this.sections.length) {
      this.sections.splice(sectionId, 1);
    }

    this.changeDetection();

  }

  public moveSectionUp(sectionId: number) {
    this.swapSections(sectionId, sectionId - 1);
  }

  public moveSectionDown(sectionId: number) {
    this.swapSections(sectionId, sectionId + 1);
  }

  public swapSections(from: number, to: number): void {

    if (this.sections.length <= 1 ||
      from < 0 || from >= this.sections.length ||
      to < 0 || to >= this.sections.length ||
      from === to) {
      return;
    }

    let temp: ArticleSection = this.sections[from];
    this.sections[from] = this.sections[to];
    this.sections[to] = temp;

    window.setTimeout(() => {
      let $section = this.$el.find('.section[data-section-id=\'' + to + '\']');

      $('html, body').animate({
        scrollTop: Math.max(0, $section.offset().top - 20 - (this.fixedMenu ? 103 : 0))
      }, 1000);

      this.changeDetection();
    });

    this.changeDetection();

  }

  public getSection(): ArticleSection[] {
    return this.sections.filter((section) => {
      return section.type !== 'carousel' && section.type !== 'tiles';
    });
  }

  public contentPaddingTop(section: ArticleSection): string {
    let value = section.contentPaddingTop >= 0 ? section.contentPaddingTop : 0;
    return value + 'px';
  }

  public contentMarginTop(section: ArticleSection): string {
    let value = section.contentPaddingTop >= 0 ? 0 : section.contentPaddingTop;
    return value + 'px';
  }

  public contentPaddingBottom(section: ArticleSection): string {
    let value = section.contentPaddingBottom >= 0 ? section.contentPaddingBottom : 0;
    return value + 'px';
  }

  public contentMarginBottom(section: ArticleSection): string {
    let value = section.contentPaddingBottom >= 0 ? 0 : section.contentPaddingBottom;
    return value + 'px';
  }

  public getHiddenMasks(sectionIndex: number): number[][] {

    function getRandomIntInclusive(min, max) {
      min = Math.ceil(min);
      max = Math.floor(max);
      return Math.floor(Math.random() * (max - min + 1)) + min;
    }

    if (!Array.isArray(this.hiddenSectionsCache[sectionIndex])) {

      this.hiddenSectionsCache[sectionIndex] = [];

      let paragraphsCount = getRandomIntInclusive(1, 2);

      for (let i = 0; i < paragraphsCount; i++) {

        let paragraphCharacters = [];

        let minChars = 200;
        let maxChars = 600;

        if (paragraphsCount <= 2) {
          minChars = 1000;
          maxChars = 1600;
        } else if (paragraphsCount <= 4) {
          minChars = 400;
          maxChars = 600;
        }

        let characterCount = getRandomIntInclusive(minChars, maxChars);
        for (let j = 0; j < characterCount; j++) {
          paragraphCharacters.push(j);
        }

        this.hiddenSectionsCache[sectionIndex].push(paragraphCharacters);

      }

    }

    return this.hiddenSectionsCache[sectionIndex];

  }

  public addScoreContent(section: ArticleSection): void {

    if (!section || !section.content) {
      return;
    }

    if (!Array.isArray((<any>section.content).metrics)) {
      (<any>section.content).metrics = [];
    }

    (<any>section.content).metrics.push({
      name: null,
      value: null
    });

  }

  public removeScoreContent(section: ArticleSection, i: number): void {

    if (!section || !section.content || !Array.isArray((<any>section.content).metrics)) {
      return;
    }

    let metrics = (<any>section.content).metrics;

    if (metrics.length <= i) {
      return;
    }

    metrics.splice(i, 1);

  }

  public addImgContent(section: ArticleSection): void {

    let content = this.getImgContent(section);

    if (content && Array.isArray(content.urls)) {
      content.urls.push({
        url: '',
        position: 'top left'
      });
    }

  }

  public removeImgContent(section: ArticleSection, i: number): void {

    let content = this.getImgContent(section);

    if (content && content.urls && content.urls.length > i) {
      content.urls.splice(i, 1);
    }

  }

  public hasSectionAccess(section: ArticleSection): boolean {

    let userAccessLevel = this.accessLevel;
    let sectionAccessLevel = section.accessLevel || 'private';

    let values = {
      'public': 1,
      'private': 2,
      'exclusive': 3
    };

    // console.log( 'has section access', result, section.type, sectionAccessLevel, userAccessLevel );

    return values.hasOwnProperty(userAccessLevel) && values.hasOwnProperty(sectionAccessLevel) && values[userAccessLevel] >= values[sectionAccessLevel];

  }

  public getImgContent(section: ArticleSection): {
    layout: string,
    urls: {
      url: string;
      position: string;
    }[]
  } {

    if (!section) {
      return null;
    }

    if (!section.content || !Array.isArray(section.content.urls)) {
      section.content = {
        urls: []
      }
    } else {

      section.content.urls.forEach((url: any, i: number) => {

        if (typeof url === 'string') {
          section.content.urls[i] = {
            url: url,
            position: 'top left'
          }
        }

        if (!section.content.urls[i].url) {
          // console.log( 'section.content.urls[ i ]', section.content.urls[ i ], url );
          section.content.urls[i].url = section.content.urls[i].private || section.content.urls[i].public || 'http://';
        }

        if (!section.content.urls[i].position) {
          section.content.urls[i].position = 'top left';
        }

        delete section.content.urls[i].content;
        delete section.content.urls[i].type;

      });
    }

    return <{
      layout: string,
      urls: {
        url: string;
        position: string;
      }[]
    }>section.content;

  }

  public changeDetection(): void {

    if (typeof this.parentChangeDetection === 'function') {
      this.parentChangeDetection();
    } else {
      this.cdRef.markForCheck();
    }

  }


}
