
<h4>Vimeo Content</h4><br><br>
<div class="select-access-level">Access Level</div>
<div class="input-type-select-access-level" [(accessLevel)]="section.accessLevel"></div><br><br>
<div class="input-title">Contents Name</div>
<textarea class="contents-entry-name" placeholder="A short name" [(ngModel)]="section.contentsName"></textarea><br><br>
<div class="input-title">Contents Icon</div>
<div class="input-type-select-icon" [(icon)]="section.contentsIcon"></div><br><br>
<div class="select-padding-top">Padding Top</div>
<input type="text" placeholder="Enter an integer" [(ngModel)]="section.contentPaddingTop"><br>
<div class="select-padding-bottom">Padding Bottom</div>
<input type="text" placeholder="Enter an integer" [(ngModel)]="section.contentPaddingBottom"><br><br>
<input type="text" [(ngModel)]="section.content" placeholder="Video ID"><br><br>