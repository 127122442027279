import * as tslib_1 from "tslib";
import { ProfileModel } from 'app/models/profile';
import { ApiService } from 'app/services/api';
// Types
import { GenericModel, GenericMasterSchemaType, GenericSchemaType } from './generic';
import { Hash, HashTree } from 'app/types/containers';
;
var ArticleFileOwner = /** @class */ (function () {
    function ArticleFileOwner() {
    }
    return ArticleFileOwner;
}());
export { ArticleFileOwner };
var ArticleFile = /** @class */ (function () {
    function ArticleFile() {
    }
    return ArticleFile;
}());
export { ArticleFile };
var ArticleSection = /** @class */ (function (_super) {
    tslib_1.__extends(ArticleSection, _super);
    function ArticleSection(ref) {
        var _this = _super.call(this, ref) || this;
        if (_this.type === 'section' && Array.isArray(_this.content)) {
            _this.content.forEach(function (section, i) {
                _this.content[i] = new ArticleSection(section);
            });
        }
        return _this;
    }
    return ArticleSection;
}(GenericSchemaType));
export { ArticleSection };
var ArticleGridSection = /** @class */ (function (_super) {
    tslib_1.__extends(ArticleGridSection, _super);
    function ArticleGridSection() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    return ArticleGridSection;
}(ArticleSection));
export { ArticleGridSection };
var SubMenu = /** @class */ (function () {
    function SubMenu() {
    }
    return SubMenu;
}());
export { SubMenu };
var ArticleAction = /** @class */ (function () {
    function ArticleAction() {
    }
    return ArticleAction;
}());
export { ArticleAction };
// Schema Type
var Article = /** @class */ (function (_super) {
    tslib_1.__extends(Article, _super);
    function Article(ref) {
        var _this = _super.call(this, ref) || this;
        if (Array.isArray(_this.sections)) {
            _this.sections.forEach(function (section, i) {
                _this.sections[i] = new ArticleSection(section);
            });
        }
        return _this;
    }
    return Article;
}(GenericMasterSchemaType));
export { Article };
var ArticleModel = /** @class */ (function (_super) {
    tslib_1.__extends(ArticleModel, _super);
    function ArticleModel(api, profile) {
        var _this = _super.call(this) || this;
        _this.api = api;
        _this.profile = profile;
        _this.init(api, 'article', {
            id: null,
            path: null,
            type: null,
            redirectPaths: [],
            deleted: null,
            data: {},
            published: null,
            author: null,
            created: null,
            nda: null,
            formId: null,
            updated: null,
            categories: [],
            tagline: null,
            title: null,
            description: null,
            sortHint: null,
            tileWidth: null,
            tileImgUrl: null,
            tileImgLargeUrl: null,
            bannerImgUrl: null,
            bannerImgUrlMobile: null,
            bannerImgPosition: null,
            bannerImgPositionMobile: null,
            bannerImgFit: null,
            bannerImgFitMobile: null,
            bannerBackground: null,
            bannerBackgroundMobile: null,
            bannerLayout: null,
            bannerLayoutMobile: null,
            bannerOpacity: null,
            bannerOpacityMobile: null,
            submenu: null,
            actions: [],
            sections: [],
            accessLevel: null,
            accessLevelExclusive: [],
            accessLevelExclusivePending: [],
            accessLevelPrivate: [],
            accessLevelPrivatePending: [],
            accessLevelInvites: null,
            accessRequirements: {
                public: null,
                private: null,
                exclusive: null
            }
        }, 1000);
        return _this;
    }
    ArticleModel.prototype.getInstance = function (ref) {
        return new Article(ref);
    };
    // public addUserPrivate( articleId: string ): Bluebird<MetaData> {
    // 	return this.api.call( 'article.addUserPrivate', { articleId: articleId } );
    // }
    //
    // public addUserExclusive( articleId: string ): Bluebird<MetaData> {
    // 	return this.api.call( 'article.addUserExclusive', { articleId: articleId } );
    // }
    ArticleModel.prototype.metaData = function () {
        return this.api.call('article.metaData', {});
    };
    ArticleModel.prototype.addPermission = function (params) {
        return this.api.call('article.addPermission', params);
    };
    ArticleModel.prototype.listPublishedUploads = function (id, cacheBust) {
        return this.api.call('article.listPublishedUploads', { id: id, cacheBust: cacheBust });
    };
    ArticleModel.prototype.search = function (params) {
        return this.api.call('article.search', params)
            .then(function (searchResponse) {
            searchResponse.articles.sort(function (left, right) {
                var leftSortHint = left.sortHint || 0;
                var rightSortHint = right.sortHint || 0;
                return leftSortHint - rightSortHint;
            });
            searchResponse.articles.forEach(function (article, i) {
                article = new Article(article);
                searchResponse.articles[i] = article;
            });
            return searchResponse;
        });
    };
    ArticleModel.prototype.getByPath = function (params, cacheBust) {
        return this.api.call('article.getByPath', params)
            .then(function (article) {
            if (article) {
                article = new Article(article);
            }
            return article;
        });
    };
    ArticleModel.prototype.documentByNameAndPath = function (params) {
        return this.api.call('article.documentByNameAndPath', params);
    };
    return ArticleModel;
}(GenericModel));
export { ArticleModel };
