/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./email-verification.component.less.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "./email.verification.component";
import * as i3 from "../../../services/app";
import * as i4 from "@angular/router";
import * as i5 from "@angular/common";
var styles_ViewsHomeEmailVerificationComponent = [i0.styles];
var RenderType_ViewsHomeEmailVerificationComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_ViewsHomeEmailVerificationComponent, data: {} });
export { RenderType_ViewsHomeEmailVerificationComponent as RenderType_ViewsHomeEmailVerificationComponent };
export function View_ViewsHomeEmailVerificationComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "p", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Thank you for verifying your email."])), (_l()(), i1.ɵeld(2, 0, null, null, 1, "p", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["You may now close this window or continue with using the site."]))], null, null); }
export function View_ViewsHomeEmailVerificationComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "div", [["class", "app-view-home-email-verification"]], null, null, null, View_ViewsHomeEmailVerificationComponent_0, RenderType_ViewsHomeEmailVerificationComponent)), i1.ɵdid(1, 245760, null, 0, i2.ViewsHomeEmailVerificationComponent, [i3.AppService, i4.ActivatedRoute, i5.Location], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var ViewsHomeEmailVerificationComponentNgFactory = i1.ɵccf(".app-view-home-email-verification", i2.ViewsHomeEmailVerificationComponent, View_ViewsHomeEmailVerificationComponent_Host_0, {}, {}, []);
export { ViewsHomeEmailVerificationComponentNgFactory as ViewsHomeEmailVerificationComponentNgFactory };
