
<div class="page-title">Edit Contract Instance</div>
<div class="page-subtitle" *ngIf="!!document">for {{ document.name }}</div>
<div class="page-subtitle" *ngIf="!document">Loading...</div>
<div class="page-subtitle" *ngIf="!!instanceEdited &amp;&amp; instanceEdited.complete">This instance is signed, and can not be edited.</div>
<div class="editor-controls">
  <button class="save-record" *ngIf="!!instanceEdited" (click)="save()" [class.enabled]="dirty">Save</button>
  <button class="cancel-record" *ngIf="!!instanceEdited" (click)="reset()" [class.enabled]="dirty">Reset</button>
</div>
<div class="editor-body" *ngIf="!app.content.loading &amp;&amp; !!instanceEdited">
  <div class="input-label">Name</div>
  <input class="instance-name" [(ngModel)]="instanceEdited.name" placeholder="Instance Name">
  <div class="input-label">Notifications</div>
  <div class="notifications-container" *ngIf="!employeeProfiles || employeeProfiles.length &lt; 1">Loading...</div>
  <div class="notifications-container" *ngIf="!!employeeProfiles &amp;&amp; employeeProfiles.length &gt; 0">
    <select class="notifications-selector change-detection-tracking" *ngIf="getNotificationOptions().length &gt; 0">
      <option *ngFor="let option of getNotificationOptions()" [value]="option[0]">{{ option[1] }}</option>
    </select>
    <div class="control-link" *ngIf="getNotificationOptions().length &gt; 0" (click)="addNotification()">Add</div>
    <div class="notifications">
      <div class="notification" *ngFor="let notification of getNotifications(); let i = index;">
        <div class="notification-name col-xs-4" *ngIf="!!notification.lastName">{{ notification.lastName }}, {{ notification.firstName }}</div>
        <div class="notification-name col-xs-4" *ngIf="!notification.lastName">{{ notification.firstName }}</div>
        <div class="notification-name col-xs-4" *ngIf="!!notification.email"><a [href]="'mailto:' + notification.email">{{ notification.email }}</a></div>
        <div class="notification-email col-xs-4" *ngIf="!notification.email">No Email On File</div>
        <div class="notification-controls col-xs-4">
          <div class="notification-control control-link" (click)="removeNotification(notification.id)">Remove</div>
        </div>
      </div>
    </div>
  </div>
  <div class="hr-rule"></div>
  <div class="input-label">Signers</div>
  <div class="signers-container" *ngIf="!profiles || profiles.length &lt; 1">Loading...</div>
  <div class="signers-container" *ngIf="!!profiles &amp;&amp; profiles.length &gt; 0">
    <select class="signers-selector change-detection-tracking" *ngIf="getContactOptions().length &gt; 0">
      <option *ngFor="let option of getContactOptions()" [value]="option[0]">{{ option[1] }}</option>
    </select>
    <div class="control-link" *ngIf="getContactOptions().length &gt; 0" (click)="addSigner()">Add</div>
    <div class="signers">
      <div class="signer" *ngFor="let signer of getSigners(); let i = index;">
        <div class="signer-name col-xs-4" *ngIf="signer.lastName">{{ signer.lastName }}, {{ signer.firstName }}</div>
        <div class="signer-name col-xs-4" *ngIf="!signer.lastName">{{ signer.firstName }}</div>
        <div class="signer-name col-xs-4" *ngIf="signer.email"><a [href]="'mailto:' + signer.email">{{ signer.email }}</a></div>
        <div class="signer-email col-xs-4" *ngIf="!signer.email">No Email On File</div>
        <div class="signer-controls col-xs-4">
          <div class="signer-control control-link" (click)="removeSigner(signer.id)">Remove</div>
        </div>
      </div>
    </div>
  </div>
</div>