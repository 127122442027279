/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./main.component.css.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "./main.component";
import * as i3 from "../../../services/app";
var styles_ViewsNewsMainComponent = [i0.styles];
var RenderType_ViewsNewsMainComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_ViewsNewsMainComponent, data: {} });
export { RenderType_ViewsNewsMainComponent as RenderType_ViewsNewsMainComponent };
export function View_ViewsNewsMainComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "div", [["class", "view-content"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["News content 1"])), (_l()(), i1.ɵeld(2, 0, null, null, 1, "div", [["class", "view-content"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["News content 2"])), (_l()(), i1.ɵeld(4, 0, null, null, 1, "div", [["class", "view-content"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["News content 3"])), (_l()(), i1.ɵeld(6, 0, null, null, 1, "div", [["class", "view-content"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["News content 4"])), (_l()(), i1.ɵeld(8, 0, null, null, 1, "div", [["class", "view-content"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["News content 5"]))], null, null); }
export function View_ViewsNewsMainComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "div", [["class", "app-view-news-main"]], null, null, null, View_ViewsNewsMainComponent_0, RenderType_ViewsNewsMainComponent)), i1.ɵdid(1, 49152, null, 0, i2.ViewsNewsMainComponent, [i3.AppService], null, null)], null, null); }
var ViewsNewsMainComponentNgFactory = i1.ɵccf(".app-view-news-main", i2.ViewsNewsMainComponent, View_ViewsNewsMainComponent_Host_0, {}, {}, []);
export { ViewsNewsMainComponentNgFactory as ViewsNewsMainComponentNgFactory };
