
<div class="page-title">Contracts</div>
<div class="document-list-controls">
  <div class="document-add control-link" (click)="addDocument()">Add</div>
</div>
<div class="error-message" *ngIf="errorMessage">{{ errorMessage }}</div>
<div class="search-results">
  <div class="search-results-list-loading" *ngIf="documentsLoading">
    <div class="loader-spinner"></div>
  </div>
  <div class="search-results-list" *ngIf="!documentsLoading &amp;&amp; results &amp;&amp; results.documents.length &gt; 0">
    <div class="search-result search-result-first hidden-xs">
      <div class="search-result-field document-name col-xs-6 col-md-5">Name</div>
      <div class="search-result-field document-url col-xs-5 col-md-4">URL</div>
      <div class="search-result-field document-instances col-xs-1 col-md-1">Instances</div>
      <div class="search-result-field document-controls col-xs-12 col-md-2">Actions</div>
    </div>
    <div class="search-result" *ngFor="let document of results.documents; let i = index;">
      <div class="search-result-field document-name col-xs-6 col-md-5">{{ document.name }}</div>
      <div class="search-result-field document-url col-xs-5 col-md-4">{{ document.url }}</div>
      <div class="search-result-field document-instances col-xs-1 col-md-1">{{ document.instanceCount }}</div>
      <div class="search-result-field document-controls col-xs-12 col-md-2" *ngIf="deletingDocument === i">
        <div class="document-control">Are you sure?</div>
        <div class="document-control control-link document-remove" (click)="deletingDocument = null; deleteDocument(document)">Yes</div>
        <div class="document-control control-link document-remove" (click)="deletingDocument = null;">No</div>
      </div>
      <div class="search-result-field document-controls col-xs-12 col-md-2" *ngIf="deletingDocument !== i">
        <div class="document-control control-link document-edit" (click)="editDocument(document)">Edit</div>
        <div class="document-control">|</div>
        <div class="document-control control-link document-remove" (click)="editDocumentInstances(document)">Instances</div>
        <div class="document-control">|</div>
        <div class="document-control control-link document-duplicate" (click)="duplicateDocument(document)">Copy</div>
        <div class="document-control">|</div>
        <div class="document-control control-link document-remove" (click)="deletingDocument = i">Delete</div>
      </div>
    </div>
  </div>
  <div class="search-results-not-found" *ngIf="!documentsLoading &amp;&amp; (!results || results.documents.length &lt; 1)">No Articles Found</div>
</div>