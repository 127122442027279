import * as tslib_1 from "tslib";
import { ApiService } from 'app/services/api';
// Types
import { GenericModel } from './generic';
var MailchimpModel = /** @class */ (function (_super) {
    tslib_1.__extends(MailchimpModel, _super);
    function MailchimpModel(api) {
        var _this = _super.call(this) || this;
        _this.init(api, 'vimeo', {
            id: null,
            name: null
        }, 10000);
        return _this;
    }
    MailchimpModel.prototype.getInstance = function (ref) {
        return ref;
    };
    MailchimpModel.prototype.getLists = function (params) {
        return this.api.call('mailchimp.getLists', params || {});
    };
    return MailchimpModel;
}(GenericModel));
export { MailchimpModel };
