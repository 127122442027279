import {Injectable} from '@angular/core';
import {ApiService} from 'app/services/api';
import * as Bluebird from 'bluebird';
import * as moment from 'moment';

// Types
import {ArticleModel} from './article';
import {ProfileModel, Profile} from './profile';
import {GenericModel, GenericMasterSchemaType, GenericSchemaType} from './generic';
import {Hash, HashTree} from 'app/types/containers';

export class LegalDocumentInstanceSigner extends GenericSchemaType {
  userId: string;
  requested: string; // ISO Date
  signed?: string; // ISO Date
  declined?: string; // ISO Date
}

export class LegalDocumentInstance extends GenericSchemaType {
  id?: string | null;
  name: string;
  author: string;
  notifications?: string[]; // userIds
  legalDocumentId: string;
  created?: string; // ISO Date
  complete?: boolean;
  signers?: LegalDocumentInstanceSigner[];
  onAccept?: {
    lib: string,
    method: string,
    params: {},
  };
  data: HashTree<string | null | number | boolean>
}

export interface SearchParams {
  query?: {
    path?: string
  };
  skip?: number;
  limit?: number;
  sort?: Hash<number>;
}

export interface SearchResponse {
  documents: LegalDocument[],
  count: number,
  total: number
}

export interface LegalDocumentSection {
  title: string;
  contents: string;
}

// Schema Type
export class LegalDocument extends GenericMasterSchemaType {
  name: string;
  url: string;
  title: string;
  instructions?: string;
  parties?: string;
  description?: string;
  version?: string;
  sections: LegalDocumentSection[];
  preSigners: string[]; // userIds
  notifications: string[]; // userIds
  instanceCount?: number;
  signatureSuccess?: string;
  returnToPreviousPageAfterSignature?: boolean;
}

export class ByUserLegalDocument extends GenericMasterSchemaType {
  contractName: string;
  timestamp: string;
  articleTitle: string;
  adminUsers: string[];
  complete: boolean;
  pdfUrl: string;
  refId: string;
}

export class MyLegalDocumentRecord {
  public document: LegalDocument;
  public instance: LegalDocumentInstance;
  public signed: boolean
}

@Injectable()
export class LegalDocumentModel extends GenericModel<LegalDocument> {

  constructor(public api: ApiService, public profile: ProfileModel, public article: ArticleModel) {
    super();

    this.init(api, 'legalDocument', {
      id: null,
      name: null,
      url: null,
      title: null,
      instructions: null,
      parties: null,
      description: null,
      version: null,
      sections: [],
      preSigners: [],
      notifications: [],
      instanceCount: null,
      signatureSuccess: null,
      returnToPreviousPageAfterSignature: false
    }, 0);

  }

  public getInstance(ref: any): LegalDocument {
    return ref;
  }

  public saveInstance(instance: LegalDocumentInstance): Bluebird<LegalDocumentInstance> {
    return this.saveDocumentInstance(instance);
  }

  public saveDocumentInstance(instance: LegalDocumentInstance): Bluebird<LegalDocumentInstance> {
    return this.api.call('legalDocument.saveInstance', instance);
  }

  public upsertArticleNdaInstance(params: {
    signerId: string;
    legalDocumentId: string;
    articleId: string;
    accessLevel: string;
  }): Bluebird<LegalDocumentInstance> {
    return this.api.call('legalDocument.upsertArticleNdaInstance', params);
  }

  public getArticleNdaAnonymousInstance(params: {
    legalDocumentId: string;
    articleId: string;
  }): Bluebird<LegalDocumentInstance> {
    return this.api.call('legalDocument.getArticleNdaAnonymousInstance', params);
  }

  public confirmEmail(params: { instanceId: string, authorId: string }): Bluebird<{ url: string, code: string }> {
    return this.api.call('legalDocument.confirmEmail', params);
  }

  public getDocumentInstances(query: {
    id?: string | null;
    author?: string | null;
    name?: string | null;
    refId?: string | null;
    legalDocumentId?: string | null;
  }): Bluebird<LegalDocumentInstance> {
    return this.api.call('legalDocument.getInstance', query);
  }

  public list(): Bluebird<LegalDocument[]> {
    return this.api.call('legalDocument.list', {});
  }

  public byUserId(id: string): Bluebird<ByUserLegalDocument[]> {
    return this.api.call('legalDocument.byUserId', {id: id});
  }

  public getByPath(params: { path: string }): Bluebird<LegalDocument> {
    return this.api.call('legalDocument.getByPath', params);
  }

  public upsertSignerInstance(params: {
    path: string, userId?: string, onAccept?: {
      lib: string,
      method: string,
      params: any
    }
  }): Bluebird<LegalDocumentInstance> {
    return this.api.call('legalDocument.upsertSignerInstance', params);
  }

  public upsertSignerInstanceByName(params: { path: string, name: string }): Bluebird<LegalDocumentInstance> {
    return this.api.call('legalDocument.upsertSignerInstanceByName', params);
  }

  public getSignerInstance(params: { path: string }): Bluebird<LegalDocumentInstance> {
    return this.api.call('legalDocument.getSignerInstance', params);
  }

  public acceptInstance(params: { id: string, urlRef?: string }): Bluebird<LegalDocumentInstanceSigner> {

    if (!params.urlRef) {
      params.urlRef = window.location.pathname;
    }

    return this.api.call('legalDocument.acceptInstance', params);

  }

  public pdfUrlByInstanceId(params: { id: string }): Bluebird<string> {
    return this.api.call('legalDocument.pdfUrlByInstanceId', params);
  }

  public sendNDAConfirmationEmail(params: { profile: Profile, instanceId: string, author: string, access?: string }): Bluebird<string> {
    return this.api.call('legalDocument.sendNDAConfirmationEmail', params);
  }

  public declineInstance(params: { path?: string, legalDocumentId?: string }): Bluebird<LegalDocumentInstanceSigner> {
    return this.api.call('legalDocument.declineInstance', params);
  }

  public myRecords(): Bluebird<MyLegalDocumentRecord[]> {
    return this.api.call('legalDocument.myRecords', {});
  }

  public search(params: SearchParams): Bluebird<SearchResponse> {

    return new Bluebird<SearchResponse>((resolve, reject) => {

      this.api.call('legalDocument.search', params)
        .then((searchResponse: SearchResponse): void => {

          searchResponse.documents.forEach((document: LegalDocument, i: number): void => {

            document = new LegalDocument(document);

            searchResponse.documents[i] = document;

          });

          resolve(searchResponse);

        })
        .catch(reject);

    });

  }

  public instancesByLegalDocumentId(id: string): Bluebird<LegalDocumentInstance[]> {
    return this.api.call('legalDocument.instancesByLegalDocumentId', {id: id});
  }

  public instanceById(id: string): Bluebird<LegalDocumentInstance> {
    return this.api.call('legalDocument.instanceById', {id: id});
  }

  public addInstanceSigner(instanceId: string, userId: string): Bluebird<LegalDocumentInstanceSigner> {
    return this.api.call('legalDocument.addInstanceSigner', {
      instanceId: instanceId,
      userId: userId
    });
  }

  public removeInstanceSigner(instanceId: string, userId: string): Bluebird<string> {
    return this.api.call('legalDocument.removeInstanceSigner', {
      instanceId: instanceId,
      userId: userId
    });
  }

  public applyMergeCodes(legalDocumentRaw: LegalDocument, signerProfile: Profile, mergeData: HashTree<any>): LegalDocument {

    // clone
    const legalDocument = JSON.parse(JSON.stringify(legalDocumentRaw));

    if (signerProfile) {
      let partyName = signerProfile.email;

      if (signerProfile.firstName && signerProfile.lastName) {
        partyName = `${signerProfile.firstName} ${signerProfile.lastName} <${signerProfile.email}>`;
      }

      mergeData.partyName = partyName;
    } else {
      mergeData.partyName = 'signer';
    }

    if (!mergeData.partyName) {
      mergeData.partyName = '[SIGNER]';
    }

    mergeData.effectiveDate = moment().tz('America/New_York').format('MMMM D, YYYY');

    const applyField = (sourceField: string) => {

      const targetField = sourceField.replace(/([A-Z])/g, '_$1').toUpperCase();

      const mergeValue: any = mergeData[sourceField];

      const typeOfMergeValue = typeof mergeValue;

      if (typeOfMergeValue !== 'string' &&
        typeOfMergeValue !== 'number') {
        return;
      }

      const swapMatch = new RegExp('{{\s*' + targetField + '\s*}}', 'g');

      const swapText = (text) => {

        if (typeof text !== 'string') {
          return text;
        }

        return text.replace(swapMatch, mergeValue);

      };

      [
        'name',
        'title',
        'parties',
        'instructions',
        'description',
        'signatureSuccess'
      ].forEach((field) => {
        try {
          legalDocument[field] = swapText(legalDocument[field]);
        } catch (e) {
          console.error('error processing', sourceField, '->', field, e);
        }
      });

      legalDocument.sections.forEach((section, i) => {
        [
          'title',
          'contents'
        ].forEach((field) => {
          try {
            section[field] = swapText(section[field]);
          } catch (e) {
            console.error('error processing', sourceField, '->', field, e);
          }
        });
      });

    };

    Object.keys(mergeData).forEach(applyField);

    return legalDocument;
  }

}



