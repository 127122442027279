// ViewsPartnersEntryComponent

import { Component, OnInit, OnDestroy, ViewChild } from '@angular/core';
import { DomSanitizer, SafeHtml, SafeResourceUrl } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { Location } from '@angular/common';
import { AppService } from 'app/services/app';
import { Profile } from 'app/models/profile';
import { ByUserLegalDocument } from 'app/models/legal.document';
import { QuestionnaireSession } from 'app/models/questionnaire';
import * as moment from 'moment';
import { MatTableDataSource } from '@angular/material';
import { AnalyticsEvent } from '../../../models/analytics';

type SortTypes = 'sortHint' | 'name' | 'position';

@Component( {
  moduleId: module.id,
  selector: 'app-view-content-profile',
  templateUrl: 'profile.component.pug',
  styleUrls: [ 'profile.component.less' ]
} )
export class ViewsContentProfileComponent implements OnInit, OnDestroy {

  public profile: Profile;
  public profileList: Profile[];
  public profileNext: Profile;
  public subscriptions: any[] = [];
  public sortOrder: SortTypes = null;
  public addressEmbedLink: SafeResourceUrl = null;
  public analyticsEvents: MatTableDataSource<AnalyticsEvent> = null;
  public contracts: MatTableDataSource<ByUserLegalDocument> = null;
  public questionnaires: MatTableDataSource<any> = null;
  public authenticatedProfile: Profile = null;

  constructor( public appService: AppService,
               public route: ActivatedRoute,
               public router: Router,
               public sanitizer: DomSanitizer,
               public location: Location ) {

    this.appService.contentLoading( true );
    this.appService.toolbar.whiteOverContent = false;
    this.appService.toolbar.backgroundColor = '#FFFFFF';

    this.appService.footer.hide = true;

    this.appService.titleService.setTitle( 'Exio - Profile - Loading...' );

  }

  public ngOnInit(): void {

    document.body.scrollTop = 0;

    this.subscriptions.push( this.appService.getAuthenticatedProfile( {
      next: ( profile ) => {

        if ( typeof profile === 'boolean' && !profile ) {

          this.appService.contentLoading( true );

        } else if ( !profile ) {

          this.router.navigate( [ '/action/login' ] );

        } else {

          this.authenticatedProfile = profile;

          this.subscriptions.push( this.route.params.subscribe( ( params: { id: string, sort: SortTypes } ) => {
            this.applyParams( params );
          } ) );

        }

      },
      error: () => {
        this.router.navigate( [ '/action/login' ] );
      },
      complete: () => {
        this.router.navigate( [ '/action/login' ] );
      }
    } ) );

  }

  public ngOnDestroy(): void {
    this.subscriptions.forEach( ( subscription ) => {
      subscription.unsubscribe();
    } );
  }

  safeUrl( url ): SafeResourceUrl {
    return this.sanitizer.bypassSecurityTrustResourceUrl( url );
  }

  public addressFormattedCityStateZip(): string {

    let address = '';

    if ( typeof this.profile.address.city === 'string' && this.profile.address.city.trim().length > 0 ) {
      address += this.profile.address.city + ', ';
    }

    if ( typeof this.profile.address.state === 'string' && this.profile.address.state.trim().length > 0 ) {
      address += this.profile.address.state + ' ';
    }

    if ( typeof this.profile.address.zip === 'string' && this.profile.address.zip.trim().length > 0 ) {
      address += this.profile.address.zip + ', ';
    }

    return address.replace( /[, ]*$/, '' ).trim();

  }

  public addressFormatted(): string {

    let address = '';

    [
      'street1',
      'city',
    ].forEach( ( field ) => {
      if ( typeof this.profile.address[ field ] === 'string' && this.profile.address[ field ].trim().length > 0 ) {
        address += this.profile.address[ field ] + ', ';
      }
    } );

    if ( typeof this.profile.address.state === 'string' && this.profile.address.state.trim().length > 0 ) {
      address += this.profile.address.state.toUpperCase() + ' ';
    }

    if ( typeof this.profile.address.zip === 'string' && this.profile.address.zip.trim().length > 0 ) {
      address += this.profile.address.zip + ', ';
    }

    if ( typeof this.profile.address.country === 'string' && this.profile.address.country.trim().length > 0 ) {
      address += this.profile.address.country.toUpperCase();
    }

    address = address.replace( /[, ]*$/, '' ).trim();

    if ( address.length < 1 ) {
      return null;
    }

    return address;

  }

  public addressLink(): string {

    const address = this.addressFormatted();

    return 'https://www.google.com/maps/search/?api=1&query=' + encodeURI( address );

  }

  public safeAddressEmbedLink(): SafeResourceUrl {

    const address = this.addressFormatted();
    const url = 'https://www.google.com/maps/embed/v1/place?key=AIzaSyA21CmmSOeRregH6SpibIg6F6cu0-9E4dU&q=' + encodeURI( address );

    return this.safeUrl( url );

  }

  public openAddress(): void {
    window.open( this.addressLink(), '_blank' );
  }

  public openWebsite(): void {
    if ( !this.profile.website ) {
      return;
    }

    window.open( this.profile.website, '_blank' );

  }

  public applyParams( params ): void {

    if ( !params || !params.id ) {
      this.profile = null;
      return;
    }

    if ( this.profile && this.profile.id === params.id ) {
      return;
    }

    this.profile = null;
    this.appService.contentLoading( true );
    this.appService.titleService.setTitle( 'Exio - Profile - Loading...' );

    this.appService
      .profileModel
      .get( params.id )
      .then( ( profile: Profile ) => {

        if ( !profile || !profile.id ) {
          this.router.navigate( [ '/404' ] );
          return null;
        }

        this.appService.contentLoading( false );

        this.profile = profile;

        this.appService.titleService.setTitle( `Exio - Profile - ` + this.profileName() );

        this.addressEmbedLink = this.safeAddressEmbedLink();

        this.appService.legalDocumentModel
          .byUserId( this.profile.id )
          .then( ( contracts: ByUserLegalDocument[] ) => {

            this.contracts = new MatTableDataSource<ByUserLegalDocument>( contracts );

            return null;

          } );

        this.appService.questionnaireModel
          .getSessionsByUserId( this.profile.id )
          .then( ( sessions: QuestionnaireSession[] ) => {

            // remove any started sessions that never completed
            sessions = sessions.filter( session => session && session.submissionId );

            this.questionnaires = new MatTableDataSource<QuestionnaireSession>( sessions );

            return null;

          } );

        // only show last year of activity
        const timeLimit = moment().subtract( 1, 'year' );

        this.appService.analytics
          .getEvents( {
            sort: [
              {
                timestamp: 'desc'
              }
            ],
            from: 0,
            size: 10000,
            filter: {
              bool: {
                must: [
                  {
                    term: {
                      userId: profile.id
                    }
                  },
                  {
                    range: {
                      timestamp: {
                        gte: timeLimit.toISOString()
                      }
                    }
                  }
                ]
              }
            }
          } )
          .then( ( events: AnalyticsEvent[] ) => {

            this.analyticsEvents = new MatTableDataSource<AnalyticsEvent>( events.filter( event => !!event.url ) );

            return null;

          } );

        return null;

      } );

  }

  public markdownToHtml( markdown: string ): SafeHtml {

    const decodeHtml = ( html ) => {
      const txt = document.createElement( 'textarea' );
      txt.innerHTML = html;
      return txt.value;
    };

    return this.sanitizer.bypassSecurityTrustHtml( this.appService.markdownToHtml( decodeHtml( markdown ) ) );

  }

  public imgUrl( url: string ): string {

    url = this.appService.exio.image.getUrl( url, <any>{
      format: 'jpg',
      actions: [
        {
          type: 'shrink',
          width: 300
        },
        {
          type: 'quality',
          quality: 100
        }
      ]
    } );

    return `url("${url}")`;
  }

  public hasAddress(): boolean {
    return !!this.profile && !!this.profile.address &&
           typeof this.profile.address.city === 'string' &&
           this.profile.address.city.trim().length > 0 &&
           typeof this.profile.address.state === 'string' &&
           this.profile.address.state.trim().length > 0;
  }

  public nextLink( profileNext: Profile ): any[] {
    const link: any[] = [ '/learn/leadership/' + profileNext.id ];

    if ( this.sortOrder && this.sortOrder !== 'sortHint' ) {
      link.push( { sort: this.sortOrder } );
    }

    return link;
  }

  public profileName(): string {

    const profile = this.profile;

    if ( !profile ) {
      return null;
    }

    if ( profile.firstName && profile.lastName ) {
      return `${profile.firstName} ${profile.lastName}`
    }

    if ( profile.firstName && !profile.lastName ) {
      return profile.firstName;
    }

    if ( !profile.firstName && profile.lastName ) {
      return profile.lastName;
    }

    return 'Name Not On File';

  }

  public titleName(): string {

    const profile = this.profile;

    if ( !profile ) {
      return null;
    }

    if ( profile.title && profile.title.trim().length > 0 ) {
      return profile.title.trim();
    }

    return 'Title Not On File';

  }

  public companyName(): string {

    const profile = this.profile;

    if ( !profile ) {
      return null;
    }

    if ( profile.company && profile.company.trim().length > 0 ) {
      return profile.company.trim();
    }

    return 'Company Not On File';

  }

  public showMap(): boolean {

    const profile = this.profile;

    if ( !profile || !profile.address ) {
      return false
    }

    if ( this.profile.address.zip || this.profile.address.state ) {
      return true;
    }

    return false;

  }

  // public titleCompany(): string {
  //
  //   const title = this.titleName();
  //   const company = this.companyName();
  //
  //   if ( title && company ) {
  //     return `${title}, ${company}`;
  //   }
  //   if ( title ) {
  //     return title;
  //   }
  //   if ( company ) {
  //     return company;
  //   }
  //
  //   return null;
  //
  // }

  public getTitle(): string {

    const title = this.titleName();
    const company = this.companyName();

    if ( title && company ) {
      return `${title}, ${company}`;
    }
    if ( title ) {
      return title;
    }
    if ( company ) {
      return company;
    }

    return null;

  }

  public enabledSocial( name: string ): boolean {
    return !!this.socialUrl( name );
  }

  public socialUrl( name: string ): string {

    const field = `${name}ProfileUrl`;

    if ( this.profile && typeof this.profile[ field ] === 'string' && this.profile[ field ].trim().length > 0 ) {
      return this.profile[ field ].trim();
    }

    return null;

  }

  public openSocial( name: string ): void {

    const url = this.socialUrl( name );

    if ( url ) {
      window.open( url, '_blank' );
    }

  }

  public openBuyerQuestionnaire(): void {
    window.open( '/action/buyer-questionnaire-readonly;params=' + this.profile.id, '_blank' );
  }

  public openSellerQuestionnaire(): void {
    window.open( '/action/seller-questionnaire-readonly;params=' + this.profile.id, '_blank' );
  }

  public downloadAvailable( contract: ByUserLegalDocument ): boolean {

    // console.log( 'contract.timestamp ', contract.timestamp );

    // this is a date after we deployed the code to store contracts in archive
    return moment( contract.timestamp ).isAfter( '2019-05-10T00:00:00.0Z' );

  }

  public downloadAuthorized( contract: ByUserLegalDocument ): boolean {

    if ( !this.authenticatedProfile || typeof this.authenticatedProfile.email !== 'string' ) {
      return false;
    }

    // console.log( 'this.authenticatedProfile.id', this.authenticatedProfile.id );
    // console.log( 'this.authenticatedProfile.email', this.authenticatedProfile.email );
    // console.log( 'contract.adminUsers', contract.adminUsers );

    if ( this.authenticatedProfile.email.match( /.+@goexio\.com$/ ) ) {
      return true;
    }

    return contract.adminUsers.indexOf( this.authenticatedProfile.id ) > -1;

  }

  public downloadContract( contract: ByUserLegalDocument ): void {

    const url = contract.pdfUrl;

    if ( url ) {
      window.open( url, '_blank' );
    }

  }

  public reviewQuestionnaire( session: QuestionnaireSession ) {

    const url = this.location.prepareExternalUrl( '/questionnaire/' + session.submissionId );

    window.open( url, '_blank' );

  }

}
