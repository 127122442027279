/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./entry.component.less.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../content/vimeo.service.component.ngfactory";
import * as i3 from "../../content/vimeo.service.component";
import * as i4 from "../../../services/app";
import * as i5 from "../../../services/api";
import * as i6 from "../../../services/video";
import * as i7 from "@angular/common";
import * as i8 from "./entry.component";
import * as i9 from "@angular/router";
var styles_ViewsVideoEntryComponent = [i0.styles];
var RenderType_ViewsVideoEntryComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_ViewsVideoEntryComponent, data: {} });
export { RenderType_ViewsVideoEntryComponent as RenderType_ViewsVideoEntryComponent };
function View_ViewsVideoEntryComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "div", [["class", "vimeo-player-service"]], null, null, null, i2.View_ContentVimeoServiceComponent_0, i2.RenderType_ContentVimeoServiceComponent)), i1.ɵdid(1, 245760, null, 0, i3.ContentVimeoServiceComponent, [i4.AppService, i5.ApiService, i6.VideoService, i1.ElementRef, i1.NgZone, i1.ChangeDetectorRef], { videoId: [0, "videoId"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.videoId; _ck(_v, 1, 0, currVal_0); }, null); }
export function View_ViewsVideoEntryComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵand(16777216, null, null, 1, null, View_ViewsVideoEntryComponent_1)), i1.ɵdid(1, 16384, null, 0, i7.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.videoId; _ck(_v, 1, 0, currVal_0); }, null); }
export function View_ViewsVideoEntryComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-view-video-entry", [], null, null, null, View_ViewsVideoEntryComponent_0, RenderType_ViewsVideoEntryComponent)), i1.ɵdid(1, 245760, null, 0, i8.ViewsVideoEntryComponent, [i4.AppService, i9.ActivatedRoute], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var ViewsVideoEntryComponentNgFactory = i1.ɵccf("app-view-video-entry", i8.ViewsVideoEntryComponent, View_ViewsVideoEntryComponent_Host_0, {}, {}, []);
export { ViewsVideoEntryComponentNgFactory as ViewsVideoEntryComponentNgFactory };
