
<input class="hidden-file-input" type="file" multiple="multiple">
<div class="cdn-browser-upload" *ngIf="!this.pageIsLoading()">
  <div class="cdn-browser-upload-input">
    <div class="cdn-browser-upload-input-border">
      <div class="cdn-browser-upload-input-label">
        <p>Drop file(s) here or click to upload.</p>
        <p>Max 20MB / File, 10 Files / Batch</p>
      </div>
    </div>
  </div>
</div>
<div class="cnd-browser-filter" *ngIf="!this.pageIsLoading()">
  <input type="text" [(ngModel)]="assetFilter" placeholder="Filter assets by title, modified date or file type">
</div>
<div class="cdn-browser-file-selector" *ngIf="!this.pageIsLoading() &amp;&amp; filesList().length &lt; 1">
  <div class="cdn-browser-files-not-found">No Files Found</div>
</div>
<div class="cdn-browser-file-selector" *ngIf="!this.pageIsLoading() &amp;&amp; filesList().length &gt; 0">
  <div class="cdn-browser-files">
    <div class="cdn-browser-counts">Showing {{ filesList().length }} of {{ this.files.length }} Assets</div>
    <div class="cdn-browser-file" *ngFor="let file of filesList(); let i = index;">
      <div class="cdn-browser-file-contents">
        <div class="filename">
          <div class="filename-content">{{ file.filename }}</div>
        </div><a class="link" [href]="downloadLink(file)" target="_blank">
          <div class="preview" [style.background-image]="previewImage(file)"></div></a>
        <div class="progress-percent" *ngIf="fileIsUploading(file) &amp;&amp; file.progress &lt; 0">Uploading...</div>
        <div class="progress-percent" *ngIf="fileIsUploading(file) &amp;&amp; file.progress &gt; -1">{{ file.progress | percent : '1.1-2' }}%</div>
        <div class="modified" *ngIf="!fileIsUploading(file)">{{ file.modified | date:'short' }}</div>
        <div class="size" *ngIf="!fileIsUploading(file)">{{ fileSize(file) }}</div>
        <div class="actions" *ngIf="!fileIsUploading(file) &amp;&amp; deletingFile !== i"><a class="link" [href]="downloadLink(file)" target="_blank"><span class="glyphicon glyphicon-cloud-download"></span></a><span class="glyphicon glyphicon-copy copy-link" [attr.data-clipboard-text]="downloadLink(file, false)"></span><span class="glyphicon glyphicon-copy copy-link blur-copy" [attr.data-clipboard-text]="downloadLinkBlur(file, 40)"></span><span class="glyphicon glyphicon-remove delete-file" (click)="deletingFile = i"></span></div>
        <div class="delete-file-confirm" *ngIf="!fileIsUploading(file) &amp;&amp; deletingFile === i">
          <div class="question">Are you sure?</div>
          <div class="confirm confirm-no" (click)="deletingFile = null">No</div>
          <div class="confirm confirm-yes" (click)="deleteFile(file)">Yes</div>
        </div>
      </div>
    </div>
  </div>
</div>