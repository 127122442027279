import {
  Component,
  Input,
  SimpleChanges,
  ElementRef,
  OnInit,
  OnDestroy,
  AfterViewChecked,
  OnChanges
} from '@angular/core';
import {AppService} from 'app/services/app';
import {DomSanitizer, SafeUrl} from '@angular/platform-browser';
import {Article} from 'app/models/article';
import {Profile} from 'app/models/profile';
import {Router} from '@angular/router';
import {Hash} from 'app/types/containers';

type ContentType = Article;
type ModeTypes = 'normal' | 'marketplace' | 'services' | 'blog' | 'video';

@Component({
  moduleId: module.id
})
class ContentTilesBaseTileComponent implements OnDestroy {

  public authenticatedProfile: Profile = null;

  @Input()
  public article: ContentType = null;

  @Input()
  public mode: ModeTypes = 'normal';

  constructor(public app: AppService, public sanitizer: DomSanitizer, public router: Router) {

    this.subscriptions.push(this.app.getAuthenticatedProfile({
      next: (profile) => {

        if (profile && profile.email) {
          this.authenticatedProfile = profile;
        } else {
          this.authenticatedProfile = null;
        }

      },
      error: () => {
        this.authenticatedProfile = null;
      },
      complete: () => {
        this.authenticatedProfile = null;
      }
    }));

  }

  public subscriptions: any[] = [];

  public ngOnDestroy(): void {
    this.subscriptions.forEach((subscription) => {
      subscription.unsubscribe();
    });
  }

  public hasCategory(category: string): boolean {

    if (!this.article || !Array.isArray(this.article.categories)) {
      return false;
    }

    if (typeof category !== 'string') {
      category = '';
    }
    category = category.trim().toLowerCase();

    return this.article.categories.indexOf(category) > -1;

  }

  public showRibbon(): boolean {

    return this.hasCategory('coming-soon') ||
      this.hasCategory('coming soon') ||
      this.hasCategory('sample');

  }

  public ribbonText(): string {

    if (!this.showRibbon()) {
      return null;
    }

    if (this.hasCategory('sample')) {
      return 'Sample';
    } else if (this.hasCategory('coming-soon') ||
      this.hasCategory('coming soon')) {
      return 'Coming Soon';
    }

  }

  public isPlatinum(): boolean {
    return this.hasCategory('platinum');
  }

  public isComingSoon(): boolean {
    return this.hasCategory('coming soon') || this.hasCategory('coming-soon');
  }

  public markdownToHtml(markdown: string): string {
    return this.app.markdownToHtml(markdown).replace(/^\s*<p>\s*/, '').replace(/\s*<\/p>\s*$/, '');
  }

  public tileImgUrl(article: Article, large?: boolean): string {
    let url = large ? (article.tileImgLargeUrl || article.tileImgUrl) : (article.tileImgUrl || article.tileImgLargeUrl);

    if (!url) {
      return null;
    }

    url = this.app.exio.image.getUrl(url, <any>{
      format: 'jpg',
      actions: [
        {
          type: 'shrink',
          height: large ? 417 : 212
        },
        {
          type: 'quality',
          quality: 100
        }
      ]
    });

    return `url("${url}")`;

  }

  public bannerImgUrl(article: Article) {
    return `url("${article.bannerImgUrl}")`;
  }

  public iconUrl(article: Article): string {

    let fragment = null;

    if (article.path) {
      fragment = article.path.replace(/^.*\//, '');
    }
    if (typeof fragment !== 'string') {
      return '';
    }

    fragment = fragment.replace(/-/g, '_');

    return `/assets/images/icons/${fragment}_icon.svg`;

  }

  public imageUrl(url: string): string {
    return `url('${url}')`;
  }

  public getAction() {
    let action = null;

    if (this.article && Array.isArray(this.article.actions) && this.article.actions.length > 0) {
      action = this.article.actions[0];
    }

    return action;
  }

  public actionUrl(action: any, article: any): string {
    return this.pathUrl(action.actionParams && action.actionParams.href || article.path);
  }

  public actionFragment(action: any, article: any): string {
    return this.pathFragment(action.actionParams && action.actionParams.href || article.path);
  }

  public pathUrl(url: string) {

    url = url.replace(/#.*$/, '').trim();

    if (url.length < 1) {
      return null;
    }

    return `/${url}`;

  }

  public pathFragment(url: string) {

    const fragment = url.replace(/^[^#]*#?/, '').trim();

    if (fragment.length < 1) {
      return null;
    }

    return fragment;

  }

  public getTitle(filter?: (str: string) => string): string {

    let title = (<Article>this.article).title;

    const parts = title.split(/\n/);

    if (typeof filter === 'function') {
      parts.forEach((part, i) => {
        parts[i] = filter.apply(this, [part]);
      });
    }
    title = parts.join('\n');

    return title.replace(/\n/g, '<br/>');

  }

  public go(article: Article): void {

    if (article && article.path) {
      this.router.navigate([article.path]);
    }

  }

  public getArticleLink(article: Article): string {

    if (!article) {
      return null;
    }

    if (typeof article.path === 'string' && article.path.trim().length > 0) {
      return article.path.trim();
    }

    if (Array.isArray(article.actions)) {

      let actionLink = null;
      article.actions.forEach((action) => {
        if (!actionLink && action && action.actionType === 'url') {
          actionLink = this.actionUrl(action, article);
        }
      });

      if (actionLink) {
        return actionLink;
      }

    }

    return null;

  }

  public contentContainerClasses(): Object {

    const classes = {};

    if (parseInt(this.article.bannerOpacity, 10) > 0) {
      const opacity = `opacity-${this.article.bannerOpacity}`;
      classes[opacity] = true;
    }

    return classes;

  }

}

@Component({
  moduleId: module.id,
  selector: '.app-content-tiles-post-tile',
  templateUrl: 'tiles.post.component.pug',
  styleUrls: ['tiles.post.component.less']
})
export class ContentTilesPostTileComponent extends ContentTilesBaseTileComponent {

  @Input()
  public article: ContentType = null;

  @Input()
  public mode: ModeTypes = 'normal';

  @Input()
  public index: number = null;

  constructor(public app: AppService, public sanitizer: DomSanitizer, public router: Router) {
    super(app, sanitizer, router);
  }

  public classes(): {} {

    const classes = {
      'is-even': this.isEven(),
      'is-odd': !this.isEven()
    };

    // classes[ 'nth-child-' + this.index ] = true;

    const twoColumnTallChild = ((this.index + 1) % 4) === 0 || (this.index % 4) === 0;
    const twoColumnShortChild = ((this.index + 2) % 4) === 0 || ((this.index + 3) % 4) === 0;
    classes['two-column-tall-child'] = twoColumnTallChild;
    classes['two-column-short-child'] = twoColumnShortChild;

    return classes;
  }

  public isEven(): boolean {
    return this.index !== null && (Math.floor(this.index / 2) * 2) === this.index;
  }

  public showTallClass(): boolean {
    return this.index !== null && (Math.floor(this.index / 2) * 2) === this.index;
  }

  public getMarketPlaceTagLine(): string {

    if (this.article && Array.isArray(this.article.categories) && this.article.categories.indexOf('incubator') > -1) {
      return 'Incubator';
    }

    return 'Market Maker';

  }

  public showFavoriteToggle(): boolean {

    return this.authenticatedProfile !== null &&
      typeof this.authenticatedProfile.id === 'string' &&
      this.authenticatedProfile.id.length > 0 &&
      this.article !== null &&
      (this.article.type === 'listing' || this.article.type === 'buyer');

  }

  public isFavorite(): boolean {
    if (!this.authenticatedProfile || !Array.isArray(this.authenticatedProfile.favoriteListings) || !this.article) {
      return false;
    }

    return this.authenticatedProfile.favoriteListings.indexOf(this.article.id) > -1;

  }

  public toggleFavorite(e: Event): void {

    e.preventDefault();
    e.stopPropagation();

    if (!this.authenticatedProfile || !this.article || !this.article.id) {
      return;
    }

    if (!Array.isArray(this.authenticatedProfile.favoriteListings)) {
      this.authenticatedProfile.favoriteListings = [];
    }

    if (this.isFavorite()) {

      const index = this.authenticatedProfile.favoriteListings.indexOf(this.article.id);

      if (index > -1) {
        this.authenticatedProfile.favoriteListings.splice(index, 1);
      }

    } else {
      this.authenticatedProfile.favoriteListings.push(this.article.id);
    }

    this.app.profileModel.save(this.authenticatedProfile)
      .then(() => {
        // NO-OP
        // console.log( 'done syncing favorites', this.authenticatedProfile.favoriteListings );
      })
      .catch((e) => {
        // NO-OP
        console.error('failed to save favorite listing', e);
      });

  }

}

@Component({
  moduleId: module.id,
  selector: '.app-content-tiles-blog-tile',
  templateUrl: 'tiles.blog.component.pug',
  styleUrls: ['tiles.blog.component.less']
})
export class ContentTilesBlogTileComponent extends ContentTilesBaseTileComponent {
  @Input()
  public article: ContentType = null;

  @Input()
  public mode: ModeTypes = 'normal';

  constructor(public app: AppService, public sanitizer: DomSanitizer, public router: Router) {
    super(app, sanitizer, router);
  }

}


@Component({
  moduleId: module.id,
  selector: '.app-content-tiles-video-tile',
  templateUrl: 'tiles.video.component.pug',
  styleUrls: ['tiles.video.component.less']
})
export class ContentTilesVideoTileComponent extends ContentTilesBaseTileComponent {
  @Input()
  public article: ContentType = null;

  @Input()
  public mode: ModeTypes = 'normal';

  constructor(public app: AppService, public sanitizer: DomSanitizer, public router: Router) {
    super(app, sanitizer, router);
  }

}

@Component({
  moduleId: module.id,
  selector: '.app-content-tiles-service-tile',
  templateUrl: 'tiles.service.component.pug',
  styleUrls: ['tiles.service.component.less']
})
export class ContentTilesServiceTileComponent extends ContentTilesBaseTileComponent {
  @Input()
  public article: ContentType = null;

  @Input()
  public mode: ModeTypes = 'normal';

  constructor(public app: AppService, public sanitizer: DomSanitizer, public router: Router) {
    super(app, sanitizer, router);
  }

}

@Component({
  moduleId: module.id,
  selector: '.app-content-tiles',
  templateUrl: 'tiles.component.pug',
  styleUrls: ['tiles.component.less']
})
export class ContentTilesComponent implements OnInit, OnDestroy, AfterViewChecked, OnChanges {

  public authenticatedProfile: Profile = null;

  @Input()
  public title: string;

  @Input()
  public disableAd: boolean;

  @Input()
  public articles: ContentType[];
  // public articlesShow: ContentType[];

  @Input()
  public mode: ModeTypes = 'normal';

  @Input()
  public layoutComplete: () => {} = null;

  public articlesLoaded: boolean = false;

  public gridImagesLoaded: boolean = false;

  public placeHolderText: string = 'Please enter your email address';

  public subscribeEmail: string = null;

  public subscribing: boolean = false;
  public subscribeComplete: boolean = false;

  public grid: any = null;

  public span2AssignedCount: number = 0;
  public span2CheckCount: number = 0;
  public span2AssignCache: Hash<boolean> = {};

  public subscriptions: any[] = [];

  constructor(public app: AppService, public el: ElementRef, public sanitizer: DomSanitizer, public router: Router) {

    this.subscriptions.push(this.app.getAuthenticatedProfile({
      next: (profile) => {

        if (profile && profile.email) {
          this.authenticatedProfile = profile;
          this.subscribeEmail = profile.email;
        } else {
          this.authenticatedProfile = null;
          this.subscribeEmail = null;
        }

      },
      error: () => {
        this.authenticatedProfile = null;
        this.subscribeEmail = null;
      },
      complete: () => {
        this.authenticatedProfile = null;
        this.subscribeEmail = null;
      }
    }));

  }


  public ngOnInit(): void {
    // clone
    // this.articlesShow = this.articles.slice( 0 );
  }

  public ngOnChanges(changes: SimpleChanges): void {

    // this.articlesLoaded = false;
    // this.gridImagesLoaded = false;

    if (this.grid) {
      this.grid.masonry('layout');
    }

  }

  public ngOnDestroy(): void {
    this.subscriptions.forEach((subscription) => {
      subscription.unsubscribe();
    });
  }

  public signUpSeller(): void {

    this.router.navigate(['/selling']);

  }

  public signUpBuyer(): void {

    this.router.navigate(['/buying']);

  }

  public getRandomIntInclusive(min: number, max: number): number {
    min = Math.ceil(min);
    max = Math.floor(max);
    return Math.floor(Math.random() * (max - min + 1)) + min;
  }

  ngAfterViewChecked(): void {

    if (this.articles.length < 1) {
      return;
    }

    if (!this.gridImagesLoaded) {

      window.setTimeout(() => {

        const $grid: any = $(this.el.nativeElement).find('.grid');

        if ($grid.find('.grid-item').length > 0) {

          this.gridImagesLoaded = true;

          this.grid = $grid.masonry({
            columnWidth: 300,
            horizontalOrder: true,
            itemSelector: '.grid-item',
            fitWidth: false,
            gutter: 30,
            transitionDuration: '0.5s'
          });

          if (this.layoutComplete) {
            this.grid.on('layoutComplete', this.layoutComplete);
          }

          window.setTimeout(() => {
            if (this.grid) {
              this.grid.masonry('layout');
            }
          }, 500);

          (<any>$grid.imagesLoaded)({background: true}).always(() => {
            window.setTimeout(() => {
              if (this.grid) {
                this.grid.masonry('layout');
              }
            }, 500);
          });

        }
      }, 100);

    }

    if (!this.articlesLoaded) {

      this.articlesLoaded = true;

      window.setTimeout(() => {
        this.grid.masonry('layout');
      }, 500);

      // window.setTimeout( () => {
      //
      // 	if ( this.disableAd || true ) {
      //
      // 		if ( this.grid ) {
      //
      // 			window.setTimeout( () => {
      // 				this.grid.masonry( 'layout' );
      // 			}, 500 );
      // 		}
      //
      // 	} else {
      //
      // 		let subscribe: Article = {
      // 			type: 'subscribe'
      // 		};
      //
      // 		if ( this.articles.length >= 5 ) {
      //
      // 			let low = 4;
      // 			let high = Math.min( this.articles.length, 6 );
      //
      // 			let insertSpot = this.getRandomIntInclusive( low, high );
      //
      // 			this.articles.splice( insertSpot, 0, subscribe );
      //
      // 		} else if ( this.articles.length > 0 ) {
      // 			this.articles.push( subscribe );
      // 		}
      //
      // 		if ( this.grid ) {
      // 			window.setTimeout( () => {
      // 				console.log( 'ad layout!' );
      // 				this.grid.masonry( 'layout' );
      // 			}, 5000 );
      // 		}
      //
      // 	}
      // } );

    }

  }

  public subscribe(): boolean {

    this.subscribing = true;

    window.setTimeout(() => {

      this.subscribing = false;
      this.subscribeComplete = true;

      window.setTimeout(() => {

        this.subscribeEmail = null;
        this.subscribeComplete = false;

      }, 3000);

    }, 3000);

    return false;

  }

}
