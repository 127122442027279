import * as tslib_1 from "tslib";
import { ApiService } from 'app/services/api';
// Types
import { GenericModel, GenericSchemaType } from './generic';
// Schema Type
var Listing = /** @class */ (function (_super) {
    tslib_1.__extends(Listing, _super);
    function Listing() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    return Listing;
}(GenericSchemaType));
export { Listing };
var ListingFileUploadUrl = /** @class */ (function () {
    function ListingFileUploadUrl() {
    }
    return ListingFileUploadUrl;
}());
export { ListingFileUploadUrl };
var ListingFileOwner = /** @class */ (function () {
    function ListingFileOwner() {
    }
    return ListingFileOwner;
}());
export { ListingFileOwner };
var ListingFile = /** @class */ (function () {
    function ListingFile() {
    }
    return ListingFile;
}());
export { ListingFile };
var ListingModel = /** @class */ (function (_super) {
    tslib_1.__extends(ListingModel, _super);
    function ListingModel(api) {
        var _this = _super.call(this) || this;
        _this.init(api, 'listing', {
            id: null,
            name: null,
            type: null,
            userId: null,
            created: null,
            updated: null,
            deleted: false,
            data: null
        }, 0);
        return _this;
    }
    ListingModel.prototype.getInstance = function (ref) {
        return ref;
    };
    ListingModel.prototype.myRecords = function () {
        return this.api.call('listing.list', {});
    };
    ListingModel.prototype.getUploadUrl = function (params) {
        return this.api.call('listing.getUploadUrl', params);
    };
    ListingModel.prototype.listUploads = function (params) {
        return this.api.call('listing.listUploads', params);
    };
    ListingModel.prototype.listPublishedUploads = function (params) {
        return this.api.call('listing.listPublishedUploads', params);
    };
    ListingModel.prototype.deleteUpload = function (params) {
        return this.api.call('listing.deleteUpload', params);
    };
    ListingModel.prototype.archiveUpload = function (params) {
        return this.api.call('listing.archiveUpload', params);
    };
    return ListingModel;
}(GenericModel));
export { ListingModel };
