
<div class="input-type-dropdown-container" [class.show-selector]="showSelector">
  <div class="input-type-dropdown-value">
    <input class="input-type-dropdown-value-contents" *ngIf="showSelector" (blur)="blur()" type="text" [(ngModel)]="filter" (change)="filterChanged()" placeholder="Filter" autofocus="autofocus">
    <div class="input-type-dropdown-value-contents" *ngIf="!showSelector" (click)="toggleOpen()">{{ currentDisplayValue }}</div>
    <div class="component-icon-container" (click)="toggleOpen()">
      <div class="component-icon" [class.arrow-down]="!showSelector" [class.arrow-up]="showSelector"></div>
    </div>
  </div>
  <div class="input-type-dropdown-selector">
    <div class="input-type-dropdown-selector-scroll-container">
      <div class="input-type-dropdown-selector-container">
        <div class="input-type-dropdown-selector-option-container" *ngFor="let option of filteredOptions()" (click)="clickOptionItem(option)">
          <div class="input-type-dropdown-selector-option">{{ option[1] }}</div>
        </div>
      </div>
    </div>
  </div>
</div>
<select class="hidden-select">
  <option value="" selected>Please Select</option>
</select>