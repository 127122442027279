import { Injectable } from '@angular/core';
import { BehaviorSubject ,  Observer ,  Subscription } from 'rxjs';

@Injectable()
export class PopUpService {

	constructor() {
	}

}
