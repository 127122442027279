import {Component, Input, ChangeDetectorRef, OnInit, OnDestroy, ElementRef, NgZone} from '@angular/core';

import {ExioImageServiceOptionsActions} from 'app/services/exio';
import {AppService} from 'app/services/app';
import {ApiService} from 'app/services/api';
import {VideoService} from 'app/services/video';

type Dimensions = {
  height: number;
  width: number;
};

type MetaData = {
  name: string;
  description: string;
  height: number;
  width: number;
  duration: number;
  files: {}[];
  pictures: {
    active: boolean;
    sizes: {
      height: number;
      width: number;
      link: string;
      link_with_play_button: string;
    }[]
  };
};


type FilLTypes = 'width' | 'height';

@Component({
  moduleId: module.id,
  selector: '.vimeo-player-service',
  templateUrl: 'vimeo.service.component.pug',
  styleUrls: ['vimeo.service.component.less']
})
export class ContentVimeoServiceComponent implements OnInit, OnDestroy {

  @Input()
  public videoId: string = null;

  @Input()
  public thumbnailUrl: string = null;

  @Input()
  public fillType: FilLTypes = 'width';

  @Input()
  public locked: boolean = false;

  @Input()
  public lockedCallback: () => void = null;

  @Input()
  public hideTitle: boolean = false;

  @Input()
  public hideDescription: boolean = false;

  @Input()
  public metaData: MetaData = null;

  public resizeHandler: () => void = null;

  public dimensionConstraint: Dimensions = null;

  public $: {
    host: JQuery;
    modal: JQuery;
    container: JQuery;
    message: JQuery;
    wait: JQuery;
    content: JQuery;
    thumbnail: JQuery;
    thumbnailPlay: JQuery;
  } = {
    host: null,
    container: null,
    modal: null,
    message: null,
    wait: null,
    content: null,
    thumbnail: null,
    thumbnailPlay: null
  };

  public state: {
    message: string;
    sliding: boolean;
    wait: boolean;
  };

  constructor(public appService: AppService, public apiService: ApiService, public videoService: VideoService, public el: ElementRef, public zone: NgZone, public changeDetector: ChangeDetectorRef) {

  }

  public init(): void {

    this.state = {
      message: null,
      sliding: false,
      wait: true,
    };

  }

  public ngOnInit(): void {

    this.zone.runOutsideAngular(() => {
      this.init();
      this.cacheJQueryHandles();
      this.loadMetaData();
    });

  }

  public subscriptions: any[] = [];

  public clearResizeHandler(): void {

    if (this.resizeHandler) {
      $('html body').off('scroll', this.resizeHandler);
      $(window).off('resize', this.resizeHandler);
      this.$.host.off('resize', this.resizeHandler);
      this.resizeHandler = null;
    }

  }

  public ngOnDestroy(): void {

    this.subscriptions.forEach((subscription) => {
      subscription.unsubscribe();
    });

    this.clearResizeHandler();

  }

  public getDimensions(fillType?: FilLTypes): Dimensions {

    if (typeof fillType !== 'string' || fillType.trim().length < 1) {
      fillType = this.fillType;
    }

    let d: Dimensions = {
      height: 0,
      width: 0
    };

    if (!this.metaData) {
      return d;
    }

    d = {
      height: this.metaData.height,
      width: this.metaData.width
    };

    // this.videoId === '202407368' && console.log( 'fillType', this.videoId, this.fillType );

    let constraint = this.dimensionConstraint;

    if (constraint && d.height !== 0 && d.width !== 0) {

      let hRatio = constraint.height / d.height;
      let wRatio = constraint.width / d.width;

      // if either height or width is larger than the constraint
      if (hRatio < 1 || wRatio < 1) {

        if (hRatio < wRatio) { // if height is more larger than width
          d.height = constraint.height;
          d.width = Math.floor(d.width * hRatio);
        } else { // if width is more larger than height
          d.width = constraint.width;
          d.height = Math.floor(d.height * wRatio);
        }

      } else if (hRatio > 1 || wRatio > 1) {

        if (hRatio < wRatio) { // if height is less smaller than width
          d.height = constraint.height;
          d.width = Math.floor(d.width * hRatio);
        } else { // if width is less smaller than height
          d.width = constraint.width;
          d.height = Math.floor(d.height * wRatio);
        }

      }

    } else if (fillType === 'width') {

      // container's width sets the pace
      d.width = this.$.host.width();

      // if the video meta data specifies a width, calculate the height
      // according to the ratio of the native video width relative to the
      // width of the container's width
      if (this.metaData.width > 0) {
        d.height = Math.floor(this.metaData.height / this.metaData.width * d.width + 0.5);
      }

      // this.videoId === '202407368' && console.log( 'd 1 host.width', d );

    } else {

      // container's width sets the pace
      d.height = this.$.host.height();

      // if the video meta data specifies a width, calculate the height
      // according to the ratio of the native video width relative to the
      // width of the container's width
      if (this.metaData.height > 0) {
        d.width = Math.floor(this.metaData.width / this.metaData.height * d.height + 0.5);
      }

      // this.videoId === '202407368' && console.log( 'd 2 host.height', d );

    }

    return d;

  }

  public updateUI(): void {

    let log = function (one?: any, two?: any, three?: any, four?: any, five?: any, six?: any) {

      let args = Array.prototype.slice.call(arguments);
      args.unshift('updateUI');

      // console.log.apply( console, args );

    };

    log('this.state', this.state);

    this.setThumbnail();

    // showing a message hides everything else
    if (this.state.message) {

      log('show message', this.state.message);

      this.$.message.html(this.state.message);

      return;
    }

    log('hide message');

    this.$.message.html();

    this.state.wait = false;

    let dimensions = this.getDimensions();

    [
      this.$.container,
      this.$.message,
      this.$.content,
      this.$.thumbnail
    ].forEach((el: JQuery) => {

      log('setting dimension', dimensions, 'to', el);
      el.css('height', dimensions.height);
      el.css('width', dimensions.width);

    });

    log('setting height to host', dimensions.height);
    this.$.host.css('height', dimensions.height);

  }

  public setMessage(message: string): void {

    this.state.message = message;
    this.updateUI();

  }

  public setThumbnail(): void {

    let actions: ExioImageServiceOptionsActions[] = <any>[
      {
        type: 'shrink',
        width: 1440
      },
      {
        type: 'quality',
        quality: 100
      }
    ];

    if (this.locked) {
      actions.push(<any>{
        type: 'blur',
        radius: 90
      });
    }

    if (this.thumbnailUrl) {

      let url = this.thumbnailUrl;

      url = this.appService.exio.image.getUrl(url, {
        format: 'jpg',
        actions: actions
      });

      this.$.thumbnail.css('background-image', `url('${url}')`);
      return;
    }

    let metaData = this.metaData;
    let bestMatch: any = null;
    let link = null;

    if (!metaData || !metaData.pictures || !metaData.pictures.sizes) {
      this.$.thumbnail.css('background-image', null);
    } else {

      metaData.pictures.sizes.forEach((picture) => {

        if (bestMatch === null) {
          bestMatch = picture;
        }

        if (picture.height > bestMatch.height) {
          bestMatch = picture;
        }

      });
    }

    if (bestMatch) {
      link = bestMatch.link || bestMatch.link_with_play_button || null;
    }

    if (link) {

      link = this.appService.exio.image.getUrl(link, {
        format: 'jpg',
        actions: actions
      });

      this.$.thumbnail.css('background-image', `url('${link}')`);
    } else {
      this.$.thumbnail.css('background-image', null);
    }

  }

  public play(): void {

    if (this.locked) {

      if (typeof this.lockedCallback === 'function') {
        this.lockedCallback();
      }

    } else {
      this.videoService.load({videoId: this.videoId, metaData: this.metaData || null});
      this.videoService.play();
    }

  }

  public pause(): void {
    this.videoService.pause();
  }

  public registerControlsPlayPauseEventHandlers() {

    if (this.$.thumbnail.hasClass('event-handlers')) {
      return;
    }
    this.$.thumbnail.addClass('event-handlers');

    this.$.thumbnail.on('click', () => {
      this.play();
    });

  }

  public registerControlsEventHandlers() {

    this.registerControlsPlayPauseEventHandlers();

    let $window = $(window);
    let $body = $('html body');
    let $host = this.$.host;

    let requestedFrame = false;

    if (!this.resizeHandler) {

      // console.log( 'resizeHandler? NO' );
      let run = () => {

        // console.log( 'resize handler' );

        if (requestedFrame) {
          return;
        }
        requestedFrame = true;

        requestAnimationFrame(() => {
          requestedFrame = false;

          this.updateUI();
        })

      };

      this.resizeHandler = function () {
        run();
      };

      $body.scroll(this.resizeHandler);
      $window.resize(this.resizeHandler);
      $host.resize(this.resizeHandler);

    }

  }

  public cacheJQueryHandles(): void {

    this.$.host = $(this.el.nativeElement);

    this.$.container = this.$.host.find('.vimeo-container');

    this.$.message = this.$.host.find('.vimeo-message');
    this.$.wait = this.$.host.find('.vimeo-wait');
    this.$.content = this.$.host.find('.vimeo-content');

    this.$.thumbnail = this.$.host.find('.vimeo-content .vimeo-thumbnail');
    this.$.thumbnailPlay = this.$.host.find('.vimeo-content .vimeo-thumbnail .vimeo-thumbnail-play');

  }

  public loadMetaData(): void {

    if (this.metaData) {

      // allow UI to settle, then fire this
      window.setTimeout(() => {
        this.registerControlsEventHandlers();

        this.updateUI();

        this.changeDetector.markForCheck();
      });

      return;
    }

    this.apiService.call('vimeo.byId', {id: this.videoId})
      .then((metaData: MetaData): void => {

        this.metaData = metaData || null;

        // allow UI to settle, then fire this
        window.setTimeout(() => {
          this.registerControlsEventHandlers();

          this.updateUI();

          this.changeDetector.markForCheck();
        });

      })
      .catch((err: any) => {

        console.error('error loading video meta data', this.videoId, err);
        this.setMessage('There was an error loading the video. Please reload the page and try again.');

        this.changeDetector.markForCheck();

      });

  }

  public hasTitle(): boolean {

    if (!this.metaData || this.hideTitle) {
      return false;
    }

    return typeof this.metaData.name === 'string' && this.metaData.name.trim().length > 0;

  }

  public hasDescription(): boolean {

    if (!this.metaData || this.hideDescription) {
      return false;
    }

    return typeof this.metaData.description === 'string' && this.metaData.description.trim().length > 0;

  }

  public getDescription(): string {

    if (!this.hasDescription()) {
      return '';
    }

    return this.appService.twoLineString(this.metaData.description);

  }

}
