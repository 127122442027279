/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./leadership.profile.component.css.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/router";
import * as i3 from "@angular/common";
import * as i4 from "./leadership.profile.component";
import * as i5 from "../../../services/app";
import * as i6 from "@angular/platform-browser";
var styles_ViewsLearnLeadershipProfileComponent = [i0.styles];
var RenderType_ViewsLearnLeadershipProfileComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_ViewsLearnLeadershipProfileComponent, data: {} });
export { RenderType_ViewsLearnLeadershipProfileComponent as RenderType_ViewsLearnLeadershipProfileComponent };
function View_ViewsLearnLeadershipProfileComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "div", [["class", "profile-img"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 0, "div", [["class", "profile-img-background"]], [[4, "background-image", null]], null, null, null, null))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.sanitizer.bypassSecurityTrustStyle(_co.imgUrl(_co.profile.headshotURL)); _ck(_v, 1, 0, currVal_0); }); }
function View_ViewsLearnLeadershipProfileComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "div", [["class", "name"]], null, null, null, null, null)), (_l()(), i1.ɵted(1, null, ["", " ", ""]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.profile.firstName; var currVal_1 = _co.profile.lastName; _ck(_v, 1, 0, currVal_0, currVal_1); }); }
function View_ViewsLearnLeadershipProfileComponent_4(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "div", [["class", "title"]], null, null, null, null, null)), (_l()(), i1.ɵted(1, null, ["", ""]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.profile.title; _ck(_v, 1, 0, currVal_0); }); }
function View_ViewsLearnLeadershipProfileComponent_5(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 0, "div", [["class", "bio"]], [[8, "innerHTML", 1]], null, null, null, null))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.markdownToHtml(_co.profile.bio); _ck(_v, 0, 0, currVal_0); }); }
function View_ViewsLearnLeadershipProfileComponent_6(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "div", [["class", "next"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 1).onClick() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(1, 16384, null, 0, i2.RouterLink, [i2.Router, i2.ActivatedRoute, [8, null], i1.Renderer2, i1.ElementRef], { routerLink: [0, "routerLink"] }, null), (_l()(), i1.ɵted(2, null, ["Next - ", " ", ""]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.nextLink(_co.profileNext); _ck(_v, 1, 0, currVal_0); }, function (_ck, _v) { var _co = _v.component; var currVal_1 = _co.profileNext.firstName; var currVal_2 = _co.profileNext.lastName; _ck(_v, 2, 0, currVal_1, currVal_2); }); }
function View_ViewsLearnLeadershipProfileComponent_7(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "a", [["class", "seo-link"]], [[8, "href", 4]], null, null, null, null)), (_l()(), i1.ɵted(1, null, ["", " ", ""]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = ("/learn/leadership/" + _co.profileNext.id); _ck(_v, 0, 0, currVal_0); var currVal_1 = _co.profile.firstName; var currVal_2 = _co.profile.lastName; _ck(_v, 1, 0, currVal_1, currVal_2); }); }
function View_ViewsLearnLeadershipProfileComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 20, "div", [["class", "content"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 19, "div", [["class", "content-container"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 2, "div", [["class", "profile-img-container"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ViewsLearnLeadershipProfileComponent_2)), i1.ɵdid(4, 16384, null, 0, i3.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(5, 0, null, null, 6, "div", [["class", "content-text"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ViewsLearnLeadershipProfileComponent_3)), i1.ɵdid(7, 16384, null, 0, i3.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ViewsLearnLeadershipProfileComponent_4)), i1.ɵdid(9, 16384, null, 0, i3.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ViewsLearnLeadershipProfileComponent_5)), i1.ɵdid(11, 16384, null, 0, i3.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(12, 0, null, null, 8, "div", [["class", "controls-container"]], null, null, null, null, null)), (_l()(), i1.ɵeld(13, 0, null, null, 7, "div", [["class", "controls"]], null, null, null, null, null)), (_l()(), i1.ɵeld(14, 0, null, null, 2, "div", [["class", "back"], ["routerLink", "/leadership"]], [[2, "no-next", null]], [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 15).onClick() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(15, 16384, null, 0, i2.RouterLink, [i2.Router, i2.ActivatedRoute, [8, null], i1.Renderer2, i1.ElementRef], { routerLink: [0, "routerLink"] }, null), (_l()(), i1.ɵted(-1, null, ["<\u00A0\u00A0Back"])), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ViewsLearnLeadershipProfileComponent_6)), i1.ɵdid(18, 16384, null, 0, i3.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ViewsLearnLeadershipProfileComponent_7)), i1.ɵdid(20, 16384, null, 0, i3.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.profile.headshotURL; _ck(_v, 4, 0, currVal_0); var currVal_1 = (_co.profile.firstName || _co.profile.lastName); _ck(_v, 7, 0, currVal_1); var currVal_2 = _co.profile.title; _ck(_v, 9, 0, currVal_2); var currVal_3 = _co.profile.bio; _ck(_v, 11, 0, currVal_3); var currVal_5 = "/leadership"; _ck(_v, 15, 0, currVal_5); var currVal_6 = _co.profileNext; _ck(_v, 18, 0, currVal_6); var currVal_7 = _co.profileNext; _ck(_v, 20, 0, currVal_7); }, function (_ck, _v) { var _co = _v.component; var currVal_4 = !_co.profileNext; _ck(_v, 14, 0, currVal_4); }); }
export function View_ViewsLearnLeadershipProfileComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "div", [["class", "back-mobile"], ["routerLink", "/leadership"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 1).onClick() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(1, 16384, null, 0, i2.RouterLink, [i2.Router, i2.ActivatedRoute, [8, null], i1.Renderer2, i1.ElementRef], { routerLink: [0, "routerLink"] }, null), (_l()(), i1.ɵted(-1, null, ["<\u00A0\u00A0Back"])), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ViewsLearnLeadershipProfileComponent_1)), i1.ɵdid(4, 16384, null, 0, i3.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = "/leadership"; _ck(_v, 1, 0, currVal_0); var currVal_1 = _co.profile; _ck(_v, 4, 0, currVal_1); }, null); }
export function View_ViewsLearnLeadershipProfileComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-view-learn-leadership-profile", [], null, null, null, View_ViewsLearnLeadershipProfileComponent_0, RenderType_ViewsLearnLeadershipProfileComponent)), i1.ɵdid(1, 245760, null, 0, i4.ViewsLearnLeadershipProfileComponent, [i5.AppService, i2.ActivatedRoute, i6.DomSanitizer], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var ViewsLearnLeadershipProfileComponentNgFactory = i1.ɵccf("app-view-learn-leadership-profile", i4.ViewsLearnLeadershipProfileComponent, View_ViewsLearnLeadershipProfileComponent_Host_0, {}, {}, []);
export { ViewsLearnLeadershipProfileComponentNgFactory as ViewsLearnLeadershipProfileComponentNgFactory };
