/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./main.component.css.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../content/loader.spinner.component.ngfactory";
import * as i3 from "../../content/loader.spinner.component";
import * as i4 from "@angular/common";
import * as i5 from "./main.component";
import * as i6 from "../../../services/app";
import * as i7 from "@angular/router";
var styles_ViewsContractsMainComponent = [i0.styles];
var RenderType_ViewsContractsMainComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_ViewsContractsMainComponent, data: {} });
export { RenderType_ViewsContractsMainComponent as RenderType_ViewsContractsMainComponent };
function View_ViewsContractsMainComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "div", [["class", "error-message"]], null, null, null, null, null)), (_l()(), i1.ɵted(1, null, ["", ""]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.errorMessage; _ck(_v, 1, 0, currVal_0); }); }
function View_ViewsContractsMainComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "div", [["class", "search-results-list-loading"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "div", [["class", "loader-spinner"]], null, null, null, i2.View_ContentLoaderSpinnerComponent_0, i2.RenderType_ContentLoaderSpinnerComponent)), i1.ɵdid(2, 49152, null, 0, i3.ContentLoaderSpinnerComponent, [], null, null)], null, null); }
function View_ViewsContractsMainComponent_5(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 6, "div", [["class", "search-result-field document-controls col-xs-12 col-md-2"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "div", [["class", "document-control"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Are you sure?"])), (_l()(), i1.ɵeld(3, 0, null, null, 1, "div", [["class", "document-control control-link document-remove"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        _co.deletingDocument = null;
        var pd_0 = (_co.deleteDocument(_v.parent.context.$implicit) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, ["Yes"])), (_l()(), i1.ɵeld(5, 0, null, null, 1, "div", [["class", "document-control control-link document-remove"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = ((_co.deletingDocument = null) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, ["No"]))], null, null); }
function View_ViewsContractsMainComponent_6(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 14, "div", [["class", "search-result-field document-controls col-xs-12 col-md-2"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "div", [["class", "document-control control-link document-edit"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.editDocument(_v.parent.context.$implicit) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, ["Edit"])), (_l()(), i1.ɵeld(3, 0, null, null, 1, "div", [["class", "document-control"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["|"])), (_l()(), i1.ɵeld(5, 0, null, null, 1, "div", [["class", "document-control control-link document-remove"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.editDocumentInstances(_v.parent.context.$implicit) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, ["Instances"])), (_l()(), i1.ɵeld(7, 0, null, null, 1, "div", [["class", "document-control"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["|"])), (_l()(), i1.ɵeld(9, 0, null, null, 1, "div", [["class", "document-control control-link document-duplicate"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.duplicateDocument(_v.parent.context.$implicit) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, ["Copy"])), (_l()(), i1.ɵeld(11, 0, null, null, 1, "div", [["class", "document-control"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["|"])), (_l()(), i1.ɵeld(13, 0, null, null, 1, "div", [["class", "document-control control-link document-remove"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = ((_co.deletingDocument = _v.parent.context.index) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, ["Delete"]))], null, null); }
function View_ViewsContractsMainComponent_4(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 10, "div", [["class", "search-result"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "div", [["class", "search-result-field document-name col-xs-6 col-md-5"]], null, null, null, null, null)), (_l()(), i1.ɵted(2, null, ["", ""])), (_l()(), i1.ɵeld(3, 0, null, null, 1, "div", [["class", "search-result-field document-url col-xs-5 col-md-4"]], null, null, null, null, null)), (_l()(), i1.ɵted(4, null, ["", ""])), (_l()(), i1.ɵeld(5, 0, null, null, 1, "div", [["class", "search-result-field document-instances col-xs-1 col-md-1"]], null, null, null, null, null)), (_l()(), i1.ɵted(6, null, ["", ""])), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ViewsContractsMainComponent_5)), i1.ɵdid(8, 16384, null, 0, i4.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ViewsContractsMainComponent_6)), i1.ɵdid(10, 16384, null, 0, i4.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_3 = (_co.deletingDocument === _v.context.index); _ck(_v, 8, 0, currVal_3); var currVal_4 = (_co.deletingDocument !== _v.context.index); _ck(_v, 10, 0, currVal_4); }, function (_ck, _v) { var currVal_0 = _v.context.$implicit.name; _ck(_v, 2, 0, currVal_0); var currVal_1 = _v.context.$implicit.url; _ck(_v, 4, 0, currVal_1); var currVal_2 = _v.context.$implicit.instanceCount; _ck(_v, 6, 0, currVal_2); }); }
function View_ViewsContractsMainComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 11, "div", [["class", "search-results-list"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 8, "div", [["class", "search-result search-result-first hidden-xs"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 1, "div", [["class", "search-result-field document-name col-xs-6 col-md-5"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Name"])), (_l()(), i1.ɵeld(4, 0, null, null, 1, "div", [["class", "search-result-field document-url col-xs-5 col-md-4"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["URL"])), (_l()(), i1.ɵeld(6, 0, null, null, 1, "div", [["class", "search-result-field document-instances col-xs-1 col-md-1"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Instances"])), (_l()(), i1.ɵeld(8, 0, null, null, 1, "div", [["class", "search-result-field document-controls col-xs-12 col-md-2"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Actions"])), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ViewsContractsMainComponent_4)), i1.ɵdid(11, 278528, null, 0, i4.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.results.documents; _ck(_v, 11, 0, currVal_0); }, null); }
function View_ViewsContractsMainComponent_7(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "div", [["class", "search-results-not-found"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["No Articles Found"]))], null, null); }
export function View_ViewsContractsMainComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "div", [["class", "page-title"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Contracts"])), (_l()(), i1.ɵeld(2, 0, null, null, 2, "div", [["class", "document-list-controls"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 1, "div", [["class", "document-add control-link"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.addDocument() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, ["Add"])), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ViewsContractsMainComponent_1)), i1.ɵdid(6, 16384, null, 0, i4.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(7, 0, null, null, 6, "div", [["class", "search-results"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ViewsContractsMainComponent_2)), i1.ɵdid(9, 16384, null, 0, i4.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ViewsContractsMainComponent_3)), i1.ɵdid(11, 16384, null, 0, i4.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ViewsContractsMainComponent_7)), i1.ɵdid(13, 16384, null, 0, i4.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.errorMessage; _ck(_v, 6, 0, currVal_0); var currVal_1 = _co.documentsLoading; _ck(_v, 9, 0, currVal_1); var currVal_2 = ((!_co.documentsLoading && _co.results) && (_co.results.documents.length > 0)); _ck(_v, 11, 0, currVal_2); var currVal_3 = (!_co.documentsLoading && (!_co.results || (_co.results.documents.length < 1))); _ck(_v, 13, 0, currVal_3); }, null); }
export function View_ViewsContractsMainComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "div", [["class", "app-view-contracts-main"]], null, null, null, View_ViewsContractsMainComponent_0, RenderType_ViewsContractsMainComponent)), i1.ɵdid(1, 114688, null, 0, i5.ViewsContractsMainComponent, [i6.AppService, i7.Router], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var ViewsContractsMainComponentNgFactory = i1.ɵccf(".app-view-contracts-main", i5.ViewsContractsMainComponent, View_ViewsContractsMainComponent_Host_0, {}, {}, []);
export { ViewsContractsMainComponentNgFactory as ViewsContractsMainComponentNgFactory };
