
<div class="signup-types-container">
  <div class="signup-type-container" *ngFor="let signupTypeDef of getSignupTypeDefs()" [class.selected-type]="signupTypeDef.id === signupType" (click)="changeSelection(signupTypeDef.id)">
    <div class="signup-type">
      <div class="name">{{ signupTypeDef.name }}</div>
      <div class="selected" *ngIf="signupTypeDef.id === signupType">Selected</div>
      <div class="selected" *ngIf="signupTypeDef.id !== signupType"></div>
      <div class="description" [innerHTML]="appService.markdownToHtml(signupTypeDef.description)"></div>
      <div class="points-container">
        <div class="point-container" *ngFor="let point of signupTypeDef.points">
          <div class="check-box">
            <div class="component-icon check active"></div>
          </div>
          <div class="point-description">{{ point }}</div>
        </div>
      </div>
      <div class="action" (click)="signupGo(signupTypeDef)">
        <div class="title">Request Access</div>
      </div>
      <div class="video-action-container">
        <div class="video-action" (click)="playVideo(signupTypeDef)">
          <div class="video-icon">
            <div class="component-icon video-play active"></div>
          </div>
          <div class="video-text">See the Difference</div>
        </div>
      </div>
    </div>
  </div>
</div>