import {Injectable} from '@angular/core';
import {BehaviorSubject, Observer, Subscription} from 'rxjs';

import {Hash, HashTree} from 'app/types/containers';

export interface ChatWorkFlowLoad {
  id: string;
  idleWait?: number;
  page?: number;
  force?: boolean;
  replacements?: HashTree<string | number | null | boolean>
  options?: HashTree<string | number | null | boolean>
}

export interface ChatWorkFlowPageItemParagraph {
  type: 'paragraph';
  params: {
    content: string;
  };
}

export interface ChatWorkFlowPageItemBreak {
  type: 'br';
}

export interface ChatWorkFlowPageItemLogin {
  type: 'login';
  params?: {}
}

export interface ChatWorkFlowPageItemEmailLogin {
  type: 'emailLogin';
  params?: {}
}

export interface ChatWorkFlowPageItemChecklist {
  type: 'checklist';
  params: {
    name: string;
    checked: boolean;
  }[];
}

export interface ChatWorkFlowPageItemContract {
  type: 'contract';
  params: {
    path: string;
    placeholder?: {};
    hideInputs?: {};
    postInput?: string;
    checked?: boolean;
    buyerFee?: boolean;
  };
}

export type ChatWorkFlowPageItem =
  ChatWorkFlowPageItemParagraph
  | ChatWorkFlowPageItemBreak
  | ChatWorkFlowPageItemLogin
  | ChatWorkFlowPageItemEmailLogin
  | ChatWorkFlowPageItemChecklist
  | ChatWorkFlowPageItemContract;

export interface ChatWorkFlowPageLink {
  id: string;
  page?: number;
}

export interface ChatWorkFlowRedirect {
  url: string;
  return?: string;
}

export interface ChatWorkFlowPage {
  link?: ChatWorkFlowPageLink;
  redirect?: ChatWorkFlowRedirect;
  title?: string;
  items?: ChatWorkFlowPageItem[];
  continueText?: string;
  continueDisabled?: boolean;
}

export interface ChatWorkFlow {
  authorEmail: string;
  pages: ChatWorkFlowPage[];
}

import {chatDefAccessPending} from 'app/services/chat/access-pending';
import {chatDefAccessPending3Days} from 'app/services/chat/access-pending-3days';
import {chatDefThankYou} from 'app/services/chat/thank-you';
import {chatDefListingPublicCommon} from 'app/services/chat/listing-public-common';
import {chatDefBuyerPublicCommon} from 'app/services/chat/buyer-public-common';
import {chatDefCanoPublicCommon} from 'app/services/chat/cano-public-common';
import {chatDefListingBuyerFeePublicCommon} from 'app/services/chat/listing-buyer-fee-public-common';
import {chatDefLoggedOut} from 'app/services/chat/logged-out';

const workFlowDefinitions: Hash<ChatWorkFlow> = {
  'logged-out': chatDefLoggedOut,
  'access-pending': chatDefAccessPending,
  'access-pending-3days': chatDefAccessPending3Days,
  'thank-you': chatDefThankYou,
  'listing-public-common': chatDefListingPublicCommon,
  'buyer-public-common': chatDefBuyerPublicCommon,
  'cano-public-common': chatDefCanoPublicCommon,
  'listing-buyer-fee-public-common': chatDefListingBuyerFeePublicCommon,
};

@Injectable()
export class ChatService {

  private workflowId: string = null;

  private loadSubject: BehaviorSubject<ChatWorkFlowLoad> = new BehaviorSubject<ChatWorkFlowLoad>(null);
  private completeSubject: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(null);
  private pageAdvanceSubject: BehaviorSubject<ChatWorkFlowPage> = new BehaviorSubject<ChatWorkFlowPage>(null);

  constructor() {
  }

  public currentWorkflowId(): string {
    return this.workflowId;
  }

  // public load( workflowId: string, idleWait?: number, force?: boolean ): void {
  public load(workflowLoad: ChatWorkFlowLoad): void {

    if (!workflowLoad.force && this.currentWorkflowId() === workflowLoad.id) {
      return;
    }

    if (typeof workflowLoad.idleWait !== 'number' || workflowLoad.idleWait < 0) {
      workflowLoad.idleWait = 0;
    }

    this.workflowId = workflowLoad.id;

    this.loadSubject.next(workflowLoad);

  }

  public unload(): void {

    this.workflowId = null;
    this.loadSubject.next(null);

  }

  public complete(success: boolean): void {

    if (!this.workflowId) {
      return;
    }

    this.completeSubject.next(success);

  }

  public pageAdvance(page: ChatWorkFlowPage): void {
    this.pageAdvanceSubject.next(page);
  }

  public onLoad(observer: Observer<ChatWorkFlowLoad>): Subscription {
    return this.loadSubject.subscribe(observer);
  }

  public onComplete(observer: Observer<boolean>): Subscription {
    return this.completeSubject.subscribe(observer);
  }

  public onPageAdvance(observer: Observer<ChatWorkFlowPage>): Subscription {
    return this.pageAdvanceSubject.subscribe(observer);
  }

  public getWorkFlowDef(workflowId: string): ChatWorkFlow {

    if (workFlowDefinitions.hasOwnProperty(workflowId)) {
      // poor-man's clone
      return <ChatWorkFlow>JSON.parse(JSON.stringify(workFlowDefinitions[workflowId]));
    }

    return null;
  }

}
