/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./section.review.component.less.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "./section.review.component";
import * as i4 from "../../../services/app";
import * as i5 from "../../../services/logic";
import * as i6 from "@angular/router";
import * as i7 from "@angular/platform-browser";
var styles_ViewsWorkflowSectionReviewComponent = [i0.styles];
var RenderType_ViewsWorkflowSectionReviewComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_ViewsWorkflowSectionReviewComponent, data: {} });
export { RenderType_ViewsWorkflowSectionReviewComponent as RenderType_ViewsWorkflowSectionReviewComponent };
function View_ViewsWorkflowSectionReviewComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "div", [["class", "item-review"]], null, null, null, null, null)), (_l()(), i1.ɵted(1, null, ["", ""]))], null, function (_ck, _v) { var currVal_0 = _v.context.$implicit.value; _ck(_v, 1, 0, currVal_0); }); }
function View_ViewsWorkflowSectionReviewComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 7, "div", [["class", "page-review col-md-4 col-xs-12"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "div", [["class", "page-title"]], null, null, null, null, null)), (_l()(), i1.ɵted(2, null, ["", ""])), (_l()(), i1.ɵeld(3, 0, null, null, 2, "div", [["class", "items-review"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ViewsWorkflowSectionReviewComponent_2)), i1.ɵdid(5, 278528, null, 0, i2.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null), (_l()(), i1.ɵeld(6, 0, null, null, 1, "div", [["class", "page-action"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.goToPage(_v.context.$implicit) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(7, null, ["", ""]))], function (_ck, _v) { var _co = _v.component; var currVal_1 = _co.getItems(_v.context.$implicit); _ck(_v, 5, 0, currVal_1); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.pageTitle(_v.context.$implicit); _ck(_v, 2, 0, currVal_0); var currVal_2 = _co.editTitle(_v.context.$implicit); _ck(_v, 7, 0, currVal_2); }); }
export function View_ViewsWorkflowSectionReviewComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "div", [["class", "page-container"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 2, "div", [["class", "pages-review"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ViewsWorkflowSectionReviewComponent_1)), i1.ɵdid(3, 278528, null, 0, i2.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.getPages(); _ck(_v, 3, 0, currVal_0); }, null); }
export function View_ViewsWorkflowSectionReviewComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "page-item-content-section-review", [], null, null, null, View_ViewsWorkflowSectionReviewComponent_0, RenderType_ViewsWorkflowSectionReviewComponent)), i1.ɵdid(1, 114688, null, 0, i3.ViewsWorkflowSectionReviewComponent, [i4.AppService, i5.LogicService, i1.ElementRef, i6.ActivatedRoute, i2.Location, i6.Router, i7.DomSanitizer, i1.NgZone], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var ViewsWorkflowSectionReviewComponentNgFactory = i1.ɵccf("page-item-content-section-review", i3.ViewsWorkflowSectionReviewComponent, View_ViewsWorkflowSectionReviewComponent_Host_0, { section: "section", workflowTargetState: "workflowTargetState", workflowDefinition: "workflowDefinition", go: "go" }, {}, []);
export { ViewsWorkflowSectionReviewComponentNgFactory as ViewsWorkflowSectionReviewComponentNgFactory };
