import { Component, OnInit, OnDestroy } from '@angular/core';
import { AppService } from 'app/services/app';
import { LegalDocument, LegalDocumentInstance, LegalDocumentInstanceSigner, MyLegalDocumentRecord } from 'app/models/legal.document';
import { Profile } from 'app/models/profile';
import { Router } from '@angular/router';

@Component( {
	moduleId: module.id,
	selector: '.content-render-my-legal-documents',
	templateUrl: 'my.legal.documents.component.html',
	styleUrls: [ 'my.legal.documents.component.css' ]
} )
export class ContentRenderMyLegalDocumentsComponent implements OnInit, OnDestroy {

	public records: MyLegalDocumentRecord[] = [];
	public profile: Profile = null;

	constructor( public app: AppService, public router: Router ) {

	}

	public subscriptions: any[] = [];

	public ngOnDestroy(): void {
		this.subscriptions.forEach( ( subscription ) => {
			subscription.unsubscribe();
		} );
	}

	public ngOnInit(): void {

		// console.log( 'init my legal documents' );
		this.subscriptions.push( this.app.getAuthenticatedProfile( {
			next: ( profile ) => {

				if ( profile ) {
					this.profile = profile;
					this.loadRecords();
				}

			},
			error: () => {
				this.profile = null;
				this.records = [];
			},
			complete: () => {
				this.profile = null;
				this.records = [];
			}
		} ) );

	}

	public loadRecords(): void {
		this.app.legalDocumentModel.myRecords()
			.then( ( records: MyLegalDocumentRecord[] ) => {
				this.app.contentLoading( false );
				this.records = records;
			} )
			.catch( () => {
				this.app.contentLoading( false );
				this.records = [];
			} );
	}

	public markdownToHtml( markdownStr: string ): string {

		if ( typeof markdownStr !== 'string' ) {
			markdownStr = '';
		}

		return this.app.markdownToHtml( markdownStr );

	}

	public viewDocument( record: MyLegalDocumentRecord ): void {
		window.open( '/view/' + record.document.url + '/' + record.instance.id, '_blank', '', true );
	}

	public signDocument( record: MyLegalDocumentRecord ): void {
		window.open( '/sign/' + record.document.url + '/' + record.instance.id, '_blank', '', true );
	}

}
