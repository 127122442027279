import { Injectable } from '@angular/core';
import { ApiService } from 'app/services/api';
import * as Bluebird from 'bluebird';

// Schema Type
export class CDNFile {
  filename: string;
  contentType: string;
  modified: string;
  preview: string;
  progress: number;
  size: number;
}

export class SignedRequest {
  requestUrl: string;
  requestContentType: string;
  downloadUrl: string;
}

@Injectable()
export class CdnModel {

  constructor( public api: ApiService ) {
  }

  public list( params: { path: string } ): Bluebird<CDNFile[]> {
    return this.api.call( 'aws.cdn.listFiles', params );
  }

  public delete( filenames: string[] ): Bluebird<any> {
    return this.api.call( 'aws.cdn.deleteFiles', { filenames: filenames } );
  }

  public getSignedUploadRequest( params: { filename: string } ): Bluebird<SignedRequest> {
    return this.api.call( 'aws.cdn.getSignedUploadRequest', params );
  }

  public getSignedDownloadRequest( params: { filename?: string, url?: string } ): Bluebird<SignedRequest> {
    return this.api.call( 'aws.cdn.getSignedDownloadRequest', params );
  }

}



