import { Component, OnInit, OnDestroy } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';

import { AppService } from 'app/services/app';
import {
	LegalDocument,
	LegalDocumentInstance,
	LegalDocumentInstanceSigner,
	LegalDocumentSection,
	SearchResponse
} from 'app/models/legal.document';
import { Profile } from 'app/models/profile';
import { Hash } from 'app/types/containers';

@Component( {
	moduleId: module.id,
	selector: '.app-view-contracts-instance-main',
	templateUrl: 'main.component.html',
	styleUrls: [ 'main.component.css' ]
} )
export class ViewsContractsInstanceMainComponent implements OnInit, OnDestroy {

	public removeInstanceCheck: number = null;
	public document: LegalDocument = null;
	public instances: LegalDocumentInstance[] = [];

	public profiles: Hash<Profile> = {};

	public instancesLoading: boolean = true;

	// see if a delete was requested for a instance
	public deletingDocument: number = null;

	constructor( public app: AppService, public router: Router, public route: ActivatedRoute ) {
		this.instancesLoading = true;
		this.app.contentLoading( true );
		this.app.toolbar.whiteOverContent = false;
		this.app.toolbar.backgroundColor = null;
		this.app.footer.showMenu = false;
	}

	public subscriptions: any[] = [];

	public ngOnDestroy(): void {
		this.subscriptions.forEach( ( subscription ) => {
			subscription.unsubscribe();
		} );
	}

	ngOnInit(): void {
		document.body.scrollTop = 0;

		this.subscriptions.push( this.route.params.subscribe( ( params: { id: string } ) => {
			this.setDocumentId( params.id );
		} ) );

	}

	public setDocumentId( id: string ): void {

		this.app.legalDocumentModel
			.instancesByLegalDocumentId( id )
			.then( ( instances: LegalDocumentInstance[] ) => {
				this.instances = instances;
				this.instancesLoading = false;
				this.app.contentLoading( false );
			} )
			.catch( () => {
				this.instances = null;
				this.instancesLoading = false;
				this.app.contentLoading( false );
			} );

		this.app.legalDocumentModel
			.get( id )
			.then( ( document: LegalDocument ) => {
				this.document = document;
			} )
			.catch( () => {
				this.document = null;
			} );


	}

	public markdownToHtml( markdownStr: string ): string {

		if ( typeof markdownStr !== 'string' ) {
			markdownStr = '';
		}

		markdownStr = markdownStr.replace( /\n/g, ' ' ).replace( /\s\s*/g, ' ' ).trim();

		return this.app.markdownToHtml( markdownStr );

	}

	public authorName( id: string ): string {

		if ( typeof id !== 'string' || id.length < 1 ) {
			return null;
		}

		if ( this.profiles[ id ] ) {
			let profile = this.profiles[ id ];
			return profile.firstName + ' ' + profile.lastName;
		}

		if ( !this.profiles.hasOwnProperty( id ) ) {

			this.profiles[ id ] = null;

			this.app.profileModel.get( id )
				.then( ( profile: Profile ) => {
					this.profiles[ id ] = profile;
				} );

		}

		return 'Loading...';

	}

	public signatureCount( instance: LegalDocumentInstance ): string {

		let unsignedCount = 0;
		let signedCount = 0;

		instance.signers.forEach( ( signer ) => {

			if ( !signer ) {
				return;
			}

			if ( typeof signer.signed === 'string' && signer.signed.length > 0 ) {
				signedCount++;
			} else {
				unsignedCount++;
			}

		} );

		return `( ${signedCount} / ${instance.signers.length} )`;

	}

	public editInstance( instance: LegalDocumentInstance ): void {

		if ( !instance || !instance.id ) {
			return;
		}

		this.router.navigate( [ 'contracts', instance.legalDocumentId, 'instance', instance.id ] );
	}

	public addInstance(): void {
		this.router.navigate( [ 'contracts', this.document.id, 'instance', 'new' ] );
	}

	public removeInstance( instance: LegalDocumentInstance ): void {

		if ( !instance || !instance.id ) {
			return;
		}

		this.router.navigate( [ '/contracts/instance/', instance.id ] );
	}

}
