/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./loader.spinner.component.css.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "./loader.spinner.component";
var styles_ContentLoaderSpinnerComponent = [i0.styles];
var RenderType_ContentLoaderSpinnerComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_ContentLoaderSpinnerComponent, data: {} });
export { RenderType_ContentLoaderSpinnerComponent as RenderType_ContentLoaderSpinnerComponent };
export function View_ContentLoaderSpinnerComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 9, "div", [["class", "cube-grid"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 0, "div", [["class", "cube cube1"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 0, "div", [["class", "cube cube2"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 0, "div", [["class", "cube cube3"]], null, null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 0, "div", [["class", "cube cube4"]], null, null, null, null, null)), (_l()(), i1.ɵeld(5, 0, null, null, 0, "div", [["class", "cube cube5"]], null, null, null, null, null)), (_l()(), i1.ɵeld(6, 0, null, null, 0, "div", [["class", "cube cube6"]], null, null, null, null, null)), (_l()(), i1.ɵeld(7, 0, null, null, 0, "div", [["class", "cube cube7"]], null, null, null, null, null)), (_l()(), i1.ɵeld(8, 0, null, null, 0, "div", [["class", "cube cube8"]], null, null, null, null, null)), (_l()(), i1.ɵeld(9, 0, null, null, 0, "div", [["class", "cube cube9"]], null, null, null, null, null))], null, null); }
export function View_ContentLoaderSpinnerComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "div", [["class", "loader-spinner"]], null, null, null, View_ContentLoaderSpinnerComponent_0, RenderType_ContentLoaderSpinnerComponent)), i1.ɵdid(1, 49152, null, 0, i2.ContentLoaderSpinnerComponent, [], null, null)], null, null); }
var ContentLoaderSpinnerComponentNgFactory = i1.ɵccf(".loader-spinner", i2.ContentLoaderSpinnerComponent, View_ContentLoaderSpinnerComponent_Host_0, {}, {}, []);
export { ContentLoaderSpinnerComponentNgFactory as ContentLoaderSpinnerComponentNgFactory };
