import {Injectable} from '@angular/core';
import {ApiService} from 'app/services/api';
import * as Bluebird from 'bluebird';

// Types
import {GenericModel} from './generic';

// Schema Type
export type Article = {
  id: string;
  type?: string;
  name: string;
  description: string;
  created?: string;
  modified?: string;
  released?: string;
  tags: string[];
  sortHint?: number;
  duration: number;
  height: number;
  width: number;
  thumbnail: string;
  thumbnailWithPlayButton: string;
}

@Injectable()
export class VimeoModel extends GenericModel<Article> {

  constructor(api: ApiService) {
    super();
    this.init(api, 'vimeo', {
      id: null,
      type: null,
      name: null,
      created: null,
      modified: null,
      released: null,
      sortHint: null,
      description: null,
      duration: null,
      tags: [],
      height: null,
      width: null,
      thumbnail: null,
      thumbnailWithPlayButton: null
    }, 60000);
  }

  public getInstance(ref: any): Article {
    return ref;
  }

  list(): Bluebird<Article[]> {
    return this.api.call('vimeo.list', {});
  }

}
