import { Component, ViewChild, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { Router } from '@angular/router';

import { AppService } from 'app/services/app';
import {
	LegalDocument,
	LegalDocumentInstance,
	LegalDocumentInstanceSigner,
	LegalDocumentSection,
	SearchResponse
} from 'app/models/legal.document';
import { Profile } from 'app/models/profile';
import { Hash } from 'app/types/containers';

@Component( {
	moduleId: module.id,
	selector: '.app-view-contracts-main',
	templateUrl: 'main.component.html',
	styleUrls: [ 'main.component.css' ]
} )
export class ViewsContractsMainComponent implements OnInit {

	public errorMessage: string = null;

	public documentsLoading: boolean = true;
	public results: SearchResponse = null;

	public showSearch: boolean = true;

	constructor( public app: AppService, public router: Router ) {
		this.documentsLoading = true;
		this.app.contentLoading( true );
		this.app.toolbar.whiteOverContent = false;
		this.app.toolbar.backgroundColor = null;
		this.app.footer.showMenu = false;
	}

	ngOnInit(): void {

		document.body.scrollTop = 0;

		this.reset();

	}

	public reset(): void {

		this.app.legalDocumentModel.search( { limit: 10000 } )
			.then( ( result: SearchResponse ) => {
				this.documentsLoading = false;
				this.app.contentLoading( false );
				this.results = result || null;
			} )
			.catch( () => {
				this.documentsLoading = false;
				this.app.contentLoading( false );
				this.results = null;
			} );

	}

	public markdownToHtml( markdownStr: string ): string {

		if ( typeof markdownStr !== 'string' ) {
			markdownStr = '';
		}

		markdownStr = markdownStr.replace( /\n/g, ' ' ).replace( /\s\s*/g, ' ' ).trim();

		return this.app.markdownToHtml( markdownStr );

	}

	public addDocument(): void {

		this.router.navigate( [ '/contracts/', 'new' ] );

	}

	public setErrorMessage( message: string ): void {
		this.errorMessage = message;
		window.setTimeout( () => {
			this.errorMessage = null;
		}, 5000 );
	}

	public editDocumentInstances( document: LegalDocument ): void {
		this.router.navigate( [ '/contracts/', document.id, 'instance' ] );
	}

	public duplicateDocument( document: LegalDocument ): void {
		this.router.navigate( [ '/contracts/', 'duplicate:' + document.id ] );
	}

	public editDocument( document: LegalDocument ): void {
		this.router.navigate( [ '/contracts/', document.id ] );
	}

	public deleteDocument( document: LegalDocument ): void {

		this.app.legalDocumentModel.delete( document.id )
			.then( () => {
				this.reset();
			} )
			.catch( ( err ) => {
				this.reset();
				this.setErrorMessage( 'Error Deleting Document: ' + err.message );
			} );

	}

}
