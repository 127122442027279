import {Component, OnInit, OnDestroy} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {AppService} from 'app/services/app';

@Component({
  moduleId: module.id,
  selector: 'app-view-video-entry',
  templateUrl: 'entry.component.pug',
  styleUrls: ['entry.component.less']
})
export class ViewsVideoEntryComponent implements OnInit, OnDestroy {

  public videoId: string = null;
  public subscriptions: any[] = [];

  constructor(public appService: AppService, public route: ActivatedRoute) {
    this.appService.toolbar.whiteOverContent = false;
    this.appService.toolbar.backgroundColor = null;
    this.appService.contentLoading(true);
    this.appService.titleService.setTitle(`Exio - Video Library`);
  }

  public ngOnDestroy(): void {
    this.subscriptions.forEach((subscription) => {
      subscription.unsubscribe();
    });
  }

  ngOnInit(): void {

    this.appService.contentLoading(true);

    document.body.scrollTop = 0;

    this.subscriptions.push(this.route.params.subscribe((params: { videoId: string }) => {
      this.appService.contentLoading(!params.videoId);
      this.videoId = params.videoId || null;
    }));

  }

}
