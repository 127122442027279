import { Component, OnInit, OnDestroy } from '@angular/core';
import { AppService } from 'app/services/app';
import { Article, SearchResponse } from 'app/models/article';
import { Listing } from 'app/models/listing';
import { Profile } from 'app/models/profile';
import { Router } from '@angular/router';
import { Location } from '@angular/common';

import * as Bluebird from 'bluebird';

@Component( {
  moduleId: module.id,
  selector: 'app-content-render-my-listings',
  templateUrl: 'my.listings.component.html',
  styleUrls: [ 'my.listings.component.css' ]
} )
export class ContentRenderMyListingsComponent implements OnInit, OnDestroy {

  public articles: Article[] = null;
  public listings: Listing[] = null;
  public authenticatedProfile: Profile = null;

  constructor( public app: AppService, public router: Router, public location: Location ) {

  }

  public subscriptions: any[] = [];

  public ngOnDestroy(): void {
    this.subscriptions.forEach( ( subscription ) => {
      subscription.unsubscribe();
    } );
  }

  public ngOnInit(): void {

    this.subscriptions.push( this.app.getAuthenticatedProfile( {
      next: ( profile ) => {

        if ( profile ) {
          this.authenticatedProfile = profile;
          this.loadAll();
        }

      },
      error: () => {
        this.authenticatedProfile = null;
        this.listings = [];
      },
      complete: () => {
        this.authenticatedProfile = null;
        this.listings = [];
      }
    } ) );

  }

  public arraysReady(): boolean {
    return Array.isArray( this.listings ) && Array.isArray( this.articles );
  }

  public loadAll(): void {

    Bluebird
      .all( [
        this.loadArticles(),
        this.loadRecords()
      ] )
      .then( () => {
        this.app.contentLoading( false );
      } )
      .catch( () => {
        this.app.contentLoading( false );
      } );

  }

  public getArticleType( article: Article ): string {

    if ( !article || !Array.isArray( article.categories ) ) {
      return 'Unknown';
    }

    const categories = article.categories
      .map( ( category ) => {
        return typeof category === 'string' ? category.trim().toLowerCase() : '';
      } )
      .filter( ( category ) => {
        return category.length > 0;
      } );

    // console.log( 'categories', categories, categories.indexOf( 'buyer' ) );

    if ( categories.indexOf( 'buyer' ) > -1 ) {
      return 'Buy';
    }

    return 'Sell';

  }

  public editArticlePermissions( article: Article ): void {
    // console.log( 'editArticlePermissions', article );

    const url = this.location.prepareExternalUrl( '/listing-management/listing/' + article.id );

    setTimeout( () => {
      window.open( url, '_blank' );
    }, 0 );

  }

  public viewListing( article: Article ): void {
    // console.log( 'viewListing', article );

    const url = this.location.prepareExternalUrl( article.path );

    setTimeout( () => {
      window.open( url, '_blank' );
    }, 0 );


  }

  public loadArticles(): Bluebird<void> {

    return this.app.articleModel
      .search( {
        query: { myAdmin: true } // only list articles for which I am an admin
      } )
      .then( ( response: SearchResponse ) => {

        // console.log( 'response.articles', response.articles );

        if ( response && Array.isArray( response.articles ) ) {
          this.articles = response.articles;
        } else {
          throw new Error( 'response missing or not articles array' );
        }

      } )
      .catch( ( e ) => {
        console.error( 'failed to load my article records', e );
      } );

  }

  public loadRecords(): Bluebird<void> {

    return this.app.listingModel
      .myRecords()
      .then( ( records: Listing[] ) => {
        this.listings = records;
      } )
      .catch( () => {
        this.listings = [];
      } );

  }

  public markdownToHtml( markdownStr: string ): string {

    if ( typeof markdownStr !== 'string' ) {
      markdownStr = '';
    }

    return this.app.markdownToHtml( markdownStr );

  }

  public addListing( type: 'buyer' | 'seller' ): void {
    // console.log( 'add listing' );

    this.router.navigate( [
      '/action/edit-' + type + '-listing/begin/target-init/new'
    ] );

  }

  public getType( listing: Listing ): string {

    let type = 'Sell';

    if ( listing && listing.type === 'buyer' ) {
      type = 'Buy';
    }

    return type;

  }

  public editListing( listing: Listing ): void {
    // console.log( 'edit listing', listing );

    if ( listing.type === 'buyer' ) {
      this.router.navigate( [
        '/action/edit-buyer-listing/start/target-init/', listing.id
      ] );
    } else {
      this.router.navigate( [
        '/action/edit-seller-listing/start/target-init/', listing.id
      ] );
    }

  }

  public removeListing( listing: Listing ): void {

    // console.log( 'remove listing', listing );

    const handler = () => {
      this.loadRecords();
    };

    this.app.listingModel.delete( listing.id )
      .then( handler )
      .catch( handler );

  }

}
