/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./banner.carousel.component.less.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "./banner.component.ngfactory";
import * as i4 from "./banner.component";
import * as i5 from "../../services/app";
import * as i6 from "@angular/platform-browser";
import * as i7 from "@angular/router";
import * as i8 from "../../services/video";
import * as i9 from "./banner.carousel.component";
var styles_ContentBannerCarouselComponent = [i0.styles];
var RenderType_ContentBannerCarouselComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_ContentBannerCarouselComponent, data: {} });
export { RenderType_ContentBannerCarouselComponent as RenderType_ContentBannerCarouselComponent };
function View_ContentBannerCarouselComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "div", [["class", "page"]], null, null, null, null, null)), i1.ɵdid(1, 278528, null, 0, i2.NgClass, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), (_l()(), i1.ɵeld(2, 0, null, null, 1, "app-content-banner", [], null, null, null, i3.View_ContentBannerComponent_0, i3.RenderType_ContentBannerComponent)), i1.ɵdid(3, 245760, null, 0, i4.ContentBannerComponent, [i5.AppService, i6.DomSanitizer, i7.Router, i8.VideoService, i1.NgZone], { article: [0, "article"], topPadding: [1, "topPadding"], blur: [2, "blur"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = "page"; var currVal_1 = _co.getPageClasses(_v.context.index); _ck(_v, 1, 0, currVal_0, currVal_1); var currVal_2 = _v.context.$implicit; var currVal_3 = _co.topPadding; var currVal_4 = _co.locked; _ck(_v, 3, 0, currVal_2, currVal_3, currVal_4); }, null); }
function View_ContentBannerCarouselComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "div", [["class", "locked-icon"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.clickedLock() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 0, "div", [["class", "component-icon lock-white"]], null, null, null, null, null))], null, null); }
function View_ContentBannerCarouselComponent_5(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "div", [["class", "control"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.selectPage(_v.context.index) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(1, 278528, null, 0, i2.NgClass, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = "control"; var currVal_1 = _co.getPageClasses(_v.context.index); _ck(_v, 1, 0, currVal_0, currVal_1); }, null); }
function View_ContentBannerCarouselComponent_4(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "div", [["class", "controls-container dots-mode"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 2, "div", [["class", "controls"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ContentBannerCarouselComponent_5)), i1.ɵdid(3, 278528, null, 0, i2.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.getArticles(); _ck(_v, 3, 0, currVal_0); }, null); }
function View_ContentBannerCarouselComponent_8(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "div", [["class", "control-next-content"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["SEE NEXT"]))], null, null); }
function View_ContentBannerCarouselComponent_9(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "div", [["class", "control-next-content"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["RESTART"]))], null, null); }
function View_ContentBannerCarouselComponent_7(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, "div", [["class", "control-next"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.selectNextPage() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ContentBannerCarouselComponent_8)), i1.ɵdid(2, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ContentBannerCarouselComponent_9)), i1.ɵdid(4, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = !_co.isLastPage(); _ck(_v, 2, 0, currVal_0); var currVal_1 = _co.isLastPage(); _ck(_v, 4, 0, currVal_1); }, null); }
function View_ContentBannerCarouselComponent_6(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 6, "div", [["class", "controls-container arrows-mode"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "div", [["class", "control-left"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.selectPreviousPage() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 0, "div", [["class", "component-icon arrow-large-left"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 1, "div", [["class", "control-right"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.selectNextPage() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 0, "div", [["class", "component-icon arrow-large-right"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ContentBannerCarouselComponent_7)), i1.ɵdid(6, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = (_co.navigationMode === "arrows"); _ck(_v, 6, 0, currVal_0); }, null); }
function View_ContentBannerCarouselComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 9, "div", [["class", "slider"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 8, "div", [["class", "slider-container"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ContentBannerCarouselComponent_2)), i1.ɵdid(3, 278528, null, 0, i2.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ContentBannerCarouselComponent_3)), i1.ɵdid(5, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ContentBannerCarouselComponent_4)), i1.ɵdid(7, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ContentBannerCarouselComponent_6)), i1.ɵdid(9, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.getArticles(); _ck(_v, 3, 0, currVal_0); var currVal_1 = _co.locked; _ck(_v, 5, 0, currVal_1); var currVal_2 = ((_co.navigationMode === "dots") && (_co.getArticleCount() > 1)); _ck(_v, 7, 0, currVal_2); var currVal_3 = (((_co.navigationMode === "arrows") || (_co.navigationMode === "gallery")) && (_co.getArticleCount() > 1)); _ck(_v, 9, 0, currVal_3); }, null); }
export function View_ContentBannerCarouselComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵand(16777216, null, null, 1, null, View_ContentBannerCarouselComponent_1)), i1.ɵdid(1, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = (_co.getArticleCount() > 0); _ck(_v, 1, 0, currVal_0); }, null); }
export function View_ContentBannerCarouselComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "div", [["class", "app-content-banner-carousel"]], null, null, null, View_ContentBannerCarouselComponent_0, RenderType_ContentBannerCarouselComponent)), i1.ɵdid(1, 245760, null, 0, i9.ContentBannerCarouselComponent, [i5.AppService, i1.ChangeDetectorRef], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var ContentBannerCarouselComponentNgFactory = i1.ɵccf(".app-content-banner-carousel", i9.ContentBannerCarouselComponent, View_ContentBannerCarouselComponent_Host_0, { articles: "articles", categories: "categories", navigationMode: "navigationMode", topPadding: "topPadding", locked: "locked", lockedCallback: "lockedCallback" }, {}, []);
export { ContentBannerCarouselComponentNgFactory as ContentBannerCarouselComponentNgFactory };
