
<div class="component-container">
  <div class="information">
    <p>WARNING: Resetting a user is permanent and can not be reversed!</p>
    <p>This tool resets user accounts used for testing and demoing purposes. The emails listed here are white listed to prevent clearing valid, real accounts.</p>
    <p>All fields and data not listed here will remain as they were prior to a reset. The data that will be reset will be cleared or reset to defaults as follows:</p>
    <ul>
      <li>CRM fields:
        <ol>
          <li>Access Level -> Public</li>
          <li>Identity Verified -> Un-checked</li>
          <li>Contact Method -> E-mail</li>
          <li>Hear About Us -> Cleared</li>
          <li>Activity Type -> Buyer</li>
          <li>Preferred Industry -> Cleared</li>
          <li>Preferred Region -> Cleared</li>
          <li>Preferred PriceRange -> Cleared</li>
          <li>Network Partner -> Un-checked</li>
          <li>Featured Network Partner -> Un-checked</li>
          <li>Featured Network Partner Description -> Cleared</li>
        </ol>
      </li>
      <li>VDR Uploads</li>
      <li>All Signatures on Legal Documents (though PDF records of the signature remain archived on the contact record in the CRM)</li>
      <li>Social Network Links</li>
      <li>Permissions on any listings/profiles/articles/etc. Both private and exclusive, as well as pending states for access.</li>
      <li>All user favorites: listings/profiles/etc.</li>
    </ul>
  </div>
  <div class="list-container">
    <div class="list-rows">
      <div class="list-row-container header-row">
        <div class="list-row">
          <div class="list-field">
            <div class="field-label">Email</div>
            <div class="field-label error-field" *ngIf="error">Error Occurred: {{ error }}</div>
          </div>
          <div class="list-field actions-field">
            <div class="field-label">Actions</div>
          </div>
        </div>
      </div>
      <div class="list-row-container" *ngFor="let email of emails">
        <div class="list-row">
          <div class="list-field">
            <div class="field-label">{{ email }}</div>
          </div>
          <div class="list-field actions-field" *ngIf="email !== confirmUser &amp;&amp; !processing[email]">
            <div class="action" (click)="resetUser(email)">Reset</div>
          </div>
          <div class="list-field actions-field" *ngIf="email === confirmUser">
            <div class="field-label">This action can not be reversed. Are you sure?</div>
            <div class="action" (click)="confirmResetUser()">Yes</div>
            <div class="action" (click)="clear()">No</div>
          </div>
          <div class="list-field actions-field" *ngIf="processing[email]">
            <div class="loader-bar"></div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>