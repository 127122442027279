import {Component, OnInit, ElementRef, OnDestroy} from '@angular/core';
import {DomSanitizer, SafeStyle} from '@angular/platform-browser';
import {Router} from '@angular/router';
import {Profile} from 'app/models/profile';
import {AppService} from 'app/services/app';
import {Article} from 'app/models/article';
import * as moment from 'moment-timezone';
import {Article as VimeoArticle} from 'app/models/vimeo';
import {VideoService} from 'app/services/video';

type SubscribeResult = {
  thumbnail?: string;
  subscribe: boolean;
};

// type ContentResult = VimeoArticle;

@Component({
  moduleId: module.id,
  selector: '.app-view-video-main',
  templateUrl: 'main.component.pug',
  styleUrls: ['main.component.less']
})
export class ViewsVideoMainComponent implements OnInit, OnDestroy {

  public $el: JQuery;
  public articles: Article[] = [];
  public bannerArticle: Article = null;
  public layoutCompleteWrapper: () => void = null;
  public loadingContent: boolean = true;

  public filterCategory: string = '*';
  public filterOptions: string[][] = [];

  public sortField: string = 'none';
  public sortOptions: string[][] = [
    ['none', 'Sort By'],
    ['title', 'Title'],
    ['newest', 'Newest'],
    ['oldest', 'Oldest']
  ];

  public showSubscribe: boolean = true;
  public subscribeComplete: boolean = false;
  public subscribeEmail: string = null;
  public profile: Profile = null;

  constructor(public appService: AppService, public videoService: VideoService, public el: ElementRef, public sanitizer: DomSanitizer, public router: Router) {

    this.appService.contentLoading(false);

    this.appService.toolbar.whiteOverContent = true;
    this.appService.toolbar.backgroundColor = null;

    this.appService.titleService.setTitle(`Exio - Video Library`);

  }

  public subscriptions: any[] = [];

  public ngOnDestroy(): void {
    this.subscriptions.forEach((subscription) => {
      subscription.unsubscribe();
    });
  }

  ngOnInit(): void {

    this.$el = $(this.el.nativeElement);

    document.body.scrollTop = 0;

    this.resetFilter();
    this.loadBanner();
    this.loadContent();

    this.subscriptions.push(this.appService.getAuthenticatedProfile({
      next: (profile) => {
        this.profile = profile || null;
        if (this.profile && this.profile.email) {
          this.subscribeEmail = this.profile.email;
        }
      },
      error: () => {
        this.profile = null;
      },
      complete: () => {
        this.profile = null;
      }
    }));

  }

  public loadBanner(): void {

    this.appService
      .articleModel
      .getByPath({
        path: 'learn'
      })
      .then((article) => {

        if (article) {
          this.bannerArticle = article;
        } else {
          this.bannerArticle = null;
        }

      })
      .catch(() => {
        this.bannerArticle = null;
      });

  }

  public loadContent(): void {

    this.loadingContent = true;

    this.appService
      .vimeoModel
      .list()
      .then((videos: VimeoArticle[]): void => {

        if (Array.isArray(videos)) {

          this.articles = [];

          videos.forEach((video) => {

            this.articles.push({
              id: video.id,
              type: 'video',
              title: video.name,
              description: video.description,
              categories: video.tags,
              tileImgUrl: video.thumbnail,
              created: video.created
            });

          });

        } else {
          this.articles = [];
        }

        this.loadingContent = false;
        this.resetFilter();
      })
      .catch(() => {
        this.articles = [];
        this.loadingContent = false;
        this.resetFilter();
      });

  }

  public backgroundImageStyle(article: Article): SafeStyle {
    let url = article.tileImgUrl || article.bannerImgUrl;

    url = this.appService.exio.image.getUrl(url, {
      format: 'jpg',
      actions: [
        {
          type: 'shrink',
          width: 300
        },
        {
          type: 'quality',
          quality: 100
        }
      ]
    });

    return this.sanitizer.bypassSecurityTrustStyle(`url('${url}')`);
  }

  public go(article: Article): void {
    this.videoService.load({videoId: article.id});
    this.videoService.play();
  }

  public is(article: Article, category: string): boolean {

    if (category === '*') {
      return true;
    }

    if (!article || !Array.isArray(article.categories)) {
      return false;
    }

    return article.categories.indexOf(category) > -1;
  }

  public getArticles(): Article[] {

    let sort = this.sortField === 'none' ? 'newest' : this.sortField;

    return this.articles.filter((article) => {
      return this.is(article, this.filterCategory);
    })
      .sort((a: Article, b: Article): number => {

        switch (sort) {
          case 'newest':
            return moment(b.created).valueOf() - moment(a.created).valueOf();
          case 'oldest':
            return moment(a.created).valueOf() - moment(b.created).valueOf();
          default:
            if (a.title < b.title) {
              return -1;
            }
            if (a.title > b.title) {
              return 1;
            }
            return 0;
        }

      });

  }

  public resetFilter(): void {

    let fixCategory = (str: string): string => {

      if (typeof str !== 'string') {
        return '';
      }

      let splitStr = str.toLowerCase().split(/[ \-]/);
      for (let i = 0; i < splitStr.length; i++) {
        splitStr[i] = splitStr[i].charAt(0).toUpperCase() + splitStr[i].substring(1);
      }
      // Directly return the joined string
      str = splitStr.join(' ');

      return str;

    };

    let categories = {};

    this.articles.forEach((article) => {

      if (article && Array.isArray(article.categories)) {
        article.categories.forEach((category) => {

          if (typeof category !== 'string' || category.toLowerCase() === 'blog') {
            return;
          }

          categories[category] = fixCategory(category);

        });
      }

    });

    let filterOptions = Object.keys(categories).sort();
    this.filterOptions = [
      ['*', 'All Topics']
    ];
    filterOptions.forEach((option) => {
      this.filterOptions.push([option, categories[option]]);
    });

  }

  public articleCountText(): string {

    let count = 0;

    this.getArticles().forEach((article) => {

      if (article.id) {
        count++;
      }

    });

    return `${count} Video${count !== 1 ? 's' : ''}`;

  }

  public hasSubscribeEmail(): boolean {

    let email = this.getSubscribeEmail();
    return typeof email === 'string' && !!email.match(/(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/);

  }

  public getSubscribeEmail(): string {

    let email = null;

    if (this.profile && this.profile.email) {
      email = this.profile.email;
    } else {
      email = this.subscribeEmail;
    }

    return email;

  }

  public subscribe(): void {

    if (!this.hasSubscribeEmail()) {
      return;
    }

    let always = () => {
      this.appService.exio.user.note({
        firstName: 'Anonymous',
        lastName: 'User',
        email: this.getSubscribeEmail(),
        subject: 'Subscribe to Blog Posts',
        comments: 'Please subscribe me to the blog'
      })
        .then(() => {
          this.subscribeComplete = true;
        })
        .catch(() => {
          this.subscribeComplete = true;
        });
    };

    if (this.profile) {
      this.appService.profileModel.save(this.profile)
        .then(always)
        .catch(always);
    } else {
      always();
    }

  }

}
