import {ChatWorkFlow} from 'app/services/chat'

export const chatDefLoggedOut: ChatWorkFlow = {
  authorEmail: 'doug.miller@goexio.com',
  pages: [
    {
      title: 'Login Expired',
      items: [
        {
          type: 'paragraph',
          params: {
            content: 'For your security, you have been logged out.'
          }
        },
        {
          type: 'paragraph',
          params: {
            content: 'Do you want us to email you another login link?'
          }
        },
        {
          type: 'emailLogin'
        }
      ]
    },
    {
      title: 'Email Sent',
      items: [
        {
          type: 'paragraph',
          params: {
            content: 'The link in your email will be good for **15 minutes**.'
          }
        },
        {
          type: 'paragraph',
          params: {
            content: 'If you do not use the link within 15 minutes, you can generate a new link by clicking the expired link in the email.'
          }
        },
        {
          type: 'paragraph',
          params: {
            content: 'Please keep the link in the email **secure**.'
          }
        },
        {
          type: 'paragraph',
          params: {
            content: 'Treat the link like a **password**.'
          }
        }
      ],
      continueText: 'OK',
      continueDisabled: false
    }
  ]
};
