import { ChatWorkFlow } from 'app/services/chat'

export const chatDefBuyerPublicCommon: ChatWorkFlow = {
  authorEmail: 'doug.miller@goexio.com',
  pages: [
    {
      title: 'Get Access',
      items: [
        {
          type: 'paragraph',
          params: {
            content: 'In order to get private access to this listing you will need to agree to the terms of the listing’s non-disclosure agreement.'
          }
        },
        {
          type: 'paragraph',
          params: {
            content: 'Click below to review the agreement and submit your request for access.'
          }
        }
      ],
      continueText: 'Review NDA',
      continueDisabled: false
    },
    {
      title: 'Sign NDA',
      items: [
        {
          type: 'paragraph',
          params: {
            content: '#Non-Disclosure Agreement'
          }
        },
        {
          type: 'contract',
          params: {
            path: 'non-disclosure-agreement'
          }
        }
      ],
      continueText: 'Send Email Verification',
      continueDisabled: false
    },
    {
      title: 'Check Your Email',
      items: [
        {
          type: 'paragraph',
          params: {
            content: 'Your request is not yet complete.\n'
          }
        },
        {
          type: 'paragraph',
          params: {
            content: 'You must click the link in the verification email in order to finish submitting your request.'
          }
        }
      ],
      continueText: 'I Will Check My Email',
      continueDisabled: false
    }
  ]
};
