import { Component, OnInit, OnDestroy, NgZone, ElementRef, Input } from '@angular/core';

import { AppService } from 'app/services/app';

type MenuSectionItem = {
	name: string;
	url: string;
};
type MenuSection = {
	name: string;
	items: MenuSectionItem[];
};
type MenuSections = MenuSection[];

@Component( {
	moduleId: module.id,
	selector: 'app-footer',
	templateUrl: 'footer.component.html',
	styleUrls: [ 'footer.component.css' ]
} )
export class MainFooterComponent {

	public copyRightYear: number = new Date().getFullYear();

	public menuItems: MenuSections = [
		{
			name: 'Marketplace',
			items: [
				{
					name: 'Private Sellers',
					url: '/marketplace/private-sellers'
				},
				{
					name: 'Premier Buyers',
					url: '/marketplace/premier-buyers'
				},
				{
					name: 'M&A Advisors',
					url: '/marketplace/advisors'
				}
			]
		},
		{
			name: 'Services',
			items: [
				{
					name: 'Sell Your Company',
					url: '/selling'
				},
				{
					name: 'Buy A Company',
					url: '/buying'
				},
				// {
				// 	name: 'Grow Your Company',
				// 	url: '/incubator'
				// }
			]
		},
		{
			name: 'Learn',
			items: [
				{
					name: 'About Exio',
					url: '/learn'
				},
				{
					name: 'Leadership',
					url: '/leadership'
				},
				{
					name: 'Case Study',
					url: '/case-study'
				},
				{
					name: 'Video Library',
					url: '/video-library'
				},
				{
					name: 'Blog',
					url: '/blog'
				}
			]
		},
		// {
		// 	name: 'Partners',
		// 	items: [
		// 		{
		// 			name: 'Programs',
		// 			url: '/partner-program'
		// 		},
		// 		{
		// 			name: 'Our Partners',
		// 			url: '/our-partners'
		// 		}
		// 	]
		// },
		{
			name: 'Contact',
			items: [
				{
					name: 'Contact Us',
					url: '/contact'
				}
			]
		}
	];

	constructor( public app: AppService ) {

	}

	public getMenuItems(): MenuSections {

		return this.menuItems;

	}

}

@Component( {
	moduleId: module.id,
	selector: 'app-footer-border',
	templateUrl: 'footer.border.component.html',
	styleUrls: [ 'footer.border.component.css' ]
} )
export class MainFooterBorderComponent implements OnInit, OnDestroy {

	@Input()
	public top: number = null;

	@Input()
	public floatAfter: number = null;

	public floating: boolean = false;

	public $window = $( window );
	public $el: JQuery = null;

	constructor( public app: AppService, public zone: NgZone, public el: ElementRef ) {
		this.$el = $( el.nativeElement );
	}

	public scrollUp(): void {
		this.zone.runOutsideAngular( () => {

			let top = 0;

			if ( Number.isInteger( this.top ) &&
				 !Number.isNaN( this.top ) &&
				 this.top >= 0 ) {
				top = this.top;
			}

			this.zone.runOutsideAngular( () => {
				requestAnimationFrame( () => {
					$( 'html, body' ).stop().animate( { scrollTop: top }, 2000 );
				} );
			} );
		} );
	}

	ngOnInit() {
		this.zone.runOutsideAngular( () => {
			window.addEventListener( 'scroll', this.scroll, true );
		} );
	}

	ngOnDestroy() {
		this.zone.runOutsideAngular( () => {
			window.removeEventListener( 'scroll', this.scroll, true );
		} );
	}

	shouldFloat(): boolean {
		return Number.isInteger( this.floatAfter ) &&
			   !Number.isNaN( this.floatAfter );
	}

	isOnScreen(): boolean {

		let elementTop = this.$el.offset().top;
		let elementBottom = elementTop + this.$el.outerHeight();
		let viewportTop = this.$window.scrollTop();
		let viewportBottom = viewportTop + this.$window.height();

		return elementBottom > viewportTop && elementTop < viewportBottom;

	}

	float(): boolean {
		return this.shouldFloat() &&
			   this.floatAfter <= this.$window.scrollTop() &&
			   !this.isOnScreen();
	}

	setFloating( floating: boolean ): void {

		if ( this.floating === floating ) {
			return;
		}

		// console.log( 'toggle floating', floating );
		this.floating = floating;

		let $elBorder = this.$el.find( '.to-top.floater' );

		if ( floating ) {
			$elBorder.addClass( 'floating' );
			$elBorder.removeClass( 'un-floating' );
		} else {
			$elBorder.addClass( 'un-floating' );
			$elBorder.removeClass( 'floating' );
		}

	}

	checkFloat(): void {

		this.setFloating( this.float() );

	}

	scroll = (): void => {
		if ( this.shouldFloat() ) {

			this.zone.runOutsideAngular( () => {
				// console.log( 'scroll', 'floatAfter', this.floatAfter, 'isOnScreen', this.isOnScreen(), this.$window.scrollTop(), 'float', this.float() );
				this.checkFloat();
			} );
		}
	};

}
