import {Injectable} from '@angular/core';
import {ApiService} from 'app/services/api';

// Types
import {Hash, Cache} from 'app/types/containers';
// import { sError } from 'app/types/standard';
import * as Bluebird from 'bluebird';

interface SearchQuery {
  [type: string]: SearchQuery | number | string | null | boolean
}

export class GenericSchemaType {
  constructor(ref?: any) {

    if (ref) {

      for (const field in ref) {
        if (ref.hasOwnProperty(field)) {
          this[field] = ref[field];
        }
      }

    }

  }
}

export class GenericMasterSchemaType extends GenericSchemaType {
  public id?: string | null | undefined = null;

  constructor(ref?: any) {
    super(ref);

    if (ref.hasOwnProperty('id')) {
      this.id = ref.id;
    } else if (ref.hasOwnProperty('_id')) {
      this.id = ref._id;
    }

    if (this.hasOwnProperty('_id')) {
      delete (<any>this)._id;
    }

  }
}

export abstract class GenericModel<T extends GenericMasterSchemaType> {

  public api: ApiService;
  public defaultInstance: T;

  public name: string = null;

  public init(api: ApiService, name: string, defaultInstance: T, cacheTimeout: number) {

    this.api = api;
    this.name = name;
    this.defaultInstance = defaultInstance;

  }

  public newInstance(): T {
    return this.clone(this.defaultInstance);
  }

  public getVersions(id: string): Bluebird<T[]> {
    return this.api.call(`${this.name}.versionsById`, {id: id});
  }

  public get(id: string, noCache?: boolean): Bluebird<T> {

    return this.api
      .call(`${this.name}.byId`, {id: id})
      .then((instance: T) => {

        if (instance) {
          instance = this.getInstance(instance);
        } else {
          instance = null;
        }

        return instance;

      });

  }

  public query(query: SearchQuery = {}): Bluebird<T[]> {

    return this.api.call(`${this.name}.list`, query);

  }

  public save(instance: T): Bluebird<T> {
    return this.api.call(`${this.name}.save`, instance);
  }

  public delete(id: string): Bluebird<boolean> {

    return new Bluebird<boolean>((resolve, reject) => {
      this.api.call(`${this.name}.delete`, {id: id})
        .then(() => {
          resolve(true);
        })
        .catch(reject);

    });

  }

  public clone(instance: T): T {
    return JSON.parse(JSON.stringify(instance));
  }

  public abstract getInstance(instance: T): T;

}



