
<div class="window-toolbar"></div>
<div class="window-container">
  <div class="window-container-content">
    <div class="window-container-content-container">
      <div class="show-sent" *ngIf="sent">
        <div class="title">Email Sent</div>
        <div class="description">
          <p>Your email will arrive shortly.</p>
          <P>Remember, don't share the secure link in the email with others.</P>
        </div>
      </div>
      <div class="show-send" *ngIf="!sent">
        <div class="title">Login By Email</div>
        <div class="description">
          <p>To login by email please provide your email below and click 'Send Link'</p>
          <P>Links are only intended for use by the owner of the email address entered here.</P>
        </div>
        <label>Enter Your Email
          <input class="email" [(ngModel)]="email">
        </label>
        <div class="action-container">
          <button *ngIf="!sending" (click)="sendLink()" [class.active]="isValidEmail()">Send Link</button>
          <div class="loader-bar-container" *ngIf="sending">
            <div class="loader-bar"></div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>