import { Component, Input, Output, EventEmitter } from '@angular/core';

import { AppService } from 'app/services/app';

@Component( {
	moduleId: module.id,
	selector: '.input-type-select-icon',
	templateUrl: 'select-icon.component.html',
	styleUrls: [ 'select-icon.component.css' ]
} )
export class StandardInputSelectIconComponent {

	@Input()
	public icon: string = null;

	@Output()
	public iconChange: EventEmitter<string> = new EventEmitter<string>();

	public options: string[] = [
		'None',
		'briefcase',
		'camera',
		'check',
		'coins',
		'controls',
		'dollar-circle',
		'financials',
		'gear',
		'handshake',
		'heart-circle',
		'page',
		'paper-and-pencil',
		'planner',
		'profile-circle',
		'radio',
		'scale',
		'search-circle',
		'trophy',
		'video-play',
		'word-bubble'
	];

	constructor( public app: AppService ) {

	}

	public getClasses(): {} {
		let classes = {};

		if ( this.icon ) {
			classes[ this.icon ] = true;
		}

		return classes;
	}

	public displayName( value: string ): string {

		value = value.replace( /(-.)/g, function ( x ) {
			return ' ' + x[ 1 ].toUpperCase()
		} );

		value = value.replace( /^./, value[ 0 ].toUpperCase() );

		return value;

	}

	public iconChanged( $event: any ): void {

		if ( !$event ) {
			return;
		}

		this.iconChange.emit( $( $event.srcElement ).val() );
	}


}
