import { Component, OnInit, OnDestroy, Input } from '@angular/core';
import { AppService } from 'app/services/app';
import { Article } from 'app/models/article';
import { Location } from '@angular/common';
import { AnalyticsModel, AnalyticsActions, AnalyticsEvent } from 'app/models/analytics';
import { ProfileModel, Profile } from 'app/models/profile';
import * as async from 'async';
import * as moment from 'moment';

declare var require: any;

import * as Highcharts from 'highcharts/highmaps';

import { Subscription } from 'rxjs';
import { MatTableDataSource } from '@angular/material';

interface AnalyticsEventItem extends AnalyticsEvent {
  user?: Profile;
}

@Component( {
  moduleId: module.id,
  selector: 'app-view-listing-analytics',
  templateUrl: './analytics.component.pug',
  styleUrls: [ './analytics.component.less' ]
} )
export class ViewsListingManagementAnalyticsComponent implements OnInit, OnDestroy {

  // configure Highcharts
  public Highcharts = Highcharts;
  public chartUpdate = false;
  public chartOptions = {
    chart: {
      events: {
        load: ( $event: Event ) => {
          this.chart = <Highcharts.Chart>(<any>$event).target;
        }
      }
    },
    title: {
      text: 'Activity by State'
    },
    subtitle: {
      text: '',
      floating: true,
      align: 'right',
      y: 50,
      style: {
        fontSize: '16px'
      }
    },
    legend: {
      layout: 'vertical',
      align: 'right',
      verticalAlign: 'middle'
    },
    colorAxis: {
      min: 0,
      max: 100,
      minColor: '#E6E7E8',
      maxColor: '#4797EE',
      labels: {
        format: '{value}%'
      }
    },
    mapNavigation: {
      enabled: false
    },
    plotOptions: {
      map: {
        states: {
          hover: {
            color: '#FFB835'
          }
        }
      }
    },
    tooltip: {
      headerFormat: '{point.key}<br/>',
      pointFormat: '{point.tooltipValue}'
    },
    series: [
      {
        data: (<any>Highcharts).geojson( require( '@highcharts/map-collection/countries/us/us-all.geo.json' ) ),
        name: 'Activity',
        dataLabels: {
          enabled: true,
          format: '{point.value}%'
        }
      },
      {
        type: 'mapline',
        data: (<any>Highcharts).geojson( require( '@highcharts/map-collection/countries/us/us-all.geo.json' ), 'mapline' ),
        color: 'silver',
        enableMouseTracking: false
      }
    ],
    drilldown: {
      activeDataLabelStyle: {
        color: '#FFFFFF',
        textDecoration: 'none',
        textOutline: '1px #000000'
      },
      drillUpButton: {
        relativeTo: 'spacingBox',
        position: {
          x: 0,
          y: 60
        }
      }
    }
  };

  public nextRequest = 0;
  public loadingContent = false;
  public lastUpdateTimeStamp: Date = null;

  @Input()
  public article: Article = null;

  public subscriptions: Subscription[] = [];

  public authenticatedProfile: Profile = null;

  public chart: Highcharts.Chart = null;

  public lastMapData = { count: {} };
  public lastEventList: MatTableDataSource<AnalyticsEventItem> = new MatTableDataSource<AnalyticsEventItem>( [] );

  public months = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December'
  ];

  public years = [];

  public activityTypes = [
    {
      value: 'pageView',
      display: 'Page View'
    },
    {
      value: 'signedNDA',
      display: 'Signed NDA'
    },
    // {
    //   value: 'signedLOI',
    //   display: 'Signed LOI'
    // },
    // {
    //   value: 'published',
    //   display: 'Published'
    // },
    // {
    //   value: 'unpublished',
    //   display: 'Unpublished'
    // },
    {
      value: 'upgradeRequestPrivate',
      display: 'Upgrade Request Private'
    },
    {
      value: 'upgradeRequestExclusive',
      display: 'Upgrade Request Exclusive'
    }
  ];

  public displayType: 'percent' | 'count' = 'percent';
  public filter: {
    url: string;
    month: number;
    year: number;
    activityTypes: AnalyticsActions[]
  } = {
    url: null,
    month: null,
    year: null,
    activityTypes: [
      'pageView',
      'signedNDA',
      'signedLOI',
      'upgradeRequestPrivate',
      'upgradeRequestExclusive'
    ]
  };

  public applyFilterTimeout: number = null;

  constructor( public appService: AppService,
               public analytics: AnalyticsModel,
               public profileModel: ProfileModel,
               public location: Location ) {
  }

  public ngOnInit(): void {

    const today = moment();

    this.filter.month = today.month();
    this.filter.year = today.year();

    this.years = [];
    for ( let i = this.filter.year; i >= 2010; i-- ) {
      this.years.push( i );
    }

    if ( this.article ) {

      this.filter.url = typeof this.article.path === 'string' ?
                        this.article.path.trim().replace( /^\/?/, '/' ) :
                        null;
    }

    this.subscriptions.push( this.appService.getAuthenticatedProfile( {
      next: ( profile ) => {

        if ( profile ) {
          this.authenticatedProfile = profile;
        } else {
          this.authenticatedProfile = null;
        }

      },
      error: () => {
        // NO-OP
      },
      complete: () => {
        // NO-OP
      }
    } ) );

    // init data
    this.applyFilter();

  }

  public ngOnDestroy(): void {

    if ( this.applyFilterTimeout ) {
      window.clearTimeout( this.applyFilterTimeout );
      this.applyFilterTimeout = null;
    }

    this.subscriptions.forEach( ( subscription ) => {
      subscription.unsubscribe();
    } );

  }

  public applyFilter(): void {

    // if already loading content, wait 1,000ms and try again
    if ( this.loadingContent ) {
      window.setTimeout( () => {
        this.applyFilter()
      }, 1000 );
      return;
    }

    this.loadingContent = true;

    if ( this.applyFilterTimeout ) {
      window.clearTimeout( this.applyFilterTimeout );
      this.applyFilterTimeout = null;
    }

    const myRequestId = ++this.nextRequest;

    async.waterfall( [
      ( done ) => {

        const today = moment();

        // don't let user select month in the future if selected year is current year
        if ( this.filter.month > today.month() &&
             this.filter.year >= today.year() ) {

          this.filter.year = today.year() - 1;

        }

        const month = this.filter.month + 1; // convert 0 indexed to 1 indexed
        const year = this.filter.year;

        const currentMonth = moment( `${year}-${month}`, 'YYYY-M' );
        const startTime = moment( currentMonth ).startOf( 'month' ).startOf( 'day' );
        const endTime = moment( currentMonth ).endOf( 'month' ).endOf( 'day' );

        const filter = {
          bool: {
            must: [
              {
                term: {
                  url: this.filter.url
                }
              },
              {
                range: {
                  timestamp: {
                    gte: startTime.toISOString(),
                    lte: endTime.toISOString()
                  }
                }
              }
            ],
            should: []
          }
        };

        if ( this.filter.activityTypes.length > 0 ) {
          filter.bool.should.push( {
            terms: {
              action: this.filter.activityTypes
            }
          } )
        } else {
          delete filter.bool.should
        }

        async.parallel( {
          mapData: ( done ) => {

            this.analytics
              .aggregateEvents( {
                filter: filter,
                aggregation: {
                  count: {
                    terms: {
                      field: 'state',
                      size: 100
                    }
                  }
                }
              } )
              .asCallback( done );

          },
          eventList: ( done ) => {

            this.analytics
              .getEvents( {
                sort: [
                  {
                    timestamp: 'desc'
                  }
                ],
                from: 0,
                size: 10000,
                filter: filter
              } ).asCallback( done );

          }
        }, done );

      },
      ( data, done ) => {

        async.eachLimit( <AnalyticsEventItem[]>data.eventList, 5, ( event, done ) => {

          // anonymous users have userIds that are just session_ + 'session ID'
          if ( typeof event.userId === 'string' && event.userId.match( /^session_/ ) ) {

            event.user = <Profile>{
              id: event.userId,
              email: null
            };

            return done();

          }

          this.profileModel
            .get( event.userId, false )
            .asCallback( ( e, user ) => {

              if ( e ) {
                return done( e );
              }

              if ( user && user.email ) {
                event.user = user;
              } else {
                event.user = <Profile>{
                  id: event.userId,
                  email: null
                };
              }

              done();

            } );

        }, ( e ) => {

          if ( e ) {
            return done( e );
          }

          return done( null, data );

        } );

      },
      ( data, done ) => {

        if ( this.nextRequest > myRequestId ) {
          // if another request started before this one finished, skip processing this one.
          return done();
        }

        // update data models
        this.lastMapData = data.mapData;
        this.lastEventList.data = data.eventList;
        this.lastUpdateTimeStamp = moment().toDate();

        // update UI from data models
        this.renderChart();

        done();
      }
    ], ( e ) => {

      this.loadingContent = false;
      if ( e ) {
        console.error( 'error updating filter', e );
      }

      // ensure only one timeout waiting to auto update filter
      if ( this.applyFilterTimeout ) {
        window.clearTimeout( this.applyFilterTimeout );
        this.applyFilterTimeout = null;
      }
      this.applyFilterTimeout = window.setTimeout( () => {
        this.applyFilter()
      }, 900000 );

    } );

  }

  public renderChart(): void {

    const counts = this.lastMapData.count || {};

    let total = 0;
    let max = 0;
    this.chartOptions.series[ 0 ].data.forEach( ( entry ) => {

      const state = entry.properties[ 'postal-code' ];

      entry.drilldown = entry.properties[ 'hc-key' ];

      entry.rawValue = 0;
      if ( counts.hasOwnProperty( state ) ) {
        entry.rawValue = counts[ state ];
      }

      if ( entry.rawValue > max ) {
        max = entry.rawValue;
      }
      total += entry.rawValue;

    } );

    this.chartOptions.series[ 0 ].data.forEach( ( entry ) => {

      const percent = total > 0 ? Math.round( entry.rawValue / total * 1000 ) / 10 : 0;
      const count = entry.rawValue;

      if ( this.displayType === 'count' ) {
        entry.value = count;
        entry.tooltipValue = percent + '%';
      } else {
        entry.value = percent;
        entry.tooltipValue = 'Count: ' + count;
      }

    } );

    if ( this.displayType === 'count' ) {
      if ( max === 0 ) {
        max = 10;
      }
      (<any>this.chartOptions.series[ 0 ]).dataLabels.format = '{point.value}';
      this.chartOptions.colorAxis.labels.format = '{value}';
      this.chartOptions.colorAxis.max = max;
      // this.chartOptions.tooltip.pointFormat = '{point.tooltipValue}';
    } else {
      (<any>this.chartOptions.series[ 0 ]).dataLabels.format = '{point.value}%';
      this.chartOptions.colorAxis.labels.format = '{value}%';
      this.chartOptions.colorAxis.max = 100;
      // this.chartOptions.tooltip.pointFormat = '{point.tooltipValue}';
    }

    // this.chartOptions = JSON.parse( JSON.stringify( this.chartOptions ) ); // clone to trigger dirty check that redraws map
    this.chartUpdate = true;

    // There is a bug with highcharts not seeing the correct size after rendering new data, we force it to the correct size
    window.setTimeout( () => {
      this.chart.setSize( 800, 400 );
    }, );

  }

  public exioUser(): boolean {
    return !!this.authenticatedProfile &&
           typeof this.authenticatedProfile.email === 'string' &&
           !!this.authenticatedProfile.email.match( /@goexio\.com$/ );
  }

  public thisMonth(): void {

    const today = moment();

    this.filter.month = today.month();
    this.filter.year = today.year();

    this.applyFilter();

  }

  public previousMonth(): void {

    this.filter.month--;

    if ( this.filter.month < 0 ) {

      // go to december of previous year
      if ( this.filter.year > 2010 ) {
        this.filter.month = 11;
        this.filter.year--;
      } else {
        // already at the oldest year we allow, put month back
        this.filter.month++;
      }

    }

    this.applyFilter();

  }

  public nextMonth(): void {

    this.filter.month++;

    if ( this.filter.month > 11 ) {

      // go to january of next year
      this.filter.month = 0;
      this.filter.year++;

    }

    this.applyFilter();

  }

  public isOldestMonth(): boolean {
    return this.filter.month === 0 &&
           this.filter.year === 2010;
  }

  public isCurrentMonth(): boolean {

    const today = moment();

    return this.filter.month === today.month() &&
           this.filter.year === today.year();

  }

  public disableYear( year: number ): boolean {

    const today = moment();

    return this.filter.month > today.month() &&
           year >= today.year();

  }

  public openProfile( id: string ): void {

    if ( !id || id.match( /^session_/ ) ) {
      return;
    }

    const url = this.location.prepareExternalUrl( '/profile/' + id );

    window.open( url, '_blank' );

  }

}
