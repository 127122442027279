/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./documents.component.less.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../loader.bar.component.ngfactory";
import * as i3 from "../loader.bar.component";
import * as i4 from "@angular/common";
import * as i5 from "./documents.component";
import * as i6 from "../../../services/app";
var styles_ContentRenderDocumentsComponent = [i0.styles];
var RenderType_ContentRenderDocumentsComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_ContentRenderDocumentsComponent, data: {} });
export { RenderType_ContentRenderDocumentsComponent as RenderType_ContentRenderDocumentsComponent };
function View_ContentRenderDocumentsComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "div", [["class", "document-field document-field-actions"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "div", [["class", "locked-icon"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 0, "div", [["class", "component-icon lock-gray"]], null, null, null, null, null))], null, null); }
function View_ContentRenderDocumentsComponent_5(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "div", [["class", "action action-view"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["View"]))], null, null); }
function View_ContentRenderDocumentsComponent_6(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "div", [["class", "action-loading"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "div", [["class", "loader-bar"]], null, null, null, i2.View_ContentLoaderBarComponent_0, i2.RenderType_ContentLoaderBarComponent)), i1.ɵdid(2, 49152, null, 0, i3.ContentLoaderBarComponent, [], null, null)], null, null); }
function View_ContentRenderDocumentsComponent_4(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, "div", [["class", "document-field document-field-actions"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ContentRenderDocumentsComponent_5)), i1.ɵdid(2, 16384, null, 0, i4.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ContentRenderDocumentsComponent_6)), i1.ɵdid(4, 16384, null, 0, i4.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = !_co.isLoading(_v.parent.context.$implicit); _ck(_v, 2, 0, currVal_0); var currVal_1 = _co.isLoading(_v.parent.context.$implicit); _ck(_v, 4, 0, currVal_1); }, null); }
function View_ContentRenderDocumentsComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 6, "div", [["class", "document"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.openDocument(_v.context.$implicit) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "div", [["class", "document-field document-field-name"]], null, null, null, null, null)), (_l()(), i1.ɵted(2, null, ["", ""])), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ContentRenderDocumentsComponent_3)), i1.ɵdid(4, 16384, null, 0, i4.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ContentRenderDocumentsComponent_4)), i1.ɵdid(6, 16384, null, 0, i4.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var currVal_1 = (_v.context.$implicit && _v.context.$implicit.locked); _ck(_v, 4, 0, currVal_1); var currVal_2 = (_v.context.$implicit && !_v.context.$implicit.locked); _ck(_v, 6, 0, currVal_2); }, function (_ck, _v) { var currVal_0 = _v.context.$implicit.name; _ck(_v, 2, 0, currVal_0); }); }
function View_ContentRenderDocumentsComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 8, "div", [["class", "documents-container"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 0, "div", [["class", "section-anchor"]], [[1, "data-section-name", 0]], null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 1, "h2", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Diligence Documents"])), (_l()(), i1.ɵeld(4, 0, null, null, 1, "div", [["class", "table-label"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Name"])), (_l()(), i1.ɵeld(6, 0, null, null, 2, "div", [["class", "documents"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ContentRenderDocumentsComponent_2)), i1.ɵdid(8, 278528, null, 0, i4.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_1 = _co.files; _ck(_v, 8, 0, currVal_1); }, function (_ck, _v) { var currVal_0 = "Diligence\nDocuments"; _ck(_v, 1, 0, currVal_0); }); }
export function View_ContentRenderDocumentsComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵand(16777216, null, null, 1, null, View_ContentRenderDocumentsComponent_1)), i1.ɵdid(1, 16384, null, 0, i4.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = (_co.files.length > 0); _ck(_v, 1, 0, currVal_0); }, null); }
export function View_ContentRenderDocumentsComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-content-render-documents", [], null, null, null, View_ContentRenderDocumentsComponent_0, RenderType_ContentRenderDocumentsComponent)), i1.ɵdid(1, 114688, null, 0, i5.ContentRenderDocumentsComponent, [i6.AppService, i1.ChangeDetectorRef], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var ContentRenderDocumentsComponentNgFactory = i1.ɵccf("app-content-render-documents", i5.ContentRenderDocumentsComponent, View_ContentRenderDocumentsComponent_Host_0, { article: "article", lockedCallback: "lockedCallback" }, {}, []);
export { ContentRenderDocumentsComponentNgFactory as ContentRenderDocumentsComponentNgFactory };
