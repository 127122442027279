// ViewsPartnersEntryComponent

import { Component, OnInit, OnDestroy } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';

import { AppService } from 'app/services/app';
import { Profile } from 'app/models/profile';

type SortTypes = 'sortHint' | 'name' | 'position';

@Component( {
  moduleId: module.id,
  selector: 'app-view-learn-leadership-profile',
  templateUrl: 'leadership.profile.component.html',
  styleUrls: [ 'leadership.profile.component.css' ]
} )
export class ViewsLearnLeadershipProfileComponent implements OnInit, OnDestroy {

  public profile: Profile;
  public profileList: Profile[];
  public profileNext: Profile;

  public sortOrder: SortTypes = null;

  public subscriptions: any[] = [];

  constructor( public appService: AppService, public route: ActivatedRoute, public sanitizer: DomSanitizer ) {

    this.appService.contentLoading( true );
    this.appService.toolbar.whiteOverContent = false;
    this.appService.toolbar.backgroundColor = '#FFFFFF';

    this.appService.footer.hide = true;

    this.appService.titleService.setTitle( 'Exio - Profile' );
  }

  public ngOnDestroy(): void {
    this.subscriptions.forEach( ( subscription ) => {
      subscription.unsubscribe();
    } );
  }

  public ngOnInit(): void {

    document.body.scrollTop = 0;

    this.subscriptions.push( this.route.params.subscribe( ( params: { id: string, sort: SortTypes } ) => {
      this.applyParams( params );
    } ) );

  }

  public applyParams( params ): void {

    if ( !params || !params.id ) {
      this.profile = null;
      this.profileNext = null;
      // this.profilePrevious = null;
      return;
    }

    if ( params.sort ) {
      this.sortOrder = params.sort;
    }

    if ( this.profile && this.profile.id === params.id ) {
      return;
    }

    this.profile = null;
    this.profileNext = null;
    // this.profilePrevious = null;
    this.appService.contentLoading( true );
    this.appService.titleService.setTitle( 'Exio - Profile' );

    const gotProfile = ( profile: Profile ) => {

      this.appService.contentLoading( false );

      this.profile = profile;

      this.appService.titleService.setTitle( `Exio - Profile - ${profile.firstName} ${profile.lastName}` );

    };

    // this.sortOrder = this.sortOrder || 'name';

    if ( Array.isArray( this.profileList ) ) {

      this.profileList.forEach( ( profile ) => {

        if ( this.profile ) {
          return;
        }

        if ( profile && profile.id === params.id ) {
          gotProfile( profile );
        }

      } );

      this.calculateNextProfile( params.id );

    } else {

      this.appService
        .profileModel
        .listFeaturedEmployees()
        .then( ( profiles: Profile[] ) => {

          // this.appService.profileModel.listNetworkProfiles()
          // 	.then( ( profiles: Profile[] ) => {

          const sortOrder = this.sortOrder || 'sortHint';

          this.profileList = profiles
            .filter( ( profile ) => {
              return !!profile && !!profile.id && !!profile.headshotURL && !!profile.title && !!profile.firstName && !!profile.lastName;
            } )
            .sort( ( a: Profile, b: Profile ) => {

              if ( sortOrder === 'sortHint' ) {
                if ( a.sortHint === b.sortHint ) {
                  return 0;
                } else if ( a.sortHint === null ) {
                  return 1;
                } else if ( b.sortHint === null ) {
                  return -1;
                } else {
                  return a.sortHint - b.sortHint;
                }
              }

              let aVal = a.lastName + ', ' + a.firstName;
              let bVal = b.lastName + ', ' + b.firstName;

              if ( sortOrder === 'position' ) {
                aVal = a.title;
                bVal = b.title;
              }

              let result;

              if ( !aVal && !bVal ) {
                result = 0;
              } else if ( !aVal ) {
                result = 1;
              } else if ( !bVal ) {
                result = -1;
              } else {
                // sort aVal alphabetically before bVal
                result = aVal < bVal ? -1 : 1;
              }

              return result;

            } );

          this.calculateNextProfile( params.id );

        } )
        .catch( ( e ) => {
          console.error( 'profile query exception', e );
        } );

    }

    if ( this.profile === null ) {
      this.appService
        .profileModel
        .get( params.id )
        .then( gotProfile );
    }

  }

  public calculateNextProfile( id: string ): void {

    this.profileNext = null;
    let foundCurrent = false;
    this.profileList.forEach( ( profile, i ) => {

      // if we found one, stop looking for more
      if ( foundCurrent ) {
        return;
      }

      if ( profile.hasOwnProperty( 'id' ) && profile.id === id ) {
        foundCurrent = true;

        // if we are not the last, set next profile
        if ( i < this.profileList.length - 1 ) {
          this.profileNext = this.profileList[ i + 1 ];
        }

      }

    } );

  }

  public markdownToHtml( markdown: string ): SafeHtml {

    const decodeHtml = ( html ) => {
      const txt = document.createElement( 'textarea' );
      txt.innerHTML = html;
      return txt.value;
    };

    return this.sanitizer.bypassSecurityTrustHtml( this.appService.markdownToHtml( decodeHtml( markdown ) ) );

  }

  public imgUrl( url: string ): string {

    url = this.appService.exio.image.getUrl( url, {
      format: 'jpg',
      actions: [
        {
          type: 'shrink',
          width: 300
        },
        {
          type: 'quality',
          quality: 100
        }
      ]
    } );

    return `url("${url}")`;
  }

  public hasAddress(): boolean {
    return !!this.profile && !!this.profile.address &&
           typeof this.profile.address.city === 'string' &&
           this.profile.address.city.trim().length > 0 &&
           typeof this.profile.address.state === 'string' &&
           this.profile.address.state.trim().length > 0;
  }

  public nextLink( profileNext: Profile ): any[] {
    const link: any[] = [ '/learn/leadership/' + profileNext.id ];

    if ( this.sortOrder && this.sortOrder !== 'sortHint' ) {
      link.push( { sort: this.sortOrder } );
    }

    return link;
  }

}
