import * as tslib_1 from "tslib";
import { ApiService } from 'app/services/api';
// Types
import { GenericModel } from './generic';
var AnalyticsModel = /** @class */ (function (_super) {
    tslib_1.__extends(AnalyticsModel, _super);
    function AnalyticsModel(api) {
        var _this = _super.call(this) || this;
        _this.init(api, 'analytics', {
            action: null,
            city: null,
            countryCode: null,
            id: null,
            ip: null,
            latitude: null,
            longitude: null,
            state: null,
            timestamp: null,
            url: null,
            userId: null,
            zipCode: null,
            meta: null
        }, 5000);
        return _this;
    }
    AnalyticsModel.prototype.logEvent = function (event) {
        if (!event.url) {
            event.url = window.location.pathname;
        }
        return this.api.call('analytics.logEvent', event);
    };
    AnalyticsModel.prototype.getEvents = function (params) {
        return this.api.call('analytics.getEvents', params);
    };
    AnalyticsModel.prototype.aggregateEvents = function (params) {
        return this.api.call('analytics.aggregateEvents', params);
    };
    AnalyticsModel.prototype.getInstance = function (instance) {
        return instance; // NO-OP
    };
    return AnalyticsModel;
}(GenericModel));
export { AnalyticsModel };
