import {Component, OnInit} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {Location} from '@angular/common';

import {AppService} from 'app/services/app';
import {Article} from 'app/models/article';
// import { Article as VimeoArticle } from 'app/models/vimeo';

// type TileTypes = Article | VimeoArticle | null;

@Component({
  moduleId: module.id,
  selector: 'app-view-home-main',
  templateUrl: 'main.component.pug',
  styleUrls: ['main.component.less']
})
export class ViewsHomeMainComponent implements OnInit {


  public carousel: Article[] = [];
  public assertions: Article[] = [];
  public loadingCount: number = 0;

  constructor(public appService: AppService, public route: ActivatedRoute, public location: Location) {

    this.appService.contentLoading(true);
    this.appService.toolbar.whiteOverContent = true;
    this.appService.toolbar.backgroundColor = null;

  }

  ngOnInit(): void {

    document.body.scrollTop = 0;
    this.loadContent(['homepage-carousel', 'homepage carousel'], 'carousel');
    this.loadContent(['assertions'], 'assertions');

  }

  public loadContent(categories: string[], field: string): void {

    const always = () => {

      this.loadingCount--;

      if (this.loadingCount <= 0) {
        this.loadingCount = 0;
        this.appService.contentLoading(false);
      }

    };

    this.loadingCount++;
    this.appService.contentLoading(true);

    this.appService
      .articleModel
      .search({
        query: {
          categories: categories
        }
      })
      .then((result) => {

        this[field] = result.articles;

        always();

      })
      .catch(always);

  }

}
